<template>
  <section id="add-report-view" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
    <div class="reports-title-header">
      <h4 class="report-title" v-if="getPatientResults">
        Patient Results Edit({{ getPatientResults.barcode }})
      </h4>
      <h4 class="report-title">Cytogenetics- Patient Report Data</h4>
      <div class="display-end">
        <el-button @click="goToReports" class="back-btn">
          <el-icon class="el-icon-back">
            <Back />
          </el-icon>Back</el-button>
      </div>
    </div>
    <div class="inner-navbar">
      <el-row :gutter="10">
        <el-col :span="8">
          <div>
            <el-autocomplete v-model="searchVisitId" :fetch-suggestions="getAutoCompletePatientsData"
              placeholder="Search VisitID, Patient Name,UHID" :clearable="true" :trigger-on-focus="false" class="el-input"
              @select="handleSelect" @clear="clearNameSearch"></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="8">
          <el-select v-model="testId" placeholder="Select Test Category " :clearable="true" filterable
            @change="fillSampleType">
            <el-option v-for="report in categoryReportTypes" :key="report"
              :label="report.ItemName + '   --  ' + report.TestId" :value="report.TestId"></el-option>
          </el-select>

          <!-- <FormError errorName="report_type"></FormError> -->
        </el-col>
        <el-col :span="8">
          <el-select v-model="reportType" placeholder="Select Report Type *" :clearable="true" filterable
            @clear="clearFields" @change="onChangeReportType()">
            <el-option v-for="(report, index) in reportTypesdata" :key="index" :label="report.label"
              :value="report.value"></el-option>
          </el-select>

          <FormError errorName="report_type"></FormError>
        </el-col>

      </el-row>
      <hr />
      <el-row :gutter="10">
        <el-col :span="8">
          <el-select v-model="patientInfo.department" allow-create placeholder="Select Department" :clearable="true"
            disabled filterable>
            <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"></el-option>
          </el-select>

          <FormError errorName="department"></FormError>
        </el-col>


        <el-col :span="8">
          <el-select v-model="patientInfo.report_template_id" placeholder="Select Report Template" :clearable="true"
            filterable @clear="askRemoveConfirmation" @change="updateTemplateData">
            <el-option v-for="(template, index) in templates" :key="index" :label="template.template_title"
              :value="template._id"></el-option>
          </el-select>

          <FormError errorName="report_template_id"></FormError>
        </el-col>

      </el-row>
    </div>

    <div class="inner">
      <span class="report-header">DEPARTMENT OF CYTOGENETICS</span>
      <div class="patient-information">
        <el-form :model="patientInfo">
          <el-row class="mb-10">
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Patient Name" class="mb-05 form-mandatory-field">
                <el-input v-model="patientInfo.patient_name" placeholder="Enter Patient Name" readonly></el-input>
              </el-form-item>
              <FormError errorName="patient_name"></FormError>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Visit Id">
                <el-input v-model="patientInfo.visit_id" placeholder="Enter Visit Id" readonly></el-input>
                <FormError errorName="visit_id"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Barcode No" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.barcode_no" placeholder="Enter Barcode No" readonly></el-input>
                <FormError errorName="barcode_no"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.ref_doctor" placeholder="Enter Reference Doctor" readonly></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.uhid" placeholder="Enter UHID No" readonly></el-input>
                <FormError errorName="uhid"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Registration Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.registration_date" type="date" placeholder="Pick a Date"
                  format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="registration_date"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Name" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_name" placeholder="Enter Client Name" readonly></el-input>
                <FormError errorName="client_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Gender" class="mb-05 mandatory-field">
                <div class="gender-select">
                  <el-select v-model="patientInfo.gender" placeholder="Select Gender" disabled>
                    <el-option v-for="(gender, index) in genderTypes" :key="index" :label="gender"
                      :value="gender"></el-option>
                  </el-select>
                  <FormError errorName="gender"></FormError>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Collected Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.collected_date" type="date" placeholder="Pick a Date"
                  format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="collected_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Code" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_code" placeholder="Enter Client Code" readonly></el-input>
                <FormError errorName="client_code"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.hospital_name" placeholder="Enter Hospital Name" readonly></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Received Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.received_date" type="date" placeholder="Pick a Date"
                  format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="received_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Address" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_add" placeholder="Enter Client Address" readonly></el-input>
                <FormError errorName="client_add"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Age" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.age" placeholder="Enter Age" readonly></el-input>
                <FormError errorName="age"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Mobile" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.mobile" placeholder="Enter Mobile Number" readonly></el-input>
                <FormError errorName="mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Phone" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.alternate_mobile" placeholder="Enter Phone Number" readonly></el-input>
                <FormError errorName="alternate_mobile"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Email" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.email" placeholder="Enter Email" readonly></el-input>
                <FormError errorName="email"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Branch" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.locality" placeholder="Enter Branch" readonly></el-input>
                <FormError errorName="locality"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.sample_type" placeholder="Enter Sample Type" readonly></el-input>
                <FormError errorName="sample_type"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                <el-date-picker v-model="patientInfo.reported_date" type="date" placeholder="Pick a Date"
                  format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
                </el-date-picker>
                <FormError errorName="reported_date"></FormError>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
      </div>
      <div>
        <div v-if="reportType == 'KARYOTYPING'">
          <div class="edit-box mb-5">
            <span class="report-header">CHROMOSOMAL ANALYSIS-KARYOTYPING</span>
          </div>
          <!-- Karyotyping Report Static Code Start -->
          <div class="double-marker">
            <div class="edit-box mb-20 edit-box-table">
              <div class="inside-table">
                <el-form :model="testResultsSummary">
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Sample Type" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeSample_Type"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Quality of Sample" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeQualityOfSample"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Yoda Cytogenetics Number" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.CytogeneticsNumber"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Clinical Indication" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeClinicalIndication"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Test Requested" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeTestRequested"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Test Methodology" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeTestMethodology"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="No of cells counted" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.NoOfCellsCounted"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="No of cells Karyotyped " readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.NoOfCellsKaryotyped"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Estimated band resolution" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.EstimatedBandResolution"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Banding method" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.BandingMethod"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="ISCN" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.ISCN"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                </el-form>
              </div>
            </div>


            <div class="editor" v-if="show" v-loading="loadingEditor">
              <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="editorData" :config="editorConfig">
              </ckeditor>
            </div>

            <div class="edit-box mb-20">
              <span class="title">Karyotype:</span>
              <div class="position-relative">
                <el-select class="p-10 mw-250" v-model="patientInfo.karyotype" placeholder="Select Karyotype *"
                  ref="intendedUseField" :clearable="true" filterable @clear="clearFields" @change="onChangeKaryoType()">
                  <el-option v-for="(report, index) in karyoTypeISCNDtails" :key="index" :label="report.Karyotype"
                    :value="report.ISCN_Number"></el-option>
                </el-select>
                <el-buttton @click="dialogFormVisible = true; addModal()" class="addButtonIcon" v-if="!patientInfo.karyotype">
                  <el-icon :size="24">
                   <CirclePlus />
                  </el-icon>
                </el-buttton>
                <el-buttton @click="dialogFormVisible = true; editISCNDetails()" class="addButtonIcon" v-else>
                  <el-icon :size="24">
                   <Edit />
                  </el-icon>
                </el-buttton>
                <el-dialog v-model="dialogFormVisible" :title="patientInfo.karyotype?'Edit Karyotype':'Add Karyotype'" width="500">
                  <el-form :model="form" :label-position="labelPosition">
                    <el-form-item label="ISCN number" :label-width="formLabelWidth" required>
                      <el-input v-model="karyotypeISCNDetails.ISCN_Number" autocomplete="off"
                        placeholder="Enter ISCN number" @blur="!patientInfo.karyotype?handleBlur('ISCN_Number'):''" />
                      <div class="text"
                        v-if="isFieldTouched('ISCN_Number') && (karyotypeISCNDetails.ISCN_Number === '' || this.ISCNDuplicateCheck.length > 0)">
                        <span>{{ karyotypeISCNDetails.ISCN_Number === '' ? 'ISCN number is required.' :
                          karyotypeISCNDetails.ISCN_Number ? 'ISCN number already exists.' : '' }}</span>
                      </div>
                    </el-form-item>
                    <el-form-item label="Interpretation" :label-width="formLabelWidth" required>
                      <el-input v-model="karyotypeISCNDetails.Interpretation" autocomplete="off" type="textarea" autosize
                        placeholder="Enter Interpretation" @blur="!patientInfo.karyotype?handleBlur('Interpretation'):''" />
                      <div class="text"
                        v-if="isFieldTouched('Interpretation') && karyotypeISCNDetails.Interpretation == ''">
                        <span>Interpretation is required.</span>
                      </div>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <div class="dialog-footer">
                      <el-button @click="dialogFormVisible = false; karyotypeISCNDetails = []">Cancel</el-button>
                      <el-button type="primary" @click="dialogFormVisible = false;this.patientInfo.karyotype?updateISCNDetails():saveISCNDetails()"
                        :disabled="karyotypeISCNDetails.ISCN_Number == '' || karyotypeISCNDetails.Interpretation === '' || ISCNDuplicateCheck.length > 0">
                        {{ patientInfo.karyotype?'Update':'Submit' }}
                      </el-button>
                    </div>
                  </template>
                </el-dialog>
              </div>
              <div class="text" style="padding: 0px 10px 4px;" v-if="checkFormError && patientInfo.karyotype == ''">
            <span>Karyotype is required.</span>
          </div>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Interpretation:</span>
              <el-input type="textarea"  autosize v-model="patientInfo.interpretation" rows="3" id="interpretation"></el-input>
              <p class="text" style="padding: 0px 10px 4px;" v-if="checkFormError && patientInfo.interpretation == ''">Interpretation is required.</p>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Recommendations:</span>
              <el-input type="textarea" autosize v-model="patientInfo.recommendations" rows="3" id="recommendations"></el-input>
            </div>
            <div class="edit-box mb-20">
              <span class="title">References:</span>
              <el-input type="textarea" autosize v-model="patientInfo.References" rows="3" id="References" style="font-size:10px;"></el-input>
            </div>
            <div class="edit-box">
              <span class="title">Disclaimer:</span>
              <el-input type="textarea" autosize v-model="patientInfo.Disclaimer" rows="3" id="Disclaimer" style="font-size:8px;"></el-input>
              <p class="text" style="padding: 0px 10px 4px" v-if="checkFormError && patientInfo.Disclaimer==''">Disclaimer is required.</p>
            </div>
          </div>
        </div>
        <!-- Karyotyping Report Static Code End -->
        <!-- Fish Report code start -->
        <div v-if="reportType == 'FISH' && patientInfo.report_template_id">

          <div class="edit-box my-5" style="margin-top: 10px;">
            <span class="report-header">CHROMOSOMAL ANALYSIS-FISH</span>
          </div>

          <!-- SOLIDFISH UI Start -->


          <div class="double-marker">
            <div class="edit-box mb-20">
              <span class="title">FLUORESCENCE IN SITU HYBRIDIZATION (FISH)[ISCN 2020]</span>
              <el-table border style="width: 100%" :data="fluorTableData">
                <el-table-column prop="sampletype	" label="Sample Type	" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Sample Type" v-model="scope.row.sampletype">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Clinical Indication	" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Clinical Indication" v-model="scope.row.clinicalindication">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="testrequested	" label="Test Requested	" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Test Requested" v-model="scope.row.testrequested">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="method" label="Method" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Method" v-model="scope.row.method">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
              </el-table>
              <el-table border style="width: 100%" :data="fluorTableData">
                <el-table-column label="Sample Quality" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Sample Quality" v-model="scope.row.SampleQuality">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Prob Details" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Prob Details" v-model="scope.row.ProbDetails">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="No. Of cells" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="No. Of cells" v-model="scope.row.NoOfcells">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
              </el-table>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Result Table</span>
              <el-table border style="width: 100%" :data="resultTableData">
                <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                <el-table-column prop="result" label="Result (ISCN 2020)" width="300" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Result (ISCN 2020)" v-model="scope.row.result">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="chromosomeloci" label="Chromosome Loci/Color" width="200" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Chromosome Loci/Color"
                            v-model="scope.row.chromosomeloci">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="noofcells" label="No Of Cells" width="160" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="No Of Cells" v-model="scope.row.noofcells">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="finalresult" label="Result" width="140" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-select v-model="scope.row.finalresult" placeholder="Select">
                          <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
              </el-table>
            </div>
            <div class="edit-box mb-20 p-5">
              <span class="title">Image</span>

              <div class="upload-btn p-10">
                <el-upload class="upload-demo report-title" action :on-change="onChangeImage" accept="image/*"
                  :auto-upload="false" multiple :show-file-list="false">
                  <el-button type="primary">Click to upload</el-button>
                </el-upload>

                <div class="el-upload__tip">
                  jpg/png files with a size less than 500KB.
                </div>
              </div>

              <div class="image-flex" v-if="this.patientInfo.HematFish_patient_image">
                <div v-for="(item, index) in patientInfo.HematFish_patient_image" :key="item" class="img-block">
                  <img alt="" :src="item.imageUrl">
                  <el-input v-model="item.imageContent"></el-input>
                  <el-button type="danger" @click="deleteImage(index)" class="remove-btn" circle>
                    <el-icon>
                      <Close />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </div>
            <!-- <div class="image-flex" v-if="this.patientInfo.HematFish_patient_image">
      <div v-for="(item,index) in patientInfo.HematFish_patient_image" :key="item">
        <img alt="" :src="item">
        <h4>Image {{ index + 1 }} </h4>
      </div>
    </div> -->
            <div class="edit-box mb-20">
              <span class="title">Interpretation Guide</span>
              <el-input type="textarea" rows="2" v-model="patientInfo.Interpretation_Guide" autosize></el-input>
            </div>
            <div class="edit-box">
              <span class="title">References</span>
              <el-input type="textarea" rows="2" v-model="patientInfo.References" autosize></el-input>
            </div>

          </div>
        </div>
        <!-- Fish Report code end -->
          <div class="p-10">
            <el-row v-if="patientInfo.report_template_id">
                  <el-col :span="6">
                    <el-checkbox label="Additional approval required" v-model="patientInfo.is_required_signature"
                      @change="handleCheckedCitiesChange"
                      :disabled="this.patientInfo.report_status === 'APPROVED' && this.patientInfo.fulfilled_signatures === 2" />
                  </el-col>
            </el-row>
          </div>
      </div>
      <ul class="action-buttons">
        <!-- <li>
            <el-button @click="clearForm" class="submit-button"
              >Clear Form</el-button
            >
          </li>-->
        <li>
          <el-button @click="printReport" class="preview-button print-button">Print</el-button>
        </li>
        <li>
          <el-button @click="previewReport" class="preview-button">Preview</el-button>
        </li>
        <!-- <li v-if="!isEditForm">
            <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
              >Save as Draft</el-button
            >
          </li>-->
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('PENDING'); isValidate = true" class="submit-button">Submit</el-button>
        </li>
        <li v-if="isEditForm && isDraft">
            <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
              >Update Draft</el-button
            >
          </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button">Make Complete</el-button>
        </li>
        <li v-if="isEditForm && !isDraft">
          <el-button @click="savePatientInfo(this.patientInfo.report_status);isValidate=true" class="submit-button">Update</el-button>
        </li>

        <li>
          <el-button @click="saveAsTemplate" class="submit-button">Save As Template</el-button>
        </li>
      </ul>
    </div>
    <div v-if="templateTitleDialogVisible" class="template-title-dialog">
      <el-dialog width="30%" v-model="templateTitleDialogVisible" title="New Template" :before-close="handleClose"
        class="template-title-dialog">
        <el-input placeholder="Please add a title for Template " v-model="templateTitle"></el-input>

        <template #footer>
          <span class="dialog-footer">
            <el-button size="medium" class="el-white" type="info" plain @click="handleClose">Cancel</el-button>
            <el-button size="medium" type="primary" @click="addNewTemplate" :loading="loading">Submit</el-button>
          </span>
        </template>

        <FormError errorName="template_title"></FormError>
      </el-dialog>
    </div>
    <ResonForChange v-if="takeReasonDialog" v-on:close="takeReasonDialog = false" v-on:reasonSubmit="updateReport">
    </ResonForChange>

  </section>
</template>
<script>
/* eslint-disable */
import FormError from "@/components/FormError";
import ResonForChange from "@/components/ReasonForChange";
import axios from "@/config/axios";
import EditorConfig from "@/config/editorConfig";
import {
  cytogeneticsTypes,
  cytogeneticsTypesMap,
  genderTypes,
  karyotypeAndInterpretetion
  // nosileBoneOptions,
  // statusOptions,
} from "@/constants/formConstants";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { ElMessageBox,ElMessage } from "element-plus";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
    ResonForChange
  },
  data() {
    return {
      checkFormError:false,
      isValidate: false,
      ISCNDuplicateCheck: [],
      dialogFormVisible: false,
      labelPosition: 'top',
      testName: '',
      testId: "",
      reportTypes: cytogeneticsTypes,
      cytogeneticsTypesMap: cytogeneticsTypesMap,
      templateTitle: "",
      reportTypesdata: cytogeneticsTypesMap,
      KaryotypeAndInterpretetion: karyotypeAndInterpretetion,
      value: "",
      methodValue: "TRFIA",
      genderTypes: genderTypes,
      templatesList: [],
      options: ["Department of CYTOGENETICS"],
      touchedFields: {
        ISCN_Number: false,
        Interpretation: false
      },
      option: [{
        value: 'Positive',
        label: 'Positive'
      },
      {
        value: 'Negative',
        label: 'Negative'
      },
      {
        value: 'Mosaic',
        label: 'Mosaic'
      }
      ],
      templates: [],
      showKaryotypingUI: false,
      showSolidfishUI: false,
      showHematfishUI: false,
      searchVisitId: "",
      karyoTypeISCNDtails: [],
      patientInfo: {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        age: "",
        gender: "",
        hospital_name: "",
        client_add: "",
        client_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        mobile: "",
        email: "",
        locality: "",
        department: "Department of CYTOGENETICS",
        report_type: "",
        category: "CYTOGENETICS",
        sub_category: "",
        report_status: "DRAFT",
        sample_type: "",
        test_name: "",
        karyotype: "",
        recommendations: "",
        interpretation: "",
        karyotypeSample_Type: "",
        karyotypeQualityOfSample: "",
        karyotypeClinicalIndication: "",
        karyotypeTestRequested: "",
        karyotypeTestMethodology: "",
        NoOfCellsCounted: "",
        typedby: "",
        NoOfCellsKaryotyped: "",
        EstimatedBandResolution: "",
        BandingMethod: "",
        ISCN: "",
        CytogeneticsNumber: "",
        Interpretation_Guide: "",
        References: "",
        HematFish_patient_image: [],
        is_required_signature: false,
        amendedBy: [],
        Disclaimer: ""
      },
      resultTableData: [],
      fluorTableData: [],
      categoryReportTypes: [],
      report_template_id: "",
      test_results: "",
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      reportType: "",
      editorData: "",
      reportSubCategory: "",
      editorConfig: EditorConfig,
      comments: "",
      testResults: {},
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      testResultsSummary: [],
      templateTitleDialogVisible: false,
      testCategory: "",

      takeReasonDialog: false,
      karyotypeISCNDetails: {
        ISCN_Number: '',
        Interpretation: '',
        created_By: ''
      }
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("resultFiles", [
      "getPatientResults",
      "getPatientResultUpdateStatus",
    ]),
    ...mapGetters("reportTemplates", [
      "getAllReportTemplates",
      "getReportTemplateAddStatus",
      "getSingleReportTemplate",
      "getSinglePatientReports",
      "getAllISCNDetails",
      "getISCNDetailsResponce",
      "getUpdatedISCNDetailsResponce"
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
      "getDepartments",
      "getReportTypes",
    ]),

    isDisableDepartment() {
      if (
        this.searchVisitId &&
        this.listOfDepartments &&
        this.listOfDepartments.length
      ) {
        return false;
      }
      return true;
    },
    isDisableReportType() {
      if (
        this.searchVisitId &&
        this.listOfDepartments &&
        this.listOfDepartments.length &&
        this.reportTypes &&
        this.reportTypes.length
      ) {
        return false;
      }
      return true;
    },
    isEditForm() {
      return this.$route.params && this.$route.params.patient_id ? true : false;
    },
    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    }
  },
  async mounted() {
    if (this.$route.params.patient_id) {
      await this.fetchSinglePatientInfo();
      await this.getVisitData();
    }

    await this.fetchReportTemplates();
    await this.$store.dispatch("errors/clear", {}, { root: true });
    setTimeout(() => {
      this.show = true;
    });
  },

  methods: {
    addModal() {
        this.karyotypeISCNDetails = {
        ISCN_Number: '',
        Interpretation: '',
      },
        this.touchedFields = {
          ISCN_Number: false,
          Interpretation: false
        }
     
    },
    editISCNDetails(){
      let ISCNDetails=this.karyoTypeISCNDtails.filter((ISCndetails)=>ISCndetails.ISCN_Number == this.patientInfo.karyotype)
      this.karyotypeISCNDetails = {
        ISCN_Number: this.patientInfo.karyotype,
        Interpretation: this.patientInfo.interpretation,
        ISCN_id:ISCNDetails[0]._id
      }
      this.ISCNDuplicateCheck=[]
      this.touchedFields = {
          ISCN_Number: true,
          Interpretation: true
        }
    },
    async handleBlur(field) {
      this.touchedFields[field] = true;
      this.ISCNDuplicateCheck = this.karyoTypeISCNDtails.filter(item => item.ISCN_Number === this.karyotypeISCNDetails.ISCN_Number);
    },
    isFieldTouched(field) {
      return this.touchedFields[field];
    },
    async handleCheckedCitiesChange(event) {
      this.patientInfo.is_required_signature = event;
    },
    fillSampleType(data) {
      let matchedReportTypes = [];
      let Testdeatils = this.categoryReportTypes.filter((testDetails) => testDetails.TestId == data)
      Testdeatils.forEach(category => {
        this.reportTypesdata.forEach(reportType => {
          if (category.ItemName.includes(reportType.value)) {
            matchedReportTypes.push(reportType);
          }
        });
      });
      this.reportType = matchedReportTypes[0]?.value
      this.onChangeReportType()
      const JavaScriptApplicants = this.categoryReportTypes.filter(
        (e) => e.TestId == data
      );
      this.testName = JavaScriptApplicants[0]?.ItemName
      if (JavaScriptApplicants[0]?.SampleTypeName) {
        this.patientInfo.sample_type = JavaScriptApplicants[0]?.SampleTypeName;
      }
      if (JavaScriptApplicants[0]?.BarcodeNo) {
        this.patientInfo.barcode_no = JavaScriptApplicants[0]?.BarcodeNo;
      }
    },
    async updateReport(reason) {
      this.updatePatientInfo(reason)
    },
    async onChangeKaryoType() {
      if (this.patientInfo.karyotype) {
        let interPretations = this.karyoTypeISCNDtails.filter((data) => data.ISCN_Number === this.patientInfo.karyotype)
        this.patientInfo.interpretation = interPretations[0].Interpretation
      }
      else {
        this.patientInfo.interpretation = ''
      }

    },
    async onChangeImage(file) {
      const isValidFile = this.validateFile(file);
      if (!isValidFile) {
        this.$message.error('Invalid file format or size. Please upload a jpg/png file with a size less than 500KB.');
        return;
      }
      try {
        const selectedFile = file.raw;
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post("patient-reports/upload-pdf", formData, file.raw);
        const pdf = response.data.data.imageUrl;
        this.patientInfo.HematFish_patient_image.push({ imageUrl: pdf, imageContent: '' });
      } catch (error) {
        console.error(error);
      }
    },
    validateFile(file) {
  const isValidType = ['image/jpeg', 'image/png'].includes(file.raw.type);
  const isValidSize = file.raw.size / 1024 < 500;
  return isValidType && isValidSize;
},
    deleteImage(index) {
      this.patientInfo.HematFish_patient_image.splice(index, 1)
    },
    async getVisitData() {
      try {
        this.searchVisitId =
          this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
        // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
      } catch (error) {
        console.log(error);
      }
    },
    async handleSelect(data) {
      this.checkFormError=false;
      this.patientInfo.visit_id = data.VisitiID;
      const age = data.Age.split(" ");
      const years = parseInt(age[0]);
      const months = parseInt(age[2]);
      const days = parseInt(age[4]);
      if (years > 0 && months > 0 && days > 0 || years > 0 && months > 0) {
        this.patientInfo.age = `${years} Y ${months} M`;
      } else if (years === 0 && months > 0 && days > 0) {
        this.patientInfo.age = `${months} M ${days} D`;
      } else if (years === 0 && months === 0 && days > 0) {
        this.patientInfo.age = `${days} D`;
      }else if ( months > 0) {
        this.patientInfo.age = `${months} M`;
      }
      else if (years === 0 && months == 0 && days == 0) {
        this.patientInfo.age = `${0} Y ${0} M ${0} D`;
      }
      else if(years>0){
        this.patientInfo.age = `${years} Y`;
      }
      this.patientInfo.gender = data.Gender.toUpperCase();
      this.patientInfo.barcode_no = data.BarcodeNo;
      this.patientInfo.hospital_name = data.HospitalName;
      this.patientInfo.client_add = data.ClientAdd;
      this.patientInfo.client_code = data.ClientCode;
      this.patientInfo.client_name = data.ClientName;
      this.patientInfo.patient_name = data.PatientName;
      this.patientInfo.ref_doctor = data.RefDoctor && !data.RefDoctor.startsWith('Dr.')
    ? `Dr. ${data.RefDoctor}`
    : data.RefDoctor || '';
      this.patientInfo.uhid = data["UHID/MR NO"];

      this.patientInfo.registration_date = data.RegisterationDate
        ? moment(data.RegisterationDate).utc().format()
        : null;
      this.patientInfo.reported_date = data.ReportedDate
        ? moment(data.ReportedDate).utc().format()
        : null;
      this.patientInfo.received_date = data.ReceivedDate
        ? moment(data.ReceivedDate).utc().format()
        : null;
      this.patientInfo.collected_date = data.CollectionDate !== '0001-01-01 00:00:00'
        ? moment.utc(data.CollectionDate, 'YYYY-MM-DD HH:mm:ss').format()
        : null;


      this.patientInfo.email = data.Email;
      this.patientInfo.mobile = data.Mobile;
      this.patientInfo.locality = data.Locality;
      this.patientInfo.alternate_mobile = data.Phone;
      // this.patientInfo.umr_no = data.umr_no;
      this.patientInfo.client_name = data.ClientName;
      this.patientInfo.req_no = data.req_no;
      if (data.test_name) {
        this.patientInfo.test_name = data.TestName;
      }
      this.patientInfo.sample_type = data.SampleTypeName;

      await this.fetchPatientDepartments('fromSelecting');
    },
    askReason() {
      this.takeReasonDialog = true
    },
    onEditReportType() {
      this.reportTypeNew = this.patientInfo.reporonChangeReportTypet_type;
      this.editReportType = true;
      this.showReportType = false;
    },
    updateReportType() {
      this.patientInfo.report_type = this.reportTypeNew;
      this.editReportType = false;
      this.showReportType = true;
    },
    cancelUpdateReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = false;
      this.showReportType = true;
    },
    async getAutoCompletePatientsData(queryString, cb) {
      if (queryString && queryString.length > 2) {
        await this.$store.dispatch("patientReports/fetchAllPatients", {
          search_string: queryString,
          get_all: true,
        });
        const patientsData = [];
        (this.getPatientDetails.data || []).forEach((el) => {
          patientsData.push({
            value: el.VisitiID + " - " + el.PatientName,
            ...el,
          });
        });

        cb(patientsData || []);
      } else {
        cb([]);
      }
    },
    async addNewTemplate() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        params.template_title = this.templateTitle;
        params.test_details = data;

        if (!data.length) {
          this.$message("No Content to Save Template");
          return;
        }
        params.template_body = data;

        this.loading = true;
        //console.log(params.sub_category)
        params.sub_category = this.reportType;
        if (params.age) {
          params.age = params.age.toString();
        }
        await this.$store.dispatch("reportTemplates/addReportTemplate", params);

        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });

          this.templateTitleDialogVisible = false;
          this.templateTitle = "";
          this.loading = false;
          await this.fetchReportTemplates();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Creating new Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        // stringContent = stringContent.trim();
        // if (!stringContent) {
        //   ElMessage.warning("Report content is not given");
        //   return;
        // }

        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };
        params.sub_category = this.reportSubCategory;
        //console.log(params.sub_category)
        params.test_details = data;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async previewReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        // stringContent = stringContent.trim();
        // if (!stringContent) {
        // ElMessage.warning("Report content is not given");
        // return;
        // }

        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };
        params.sub_category = this.reportType;
        params.test_details = data;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    saveAsTemplate() {
      // let dd = window.document.getElementsByClassName("ck-content");
      // const data = dd[0].innerHTML;
      // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      // stringContent = stringContent.trim();
      // if (!stringContent) {
      //   ElMessage.warning("Report content is not given");
      //   return;
      // }
      this.templateTitleDialogVisible = true;
    },
    async fetchSingleReportTemplate(templateId) {
      try {
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: templateId }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async updateTemplateData(templateId) {
      await this.fetchSingleReportTemplate(templateId);

      let templateData = this.templates.find((x) => x._id == templateId);
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.template_body
      ) {
        templateData.template_body = this.getSingleReportTemplate.template_body;
      }
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.test_details
      ) {
        templateData.test_details = this.getSingleReportTemplate.test_details;
      }

      if (templateData) {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML || "";
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (stringContent) {
          ElMessageBox.confirm(
            "Are you want to update template data. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "No",
              type: "warning",
            }
          )
            .then(async () => {
              await this.updateEditorData(templateData);
            })
            .catch(() => { });
        } else {
          this.updateEditorData(templateData);
        }
      }
    },
    updateEditorData(templateData) {
      this.show = false;
      this.loadingEditor = true;
      setTimeout(() => {
        this.loadingEditor = false;
        this.show = true;
        this.editorData = templateData.template_body || "";
        // this.patientInfo.karyotypeClinicalIndication = templateData.karyotypeClinicalIndication
        this.patientInfo.karyotypeQualityOfSample = templateData.karyotypeQualityOfSample
        this.patientInfo.karyotypeSample_Type = templateData.karyotypeSample_Type
        this.patientInfo.karyotypeTestMethodology = templateData.karyotypeTestMethodology
        this.patientInfo.karyotypeTestRequested = templateData.karyotypeTestRequested
        this.patientInfo.interpretation = ''
        this.patientInfo.recommendations = templateData.recommendations
        this.patientInfo.NoOfCellsCounted = templateData.NoOfCellsCounted
        this.patientInfo.NoOfCellsKaryotyped = templateData.NoOfCellsKaryotyped
        this.patientInfo.EstimatedBandResolution = templateData.EstimatedBandResolution
        this.patientInfo.BandingMethod = templateData.BandingMethod
        this.patientInfo.ISCN = templateData.ISCN
        this.patientInfo.CytogeneticsNumber = templateData.CytogeneticsNumber
        this.testResultsSummary = templateData.karyotypeClinicalIndication || "";
        this.patientInfo.report_type = templateData.report_type || "";
        this.reportType = templateData.sub_category || "";
        this.patientInfo.Interpretation_Guide = templateData.Interpretation_Guide,
          this.patientInfo.References = templateData.References
        this.patientInfo.Disclaimer = templateData.Disclaimer
        if (this.reportType == 'FISH') {
          this.resultTableData = templateData.HematFishResultTable,
            this.fluorTableData = templateData.fluorTableData
          this.patientInfo.HematFishResultTable = templateData.HematFishResultTable,
            this.patientInfo.fluorTableData = templateData.fluorTableData,
            this.patientInfo.HematFish_patient_image = templateData.HematFish_patient_image
        }

      }, 100);
    },
    async loadInitData() {
      await this.$store.dispatch("errors/clear", {}, { root: true });
      if (this.$route.params.template_id) {
        await this.fetchSinglePatientInfo();
      }

      setTimeout(() => {
        this.show = true;
      });
    },
    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";

        let params = {
          result_file_id: this.$route.params.result_file_id,
          patient_id: this.$route.params.patient_id,
        };
        await this.$store.dispatch(
          "resultFiles/fetchSinglePatientResults",
          params
        );
        await this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "CYTOGENETICS",
        };
        if (this.reportType) {
          const selected_category = this.cytogeneticsTypesMap.find(type => type.value === this.reportType);

          if (selected_category) {
            params.sub_category = selected_category.value;
          }
        }
        await this.$store.dispatch("reportTemplates/fetchAllReportTemplates", params);
        await this.$store.dispatch("reportTemplates/fetchAllISCNDetails", params);
        this.karyoTypeISCNDtails = this.getAllISCNDetails.data;
        this.templates = this.getAllReportTemplates.data;
        this.templatesList = this.getAllReportTemplates.data;
        if (this.testId && this.reportType == 'FISH') {
          let template = this.templates.filter((data) => data.template_title === this.testName)
          if (template.length > 0) {
            this.patientInfo.report_template_id = template[0]?._id
            if (!this.$route.params.patient_id) {
              this.updateTemplateData(this.patientInfo.report_template_id)
            }
          }
        }
        if (this.reportType == 'KARYOTYPING') {
          this.templates = this.templates.filter((data) => data.template_title == 'KARYOTYPING')
          this.patientInfo.report_template_id = this.templates[0]?._id
          if (!this.$route.params.patient_id) {
            this.updateTemplateData(this.patientInfo.report_template_id)
          }
        }
        else if (!this.testId && !this.$route.params.patient_id) {
          this.patientInfo.report_template_id = ''
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    // onChangeReportType(results) {
    //       let testId = this.reportTypes.filter(
    //         (itemInArray) => itemInArray.TestId === results
    //       );
    //       this.reportName = testId[0].ItemName;
    //       this.reportType = testId[0].ItemName;

    //       this.patientInfo.report_type = testId[0].ItemName;
    //       this.patientInfo.report_template_id = "";
    //       this.patientInfo.test_name =
    //         this.cytogeneticsTypesMap[this.reportType];

    //       this.fetchReportTemplates();
    //     },
    onChangeReportType() {
      this.reportSubCategory = this.reportType;
      this.patientInfo.report_type = this.reportType;
      this.patientInfo.sub_category = this.reportType
      this.patientInfo.report_template_id = "";
      this.patientInfo.test_name =
        this.cytogeneticsTypesMap[this.reportType];
      this.fetchReportTemplates();
    },

    async setPatientData() {
      this.patientInfo = { ...this.getSinglePatientReports };
      if (this.getSinglePatientReports.amendment_history) {
        this.patientInfo.amendedBy = this.getSinglePatientReports.amendment_history
      }
      await this.fetchPatientDepartments('fromEditing');
      this.editorData = this.getSinglePatientReports.test_details;
      this.reportType = this.patientInfo.sub_category;
      this.reportName = this.patientInfo.report_type;
      if (this.reportType == 'FISH') {
        this.resultTableData = this.getSinglePatientReports.HematFishResultTable,
          this.fluorTableData = this.getSinglePatientReports.fluorTableData;
      }
      if (this.getDepartments && this.getDepartments.data) {
        this.departmentsData = this.getDepartments.data;
      }

      const JavaScriptApplicants = this.departmentsData.filter((e) =>
        e.department.includes(this.patientInfo.department)
      );

      JavaScriptApplicants.forEach(
        (x) => (this.reportTypes = x.test_categories)
      );
      this.testId = this.getSinglePatientReports.result_test_id;
    },
    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.updateEditorData({});
        })
        .catch(() => { });
    },
    async goToReports() {
      if (this.$route.params.patient_id) {
        this.$router.push({
          name: "PatientReportView",
          query: this.$route.query,
          params: { status: this.$route.params.status },
        });
      } else {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      }
    },
    clearNameSearch() {
      this.categoryReportTypes = []
      this.testId = '',
        this.reportType = ''
      this.fetchReportTemplates()
      let patientInfo = {};
      this.patientInfo = { ...this.patientInfo, ...patientInfo };
    },
    async fetchPatientDepartments(from) {
      this.loading = true;
      let params = {};
      if (this.patientInfo.visit_id) {
        params.visit_id = this.patientInfo.visit_id;
        params.department = "CYTOGENETICS";
      }
      await this.$store.dispatch("patientReports/fetchAllDepartments", params);
      if (
        this.getDepartments &&
        this.getDepartments.data &&
        this.getDepartments.data.length
      ) {
        this.listOfDepartments = this.getDepartments.data;

        this.listOfDepartments.forEach(
          (x) => (this.categoryReportTypes = x.test_categories)
        );
        if (this.categoryReportTypes.length == 1) {
          this.testId = this.categoryReportTypes[0].TestId
          this.testName = this.categoryReportTypes[0].ItemName
        }
        else {
          this.testId = ''
        }
        let matchedReportTypes = [];

        this.categoryReportTypes.forEach(category => {
          this.reportTypesdata.forEach(reportType => {
            if (category.ItemName.includes(reportType.value)) {
              matchedReportTypes.push(reportType);
            }
          });
        });
        if (matchedReportTypes.every(data => data.value === matchedReportTypes[0].value) && from == 'fromSelecting') {
          this.reportType = matchedReportTypes[0].value
          this.onChangeReportType()
        }
        else if (from == 'fromSelecting') {
          this.reportType = ''
          this.patientInfo.report_template_id = ''
          this.onChangeReportType()
        }
        // }
      } else {
        this.listOfDepartments = [];
        this.reportType = ''
        from == 'fromSelecting' ? this.patientInfo.report_template_id = '' : ''
        this.testId = ''
        this.categoryReportTypes = []
        this.templates = this.templatesList
        from == 'fromSelecting' ? this.patientInfo.report_template_id = '' : ''
      }
      this.loading = false;
    },
    savePatientInfo(status) {
      this.reasonStatus = status
      if(!this.isFieldValid() && this.reportType == 'KARYOTYPING'){
        return;
      }
      if (this.$route.params.patient_id && status === 'APPROVED') {
        this.askReason()
      } else if (this.$route.params.patient_id) {
        this.updatePatientInfo();
      } else {
        this.addPatientInfo();
      }
    },

    async printReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        // stringContent = stringContent.trim();
        // if (!stringContent) {
        // ElMessage.warning("Report content is not given");
        // return;
        // }
        this.loading = true;
        this.loadingText = "Loading..";
        let params = { ...this.patientInfo };
        if (params.sub_category == '') {
          params.sub_category = this.reportType;
        }
        params.test_details = data;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreviewPrint",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to print report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    // async addPatientInfo(reason) {
    //   try {
    //     this.loading = true;
    //     this.loadingText = "Loading...";
    //     let patient_demographics = { ...this.patientInfo };

    //     if (patient_demographics.client_code != "") {
    //       const code = patient_demographics.client_code.toString();
    //       patient_demographics.client_code = code;
    //     }

    //     let params = { patient_demographics };

    //     if (this.report_template_id) {
    //       params.report_template_id = this.report_template_id || "";
    //     }
    //     if (this.riskage21 && this.rskAge_T21) {
    //       this.testResultsSummary.rskAge_T21 =
    //         this.riskage21 + ":" + this.rskAge_T21;
    //     } else if (this.rskAge_T21) {
    //       this.testResultsSummary.rskAge_T21 = 1 + ":" + this.rskAge_T21;
    //     } else {
    //       this.testResultsSummary.rskAge_T21 = "";
    //     }
    //     if (this.riskValue21 && this.rskValue_T21) {
    //       this.testResultsSummary.rskValue_T21 =
    //         this.riskValue21 + ":" + this.rskValue_T21;
    //     } else if (this.rskValue_T21) {
    //       this.testResultsSummary.rskValue_T21 = 1 + ":" + this.rskValue_T21;
    //     } else {
    //       this.testResultsSummary.rskValue_T21 = "";
    //     }
    //     if (this.riskage18 && this.rskAge_T18) {
    //       this.testResultsSummary.rskAge_T18 =
    //         this.riskage18 + ":" + this.rskAge_T18;
    //     } else if (this.rskAge_T18) {
    //       this.testResultsSummary.rskAge_T18 = 1 + ":" + this.rskAge_T18;
    //     } else {
    //       this.testResultsSummary.rskAge_T18 = "";
    //     }
    //     if (this.riskValue18 && this.rskValue_T18) {
    //       this.testResultsSummary.rskValue_T18 =
    //         this.riskValue18 + ":" + this.rskValue_T18;
    //     } else if (this.rskValue_T18) {
    //       this.testResultsSummary.rskValue_T18 = 1 + ":" + this.rskValue_T18;
    //     } else {
    //       this.testResultsSummary.rskValue_T18 = "";
    //     }

    //     if (this.riskage13 && this.rskAge_T13) {
    //       this.testResultsSummary.rskAge_T13 =
    //         this.riskage13 + ":" + this.rskAge_T13;
    //     } else if (this.rskAge_T13) {
    //       this.testResultsSummary.rskAge_T13 = 1 + ":" + this.rskAge_T13;
    //     } else {
    //       this.testResultsSummary.rskAge_T13 = "";
    //     }
    //     if (this.riskValue13 && this.rskValue_T13) {
    //       this.testResultsSummary.rskValue_T13 =
    //         this.riskValue13 + ":" + this.rskValue_T13;
    //     } else if (this.rskValue_T13) {
    //       this.testResultsSummary.rskValue_T13 = 1 + ":" + this.rskValue_T13;
    //     } else {
    //       this.testResultsSummary.rskValue_T13 = "";
    //     }
    //     if (this.riskageTS && this.rskAge_TS) {
    //       this.testResultsSummary.rskAge_TS =
    //         this.riskageTS + ":" + this.rskAge_TS;
    //     } else if (this.rskAge_TS) {
    //       this.testResultsSummary.rskAge_TS = 1 + ":" + this.rskAge_TS;
    //     } else {
    //       this.testResultsSummary.rskAge_TS = "";
    //     }
    //     if (this.riskValueTS && this.rskValue_TS) {
    //       this.testResultsSummary.rskValue_TS =
    //         this.riskValueTS + ":" + this.rskValue_TS;
    //     } else if (this.rskValue_TS) {
    //       this.testResultsSummary.rskValue_TS = 1 + ":" + this.rskValue_TS;
    //     } else {
    //       this.testResultsSummary.rskValue_TS = "";
    //     }
    //     if (this.rskAgePE32first && this.rskAgePE32second) {
    //       this.testResultsSummary.rskAge_PE_LT_32 =
    //         this.rskAgePE32first + ":" + this.rskAgePE32second;
    //     } else if (this.rskAgePE32second) {
    //       this.testResultsSummary.rskAge_PE_LT_32 =
    //         1 + ":" + this.rskAgePE32second;
    //     } else {
    //       this.testResultsSummary.rskAge_PE_LT_32 = "";
    //     }
    //     if (this.rskValuePE32first && this.rskValuePE32Second) {
    //       this.testResultsSummary.rskValue_PE_LT_32 =
    //         this.rskValuePE32first + ":" + this.rskValuePE32Second;
    //     } else if (this.rskValuePE32Second) {
    //       this.testResultsSummary.rskValue_PE_LT_32 =
    //         1 + ":" + this.rskValuePE32Second;
    //     } else {
    //       this.testResultsSummary.rskValue_PE_LT_32 = "";
    //     }
    //     if (this.rskAgePE34first && this.rskAgePE34second) {
    //       this.testResultsSummary.rskAge_PE_LT_34 =
    //         this.rskAgePE34first + ":" + this.rskAgePE34second;
    //     } else if (this.rskAgePE34second) {
    //       this.testResultsSummary.rskAge_PE_LT_34 =
    //         this.rskAgePE34first + ":" + this.rskAgePE34second;
    //     } else {
    //       this.testResultsSummary.rskAge_PE_LT_34 = "";
    //     }
    //     if (this.rskValuePE34first && this.rskValuePE34Second) {
    //       this.testResultsSummary.rskValue_PE_LT_34 =
    //         this.rskValuePE34first + ":" + this.rskValuePE34Second;
    //     } else if (this.rskValuePE34Second) {
    //       this.testResultsSummary.rskValue_PE_LT_34 =
    //         1 + ":" + this.rskValuePE34Second;
    //     } else {
    //       this.testResultsSummary.rskValue_PE_LT_34 = "";
    //     }

    //     if (this.rskAgePE37first && this.rskAgePE37second) {
    //       this.testResultsSummary.rskAge_PE_LT_37 =
    //         this.rskAgePE37first + ":" + this.rskAgePE37second;
    //     } else if (this.rskAgePE37second) {
    //       this.testResultsSummary.rskAge_PE_LT_37 =
    //         1 + ":" + this.rskAgePE37second;
    //     } else {
    //       this.testResultsSummary.rskAge_PE_LT_37 = "";
    //     }

    //     if (this.rskValuePE37first && this.rskValuePE37Second) {
    //       this.testResultsSummary.rskValue_PE_LT_37 =
    //         this.rskValuePE37first + ":" + this.rskValuePE37Second;
    //     } else if (this.rskValuePE37Second) {
    //       this.testResultsSummary.rskValue_PE_LT_37 =
    //         1 + ":" + this.rskValuePE37Second;
    //     }

    //     if (this.rskAgePEG37first && this.rskAgePEG37second) {
    //       this.testResultsSummary.rskAge_PE_GE_37 =
    //         this.rskAgePEG37first + ":" + this.rskAgePEG37second;
    //     } else if (this.rskAgePEG37second) {
    //       this.testResultsSummary.rskAge_PE_GE_37 =
    //         1 + ":" + this.rskAgePEG37second;
    //     } else {
    //       this.testResultsSummary.rskAge_PE_GE_37 = "";
    //     }

    //     if (this.rskValuePEG37first && this.rskValuePEG37Second) {
    //       this.testResultsSummary.rskValue_PE_GE_37 =
    //         this.rskValuePEG37first + ":" + this.rskValuePEG37Second;
    //     } else if (this.rskValuePEG37Second) {
    //       this.testResultsSummary.rskValue_PE_GE_37 =
    //         1 + ":" + this.rskValuePEG37Second;
    //     } else {
    //       this.testResultsSummary.rskValue_PE_GE_37 = "";
    //     }

    //     params.test_results_summary = [this.testResultsSummary];

    //     params.recommendations = this.recommendations;
    //     params.comments = this.comments;
    //    console.log(params.result_file_id)
    //     // let patientId = this.$route.params.patient_id;
    //     // let resultFileId = this.$route.params.result_file_id;
    //     if (reason && reason.reason) {
    //       params.amendment_reason = reason.reason
    //     }
    //     if (reason && reason.is_amendment) {
    //       params.is_amendment = reason.is_amendment
    //     }
    //    await this.$store.dispatch("patientReports/addPatientReport", {
    //       params,

    //     });
    //     if (this.getPatientResultUpdateStatus) {
    //       this.$notify.success({
    //         title: "Success",
    //         message: "Patient Report Updated Successfully",
    //       });
    //       await this.goToReports();
    //     } else if (!this.getErrors) {
    //       this.$notify.error({
    //         title: "Error",
    //         message: "Error while updating Report",
    //       });
    //     }
    //     this.loading = false;
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    clearFields() {
      //this.templates = [];
      this.showKaryotypingUI = false;
      this.showSolidfishUI = false;
      this.showHematfishUI = false;
    },
    async addPatientInfo() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.reportType == 'KARYOTYPING'){
          const stringContent = /<img[^>]+>/i.test(data);
        if (!stringContent) {
          ElMessage.warning("Please upload a image");
          return;
        }
        }
        this.loading = true;
        this.loadingText = "Loading...";
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }

        params.test_details = data;
        if (this.reasonStatus) {
          params.report_status = this.reasonStatus;
        }
        params.sub_category = this.reportType;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if (this.reportType == 'FISH') {
          params.HematFishResultTable = this.resultTableData
          params.fluorTableData = this.fluorTableData
        }

        params.typedby = this.getProfileDetails.first_name
        await this.$store.dispatch("patientReports/addPatientReport", params);
        if (this.getPatientAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Added Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async updatePatientInfo(reason) {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;

        if(this.reportType == 'KARYOTYPING'){
          const stringContent = /<img[^>]+>/i.test(data);
        if (!stringContent) {
          ElMessage.warning("Please upload a image");
          return;
        }
        }
        this.loading = true;
        this.loadingText = "Loading...";
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        if (params.age == null) {
          params.age = "";
        }
        if (params.reported_date == null) {
          params.reported_date = "";
        }
        if (params.received_date == null) {
          params.received_date = "";
        }
        if (this.reasonStatus) {
          params.report_status = this.reasonStatus;
        }
        params.test_details = data;
        params.sub_category = this.reportType;
        let patientId = this.$route.params.patient_id;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if (reason && reason.reason) {
          params.amendment_reason = reason.reason
        }
        if (reason && reason.is_amendment) {
          params.is_amendment = reason.is_amendment
        }
        if (this.reportType == 'FISH') {
          params.HematFishResultTable = this.resultTableData
          params.fluorTableData = this.fluorTableData
        }
        await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async handleClose() {
      this.templateTitle = "";
      this.templateTitleDialogVisible = false;
      await this.$store.dispatch("errors/clear", {}, { root: true });
    },
    async saveISCNDetails() {
      let params = {
        ...this.karyotypeISCNDetails,
        created_By: this.getProfileDetails.first_name + +'' + this.getProfileDetails.last_name
      }
      await this.$store.dispatch("reportTemplates/saveISCNDetails", {
        ...params
      });
      if (this.getISCNDetailsResponce.data) {
        await this.$store.dispatch("reportTemplates/fetchAllISCNDetails", params);
        this.karyoTypeISCNDtails = this.getAllISCNDetails.data;
        this.patientInfo.karyotype = this.getISCNDetailsResponce.data.ISCN_Number;
        this.patientInfo.interpretation = this.getISCNDetailsResponce.data.Interpretation;
      }
    },
    async updateISCNDetails() {
      let params = {
        ...this.karyotypeISCNDetails,
        updated_By: this.getProfileDetails.first_name + +'' + this.getProfileDetails.last_name
      }
      await this.$store.dispatch("reportTemplates/updateISCNDetails", {
        ...params
      }); 
      if (this.getUpdatedISCNDetailsResponce) {
        await this.$store.dispatch("reportTemplates/fetchAllISCNDetails", params);
        this.karyoTypeISCNDtails = this.getAllISCNDetails.data;
        this.patientInfo.karyotype = this.getUpdatedISCNDetailsResponce.data.ISCN_Number;
        this.patientInfo.interpretation = this.getUpdatedISCNDetailsResponce.data.Interpretation;
      }
    },
    isFieldValid() {
    this.checkFormError = true;
    let emptyField = null;
    Object.keys(this.patientInfo).forEach(key => {
        if (this.patientInfo[key] === '') {
          if(document.getElementById(key)){
            emptyField = document.getElementById(key)
              return false;
          } 
        }
      });
      if (emptyField) {
        emptyField.scrollIntoView({ behavior: 'smooth' });
        return false;
      }
      return true;
    }
}
};
</script>
  
<style lang="scss" scoped>
.lower_abnormal {
  font-weight: bold;
}

.upper_abnormal {
  font-weight: bold;
  background-color: #b7d2ec;
}
</style>
<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 0 auto;

  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 15px;

    .display-end {
      display: flex;
      text-align: end;
    }

    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .inner-navbar {
    margin-bottom: 10px;

    .mt-10 {
      margin-top: 10px;
    }

    .filter-navigation {
      display: flex;
      justify-content: space-between;

      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .tms-report-divider {
    width: 100%;
    height: 3px;
    background: #b7d2ec;
    margin: 15px 0;
  }

  @media print {
    .tms-report-divider {
      display: none;
    }

    .header {
      height: 378.5px !important
    }

    .patient-information {
      height: 1000px !important
    }
  }

  .inner {
    margin-bottom: 50px;

    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;

    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }

      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 500;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }

      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;

        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }

    .report-sub-title {
      height: 39px;
      line-height: 39px;
      color: #fff !important ;
      font-size: 18px;
      text-align: center;
      margin-top: 0px !important;
      padding-top: 0rem !important;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);
      font-weight: 500;
    }

    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }

    .editor,
    .comments-editor {
      margin-top: 0.5em;
      padding: 10px;

      .ck-editor__editable {
        min-height: 320px;
      }
    }

    .image__caption_highlighted {
      min-height: auto !important;
    }

    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;

      li {
        &:not(:last-child) {
          margin-right: 20px;
        }

        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;

          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 500;
          }

          &.print-button {
            background: #f56c6c !important;
          }

          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }
  }

  figure {
    &.table {
      br {
        display: none;
      }

      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }

  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;

      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }

  .table-data {
    padding-top: 1em;
  }

  .el-table {
    .el-table thead {
      background-color: #28b4a6;
      font-weight: 500;
    }
  }

  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 500;
  //   }
  // }
  .result-table {
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
}

.double-marker {
  padding: 10px 0 20px;

  .text-area-sub-header {
    padding: 5px 20px;
  }

  .el-textarea__inner {
    width: 100%;
    margin: auto;
  }
}

.text {
  color: #dc3545;
}
</style>
  
<style lang="scss">
.bio-chemistry-report-title {
  text-align: center;
  background: #e0f3f1;
  border-radius: 5px;
  margin-top: 10px !important;
  padding: 6px 0;
  font-weight: 500;
  font-size: 15px;
  color: #333;
}

.bio-chemistry-data-table {
  border: 1px solid rgba(7, 162, 149, 0.6);
  margin: 20px 10px 10px 10px;
  padding: 0 !important;

  table {
    th {
      background: #2eaf9f;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      padding: 4px 10px;
      letter-spacing: 0;
      position: relative;
      text-align: left;

      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 2px;
          bottom: 2px;
          background: rgba(255, 255, 255, 0.6);
          width: 1px;
        }
      }

      .cell {
        padding: 0;
      }
    }

    td {
      padding: 5px 10px;
      font-size: 14px;
      border-bottom: 1px solid #2eaf9f;
      font-weight: 500;
      color: #000;

      &:not(:last-child) {
        border-right: 1px solid #2eaf9f;
      }

      .cell {
        padding: 0;
      }
    }
  }
}

.tms-report-editor-table {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  .data-table {
    margin-bottom: 20px;

    table {
      border-collapse: collapse;
      width: 100%;

      th,
      td {
        padding: 5px 10px !important;
        font-size: 15px;
      }

      th {
        background: #f5f5f5;
        font-weight: 500;
      }

      .table-head {
        background: #28b4a6;
        color: #fff;
        text-align: center !important;
        font-size: 15px !important;

        .cell {
          line-height: 20px !important;
        }
      }
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.mw-250 {
  max-width: 250px;
}

.p-10 {
  padding: 10px;
}

.image-flex {
  display: grid;
  grid-template-columns: repeat(3, 265px);
  gap: 30px;
  padding: 0 10px;
  margin: 20px 0;
  align-items: center;
  justify-items: center;

  img {
    width: 240px;
    max-width: 240px;
    height: 200px;
    object-fit: contain;
  }

  h4 {
    text-align: center;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
  }

  .upload-btn {
    padding: 10px;
  }

  .img-block {
    position: relative;
    background: #f3f3f3;
    padding: 10px;
    border-radius: 6px;

    .remove-btn {
      display: none;
    }

    &:hover {
      .remove-btn {
        display: block;
        cursor: pointer;
      }
    }

    .remove-btn {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}

.position-relative {
  position: relative;

  .addButtonIcon {
    position: absolute;
    bottom: 14px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #0061ec;
  }
}
.el-form-item__label:before {
    display: none !important;
}
.el-form-item__label:after {
    content: "*";
    color: var(--el-color-danger);
    margin-left: 4px !important;
}
</style>
  