import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    allReportTemplates: null,
    singleReportTemplate: null,
    reportTemplateAddStatus: false,
    reportTemplateAddError: null,
    reportTemplateUpdateStatus: null,
    reportTemplateUpdateError: null,
    templateDeleteStatus: null,
    reportTemplatePreview: null,
    templateMoveToLiveStatus: false,
    allISCNDetails:null
  };
};

const state = initialState();

const getters = {
  getAllReportTemplates: (state) => state.allReportTemplates,
  getAllISCNDetails: (state) => state.allISCNDetails,
  getSingleReportTemplate: (state) => state.singleReportTemplate,
  getReportTemplateAddStatus: (state) => state.reportTemplateAddStatus,
  getReportTemplateAddError: (state) => state.reportTemplateAddError,
  getReportTemplateUpdateStatus: (state) => state.reportTemplateUpdateStatus,
  getReportTemplateUpdateError: (state) => state.reportTemplateUpdateError,
  getReportTemplateDeleteStatus: (state) => state.templateDeleteStatus,
  getReportTemplatePreview: (state) => state.reportTemplatePreview,
  getTemplateMoveStatus: (state) => state.templateMoveToLiveStatus,
  getISCNDetailsResponce:(state) => state.ISCNDetailsData,
  getUpdatedISCNDetailsResponce:(state) => state.updatedISCNDetailsData
};

const mutations = {
  setAllReportTemplates(state, data) {
    state.allReportTemplates = data;
  },
  setAllkaryotypeISCNDetails(state, data) {
    state.allISCNDetails = data;
  },
  setkaryotypeISCNData(state, data) {
    state.ISCNDetailsData = data;
  },
  setupdatekaryotypeISCNData(state, data) {
    state.updatedISCNDetailsData = data;
  },
  setSingleReportTemplate(state, data) {
    state.singleReportTemplate = data;
  },
  setReportTemplateAddStatus(state, data) {
    state.reportTemplateAddStatus = data;
  },
  setReportTemplateAddError(state, data) {
    state.reportTemplateAddError = data;
  },
  seReportTemplateUpdateStatus(state, data) {
    state.reportTemplateUpdateStatus = data;
  },
  setReportTemplateUpdateError(state, data) {
    state.reportTemplateUpdateError = data;
  },
  setTemplateDeleteStatus(state, status) {
    state.templateDeleteStatus = status;
  },
  setReportTemplateData(state, data) {
    state.reportTemplatePreview = data;
  },
  setReportTemplateMoveStatus(state, status) {
    state.templateMoveToLiveStatus = status
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  async fetchAllReportTemplates({ commit, dispatch }, params) {
    try {
      commit("setAllReportTemplates", null);
      const response = await axios.get("/report-templates", { params });

      commit("setAllReportTemplates", response.data);
    } catch (err) {
      commit("setAllReportTemplates", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllISCNDetails({ commit, dispatch }) {
    try {
      commit("setAllkaryotypeISCNDetails", null);
      const response = await axios.get("/cytogenetics/karyotypeISCN");
      commit("setAllkaryotypeISCNDetails", response.data);
    } catch (err) {
      commit("setAllkaryotypeISCNDetails", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async saveISCNDetails({ commit, dispatch },params) {
    try {
      commit("setkaryotypeISCNData", null);
      const response = await axios.post("/cytogenetics/karyotypeISCN",params);
      commit("setkaryotypeISCNData", response.data);
    } catch (err) {
      commit("setkaryotypeISCNData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async updateISCNDetails({ commit, dispatch },params) {
    try {
      commit("setupdatekaryotypeISCNData", null);
      const response = await axios.put("/cytogenetics/karyotypeISCN/"+ params.ISCN_id, params);
      commit("setupdatekaryotypeISCNData", response.data);
    } catch (err) {
      commit("setupdatekaryotypeISCNData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchSingleReportTemplate({ commit, dispatch }, params) {
    try {
      commit("setSingleReportTemplate", null);
      const response = await axios.get(
        "/report-templates/" + params.template_id
      );

      commit("setSingleReportTemplate", response.data.data);
    } catch (err) {
      commit("setSingleReportTemplate", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async updateReportTemplate({ commit, dispatch }, params) {
    try {
      commit("seReportTemplateUpdateStatus", false);
      await axios.patch("/report-templates/" + params.template_id, params);
      commit("seReportTemplateUpdateStatus", true);
    } catch (err) {
      commit("seReportTemplateUpdateStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async addReportTemplate({ commit, dispatch }, params) {
    try {
      commit("setReportTemplateAddStatus", false);
      await axios.post("/report-templates", params);
      commit("setReportTemplateAddStatus", true);
    } catch (err) {
      commit("setReportTemplateAddStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async archiveReportTemplateData({ commit, dispatch }, templateId) {
    try {
      commit("setTemplateDeleteStatus", null);
      const response = await axios.delete(
        `/report-templates/${templateId}/archive`
      );
      commit("setTemplateDeleteStatus", response.data);
    } catch (err) {
      commit("setTemplateDeleteStatus", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async addReportTemplatePreview({ commit, dispatch }, params) {
    try {
      commit("setReportTemplateData", null);
      const response = await axios.post(
        "/patient-reports/report-html-preview",
        params
      );
      commit("setReportTemplateData", response.data);
    } catch (err) {
      commit("setReportTemplateData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async movingTemplatesToLive({ commit, dispatch }, params) {
    try {
      let templateId = params.template_id;
      delete params.template_id
      commit("setReportTemplateMoveStatus", false);
      await axios.post(
        `/report-template/${templateId}/move`,
        params
      );
      commit("setReportTemplateMoveStatus", true);
    } catch (err) {
      commit("setReportTemplateMoveStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  addPatientData({ commit }, params) {
    commit("setPatientPreviewReportData", params);
  },
  reset: function ({ commit }) {
    commit("reset", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
