<template>
    <div class="PapSmearAbove">
        <div class="edit-box mb-20">
            <span class="title">LAB. REF. NO.</span>
            <ckeditor :editor="editor" tag-name="div" v-model="LabRefNo" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">CLINICAL DIAGNOSIS</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Clinical_Diagnosis" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">SPECIMEN TYPE</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Specimen_type" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">SPECIMEN ADEQUACY</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Specimen_Adequacy" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">MICROSCOPY</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Microscopy"  ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">ORGANISMS</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="Organisms" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Interpretation" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" v-model="Note" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
     props:{
        PusForPapSmearAbove_data:{
            type:Array,
            required:true
        },
        patientInfo:{
            type: String, 
        }
    },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
             LabRefNo:this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].LabRefNo:'',
            Clinical_Diagnosis:this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].Clinical_Diagnosis:'',
            Specimen_type : this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].Specimen_type :'',
            Specimen_Adequacy :this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].Specimen_Adequacy :'',
            Microscopy :this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].Microscopy :'', 
            Organisms :this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].Organisms :'',
            Interpretation :this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].Interpretation :'',
            Note : this.PusForPapSmearAbove_data?.length==1?this.PusForPapSmearAbove_data[0].Note :''
        }
        
    },
    methods:{
        childMethod() {
            let dd = window.document.getElementsByClassName("ck-content");
            const LabRefNo = dd[0]?.innerHTML; 
            const Clinical_Diagnosis = dd[1]?.innerHTML; 
            const Specimen_type = dd[2]?.innerHTML; 
            const Specimen_Adequacy = dd[3]?.innerHTML; 
            const Microscopy = dd[4]?.innerHTML; 
            const Organisms = dd[5]?.innerHTML; 
            const Interpretation = dd[6]?.innerHTML; 
            const Note = dd[7]?.innerHTML; 

            const result = [{
                LabRefNo : LabRefNo ,
                Clinical_Diagnosis:Clinical_Diagnosis,
                Specimen_type:Specimen_type,
                Specimen_Adequacy:Specimen_Adequacy,
                Microscopy:Microscopy,
                Organisms:Organisms,
                Interpretation:Interpretation,
                Note:Note
            }]
            this.$emit('childMethod', result);
        }
    }
}
</script>


<style lang="scss" scoped>
.PapSmearAbove {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>