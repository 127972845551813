<template>
    <div class="CytologySlideReporting">
        <div class="edit-box mb-20">
            <span class="title">Cytology Type</span>
            <el-input placeholder="Please enter Cytology Type"  v-model="cytology_type"/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LAB. REF. NO.</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="lab_ref_no">
            </ckeditor>
        </div>
        <div class="edit-box mb-20" v-if="this.onCheckFeilds()">
            <span class="title">CLINICAL DETAILS</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="clinical_details">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">SPECIMEN</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="specimen">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">MICROSCOPY</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="microscopy">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">IMPRESSION</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="Impression">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="note">
            </ckeditor>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default {
    components: {
        ckeditor: CKEditor.component,
    },
    props: {
        patientInfo: {
            type: Array,
            default: null
        },
        subCategory: {
            type: String
        }
    },
    mounted() {
        console.log('vinay', this.patientInfo, this.subCategory)
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            resultData: "",
            references: "",
            remarks: "",
            textarea: "",
            editorConfig: EditorConfig,
            Impression: this.patientInfo[0]?.Impression ? this.patientInfo[0].Impression : '',
            lab_ref_no: this.patientInfo[0]?.lab_ref_no ? this.patientInfo[0].lab_ref_no : '',
            clinical_details: this.patientInfo[0]?.clinical_details ? this.patientInfo[0].clinical_details : '',
            specimen: this.patientInfo[0]?.specimen ? this.patientInfo[0].specimen : '',
            microscopy: this.patientInfo[0]?.microscopy ? this.patientInfo[0].microscopy : '',
            note: this.patientInfo[0]?.note ? this.patientInfo[0].note : '',
            cytology_type:this.patientInfo[0]?.cytology_type ? this.patientInfo[0].cytology_type : ''
        }

    },
    methods: {
        childMethod() {
            let dd = window.document.getElementsByClassName("ck-content");
            const lab_Ref_No = dd[0]?.innerHTML;
            const clinical_details = this.onCheckFeilds()?dd[1]?.innerHTML:'';
            const specimen = this.onCheckFeilds()?dd[2]?.innerHTML:dd[1]?.innerHTML;
            const Microscopy = this.onCheckFeilds()?dd[3]?.innerHTML:dd[2]?.innerHTML;
            const Impression = this.onCheckFeilds()?dd[4]?.innerHTML:dd[3]?.innerHTML;
            const note = this.onCheckFeilds()?dd[5]?.innerHTML:dd[4]?.innerHTML;
            const result = [{
                Impression: Impression,
                lab_ref_no: lab_Ref_No,
                clinical_details: clinical_details,
                specimen: specimen,
                microscopy: Microscopy,
                note: note,
                cytology_type:this.cytology_type
            }]
            this.$emit('childMethod', result);
        },
        onCheckFeilds() {
            const subCategorys = [
                "Fluid For Cytology", "Fluid_For_Cytology",
                "Urine For Cytology", "Urine_For_Cytology",
                "Sputum For Cytology", "Sputum_For_Cytology"
            ];
            return !subCategorys.includes(this.subCategory)
        }
    },

}
</script>


<style lang="scss" scoped>
.CytologySlideReporting {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>