import store from '@/store'

export default {

    user(to, from, next) {

        let authDetails = store.getters['auth/getAuthenticationDetails']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null
        var isAuthenticated = accessToken ? true : false
        let redirectPath = '/signin'

        next(isAuthenticated ? true : {
            path: redirectPath,
            query: {
                redirect: to.name,
                params: JSON.stringify(to.params),
                query: JSON.stringify(to.query)
            }
        })
    },
    guest(to, from, next) {

        let authDetails = store.getters['auth/getAuthenticationDetails']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null
        var isAuthenticated = accessToken ? true : false
        let redirectPath = '/dashboard'

        next(!isAuthenticated ? true : {
            path: redirectPath
        })
    },
}