<template>
  <section id="dashboard">
    <MyHeader v-if="getAuthenticatedUser && getAuthenticatedUser.user_type"></MyHeader>
    <div>
      <main class="main-dashboard-view">
        <div class="container">
          <el-row
            type="flex"
            justify="center"
          >
            <el-col>
              <router-view :layout="layout" />
            </el-col>
          </el-row>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import MyHeader from "@/components/MyHeader";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      layout: "div"
    };
  },
  components: {
    MyHeader
  },
  computed: {
    // ...mapGetters("navMenu", ["getIsSideNavOpen"]),
    ...mapGetters("auth", ["getAuthenticatedUser"])
  }
};
</script>
<style lang="scss">
#dashboard {
  height: 100vh;
  width: 100%;
  .main-dashboard-view {
    padding: 2em 0;
  }
  .footer {
    height: 100% !important;
  }
}
</style>
