<template>
  <Settings></Settings>
</template>

<script>
import Settings from "@/components/Settings";
export default {
  components: {
    Settings,
  },
};
</script>
