<template>
    <section id="add-report-view" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
      <div class="reports-title-header">
        <h4 class="report-title" v-if="getPatientResults">
          Patient Results Edit({{ getPatientResults.barcode }})
        </h4>
  
        <div class="display-end">
          <el-button @click="goToReports" class="back-btn">
            <el-icon class="el-icon-back">
              <Back />
            </el-icon>
            Back</el-button>
        </div>
      </div>
  
      <div class="inner">
        <span class="report-header">DEPARTMENT OF BIOCHEMICAL GENETICS</span>
        <div class="patient-information">
          <el-form :model="patientInfo">
            <el-row>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Visit Id">
                  <el-input v-model="patientInfo.visit_id" placeholder="Enter Visit Id" disabled></el-input>
                  <FormError errorName="visit_id"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Patient Name" class="mb-05 form-mandatory-field">
                  <el-input v-model="patientInfo.patient_name" placeholder="Enter Patient Name"></el-input>
                </el-form-item>
                <FormError errorName="patient_demographics.patient_name"></FormError>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.hospital_name" placeholder="Enter Hospital Name"></el-input>
                  <FormError errorName="ref_doctor"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.ref_doctor" placeholder="Enter Reference Doctor"></el-input>
                  <FormError errorName="ref_doctor"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Client Address" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.client_add" placeholder="Enter Client Address"></el-input>
                  <FormError errorName="client_add"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Client Code" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.client_code" placeholder="Enter Client Code"></el-input>
                  <FormError errorName="client_code"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.uhid" placeholder="Enter UHID No"></el-input>
                  <FormError errorName="uhid"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Gender" class="mb-05 mandatory-field">
                  <div class="gender-select">
                    <el-select v-model="patientInfo.gender" placeholder="Select Gender">
                      <el-option v-for="(gender, index) in genderTypes" :key="index" :label="gender"
                        :value="gender"></el-option>
                    </el-select>
                    <FormError errorName="gender"></FormError>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Age" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.age" placeholder="Enter Age"></el-input>
                  <FormError errorName="age"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Mobile" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.mobile" placeholder="Enter Mobile Number"></el-input>
                  <FormError errorName="mobile"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Phone" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.alternate_mobile" placeholder="Enter Phone Number"></el-input>
                  <FormError errorName="alternate_mobile"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Email" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.email" placeholder="Enter Email"></el-input>
                  <FormError errorName="email"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Locality" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.locality" placeholder="Enter Locality"></el-input>
                  <FormError errorName="locality"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Client Name" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.client_name" placeholder="Enter Client Name"></el-input>
                  <FormError errorName="client_name"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.sample_type" placeholder="Enter Sample Type"></el-input>
                  <FormError errorName="sample_type"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Barcode No" class="mb-05 mandatory-field">
                  <el-input v-model="patientInfo.barcode_no" placeholder="Enter Barcode No"></el-input>
                  <FormError errorName="barcode_no"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Registration Date" class="mb-05 mandatory-field">
                  <el-date-picker v-model="patientInfo.registration_date" type="date" placeholder="Pick a Date"
                    format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate">
                  </el-date-picker>
                  <FormError errorName="registration_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Collected Date" class="mb-05 mandatory-field">
                  <el-date-picker v-model="patientInfo.collected_date" type="date" placeholder="Pick a Date"
                    format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate">
                  </el-date-picker>
                  <FormError errorName="collected_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                  <el-date-picker v-model="patientInfo.reported_date" type="date" placeholder="Pick a Date"
                    format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate">
                  </el-date-picker>
                  <FormError errorName="reported_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Received Date" class="mb-05 mandatory-field">
                  <el-date-picker v-model="patientInfo.received_date" type="date" placeholder="Pick a Date"
                    format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate">
                  </el-date-picker>
                  <FormError errorName="received_date"></FormError>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
  
        <!-- SOLIDFISH UI Start -->
  
        <div class="double-marker" v-if="getPatientResults &&
          getPatientResults.test_category == 'SOLIDFISH'">
  
          <div class="edit-box mb-20">
            <span class="title">FLUORESCENCEIN IN SITU HYBRIDIZATION(FISH)[ISCN 2020]</span>
            <el-table border style="width: 100%" :data="fluorTableData">
              <el-table-column prop="sampletype" label="Sample Type" width="200"></el-table-column>
              <el-table-column prop="clinicalindication" label="Clinical Indication" width="300"></el-table-column>
              <el-table-column prop="testrequested	" label="Test Requested	">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item class="mb-0">
                        <el-input placeholder="Sample Type" value="-" v-model="rskAge_T21">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column prop="method" label="Method">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item class="mb-0">
                        <el-input placeholder="Sample Type" value="-" v-model="rskAge_T21">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
            </el-table>
          </div>
          <div class="edit-box mb-20">
            <span class="title">ResultTable:</span>
            <el-table border style="width: 100%" :data="resultTableData">
              <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
              <el-table-column prop="result" label="Result (ISCN2020)" width="300"></el-table-column>
              <el-table-column prop="chromosomeloci" label="Chromosome Loci/Color" width="200"></el-table-column>
              <el-table-column prop="noofcells" label="No Of Cells" width="160">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item class="mb-0">
                        <el-input placeholder="Sample Type" value="200/200" v-model="rskAge_T21">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
              <el-table-column prop="finalresult" label="Result" width="140">
                <el-form>
                  <el-row>
                    <el-col :span="24">
                      <el-select v-model="value" placeholder="Select">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form>
              </el-table-column>
            </el-table>
          </div>
          <div class="edit-box mb-20">
            <span class="title">Interpretation Guide</span>
            <el-input type="textarea" rows="2"
              value="Fluorescenceinsituhybridization(FISH)withanabovementionedprobepaneloninterphasecells did not show evidence of monosomy 5/del(5q), monosomy 7/del(7q), trisomy 8, del(20q) andTP53(17pdeleEon)."></el-input>
          </div>
          <div class="edit-box mb-20">
            <span class="title">References</span>
            <el-input type="textarea" rows="2"
              value="Grant E. Nybakken and Adam Bagg. The Genetic Basis and Expanding Role of Molecular Analysis in the Diagnosis, Prognosis, and Therapeutic Design for Myelodysplastic Syndromes. The Journal of Molecular Diagnostics, Vol. 16, No. 2, March 2014."></el-input>
          </div>
          
  
        </div>
  
        <!-- SOLIDFISH UI End -->
  
  
  
        <ul class="action-buttons">
          <li>
            <el-button @click="savePatientInfo" class="submit-button">Submit</el-button>
          </li>
        </ul>
        <FormError errorName="critical_error"></FormError>
      </div>
      <ResonForChange v-if="takeReasonDialog" v-on:close="takeReasonDialog = false" v-on:reasonSubmit="updateReport">
      </ResonForChange>
    </section>
  </template>
  <script>
  import {
    genderTypes,
    // nosileBoneOptions,
    // statusOptions,
  } from "@/constants/formConstants";
  import FormError from "@/components/FormError";
  import { mapGetters } from "vuex";
  import ResonForChange from "@/components/ReasonForChange"
  export default {
    components: {
      FormError,
      ResonForChange
    },
    data() {
      return {
        templateTitle: "",
        value: "",
        methodValue: "TRFIA",
        genderTypes: genderTypes,
        // nbOptions: nosileBoneOptions,
        // statusOptions: statusOptions,
        patientInfo: {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          client_add: "",
          client_code: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          client_name: "",
          barcode_no: "",
          registration_date: "",
          collected_date: "",
          reported_date: "",
          received_date: "",
          sample_type: "",
        },
        report_template_id: "",
        test_results: "",
        loading: false,
        loadingText: "",
        show: false,
        editorData: "",
        comments: "",
        recommendations: "",
        testResults: {},
  
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
        interpretationData: "",
        patientLoading: false,
        testResultsSummary: [],
        templateTitleDialogVisible: false,
        testCategory: "",
  
        rskAge_T21: "",
        rskValue_T21: "",
        rskAge_T18: "",
        rskValue_T18: "",
        rskAge_T13: "",
        rskValue_T13: "",
        rskAge_TS: "",
        rskValue_TS: "",
        riskage21: "",
        riskValue21: "",
        riskage18: "",
        riskValue18: "",
        riskage13: "",
        riskValue13: "",
        riskageTS: "",
        riskValueTS: "",
        rskAgePE32first: "",
        rskAgePE32second: "",
        rskValuePE32first: "",
        rskValuePE32Second: "",
        rskAgePE34first: "",
        rskAgePE34second: "",
        rskValuePE34first: "",
        rskValuePE34Second: "",
        rskAgePE37first: "",
        rskAgePE37second: "",
        rskValuePE37first: "",
        rskValuePE37Second: "",
        rskAgePEG37first: "",
        rskAgePEG37second: "",
        rskValuePEG37first: "",
        rskValuePEG37Second: "",
        takeReasonDialog: false,
        fluorTableData: [
          {
            sampletype: '-',
            clinicalindication: '-',
            testrequested: '',
            method: '-',
          },
        ],
        resultTableData: [
          {
            sno: '1',
            result: 'nucish (D5S23, D5S721×2),(EGR1×2)',
            chromosomeloci: '5p15.31-15.2(hTERT)-Green 5q31.2-q32 (CSF1R,EGR1)- Red',
            noofcells: '',
            finalresult: '-',
          },
          {
            sno: '2',
            result: 'nucish (D7Z1×2),(D7S486×2)',
            chromosomeloci: '7q31 (LSI D7S486 )-Red 7p11.1-q11.1 Alpha Satellite DNA (CEP7)- Green',
            noofcells: '',
            finalresult: '-',
          },
          {
            sno: '3',
            result: 'nucish (D20S108×2)	',
            chromosomeloci: 'LSI D20S108 (20q12)- Red',
            noofcells: '',
            finalresult: '-',
          },
          {
            sno: '4',
            result: 'nucish (D8Z2×2)	',
            chromosomeloci: '8p11.1-q11.1 Alpha Satellite DNA (CEP8, D8Z2)- Green	',
            noofcells: '',
            finalresult: '-',
          },
          {
            sno: '5',
            result: 'nucish (CEP 17×2)(TP53×2)	',
            chromosomeloci: '(TP53 )-Red (CEN 17)-Green	',
            noofcells: '',
            finalresult: '-',
          },
        ],
      };
    },
    computed: {
      ...mapGetters("errors", ["getErrors", "getError"]),
      ...mapGetters("resultFiles", [
        "getPatientResults",
        "getPatientResultUpdateStatus",
      ]),
    },
    async mounted() {
      await this.loadInitData();
    },
  
    methods: {
      async updateReport(reason) {
        this.addPatientInfo(reason)
      },
      askReason() {
        this.takeReasonDialog = true
      },
      async loadInitData() {
        try {
          this.loading = true;
          this.loadingText = "Fetching data...";
          await this.$store.dispatch("errors/clear", {}, { root: true });
          await this.fetchSinglePatientInfo();
  
          // await this.department();
  
          setTimeout(() => {
            this.show = true;
            this.loading = false;
          });
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      },
  
      async fetchSinglePatientInfo() {
        try {
          this.loading = true;
          this.loadingText = "Fetching data...";
          let params = {
            result_file_id: this.$route.params.result_file_id,
            patient_id: this.$route.params.patient_id,
          };
          await this.$store.dispatch(
            "resultFiles/fetchSinglePatientResults",
            params
          );
          await this.setPatientData();
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
      setPatientRiskValues() {
        if (this.testResultsSummary.rskAge_T21) {
          const riskage21 = this.testResultsSummary.rskAge_T21.split(":");
          this.riskage21 = riskage21[0];
          this.rskAge_T21 = riskage21[1];
        }
        if (this.testResultsSummary.rskValue_T21) {
          const riskValue21 = this.testResultsSummary.rskValue_T21.split(":");
          this.riskValue21 = riskValue21[0];
          this.rskValue_T21 = riskValue21[1];
        }
        if (this.testResultsSummary.rskAge_T18) {
          const riskage18 = this.testResultsSummary.rskAge_T18.split(":");
          this.riskage18 = riskage18[0];
          this.rskAge_T18 = riskage18[1];
        }
        if (this.testResultsSummary.rskValue_T18) {
          const riskValue18 = this.testResultsSummary.rskValue_T18.split(":");
          this.riskValue18 = riskValue18[0];
          this.rskValue_T18 = riskValue18[1];
        }
        if (this.testResultsSummary.rskAge_T13) {
          const riskage13 = this.testResultsSummary.rskAge_T13.split(":");
          this.riskage13 = riskage13[0];
          this.rskAge_T13 = riskage13[1];
        }
        if (this.testResultsSummary.rskValue_T13) {
          const riskValue13 = this.testResultsSummary.rskValue_T13.split(":");
          this.riskValue13 = riskValue13[0];
          this.rskValue_T13 = riskValue13[1];
        }
        if (this.testResultsSummary.rskAge_TS) {
          const riskageTS = this.testResultsSummary.rskAge_TS.split(":");
          this.riskageTS = riskageTS[0];
          this.rskAge_TS = riskageTS[1];
        }
        if (this.testResultsSummary.rskValue_TS) {
          const riskValueTS = this.testResultsSummary.rskValue_TS.split(":");
          this.riskValueTS = riskValueTS[0];
          this.rskValue_TS = riskValueTS[1];
        }
        if (this.testResultsSummary.rskAge_PE_LT_32) {
          const riskage = this.testResultsSummary.rskAge_PE_LT_32.split(":");
          this.rskAgePE32first = riskage[0];
          this.rskAgePE32second = riskage[1];
        }
        if (this.testResultsSummary.rskValue_PE_LT_32) {
          const riskage = this.testResultsSummary.rskValue_PE_LT_32.split(":");
          this.rskValuePE32first = riskage[0];
          this.rskValuePE32Second = riskage[1];
        }
  
        if (this.testResultsSummary.rskAge_PE_LT_34) {
          const riskage = this.testResultsSummary.rskAge_PE_LT_34.split(":");
          this.rskAgePE34first = riskage[0];
          this.rskAgePE34second = riskage[1];
        }
        if (this.testResultsSummary.rskValue_PE_LT_34) {
          const riskage = this.testResultsSummary.rskValue_PE_LT_34.split(":");
          this.rskValuePE34first = riskage[0];
          this.rskValuePE34Second = riskage[1];
        }
  
        if (this.testResultsSummary.rskAge_PE_LT_37) {
          const riskage = this.testResultsSummary.rskAge_PE_LT_37.split(":");
          this.rskAgePE37first = riskage[0];
          this.rskAgePE37second = riskage[1];
        }
        if (this.testResultsSummary.rskValue_PE_LT_37) {
          const riskage = this.testResultsSummary.rskValue_PE_LT_37.split(":");
          this.rskValuePE37first = riskage[0];
          this.rskValuePE37Second = riskage[1];
        }
        if (this.testResultsSummary.rskAge_PE_GE_37) {
          const riskage = this.testResultsSummary.rskAge_PE_GE_37.split(":");
          this.rskAgePEG37first = riskage[0];
          this.rskAgePEG37second = riskage[1];
        }
        if (this.testResultsSummary.rskValue_PE_GE_37) {
          const riskage = this.testResultsSummary.rskValue_PE_GE_37.split(":");
          this.rskValuePEG37first = riskage[0];
          this.rskValuePEG37Second = riskage[1];
        }
      },
      async setPatientData() {
        if (
          this.getPatientResults &&
          this.getPatientResults.test_results_summary &&
          this.getPatientResults.test_results_summary.length
        ) {
          this.testResultsSummary =
            this.getPatientResults.test_results_summary[0];
        }
        this.setPatientRiskValues();
        if (
          this.getPatientResults &&
          this.getPatientResults.patient_demographics
        ) {
          this.patientInfo = {
            ...this.patientInfo,
            ...this.getPatientResults.patient_demographics,
          };
        }
        if (this.getPatientResults && this.getPatientResults.sample_type) {
          this.patientInfo.sample_type = this.getPatientResults.sample_type;
        }
        if (this.getPatientResults && this.getPatientResults.comments) {
          this.comments = this.getPatientResults.comments;
        }
        if (this.getPatientResults && this.getPatientResults.recommendations) {
          this.recommendations = this.getPatientResults.recommendations;
        }
        this.testCategory = this.getPatientResults.test_category;
      },
  
      goToReports() {
        if (this.$route.query && this.$route.query.fromRoute) {
          this.$router.go(-1);
        } else {
          this.$router.push({
            name: "PatientResultFiles",
            query: this.$route.query,
          });
        }
      },
      savePatientInfo() {
        if (this.getPatientResults &&
          this.getPatientResults.is_finalized &&
          (this.$route.params.result_file_id || this.$route.params.patient_id)) {
          this.askReason()
        } else {
          this.addPatientInfo();
        }
      },
      async addPatientInfo(reason) {
        try {
          this.loading = true;
          this.loadingText = "Loading...";
          let patient_demographics = { ...this.patientInfo };
  
          if (patient_demographics.client_code != "") {
            const code = patient_demographics.client_code.toString();
            patient_demographics.client_code = code;
          }
  
          let params = { patient_demographics };
  
          if (this.report_template_id) {
            params.report_template_id = this.report_template_id || "";
          }
          if (this.riskage21 && this.rskAge_T21) {
            this.testResultsSummary.rskAge_T21 =
              this.riskage21 + ":" + this.rskAge_T21;
          } else if (this.rskAge_T21) {
            this.testResultsSummary.rskAge_T21 = 1 + ":" + this.rskAge_T21;
          } else {
            this.testResultsSummary.rskAge_T21 = "";
          }
          if (this.riskValue21 && this.rskValue_T21) {
            this.testResultsSummary.rskValue_T21 =
              this.riskValue21 + ":" + this.rskValue_T21;
          } else if (this.rskValue_T21) {
            this.testResultsSummary.rskValue_T21 = 1 + ":" + this.rskValue_T21;
          } else {
            this.testResultsSummary.rskValue_T21 = "";
          }
          if (this.riskage18 && this.rskAge_T18) {
            this.testResultsSummary.rskAge_T18 =
              this.riskage18 + ":" + this.rskAge_T18;
          } else if (this.rskAge_T18) {
            this.testResultsSummary.rskAge_T18 = 1 + ":" + this.rskAge_T18;
          } else {
            this.testResultsSummary.rskAge_T18 = "";
          }
          if (this.riskValue18 && this.rskValue_T18) {
            this.testResultsSummary.rskValue_T18 =
              this.riskValue18 + ":" + this.rskValue_T18;
          } else if (this.rskValue_T18) {
            this.testResultsSummary.rskValue_T18 = 1 + ":" + this.rskValue_T18;
          } else {
            this.testResultsSummary.rskValue_T18 = "";
          }
  
          if (this.riskage13 && this.rskAge_T13) {
            this.testResultsSummary.rskAge_T13 =
              this.riskage13 + ":" + this.rskAge_T13;
          } else if (this.rskAge_T13) {
            this.testResultsSummary.rskAge_T13 = 1 + ":" + this.rskAge_T13;
          } else {
            this.testResultsSummary.rskAge_T13 = "";
          }
          if (this.riskValue13 && this.rskValue_T13) {
            this.testResultsSummary.rskValue_T13 =
              this.riskValue13 + ":" + this.rskValue_T13;
          } else if (this.rskValue_T13) {
            this.testResultsSummary.rskValue_T13 = 1 + ":" + this.rskValue_T13;
          } else {
            this.testResultsSummary.rskValue_T13 = "";
          }
          if (this.riskageTS && this.rskAge_TS) {
            this.testResultsSummary.rskAge_TS =
              this.riskageTS + ":" + this.rskAge_TS;
          } else if (this.rskAge_TS) {
            this.testResultsSummary.rskAge_TS = 1 + ":" + this.rskAge_TS;
          } else {
            this.testResultsSummary.rskAge_TS = "";
          }
          if (this.riskValueTS && this.rskValue_TS) {
            this.testResultsSummary.rskValue_TS =
              this.riskValueTS + ":" + this.rskValue_TS;
          } else if (this.rskValue_TS) {
            this.testResultsSummary.rskValue_TS = 1 + ":" + this.rskValue_TS;
          } else {
            this.testResultsSummary.rskValue_TS = "";
          }
          if (this.rskAgePE32first && this.rskAgePE32second) {
            this.testResultsSummary.rskAge_PE_LT_32 =
              this.rskAgePE32first + ":" + this.rskAgePE32second;
          } else if (this.rskAgePE32second) {
            this.testResultsSummary.rskAge_PE_LT_32 =
              1 + ":" + this.rskAgePE32second;
          } else {
            this.testResultsSummary.rskAge_PE_LT_32 = "";
          }
          if (this.rskValuePE32first && this.rskValuePE32Second) {
            this.testResultsSummary.rskValue_PE_LT_32 =
              this.rskValuePE32first + ":" + this.rskValuePE32Second;
          } else if (this.rskValuePE32Second) {
            this.testResultsSummary.rskValue_PE_LT_32 =
              1 + ":" + this.rskValuePE32Second;
          } else {
            this.testResultsSummary.rskValue_PE_LT_32 = "";
          }
          if (this.rskAgePE34first && this.rskAgePE34second) {
            this.testResultsSummary.rskAge_PE_LT_34 =
              this.rskAgePE34first + ":" + this.rskAgePE34second;
          } else if (this.rskAgePE34second) {
            this.testResultsSummary.rskAge_PE_LT_34 =
              this.rskAgePE34first + ":" + this.rskAgePE34second;
          } else {
            this.testResultsSummary.rskAge_PE_LT_34 = "";
          }
          if (this.rskValuePE34first && this.rskValuePE34Second) {
            this.testResultsSummary.rskValue_PE_LT_34 =
              this.rskValuePE34first + ":" + this.rskValuePE34Second;
          } else if (this.rskValuePE34Second) {
            this.testResultsSummary.rskValue_PE_LT_34 =
              1 + ":" + this.rskValuePE34Second;
          } else {
            this.testResultsSummary.rskValue_PE_LT_34 = "";
          }
  
          if (this.rskAgePE37first && this.rskAgePE37second) {
            this.testResultsSummary.rskAge_PE_LT_37 =
              this.rskAgePE37first + ":" + this.rskAgePE37second;
          } else if (this.rskAgePE37second) {
            this.testResultsSummary.rskAge_PE_LT_37 =
              1 + ":" + this.rskAgePE37second;
          } else {
            this.testResultsSummary.rskAge_PE_LT_37 = "";
          }
  
          if (this.rskValuePE37first && this.rskValuePE37Second) {
            this.testResultsSummary.rskValue_PE_LT_37 =
              this.rskValuePE37first + ":" + this.rskValuePE37Second;
          } else if (this.rskValuePE37Second) {
            this.testResultsSummary.rskValue_PE_LT_37 =
              1 + ":" + this.rskValuePE37Second;
          }
  
          if (this.rskAgePEG37first && this.rskAgePEG37second) {
            this.testResultsSummary.rskAge_PE_GE_37 =
              this.rskAgePEG37first + ":" + this.rskAgePEG37second;
          } else if (this.rskAgePEG37second) {
            this.testResultsSummary.rskAge_PE_GE_37 =
              1 + ":" + this.rskAgePEG37second;
          } else {
            this.testResultsSummary.rskAge_PE_GE_37 = "";
          }
  
          if (this.rskValuePEG37first && this.rskValuePEG37Second) {
            this.testResultsSummary.rskValue_PE_GE_37 =
              this.rskValuePEG37first + ":" + this.rskValuePEG37Second;
          } else if (this.rskValuePEG37Second) {
            this.testResultsSummary.rskValue_PE_GE_37 =
              1 + ":" + this.rskValuePEG37Second;
          } else {
            this.testResultsSummary.rskValue_PE_GE_37 = "";
          }
  
          params.test_results_summary = [this.testResultsSummary];
  
          params.recommendations = this.recommendations;
          params.comments = this.comments;
  
          let patientId = this.$route.params.patient_id;
          let resultFileId = this.$route.params.result_file_id;
          if (reason && reason.reason) {
            params.amendment_reason = reason.reason
          }
          if (reason && reason.is_amendment) {
            params.is_amendment = reason.is_amendment
          }
          await this.$store.dispatch("resultFiles/updatePatientResults", {
            params,
            patientId,
            resultFileId,
          });
          if (this.getPatientResultUpdateStatus) {
            this.$notify.success({
              title: "Success",
              message: "Patient Report Updated Successfully",
            });
            await this.goToReports();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error while updating Report",
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .lower_abnormal {
    font-weight: bold;
  }
  
  .upper_abnormal {
    font-weight: bold;
    background-color: #b7d2ec;
  }
  </style>
  <style lang="scss">
  #add-report-view {
    max-width: 900px;
    margin: 0 auto;
  
    .reports-title-header {
      display: grid;
      grid-template-columns: 88% auto;
      margin-bottom: 15px;
  
      .display-end {
        display: flex;
        text-align: end;
      }
  
      .report-title {
        text-align: center;
        color: #000;
        font-size: 20px;
        font-weight: 500;
      }
    }
  
    .inner-navbar {
      margin-bottom: 10px;
  
      .mt-10 {
        margin-top: 10px;
      }
  
      .filter-navigation {
        display: flex;
        justify-content: space-between;
  
        // align-items: center;
        li {
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  
    .tms-report-divider {
      width: 100%;
      height: 3px;
      background: #b7d2ec;
      margin: 15px 0;
    }
  
    @media print {
      .tms-report-divider {
        display: none;
      }
    }
  
    .inner {
      margin-bottom: 50px;
  
      .report-header {
        margin: 0;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        background: #2eaf9f;
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
  
      border: 1px solid rgba(7, 162, 149, 0.6);
      border-radius: 10px;
      padding: 0;
      background: #fff;
      overflow: hidden;
  
      .patient-information {
        .el-form-item {
          margin-bottom: 0;
        }
  
        .form-mandatory-field {
          .el-form-item__label:after {
            content: "*";
            color: red !important;
            font-size: 15px;
            line-height: 1;
            padding-left: 3px;
          }
        }
  
        .el-form-item__label {
          min-width: 40%;
          text-align: left;
          background: #e0f3f1;
          padding-left: 10px;
          border-bottom: 1px solid #e0f3f1;
          font-weight: 500;
          font-size: 11px;
          color: #000;
        }
  
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
  
        .el-input__inner {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          border-left: none;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          border-top: none;
          border-radius: 0;
          color: #000;
          font-weight: 500;
  
          // text-transform: uppercase;
          &::placeholder {
            color: #5a5a5a;
            font-size: 13px;
            text-transform: capitalize;
          }
        }
      }
  
      .report-sub-title {
        color: #43454b;
        font-size: 18px;
        text-align: center;
        margin-top: -1px;
        padding-top: 1rem;
        border-top: 1.2px solid rgba(0, 0, 0, 0.2);
  
        font-weight: 500;
      }
  
      .report-type-edit-info {
        display: flex;
        margin-top: 0.5em;
        padding: 0 10px;
        gap: 5px;
      }
  
      .editor,
      .comments-editor {
        margin-top: 0.5em;
        padding: 10px;
  
        .ck-editor__editable {
          min-height: 320px;
        }
      }
  
      .image__caption_highlighted {
        min-height: auto !important;
      }
  
      .ck-dropdown__panel {
        max-height: 230px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
  
      .ck-placeholder {
        min-height: auto !important;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #2eaf9f;
      }
  
      .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        margin: 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
  
        li {
          &:not(:last-child) {
            margin-right: 20px;
          }
  
          .el-button {
            padding: 9px 15px;
            min-height: auto;
            line-height: 1;
  
            &.preview-button {
              background: #28b4a6;
              color: #fff;
              font-weight: 500;
            }
  
            &.print-button {
              background: #f56c6c !important;
            }
  
            &.submit-button {
              background: #0061ec;
              color: #fff;
              font-weight: 500;
            }
          }
        }
      }
    }
  
    figure {
      &.table {
        br {
          display: none;
        }
  
        br[data-cke-filler="true"] {
          display: block !important;
        }
      }
    }
  
    .ck {
      ul {
        list-style: disc;
        padding-left: 30px;
  
        li {
          padding-left: 5px;
          list-style-type: disc !important;
        }
      }
    }
  
    .table-data {
      padding-top: 1em;
    }
  
    .el-table {
      .el-table thead {
        background-color: #28b4a6;
        font-weight: 500;
      }
    }
  
    // .input-error {
    //   .el-input__inner {
    //     border: 1.5px solid red !important;
    //   }
    //   ::placeholder {
    //     color: red !important;
    //     font-weight: 500;
    //   }
    // }
    .result-table {
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
  
      td,
      th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
    }
  }
  
  .double-marker {
    padding: 10px 0 20px;
  
    .text-area-sub-header {
      padding: 5px 20px;
    }
  
    .el-textarea__inner {
      width: 100%;
      margin: auto;
    }
  }
  </style>
  
  <style lang="scss">
  .bio-chemistry-report-title {
    text-align: center;
    background: #e0f3f1;
    border-radius: 5px;
    margin-top: 10px !important;
    padding: 6px 0;
    font-weight: 500;
    font-size: 15px;
    color: #333;
  }
  
  .bio-chemistry-data-table {
    border: 1px solid rgba(7, 162, 149, 0.6);
    margin: 20px 10px 10px 10px;
    padding: 0 !important;
  
    table {
      th {
        background: #2eaf9f;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        padding: 4px 10px;
        letter-spacing: 0;
        position: relative;
        text-align: left;
  
        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 2px;
            bottom: 2px;
            background: rgba(255, 255, 255, 0.6);
            width: 1px;
          }
        }
  
        .cell {
          padding: 0;
        }
      }
  
      td {
        padding: 5px 10px;
        font-size: 14px;
        border-bottom: 1px solid #2eaf9f;
        font-weight: 500;
        color: #000;
  
        &:not(:last-child) {
          border-right: 1px solid #2eaf9f;
        }
  
        .cell {
          padding: 0;
        }
      }
    }
  }
  
  .tms-report-editor-table {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  
    .data-table {
      margin-bottom: 20px;
  
      table {
        border-collapse: collapse;
        width: 100%;
  
        th,
        td {
          padding: 5px 10px !important;
          font-size: 15px;
        }
  
        th {
          background: #f5f5f5;
          font-weight: 500;
        }
  
        .table-head {
          background: #28b4a6;
          color: #fff;
          text-align: center !important;
          font-size: 15px !important;
  
          .cell {
            line-height: 20px !important;
          }
        }
      }
    }
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  </style>
  