import axios from "@/config/axios"
import * as PrivateAxios from "axios"

// initial state
const initialState = () => {
  return {
    acknowledgements: null,
    singleAcknowledgement: null,
    addAcknowledgements: false,
    acknowledgementUpdateStatus: false,
    exportAcknowledgementData: false,
    acknowledgementStatus: false,
    deleteAcknowledgementStatus: false,
    searchBarcodeData: null,
    resendEmail: false,
    addCommentsStatus: false,
    deleteCommentStatus: false,
    allAcknowledgementComments: null,
    updateCommentStatus: false,
    acknowledgementLogs: null,
    acknowledgementEmailLogs: null,
    sendWhatsappStatus: false,
    sendSMSStatus: false,
    rejectedReasons: [],
    clientEmails: [],
    testNames: [],
    departments: [],
    attachmentUpload: null,
    deleteAttachment: false,
    downloadAttachment: null,
    updateAttachments: null,
  }
}

const state = initialState()

const getters = {
  getAllAcknowledgements: (state) => state.acknowledgements,
  getSingleAcknowledgement: (state) => state.singleAcknowledgement,
  getAddAcknowledgements: (state) => state.addAcknowledgements,
  getAcknowledgementUpdateStatus: (state) => state.acknowledgementUpdateStatus,
  getExportAcknowledgementData: (state) => state.exportAcknowledgementData,
  getAcknowledgementStatus: (state) => state.acknowledgementStatus,
  getDeleteAcknowledgementStatus: (state) => state.deleteAcknowledgementStatus,
  getBarcodeData: (state) => state.searchBarcodeData,
  getResendEmail: (state) => state.resendEmail,
  getAddCommentsStatus: (state) => state.addCommentsStatus,
  getDeleteCommentStatus: (state) => state.deleteCommentStatus,
  getAllAcknowledgementComments: (state) => state.allAcknowledgementComments,
  getUpdateCommentStatus: (state) => state.updateCommentStatus,
  getAllAcknowledgementLogs: (state) => state.acknowledgementLogs,
  getAcknowledgementEmailLogs: (state) => state.acknowledgementEmailLogs,
  getSendWhatsappStatus: (state) => state.sendWhatsappStatus,
  getSendSMSStatus: (state) => state.sendSMSStatus,
  getRejectedReasons: (state) => state.rejectedReasons,
  getClientEmails: (state) => state.clientEmails,
  getTestNames: (state) => state.testNames,
  getAllDepartments: (state) => state.departments,
  getAttachmentUploadData: (state) => state.attachmentUpload,
  getDeleteAttachmentStatus: (state) => state.deleteAttachment,
  getDownloadAttachment: (state) => state.downloadAttachment,
  getUpdateAttachmentsStatus: (state) => state.updateAttachments,
}

const mutations = {
  setAllAcknowledgements(state, data) {
    state.acknowledgements = data
  },
  setSingleAcknowledgement(state, data) {
    state.singleAcknowledgement = data
  },
  setAcknowledgements(state, data) {
    state.addAcknowledgements = data
  },

  setAcknowledgementUpdateStatus(state, data) {
    state.acknowledgementUpdateStatus = data
  },
  setExportAcknowledgementData(state, data) {
    state.exportAcknowledgementData = data
  },
  setAcknowledgementStatus(state, data) {
    state.acknowledgementStatus = data
  },
  setDeleteAcknowledgementStatus(state, data) {
    state.deleteAcknowledgementStatus = data
  },
  setBarcodeData(state, data) {
    state.searchBarcodeData = data
  },
  setResendEmail(state, data) {
    state.resendEmail = data
  },
  setAddCommentsStatus(state, data) {
    state.addCommentsStatus = data
  },
  setDeleteCommentStatus(state, data) {
    state.deleteCommentStatus = data
  },
  setAllAcknowledgementComments(state, data) {
    state.allAcknowledgementComments = data
  },
  setUpdateCommentStatus(state, data) {
    state.updateCommentStatus = data
  },
  setAllAcknowledgementLogs(state, data) {
    state.acknowledgementLogs = data
  },
  setAllAcknowledgementEmailLogs(state, data) {
    state.acknowledgementEmailLogs = data
  },
  setSendWhatsappStatus(state, data) {
    state.sendWhatsappStatus = data
  },
  setSendSMSStatus(state, data) {
    state.sendSMSStatus = data
  },
  setRejectedReasons(state, data) {
    state.rejectedReasons = data
  },
  setClientEmails(state, data) {
    state.clientEmails = data
  },
  setTestNames(state, data) {
    state.testNames = data
  },
  setDepartments(state, data) {
    state.departments = data
  },
  setAttachmentUpload(state, data) {
    state.attachmentUpload = data
  },
  setDeleteAttachment(state, data) {
    state.deleteAttachment = data
  },
  setDownloadAttachment(state, data) {
    state.downloadAttachment = data
  },
  setUpdateAttachments(state, data) {
    state.updateAttachments = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  async fetchAllAcknowledgements({ commit, dispatch }, params) {
    try {
      commit("setAllAcknowledgements", null)
      const response = await axios.get("/acknowledgements", { params })

      commit("setAllAcknowledgements", response.data)
    } catch (err) {
      commit("setAllAcknowledgements", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAllRejectedReasons({ commit, dispatch }, params) {
    try {
      commit("setRejectedReasons", [])
      const response = await axios.get("/acknowledgements/list/reasons", {
        params,
      })

      commit("setRejectedReasons", response.data)
    } catch (err) {
      commit("setRejectedReasons", [])
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAllClientEmails({ commit, dispatch }, params) {
    try {
      commit("setClientEmails", [])
      const response = await axios.get("/acknowledgements/list/emails", {
        params,
      })

      commit("setClientEmails", response.data)
    } catch (err) {
      commit("setClientEmails", [])
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAllTestNames({ commit, dispatch }, params) {
    try {
      commit("setTestNames", [])
      const response = await axios.get("/patients/tests", {
        params,
      })

      commit("setTestNames", response.data)
    } catch (err) {
      commit("setTestNames", [])
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchDepartments({ commit, dispatch }, params) {
    try {
      commit("setDepartments", [])
      const response = await axios.get("/reply-to/departments", {
        params,
      })
      commit("setDepartments", response.data)
    } catch (err) {
      commit("setDepartments", [])
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAcknowledgementsById({ commit, dispatch }, params) {
    try {
      commit("setSingleAcknowledgement", null)
      const response = await axios.get("/acknowledgements/" + params)

      commit("setSingleAcknowledgement", response.data.data)
    } catch (err) {
      commit("setSingleAcknowledgement", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async updateAcknowledgement({ commit, dispatch }, { params, id }) {
    try {
      commit("setAcknowledgementUpdateStatus", false)
      await axios.patch("/acknowledgements/" + id, params)
      commit("setAcknowledgementUpdateStatus", true)
    } catch (err) {
      commit("setAcknowledgementUpdateStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async addAcknowledgement({ commit, dispatch }, params) {
    try {
      commit("setAcknowledgements", false)
      await axios.post("/acknowledgements", params)
      commit("setAcknowledgements", true)
    } catch (err) {
      commit("setAcknowledgements", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async uploadAttachment({ commit, dispatch }, { params, file }) {
    try {
      commit("setAttachmentUpload", null)
      const { data } = await axios.post(
        "/acknowledgements/upload-a-file/ack-proofs",
        params
      )
      if (data.success && data.data && data.data.target_url) {
        let pAxios = PrivateAxios.create({})
        await pAxios.put(data.data.target_url, file.raw)
      } else {
        commit("setAttachmentUpload", null)
      }
      commit("setAttachmentUpload", data.data)
    } catch (err) {
      commit("setAttachmentUpload", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async updateAttachment(
    { commit, dispatch },
    { params, acknowledgement_id, file }
  ) {
    try {
      commit("setUpdateAttachments", null)
      const { data } = await axios.patch(
        "/acknowledgements/" + acknowledgement_id + "/attachment",
        params
      )
      if (data.success && data.data && data.data.target_url) {
        let pAxios = PrivateAxios.create({})
        await pAxios.put(data.data.target_url, file.raw)
      } else {
        commit("setUpdateAttachments", null)
      }
      commit("setUpdateAttachments", data.data)
    } catch (err) {
      commit("setUpdateAttachments", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async exportAcknowledgementData({ commit, dispatch }, params) {
    try {
      commit("setExportAcknowledgementData", false)
      let response = await axios.get("/acknowledgements-list/export-as-csv", {
        params,
      })
      commit("setExportAcknowledgementData", true)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "acknowledgements.csv") //or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      commit("setExportAcknowledgementData", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  updateAcknowledgementStatus: async (
    { commit, dispatch },
    { acknowledgement_id, data }
  ) => {
    try {
      commit("errors/clear", null, { root: true })
      commit("setAcknowledgementStatus", false)
      await axios.patch(
        `/acknowledgements/${acknowledgement_id}/status-update`,
        data
      )
      commit("setAcknowledgementStatus", true)
    } catch (error) {
      commit("setAcknowledgementStatus", false)
      await dispatch("errors/errorResponse", error.response, { root: true })
    }
  },
  async deleteAcknowledgement({ commit, dispatch }, params) {
    try {
      commit("setDeleteAcknowledgementStatus", false)
      await axios.delete("/acknowledgements/" + params)

      commit("setDeleteAcknowledgementStatus", true)
    } catch (err) {
      commit("setDeleteAcknowledgementStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchBarcodeData({ commit, dispatch }, barcode_no) {
    try {
      commit("setBarcodeData", null)
      const response = await axios.get("/patient/" + barcode_no)

      commit("setBarcodeData", response.data.data)
    } catch (err) {
      commit("setBarcodeData", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async resendEmails({ commit, dispatch }, { params, acknowledgement_id }) {
    try {
      commit("setResendEmail", false)
      await axios.post(
        "/acknowledgements/resend-email/" + acknowledgement_id,
        params
      )
      commit("setResendEmail", true)
    } catch (err) {
      commit("setResendEmail", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async addAcknowledgementComments(
    { commit, dispatch },
    { params, acknowledgement_id }
  ) {
    try {
      commit("setAddCommentsStatus", false)
      await axios.post(
        "/acknowledgements/" + acknowledgement_id + "/comments",
        params
      )
      commit("setAddCommentsStatus", true)
    } catch (err) {
      commit("setAddCommentsStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async deleteAcknowledgementComment({ commit, dispatch }, params) {
    try {
      let acknowledgement_id = params.acknowledgement_id
      let comment_id = params.comment_id
      commit("setDeleteCommentStatus", false)
      await axios.delete(
        "/acknowledgements/" + acknowledgement_id + "/comments/" + comment_id
      )
      commit("setDeleteCommentStatus", true)
    } catch (err) {
      commit("setDeleteCommentStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async updateAcknowledgementComment({ commit, dispatch }, params) {
    try {
      let acknowledgement_id = params.acknowledgement_id
      let comment_id = params.comment_id
      commit("setUpdateCommentStatus", false)
      await axios.patch(
        "/acknowledgements/" + acknowledgement_id + "/comments/" + comment_id,
        params
      )
      commit("setUpdateCommentStatus", true)
    } catch (err) {
      commit("setUpdateCommentStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAllAcknowledgementComments(
    { commit, dispatch },
    acknowledgement_id
  ) {
    try {
      commit("setAllAcknowledgementComments", null)
      const response = await axios.get(
        "/acknowledgements/" + acknowledgement_id + "/comments"
      )

      commit("setAllAcknowledgementComments", response.data.data)
    } catch (err) {
      commit("setAllAcknowledgementComments", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAllAcknowledgementsLogs({ commit, dispatch }, acknowledgement_id) {
    try {
      commit("setAllAcknowledgementLogs", null)
      const response = await axios.get(
        "/acknowledgements/" + acknowledgement_id + "/audit-logs"
      )

      commit("setAllAcknowledgementLogs", response.data)
    } catch (err) {
      commit("setAllAcknowledgementLogs", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async fetchAllAcknowledgementsEmailLogs(
    { commit, dispatch },
    acknowledgement_id
  ) {
    try {
      commit("setAllAcknowledgementEmailLogs", null)
      const response = await axios.get(
        "/acknowledgements/" + acknowledgement_id + "/email-logs"
      )

      commit("setAllAcknowledgementEmailLogs", response.data)
    } catch (err) {
      commit("setAllAcknowledgementEmailLogs", null)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async resendWhatsapp({ commit, dispatch }, params) {
    try {
      let acknowledgement_id = params.acknowledgement_id
      commit("setSendWhatsappStatus", false)
      await axios.post(
        "/acknowledgements/notifications/" +
          acknowledgement_id +
          "/resend-whatsapp"
      )
      commit("setSendWhatsappStatus", true)
    } catch (err) {
      commit("setSendWhatsappStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async reSendSMS({ commit, dispatch }, params) {
    try {
      let acknowledgement_id = params.acknowledgement_id
      commit("setSendSMSStatus", false)
      await axios.post(
        "/acknowledgements/notifications/" + acknowledgement_id + "/resend-sms",
        params
      )
      commit("setSendSMSStatus", true)
    } catch (err) {
      commit("setSendSMSStatus", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async deleteAttachment({ commit, dispatch }, { attachment_id, params }) {
    try {
      commit("setDeleteAttachment", false)
      let dataSend = {
        proof: params.proof,
      }
      await axios.delete("/acknowledgements/" + attachment_id + "/attachment", {
        data: dataSend,
      })

      commit("setDeleteAttachment", true)
    } catch (err) {
      commit("setDeleteAttachment", false)
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  downloadAttachmentAcknowledge: async ({ commit }, params) => {
    try {
      commit("setDownloadAttachment", null)
      let response = await axios.post(
        "/acknowledgements/get-a-file/ack-proofs",
        {
          path: params.proof,
        }
      )
      commit("setDownloadAttachment", response.data.data)
    } catch (error) {
      commit("setDownloadAttachment", null)
    }
  },
  reset: function ({ commit }) {
    commit("reset", null)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
