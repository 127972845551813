import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    allPatientReports: null,
    singlePatientReports: null,
    patientReportPreviewData: null,
    patientAddStatus: false,
    patientDataOnSave: null,
    patientUpdateStatus: false,
    patientReportDownloadStatus: false,
    reportDownloadUrl: null,
    reportDownloadData: null,
    reportApproveStatus: false,
    reportApproveStatuses: false,
    reportGenerateStatus: false,
    patientReportPreview: null,
    reportVerifyStatus: false,
    patientDetails: null,
    patientReportData: null,
    deparments: null,
    reportTypes: null,
    reportDispatchStatus: false,
    sendWhatsAppReportStatus: false,
    emailSentStatus: false,
    smsSentStatus: false,
    doctorWhatsappSentStatus: false,
    doctorEmailSentStatus: false,
    resyncLisReport: null,
    resyncResultFileLisReport: null,
    reportsLogs: null,
    allclinicaldetails:null
  };
};

const state = initialState();

const getters = {
  getAllallclinicaldetails: (state) => state.allclinicaldetails,
  getPatientDetails: (state) => state.patientDetails,
  getAllPatientReports: (state) => state.allPatientReports,
  getSinglePatientReports: (state) => state.singlePatientReports,
  getPatientReportPreviewData: (state) => state.patientReportPreviewData,
  getPatientAddStatus: (state) => state.patientAddStatus,
  getPatientDataOnSave: (state) => state.patientDataOnSave,
  getPatientUpdateStatus: (state) => state.patientUpdateStatus,
  getReportDownloadStatus: (state) => state.patientReportDownloadStatus,
  getReportDownloadUrl: (state) => state.reportDownloadUrl,
  getReportDownloadData: (state) => state.reportDownloadData,
  getReportApproveStatus: (state) => state.reportApproveStatus,
  getReportApproveStatuses: (state) => state.reportApproveStatuses,
  getReportDispatchStatus: (state) => state.reportDispatchStatus,
  getReportGenerateStatus: (state) => state.reportGenerateStatus,
  getPatientReportPreview: (state) => state.patientReportPreview,
  getReportVerifyStatus: (state) => state.reportVerifyStatus,
  getPatientReportData: (state) => state.patientReportData,
  getDepartments: (state) => state.deparments,
  getReportTypes: (state) => state.reportTypes,
  getWhatsAppReportStatus: (state) => state.sendWhatsAppReportStatus,
  getEmailSentStatus: (state) => state.emailSentStatus,
  getSmsSentStatus: (state) => state.smsSentStatus,
  getDoctorWhatsappSentStatus: (state) => state.doctorWhatsappSentStatus,
  getDoctorEmailSentStatus: (state) => state.doctorEmailSentStatus,
  getResyncLisReport: (state) => state.resyncLisReport,
  getResyncResultFileLisReport: (state) => state.resyncResultFileLisReport,
  getReportsLogs: (state) => state.reportsLogs,

};

const mutations = {
  setAllclinicaldetails(state, data) {
    state.allclinicaldetails = data;
  },
  setPatientData(state, data) {
    state.patientDetails = data;
  },
  setDepartments(state, data) {
    state.deparments = data;
  },
  setAllPatientReports(state, data) {
    state.allPatientReports = data;
  },
  setSinglePatientReportData(state, data) {
    state.singlePatientReports = data;
  },
  setPatientReportData(state, data) {
    state.patientReportPreviewData = data;
  },
  setPatientAddStatus(state, status) {
    state.patientAddStatus = status;
  },
  setPatientDataOnSave(state, data) {
    state.patientDataOnSave = data;
  },
  setPatientUpdateStatus(state, status) {
    state.patientUpdateStatus = status;
  },
  setReportDownloadStatus(state, status) {
    state.patientReportDownloadStatus = status;
  },
  setReportDownloadUrl(state, data) {
    state.reportDownloadUrl = data;
  },
  setReportDownloadData(state, data) {
    state.reportDownloadData = data;
  },
  setReportApproveStatus(state, status) {
    state.reportApproveStatus = status;
  },
  setReportApproveStatuses(state, status) {
    state.reportApproveStatuses = status;
  },
  setReportDispatchStatus(state, status) {
    state.reportDispatchStatus = status;
  },
  setReportGenerateStatus(state, status) {
    state.reportGenerateStatus = status;
  },

  setPatientPreviewData(state, data) {
    state.patientReportPreview = data;
  },
  setReportVerifyStatus(state, status) {
    state.reportVerifyStatus = status;
  },
  setPatientPreviewReportData(state, data) {
    state.patientReportData = data;
  },
  setReports(state, data) {
    state.reportTypes = data;
  },
  setWhatsAppReportStatus(state, status) {
    state.sendWhatsAppReportStatus = status;
  },
  setEmailSentStatus(state, data) {
    state.emailSentStatus = data;
  },
  setSmsSentStatus(state, data) {
    state.smsSentStatus = data;
  },
  setDoctorWhtsappSentStatus(state, data) {
    state.doctorWhatsappSentStatus = data;
  },
  setDoctorEmailSentStatus(state, data) {
    state.doctorEmailSentStatus = data;
  },
  setResyncLisReport(state, data) {
    state.resyncLisReport = data;
  },
  setResyncResultFileLisReport(state, data) {
    state.resyncResultFileLisReport = data;
  },
  setReportsLogs(state, data) {
    state.reportsLogs = data;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  async fetchAllPatients({ commit, dispatch }, params) {
    try {
      commit("setPatientData", null);
      const response = await axios.get("/patients", { params });

      commit("setPatientData", response.data);
    } catch (err) {
      commit("setPatientData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllDepartments({ commit, dispatch }, params) {
    try {
      commit("setDepartments", null);
      const response = await axios.get("/patients/departments", { params });

      commit("setDepartments", response.data);
    } catch (err) {
      commit("setDepartments", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllReports({ commit, dispatch }, params) {
    try {
      commit("setReports", null);
      const response = await axios.get("/patients/test-results", { params });
      commit("setReports", response.data.data);
    } catch (err) {
      commit("setReports", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllPatientReports({ commit, dispatch }, params) {
    try {
      // commit("setAllPatientReports", null);
      const response = await axios.get("/patient-reports", { params });

      commit("setAllPatientReports", response.data);
    } catch (err) {
      // commit("setAllPatientReports", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchAllclinicaldetails({ commit, dispatch }, params) {
    try {
      commit("setAllclinicaldetails", null);
      const response = await axios.get("/patient-clinicaldetails", { params });

      commit("setAllclinicaldetails", response.data);
    } catch (err) {
      commit("setAllclinicaldetails", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async fetchSinglePatientReports({ commit }, patientId) {
    try {
      commit("setSinglePatientReportData", null);

      const response = await axios.get(`/patient-reports/${patientId}`);

      commit("setSinglePatientReportData", response.data.data);
    } catch (err) {
      commit("setSinglePatientReportData", null);
    }
  },

  async addPatientReport({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setPatientAddStatus", false);
      let response = await axios.post("/patient-reports", params);
      commit("setPatientDataOnSave", response.data);
      commit("setPatientAddStatus", true);
    } catch (err) {
      commit("setPatientAddStatus", false);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  async updatePatientReport({ commit, dispatch }, { params, patientId }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setPatientUpdateStatus", false);
      await axios.put("/patient-reports/" + patientId, params);
      commit("setPatientUpdateStatus", true);
    } catch (err) {
      commit("setPatientUpdateStatus", false);
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    }
  },
  async downloadPatientReports({ commit, dispatch }, params) {
    try {
      const patientId = params.patientId;
      delete params.patientId;
      commit("errors/clear", null, { root: true });
      commit("setReportDownloadStatus", false);
      commit("setReportDownloadUrl", null);
      const response = await axios.get(
        `/patient-reports/${patientId}/download`,
        { params }
      );

      commit("setReportDownloadStatus", true);
      commit("setReportDownloadUrl", response.data);
    } catch (err) {
      console.log(err);
      commit("setReportDownloadStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async downloadProvosionalPatientReport({ commit, dispatch }, params) {
    try {
      const patientId = params.patientId;
      delete params.patientId;
      commit("errors/clear", null, { root: true });
      commit("setReportDownloadStatus", false);
      commit("setReportDownloadData", null);
      const response = await axios.get(
        `/patient-reports/${patientId}/pre-download`,
        { params }
      );

      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.data
      ) {
        // const arrayBuffer = response.data.data.data;
        // const pdfBuffer = new Uint8Array(arrayBuffer);

        // var blob = new Blob([pdfBuffer], { type: "application/pdf" });
        // var blobURL = URL.createObjectURL(blob);
        const blobURL = `data:application/pdf;base64,${response.data.data}`;

        var tmpLink = document.createElement("a");
        tmpLink.href = blobURL;
        tmpLink.setAttribute(
          "download",
          response.data.filename || "report.pdf"
        );

        // temporarily add link to body and initiate the download
        document.body.appendChild(tmpLink);
        tmpLink.click();
        document.body.removeChild(tmpLink);

        commit("setReportDownloadStatus", true);
        // commit("setReportDownloadData", response.data);
      } else {
        commit("setReportDownloadStatus", false);
      }
    } catch (err) {
      console.log(err);
      commit("setReportDownloadStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async generatePatientReports({ commit, dispatch }, params) {
    try {
      let patientId = params.patientId;
      delete params.patientId;

      commit("errors/clear", null, { root: true });
      commit("setReportGenerateStatus", false);

      await axios.post(`/patient-reports/${patientId}/generate`, params);
      commit("setReportGenerateStatus", true);
    } catch (err) {
      commit("setReportGenerateStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async sendWhatsAppPatientReports({ commit, dispatch }, { reportId }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setWhatsAppReportStatus", false);

      await axios.post(
        `/patient-reports/${reportId}/send-report-to-patient-whatsapp`
      );
      commit("setWhatsAppReportStatus", true);
    } catch (err) {
      commit("setWhatsAppReportStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async sendPatientReportsbyEmail({ commit, dispatch }, { reportId }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setEmailSentStatus", false);

      await axios.post(
        `/patient-reports/${reportId}/send-report-to-patient-email`
      );
      commit("setEmailSentStatus", true);
    } catch (err) {
      commit("setEmailSentStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async sendPatientReportsbySms({ commit, dispatch }, { reportId }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setSmsSentStatus", false);

      await axios.post(
        `/patient-reports/${reportId}/send-report-to-patient-by-sms`
      );
      commit("setSmsSentStatus", true);
    } catch (err) {
      commit("setSmsSentStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async sendReportToDoctorWhatsapp({ commit, dispatch }, { params, reportId }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setDoctorWhtsappSentStatus", false);

      await axios.post(
        `/patient-reports/${reportId}/send-report-to-doctor-whatsapp`,
        params
      );
      commit("setDoctorWhtsappSentStatus", true);
    } catch (err) {
      commit("setDoctorWhtsappSentStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async sendReportToDoctorEmail({ commit, dispatch }, { params, reportId }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setDoctorEmailSentStatus", false);

      await axios.post(
        `/patient-reports/${reportId}/send-report-to-doctor-email`,
        params
      );
      commit("setDoctorEmailSentStatus", true);
    } catch (err) {
      commit("setDoctorEmailSentStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async approvePatientReport({ commit, dispatch }, params) {
    try {
      let patientId = params.patientId;
      delete params.patientId;
      commit("errors/clear", null, { root: true });
      commit("setReportApproveStatus", false);
      await axios.patch(`/patient-reports/${patientId}/approve`, params);

      commit("setReportApproveStatus", true);
    } catch (err) {
      commit("setReportApproveStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async approvePatientReports({ commit, dispatch }, params) {
    try {
      let patientId = params.patientId;
      delete params.patientId;
      commit("errors/clear", null, { root: true });
      commit("setReportApproveStatuses", false);
      await axios.patch(
        `/patient-reports/${patientId}/secondary-approve`,
        params
      );

      commit("setReportApproveStatuses", true);
    } catch (err) {
      commit("setReportApproveStatuses", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async tertiaryApprovePatientReport({ commit, dispatch }, params) {
    try {
      let patientId = params.patientId;
      delete params.patientId;
      commit("errors/clear", null, { root: true });
      commit("setReportApproveStatuses", false);
      await axios.patch(
        `/patient-reports/${patientId}/tertiary-approve`,
        params
      );

      commit("setReportApproveStatuses", true);
    } catch (err) {
      commit("setReportApproveStatuses", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async quaternaryApprovePatientReport({ commit, dispatch }, params) {
    try {
      let patientId = params.patientId;
      delete params.patientId;
      commit("errors/clear", null, { root: true });
      commit("setReportApproveStatuses", false);
      await axios.patch(
        `/patient-reports/${patientId}/quaternary-approve`,
        params
      );

      commit("setReportApproveStatuses", true);
    } catch (err) {
      commit("setReportApproveStatuses", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async dispatchPatientReport({ commit, dispatch }, { patientId, params }) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setReportDispatchStatus", false);
      await axios.patch(`/patient-reports/${patientId}/dispatch`, params);

      commit("setReportDispatchStatus", true);
    } catch (err) {
      commit("setReportDispatchStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async verifyPatientReport({ commit, dispatch }, patientId) {
    try {
      commit("errors/clear", null, { root: true });
      commit("setReportVerifyStatus", false);
      await axios.patch(`/patient-reports/${patientId}/verify`);

      commit("setReportVerifyStatus", true);
    } catch (err) {
      commit("setReportVerifyStatus", false);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchPatientPreview({ commit, dispatch }, params) {
    try {
      const patientId = params.patientId;
      delete params.patientId;
      commit("setPatientPreviewData", null);
      const response = await axios.get(
        `/patient-reports/${patientId}/preview`,
        { params }
      );
      commit("setPatientPreviewData", response.data);
    } catch (err) {
      commit("setPatientPreviewData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async addPatientReportPreview({ commit, dispatch }, params) {
    try {
      commit("setPatientReportData", null);
      const response = await axios.post(
        "/patient-reports/report-preview",
        params
      );
      return response.data.data;
      // commit("setPatientReportData", response.data.data);
    } catch (err) {
      // commit("setPatientReportData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
      return null;
    }
  },
  async syncLisReport({ commit, dispatch }, params) {
    try {
      commit("setResyncLisReport", null);
      const response = await axios.patch(
        "/patient-reports/" + params.reportId + "/lis-resync",
        params
      );
      commit("setResyncLisReport", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, { root: true });
      return null;
    }
  },
  async syncResultFileLisReport({ commit, dispatch }, params) {
    try {
      commit("setResyncResultFileLisReport", null);
      const response = await axios.patch(
        "/test-result-files/" +
        params.result_file_id +
        "/patients/" +
        params.patient_id +
        "/lis-resync",
        params
      );
      commit("setResyncResultFileLisReport", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, { root: true });
      return null;
    }
  },
  async fetchReportsLogs({ commit, dispatch }, params) {
    try {
      commit("setReportsLogs", null);
      const response = await axios.get("/patient-report-logs", { params });

      commit("setReportsLogs", response.data);
    } catch (err) {
      commit("setReportsLogs", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  addPatientData({ commit }, params) {
    commit("setPatientPreviewReportData", params);
  },
  reset: function ({ commit }) {
    commit("reset", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
