<template>
    <section
      id="add-report-view"
      v-loading.fullscreen.lock="loading"
      :element-loading-text="loadingText"
    >
      <div class="reports-title-header">
        <h4 class="report-title">Molecular Diagnostics - Patient Report Data</h4>
        <div class="display-end">
          <el-button @click="goToReports" class="back-btn"
            ><el-icon class="el-icon-back"><Back /></el-icon>Back</el-button
          >
        </div>
      </div>
  
      <div class="inner-navbar">
        <el-row :gutter="10">
          <el-col :span="8">
            <div>
              <el-autocomplete
                v-model="searchVisitId"
                :fetch-suggestions="getAutoCompletePatientsData"
                placeholder="Search VisitID, Patient Name,UHID"
                :clearable="true"
                :trigger-on-focus="false"
                class="el-input"
                @select="handleSelect"
                @clear="clearNameSearch"
              ></el-autocomplete>
            </div>
          </el-col>
  
          <el-col :span="8">
            <el-select
              v-model="reportType"
              placeholder="Select Report Type *"
              :clearable="true"
              filterable
              @clear="clearFields"
              @change="onChangeReportType()"
            >
              <el-option
                v-for="(report, index) in reportTypes"
                :key="index"
                :label="report.label"
                :value="report.value"
              ></el-option>
            </el-select>
            <FormError errorName="report_type"></FormError>
          </el-col>
          <el-col :span="8">
            <el-select
              v-model="testId"
              placeholder="Select Test Category "
              :clearable="true"
              filterable
              @clear="askRemoveConfirmation"
              @change="fillSampleType"
            >
              <el-option
                v-for="report in categoryReportTypes"
                :key="report"
                :label="report.ItemName + '   --  ' + report.TestId"
                :value="report.TestId"
              ></el-option>
            </el-select>
  
            <FormError errorName="report_type"></FormError>
          </el-col>
        </el-row>
        <hr />
        <el-row :gutter="10">
          <el-col :span="8">
            <el-select
              v-model="patientInfo.department"
              allow-create
              placeholder="Select Department"
              :clearable="true"
              filterable
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
  
            <FormError errorName="department"></FormError>
          </el-col>
  
          <el-col :span="8">
            <el-select
              v-model="patientInfo.report_template_id"
              placeholder="Select Report Template"
              :clearable="true"
              filterable
              @clear="askRemoveConfirmation"
              @change="updateTemplateData"
            >
              <el-option
                v-for="(template, index) in templates"
                :key="index"
                :label="template.template_title"
                :value="template._id"
              ></el-option>
            </el-select>
  
            <FormError errorName="report_template_id"></FormError>
          </el-col>
        </el-row>
      </div>
      <div class="inner">
        <span class="report-header">{{ patientInfo.department }}</span>
        <div class="patient-information">
          <el-form :model="patientInfo">
            <el-row>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Patient Name"
                  class="mb-05 form-mandatory-field"
                >
                  <el-input
                    v-model="patientInfo.patient_name"
                    placeholder="Enter Patient Name"
                  ></el-input>
                </el-form-item>
                <FormError errorName="patient_name"></FormError>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Visit Id">
                  <el-input
                    v-model="patientInfo.visit_id"
                    placeholder="Enter Visit Id"
                    disabled
                  ></el-input>
                  <FormError errorName="visit_id"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.ref_doctor"
                    placeholder="Enter Reference Doctor"
                  ></el-input>
                  <FormError errorName="ref_doctor"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.uhid"
                    placeholder="Enter UHID No"
                  ></el-input>
                  <FormError errorName="uhid"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Gender" class="mb-05 mandatory-field">
                  <div class="gender-select">
                    <el-select
                      v-model="patientInfo.gender"
                      placeholder="Select Gender"
                    >
                      <el-option
                        v-for="(gender, index) in genderTypes"
                        :key="index"
                        :label="gender"
                        :value="gender"
                      ></el-option>
                    </el-select>
                    <FormError errorName="gender"></FormError>
                  </div>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Age" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.age"
                    placeholder="Enter Age"
                  ></el-input>
                  <FormError errorName="age"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Mobile" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.mobile"
                    placeholder="Enter Mobile Number"
                  ></el-input>
                  <FormError errorName="mobile"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Phone" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.alternate_mobile"
                    placeholder="Enter Phone Number"
                  ></el-input>
                  <FormError errorName="alternate_mobile"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Email" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.email"
                    placeholder="Enter Email"
                  ></el-input>
                  <FormError errorName="email"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Locality" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.locality"
                    placeholder="Enter Locality"
                  ></el-input>
                  <FormError errorName="locality"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.hospital_name"
                    placeholder="Enter Hospital Name"
                  ></el-input>
                  <FormError errorName="hospital_name"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Registration Date"
                  class="mb-05 mandatory-field"
                >
                  <el-date-picker
                    v-model="patientInfo.registration_date"
                    type="datetime"
                    placeholder="Pick a Date"
                    format="DD-MM-YYYY hh:mm A"
                    :disabled-date="disabledDate"
                  ></el-date-picker>
                  <FormError errorName="registration_date"></FormError>
                </el-form-item>
              </el-col>
  
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                  <el-date-picker
                    v-model="patientInfo.reported_date"
                    type="datetime"
                    placeholder="Pick a Date"
                    format="DD-MM-YYYY hh:mm A"
                    :disabled-date="disabledDate"
                  ></el-date-picker>
                  <FormError errorName="reported_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item
                  label="Collected Date"
                  class="mb-05 mandatory-field"
                >
                  <el-date-picker
                    v-model="patientInfo.collected_date"
                    type="datetime"
                    placeholder="Pick a Date"
                    format="DD-MM-YYYY hh:mm A"
                    :disabled-date="disabledDate"
                  ></el-date-picker>
                  <FormError errorName="collected_date"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Test Name" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.test_name"
                    placeholder="Enter Test Name"
                  ></el-input>
                  <FormError errorName="test_name"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.sample_type"
                    placeholder="Enter Sample Type"
                  ></el-input>
                  <FormError errorName="sample_type"></FormError>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
                <el-form-item label="Client Code" class="mb-05 mandatory-field">
                  <el-input
                    v-model="patientInfo.client_code"
                    placeholder="Enter Client Code"
                  ></el-input>
                  <FormError errorName="client_code"></FormError>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
  
        <div
          class="report-sub-title"
          v-if="showReportType && patientInfo.report_type"
        >
          {{ patientInfo.report_type }}
          <span @click="onEditReportType" class="cursor-pointer">
            <el-icon><Edit /></el-icon>
          </span>
        </div>
        <div class="report-type-edit-info" v-else-if="editReportType">
          <el-input v-model="reportTypeNew"></el-input>
          <el-button
            type="success"
            @click="updateReportType"
            :disabled="isReportTypeUpdateDisable"
          >
            <el-icon><Check /></el-icon>
          </el-button>
          <el-button type="danger" @click="cancelUpdateReportType">
            <el-icon><Close /></el-icon>
          </el-button>
        </div>
        <div class="editor" v-loading="loadingEditor">
          <ckeditor
            :editor="editor"
            tag-name="div"
            ref="myEditor"
            v-model="editorData"
            :config="editorConfig"
          ></ckeditor>
        </div>
        <!-- FabryLeukocytes -->
        <div v-if="reportType==='FabryLeukocytes' && patientInfo.report_template_id">
            <FabryLeukocytes :Fabry_Leukocytes_Array="Fabry_Leukocytes" ref="childComponentRef"></FabryLeukocytes>
          </div>
        <!-- FabryLeukocytes -->
        <FormError errorName="critical_error"></FormError>
  
        <ul class="action-buttons">
          <!-- <li>
            <el-button @click="clearForm" class="submit-button"
              >Clear Form</el-button
            >
          </li>-->
          <li>
            <el-button @click="printReport" class="preview-button print-button"
              >Print</el-button
            >
          </li>
          <li>
            <el-button @click="previewReport" class="preview-button"
              >Preview</el-button
            >
          </li>
          <!-- <li v-if="!isEditForm">
            <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
              >Save as Draft</el-button
            >
          </li>-->
          <li v-if="!isEditForm">
            <el-button @click="savePatientInfo('PENDING')" class="submit-button"
              >Submit</el-button
            >
          </li>
          <!-- <li v-if="isEditForm && isDraft">
            <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
              >Update Draft</el-button
            >
          </li>-->
          <li v-if="isEditForm && isDraft">
            <el-button @click="savePatientInfo('PENDING')" class="submit-button"
              >Make Complete</el-button
            >
          </li>
          <li v-if="isEditForm && !isDraft">
            <el-button
              @click="savePatientInfo(this.patientInfo.report_status)"
              class="submit-button"
              >Update</el-button
            >
          </li>
  
          <li>
            <el-button @click="saveAsTemplate" class="submit-button"
              >Save As Template</el-button
            >
          </li>
        </ul>
      </div>
  
      <div v-if="templateTitleDialogVisible" class="template-title-dialog">
        <el-dialog
          width="30%"
          v-model="templateTitleDialogVisible"
          title="New Template"
          :before-close="handleClose"
          class="template-title-dialog"
        >
          <el-input
            placeholder="Please add a titile for Template "
            v-model="templateTitle"
          ></el-input>
  
          <template #footer>
            <span class="dialog-footer">
              <el-button
                size="medium"
                class="el-white"
                type="info"
                plain
                @click="handleClose"
                >Cancel</el-button
              >
              <el-button
                size="medium"
                type="primary"
                @click="addNewTemplate"
                :loading="loading"
                >Submit</el-button
              >
            </span>
          </template>
  
          <FormError errorName="template_title"></FormError>
        </el-dialog>
      </div>
  
      
  
    </section>
  </template>
  
  <script>
  import CKEditor from "@ckeditor/ckeditor5-vue";
  import {
    genderTypes,
    biochemicalGeneticsTypeMap,
  } from "@/constants/formConstants";
  import { ElMessageBox, ElMessage } from "element-plus";
  import { mapGetters } from "vuex";
  
  import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
  
  import FormError from "@/components/FormError";
  import moment from "moment";
  import EditorConfig from "@/config/editorConfig";
  import FabryLeukocytes from "../bioChemicalGenetics/FabryLeukocytes.vue";

  export default {
    components: {
      ckeditor: CKEditor.component,
      FormError,
      FabryLeukocytes
    },
  
    data() {
      return {
        askSaveForm: false,
        templateTitleDialogVisible: false,
        templateTitle: "",
        biochemicalGeneticsTypeMap: biochemicalGeneticsTypeMap,
        value: "",
        genderTypes: genderTypes,
        options: ["Department of Molecular Diagnostics"],
        templates: [],
        patientInfo: {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          client_code: "",
          uhid: "",
          mr_no: "",
          age: "",
          gender: "",
          hospital_name: "",
          client_add: "",
          client_name: "",
          barcode_no: "",
          registration_date: "",
          collected_date: "",
          reported_date: "",
          received_date: "",
  
          mobile: "",
          email: "",
          locality: "",
  
          department: "Department of Molecular Diagnostics",
          report_type: "",
          category: "Molecular_Diagnostics",
          report_status: "DRAFT",
          sample_type: "",
          test_name: "",
        },
        unSavedChanges: false,
        loading: false,
        loadingText: "",
        editor: ClassicEditor,
        show: false,
        editorData: "",
        searchVisitId: "",
        reportType: "",
        showReportType: true,
        editReportType: false,
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
        editorConfig: EditorConfig,
        showAddTemplate: false,
        reportTypeNew: "",
        reportSubCategory: "",
        categoryReportTypes: [],
        listOfDepartments: [],
        testId: "",
      };
    },
    computed: {
      ...mapGetters("errors", ["getErrors", "getError"]),
      ...mapGetters("patientReports", [
        "getPatientAddStatus",
        "getSinglePatientReports",
        "getPatientUpdateStatus",
        "getPatientDetails",
        "getDepartments",
      ]),
  
      ...mapGetters("reportTemplates", [
        "getAllReportTemplates",
        "getReportTemplateAddStatus",
        "getSingleReportTemplate",
      ]),
  
      isReportTypeUpdateDisable() {
        return this.reportTypeNew &&
          this.reportTypeNew.trim() &&
          this.reportTypeNew.trim().length
          ? false
          : true;
      },
  
      isEditForm() {
        return this.$route.params && this.$route.params.patient_id ? true : false;
      },
      isDraft() {
        if (
          this.isEditForm &&
          this.patientInfo.report_status &&
          this.patientInfo.report_status != "DRAFT"
        ) {
          return false;
        }
        return true;
      },
  
      noContent() {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        return data.length ? false : true;
      },
    },
    async mounted() {
      if (this.$route.params.patient_id) {
        await this.fetchSinglePatientInfo();
        await this.getVisitData();
        
      }
  
      await this.fetchReportTemplates();
      await this.$store.dispatch("errors/clear", {}, { root: true });
  
      setTimeout(() => {
        this.show = true;
      });
    },
  
    methods: {
      clearFields() {
        this.templates = [];
      },
      onEditReportType() {
        this.reportTypeNew = this.patientInfo.reporonChangeReportTypet_type;
        this.editReportType = true;
        this.showReportType = false;
      },
      updateReportType() {
        this.patientInfo.report_type = this.reportTypeNew;
        this.editReportType = false;
        this.showReportType = true;
      },
      cancelUpdateReportType() {
        this.reportTypeNew = this.patientInfo.report_type;
        this.editReportType = false;
        this.showReportType = true;
      },
      onChangeReportType() {
        this.reportSubCategory = this.reportType;
        this.patientInfo.report_type = this.reportType;
        this.patientInfo.report_template_id = "";
        this.patientInfo.test_name =
          this.biochemicalGeneticsTypeMap[this.reportType];
        this.fetchReportTemplates();
      },
      askSaveConfirmation() {
        this.askSaveForm = true;
      },
     async getAutoCompletePatientsData(queryString, cb) {
        // eslint-disable-next-line no-debugger
        if (queryString) {
          await this.$store.dispatch("patientReports/fetchAllPatients", {
            search_string: queryString,
            get_all: true,
          });
          const patientsData = [];
          (this.getPatientDetails.data || []).forEach((el) => {
            patientsData.push({
              value: el.VisitiID + " - " + el.PatientName,
              ...el,
            });
            console.log(patientsData);
          });
  
          cb(patientsData || []);
          console.log(cb(patientsData || []));
        } else {
          cb([]);
        }
      },
      async getVisitData() {
        try {
          this.searchVisitId =
            this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
          // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
        } catch (error) {
          console.log(error);
        }
      },
      async handleSelect(data) {
        this.patientInfo.visit_id = data.VisitiID;
        this.patientInfo.patient_name = data.PatientName;
        this.patientInfo.ref_doctor = data.RefDoctor && !data.RefDoctor.startsWith('Dr.')
      ? `Dr. ${data.RefDoctor}`
      : data.RefDoctor || '';
        this.patientInfo.client_code = data.ClientCode;
        this.patientInfo.uhid = data["UHID/MR NO"];
        const age = data.Age.split(" ");
        const years = parseInt(age[0]);
        const months = parseInt(age[2]);
        const days = parseInt(age[4]);
        if (years > 0 && months > 0 && days > 0 || years > 0 && months > 0) {
          this.patientInfo.age = `${years} Y ${months} M`;
        } else if (years === 0 && months > 0 && days > 0) {
          this.patientInfo.age = `${months} M ${days} D`;
        } else if (years === 0 && months === 0 && days > 0) {
          this.patientInfo.age = `${days} D`;
        }
        else if(years>0){
          this.patientInfo.age = `${years} Y`;
        }
        this.patientInfo.gender = data.Gender.toUpperCase();
        this.patientInfo.hospital_name = data.HospitalName;
        this.patientInfo.client_add = data.ClientAdd;
        this.patientInfo.client_name = data.ClientName;
        this.patientInfo.barcode_no = data.BarcodeNo;
  
        this.patientInfo.registration_date = data.RegisterationDate
          ? moment(data.RegisterationDate).utc().format()
          : null;
        this.patientInfo.collected_date = data.CollectionDate
          ? moment(data.CollectionDate).utc().format()
          : null;
        this.patientInfo.reported_date = data.ReportedDate
          ? moment(data.ReportedDate).utc().format()
          : null;
        this.patientInfo.received_date = data.ReceivedDate
          ? moment(data.ReceivedDate).utc().format()
          : null;
  
        this.patientInfo.email = data.Email;
        this.patientInfo.mobile = data.Mobile;
        this.patientInfo.locality = data.Locality;
        this.patientInfo.alternate_mobile = data.Phone;
  
        this.patientInfo.sample_type = data.SampleType;
        if (data.TestName) {
          this.patientInfo.test_name = data.TestName;
        }
        await this.fetchPatientDepartments();
      },
      async fetchPatientDepartments() {
        this.loading = true;
        let params = {};
        if (this.patientInfo.visit_id) {
          params.visit_id = this.patientInfo.visit_id;
          params.department = "biochemical-genetics";
        }
        await this.$store.dispatch("patientReports/fetchAllDepartments", params);
        if (
          this.getDepartments &&
          this.getDepartments.data &&
          this.getDepartments.data.length
        ) {
          this.listOfDepartments = this.getDepartments.data;
  
          this.listOfDepartments.forEach(
            (x) => (this.categoryReportTypes = x.test_categories)
          );
        } else {
          this.listOfDepartments = [];
        }
        this.loading = false;
      },
      fillSampleType(data) {
        const JavaScriptApplicants = this.categoryReportTypes.filter(
          (e) => e.TestId == data
        );
        if (JavaScriptApplicants[0].SampleTypeName) {
          this.patientInfo.sample_type = JavaScriptApplicants[0].SampleTypeName;
        }
        if (JavaScriptApplicants[0].BarcodeNo) {
          this.patientInfo.barcode_no = JavaScriptApplicants[0].BarcodeNo;
        }
      },
      clearNameSearch() {
        let patientInfo = {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          client_add: "",
          client_code: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          client_name: "",
          hospital_name: "",
          barcode_no: "",
          registration_date: "",
          collected_date: "",
          reported_date: "",
          received_date: "",
  
          email: "",
          alternate_mobile: "",
          mobile: "",
          locality: "",
          sample_type: "",
        };
        this.patientInfo = { ...this.patientInfo, ...patientInfo };
      },
      async fetchSinglePatientInfo() {
        this.loading = true;
        this.loadingText = "Fetching data...";
        try {
          await this.$store.dispatch(
            "patientReports/fetchSinglePatientReports",
            this.$route.params.patient_id
          );
          this.setPatientData();
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
      async setPatientData() {
        this.patientInfo = { ...this.getSinglePatientReports };
        this.reportType = this.patientInfo.sub_category;
        this.editorData = this.getSinglePatientReports.test_details;
        this.reportSubCategory = this.patientInfo.sub_category;
        await this.fetchPatientDepartments();
        if (this.getSinglePatientReports.result_test_id) {
          this.testId = this.getSinglePatientReports.result_test_id;
        }
      },
      async clearForm() {
        await ElMessageBox.confirm(
          "Are you sure to clear the form. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            await this.clearPatientForm();
          })
          .catch(() => {});
      },
      async handleClose() {
        this.templateTitle = "";
        this.templateTitleDialogVisible = false;
        await this.$store.dispatch("errors/clear", {}, { root: true });
      },
  
      async addNewTemplate() {
        try {
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML;
          let params = { ...this.patientInfo };
          if (params.client_code && params.client_code != "") {
            const code = params.client_code.toString();
            params.client_code = code;
          }
          params.template_title = this.templateTitle;
          params.test_details = data;
  
          if (!data.length) {
            this.$message("No Content to Save Template");
            return;
          }
          params.template_body = data;
  
          this.loading = true;
          params.sub_category = this.reportSubCategory;
          if (params.age) {
            params.age = params.age.toString();
          }
  
          await this.$store.dispatch("reportTemplates/addReportTemplate", params);
  
          if (this.getReportTemplateAddStatus) {
            this.$notify.success({
              title: "Success",
              message: "Report Template Added Successfully",
            });
  
            this.templateTitleDialogVisible = false;
            this.templateTitle = "";
  
            await this.fetchReportTemplates();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error at Creating new Template",
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
        }
      },
  
      saveAsTemplate() {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
        this.templateTitleDialogVisible = true;
      },
      clearPatientForm(clearPatientData = true) {
        if (clearPatientData) {
          this.patientInfo = {
            visit_id: "",
            patient_name: "",
            ref_doctor: "",
            uhid: "",
            mr_no: "",
            gender: "",
            age: "",
            hospital_name: "",
            registration_date: "",
            collected_date: "",
            reported_date: "",
            received_date: "",
            sample_type: "",
          };
        }
  
        window.document.getElementsByClassName("ck-content")[0].innerText = "";
        window.document.getElementsByClassName("ck-content").innerHTML = "";
        // this.editorData = "";
      },
      async previewReport() {
        try {
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML;
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
  
          this.loading = true;
          this.loadingText = "Loading..";
  
          let params = { ...this.patientInfo };
          params.sub_category = this.reportSubCategory;
          params.test_details = data;
          if (
            this.patientInfo.patient_name &&
            this.patientInfo.department &&
            this.patientInfo.report_type
          ) {
            this.$store.dispatch("patientReports/addPatientData", params);
  
            let routerData = this.$router.resolve({
              name: "PatientReportPreview",
            });
  
            window.open(routerData.href, "_blank");
          } else {
            ElMessageBox.alert(
              "Please Fill mandatory Fields to preview report",
              "Alert",
              {
                confirmButtonText: "OK",
              }
            );
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
      async printReport() {
        try {
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML;
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
  
          this.loading = true;
          this.loadingText = "Loading..";
  
          let params = { ...this.patientInfo };
  
          params.test_details = data;
          if (
            this.patientInfo.patient_name &&
            this.patientInfo.department &&
            this.patientInfo.report_type
          ) {
            this.$store.dispatch("patientReports/addPatientData", params);
  
            let routerData = this.$router.resolve({
              name: "PatientReportPreviewPrint",
            });
  
            window.open(routerData.href, "_blank");
          } else {
            ElMessageBox.alert(
              "Please Fill mandatory Fields to print report",
              "Alert",
              {
                confirmButtonText: "OK",
              }
            );
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
      async goToReports() {
        if (this.$route.params.patient_id) {
          this.$router.push({
            name: "PatientReportView",
            query: this.$route.query,
            params: { status: this.$route.params.status },
          });
        } else {
          this.$router.push({ name: "PatientReports", query: this.$route.query });
        }
      },
  
      async fetchReportTemplates() {
        try {
          this.loading = true;
          this.loadingText = "Fetching data..";
          let params = {
            get_all: true,
            category: "Cytopathology",
          };
  
          if (this.reportType) {
            params.sub_category =
              this.biochemicalGeneticsTypeMap[this.reportType];
          }
          await this.$store.dispatch(
            "reportTemplates/fetchAllReportTemplates",
            params
          );
  
          this.templates = this.getAllReportTemplates.data;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      },
  
      savePatientInfo(status) {
        if (this.$route.params.patient_id) {
          this.updatePatientInfo(status);
        } else {
          this.addPatientInfo(status);
        }
      },
      async fetchSingleReportTemplate(templateId) {
        try {
          await this.$store.dispatch(
            "reportTemplates/fetchSingleReportTemplate",
            { template_id: templateId }
          );
        } catch (err) {
          console.log(err);
        }
      },
      async updateTemplateData(templateId) {
        await this.fetchSingleReportTemplate(templateId);
  
        let templateData = this.templates.find((x) => x._id == templateId);
        if (
          this.getSingleReportTemplate &&
          this.getSingleReportTemplate.template_body
        ) {
          templateData.template_body = this.getSingleReportTemplate.template_body;
        }
        if (
          this.getSingleReportTemplate &&
          this.getSingleReportTemplate.test_details
        ) {
          templateData.test_details = this.getSingleReportTemplate.test_details;
        }
        if (templateData) {
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML || "";
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (stringContent) {
            ElMessageBox.confirm(
              "Are you want to update template data. Continue?",
              "Warning",
              {
                confirmButtonText: "OK",
                cancelButtonText: "No",
                type: "warning",
              }
            )
              .then(async () => {
                await this.updateEditorData(templateData);
              })
              .catch(() => {});
          } else {
            this.updateEditorData(templateData);
          }
        }
      },
      updateEditorData(templateData) {
        this.show = false;
        this.loadingEditor = true;
        setTimeout(() => {
          this.loadingEditor = false;
          this.show = true;
          this.editorData = templateData.template_body || "";
          this.patientInfo.report_type = templateData.report_type || "";
          this.reportType = templateData.sub_category || "";
  
        }, 100);
      },
      async addPatientInfo(status) {
        try {
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML;
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
          this.loading = true;
          this.loadingText = "Loading...";
          let params = { ...this.patientInfo };
          if (params.client_code && params.client_code != "") {
            
            const code = params.client_code.toString();
            params.client_code = code;
          }
  
          params.test_details = data;
          params.report_status = status;
          params.sub_category = this.reportSubCategory;
          if (!params.report_template_id) {
            params.report_template_id = null;
          }
          if (this.testId) {
            params.result_test_id = this.testId;
          }
          await this.$store.dispatch("patientReports/addPatientReport", params);
          if (this.getPatientAddStatus) {
            this.$notify.success({
              title: "Success",
              message: "Patient Report Added Successfully",
            });
            await this.goToReports();
          } else if (this.getErrors) {
            this.scrollIntoFirstError();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error while Adding Report",
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
      async updatePatientInfo(status) {
        try {
          let dd = window.document.getElementsByClassName("ck-content");
          const data = dd[0].innerHTML;
  
          let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
          stringContent = stringContent.trim();
          if (!stringContent) {
            ElMessage.warning("Report content is not given");
            return;
          }
          this.loading = true;
          this.loadingText = "Loading...";
          let params = { ...this.patientInfo };
          if (params.client_code && params.client_code != "") {
            const code = params.client_code.toString();
            params.client_code = code;
          }
          if (params.age == null) {
            params.age = "";
          }
          if (params.reported_date == null) {
            params.reported_date = "";
          }
          if (params.received_date == null) {
            params.received_date = "";
          }
          params.report_status = status;
  
          params.test_details = data;
          params.sub_category = this.reportSubCategory;
          let patientId = this.$route.params.patient_id;
          if (!params.report_template_id) {
            params.report_template_id = null;
          }
          if (this.testId) {
            params.result_test_id = this.testId;
          }
          await this.$store.dispatch("patientReports/updatePatientReport", {
            params,
            patientId,
          });
          if (this.getPatientUpdateStatus) {
            this.$notify.success({
              title: "Success",
              message: "Patient Report Updated Successfully",
            });
            await this.goToReports();
          } else if (this.getErrors) {
            this.scrollIntoFirstError();
          } else if (!this.getErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error while Updating Report",
            });
          }
          this.loading = false;
        } catch (err) {
          console.log(err);
        }
      },
  
      async askRemoveConfirmation() {
        await ElMessageBox.confirm(
          "Are you want to clear template data. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "No",
            type: "warning",
          }
        )
          .then(async () => {
            await this.updateEditorData({});
          })
          .catch(() => {});
      },
      scrollIntoFirstError() {
        var el = this.$el.getElementsByClassName("error")[0];
        if (el) {
          el.scrollIntoView({ block: "center" });
        }
      },
    },
  };
</script>

<style lang="scss">

  #add-report-view {
    max-width: 900px;
    margin: 0 auto;
    .reports-title-header {
      display: grid;
      grid-template-columns: 88% auto;
      margin-bottom: 10px;
      .display-end {
        text-align: end;
      }
      .report-title {
        text-align: center;
        color: #000;
        font-size: 20px;
        font-weight: 500;
      }
  
      .back-btn {
        padding: 9px 15px;
        min-height: auto;
        line-height: 1;
        color: #333;
        border: 1px solid #47525d42;
        background-color: white;
  
        border-radius: 5px;
  
        font-weight: 500;
        .el-icon-back {
          margin-right: 4px;
        }
        &:active,
        &:hover,
        &:focus {
          border: 1px solid #47525d42;
        }
      }
    }
  
    .inner-navbar {
      margin-bottom: 10px;
      .filter-navigation {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        li {
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  
    .inner {
      margin-bottom: 50px;
      .report-header {
        margin: 0;
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        background: #2eaf9f;
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      border: 1px solid rgba(7, 162, 149, 0.6);
      border-radius: 10px;
      padding: 0;
      background: #fff;
      overflow: hidden;
      .patient-information {
        .el-form-item {
          margin-bottom: 0;
        }
        .form-mandatory-field {
          .el-form-item__label:after {
            content: "*";
            color: red !important;
            font-size: 15px;
            line-height: 1;
            padding-left: 3px;
          }
        }
  
        .el-form-item__label {
          min-width: 40%;
          text-align: left;
          background: #e0f3f1;
          padding-left: 10px;
          border-bottom: 1px solid #e0f3f1;
          font-weight: 600;
          font-size: 11px;
          color: #000;
        }
  
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
        .el-input__inner {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          border-left: none;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          border-top: none;
          border-radius: 0;
          color: #000;
          font-weight: 500;
          // text-transform: uppercase;
          &::placeholder {
            color: #5a5a5a;
            font-size: 13px;
            text-transform: capitalize;
          }
        }
      }
      .report-sub-title {
        color: #43454b;
        font-size: 18px;
        text-align: center;
        margin-top: -1px;
        padding-top: 1rem;
        border-top: 1.2px solid rgba(0, 0, 0, 0.2);
  
        font-weight: 600;
      }
      .report-type-edit-info {
        display: flex;
        margin-top: 0.5em;
        padding: 0 10px;
        gap: 5px;
      }
      .editor {
        margin-top: 0.5em;
        padding: 10px;
        .ck-editor__editable {
          min-height: 320px;
        }
      }
      .image__caption_highlighted {
        min-height: auto !important;
      }
      .ck-dropdown__panel {
        max-height: 230px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .ck-placeholder {
        min-height: auto !important;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }
  
      .ck-dropdown__panel::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #2eaf9f;
      }
      .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        margin: 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        li {
          &:not(:last-child) {
            margin-right: 20px;
          }
          .el-button {
            padding: 9px 15px;
            min-height: auto;
            line-height: 1;
            &.preview-button {
              background: #28b4a6;
              color: #fff;
              font-weight: 600;
            }
            &.print-button {
              background: #f56c6c !important;
            }
            &.submit-button {
              background: #0061ec;
              color: #fff;
              font-weight: 600;
            }
          }
        }
      }
    }
    figure {
      &.table {
        br {
          display: none;
        }
        br[data-cke-filler="true"] {
          display: block !important;
        }
      }
    }
    .ck {
      ul {
        list-style: disc;
        padding-left: 30px;
        li {
          padding-left: 5px;
          list-style-type: disc !important;
        }
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .template-title-dialog {
    .el-dialog__header {
      background-color: #a09b9b6b;
      .el-dialog__close {
        font-size: 20px;
        color: red;
      }
    }
  }
</style>
