<template>
  <div
    id="printable-item"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <!-- <div v-html="getPatientReportPreview"></div> -->
  </div>

  <!-- <section
    v-loading="loading"
    :element-loading-text="loadingText"
    id="previewReport"
  >
    <div class="inner-nav-btn" v-if="getSinglePatientReports">
      <el-button class="print-btn" icon="el-icon-printer" @click="printReport"
        >Print</el-button
      >
    </div>
  </section> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,

      patientId: "",
      loadingText: "",
      include_header_footer: true,
      is_color_variant: true,
    };
  },
  computed: {
    ...mapGetters("patientReports", [
      "getSinglePatientReports",
      "getReportApproveStatus",
      "getReportVerifyStatus",
      "getReportDownloadUrl",
      "getPatientReportPreview",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  async mounted() {
    this.patientId = this.$route.params.patient_id;
    this.include_header_footer = this.$route.query.selectedStatus;
    this.is_color_variant = this.$route.query.isColorPrint;
    await Promise.all([
      this.fetchSinglePatientInfo(),
      this.fetchSinglePatientPreview(),
    ]);

    let classesData = ["reportPage"];
    if (this.$route.query.selectedStatus == "false") {
      classesData.push("hide-header", "hide-footer");
    }
    if (this.$route.query.isColorPrint == "false") {
      classesData.push("black-and-white");
    }
    if (this.getSinglePatientReports.report_status != "APPROVED") {
      classesData.push("duplicate");
    }

    document
      .querySelectorAll(".pagination-display")
      .forEach((el) => el.remove());

    this.toggleBodyClass("addClass", classesData.join(" "));
    // this.toggleBodyIDKey("addClass");
    // this.addPageNumbers();
    setTimeout(() => {
      window.print();
    }, 2500);
    window.document.write(this.getPatientReportPreview);
    window.document.print();
  },
  methods: {
    // addPageNumbers() {
    //   var totalPages = Math.ceil(document.body.scrollHeight / 1123);
    //   for (var i = 1; i <= totalPages; i++) {
    //     var pageNumberDiv = document.createElement("div");
    //     pageNumberDiv.classList.add("pagination-display");
    //     pageNumberDiv.classList.add("mt-40-" + i);
    //     var pageNumber = document.createTextNode(
    //       "Page " + i + " of " + totalPages
    //     );
    //     pageNumberDiv.style.position = "absolute";
    //     if (
    //       document.body.className.match(
    //         "hide-header",
    //         "hide-footer",
    //         "letter-head"
    //       )
    //     ) {
    //       pageNumberDiv.style.top = "calc((" + i + " * (297mm - 20px)))";
    //     } else {
    //       pageNumberDiv.style.top = "calc((" + i + " * (297mm + 135px)))";
    //     }

    //     pageNumberDiv.style.height = "16px";
    //     pageNumberDiv.appendChild(pageNumber);
    //     let paginationBody = document.getElementById("pagination-body");

    //     if (paginationBody) {
    //       paginationBody.appendChild(pageNumberDiv);
    //     }
    //   }
    // },
    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "patientReports/fetchSinglePatientReports",
          this.patientId
        );

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async fetchSinglePatientPreview() {
      try {
        this.loading = true;
        let params = {
          patientId: this.patientId,
          include_header_footer: this.include_header_footer,
          is_color_variant: this.is_color_variant,
        };
        this.loadingText = "Fecthing Patient Report..";

        await this.$store.dispatch(
          "patientReports/fetchPatientPreview",
          params
        );

        // if (this.getPatientReportPreview) {
        //   document.write(this.getPatientReportPreview);
        // }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    printReport() {
      window.print();
    },

    async toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      let classNames = className.split(" ");
      if (addRemoveClass === "addClass") {
        for (let i = 0; i < classNames.length; i++) {
          el.classList.add(classNames[i]);
        }
      } else {
        for (let i = 0; i < classNames.length; i++) {
          el.classList.remove(classNames[i]);
        }
      }
    },
    toggleBodyIDKey(addRemoveClass) {
      const el = document.body;
      if (addRemoveClass === "addClass") {
        el.id = "pagination-body";
      } else {
        el.id = "";
      }
    },
  },
  unmounted() {
    let classesData = ["reportPage"];
    if (this.$route.query.selectedStatus == "false") {
      classesData.push("hide-header", "hide-footer");
    }
    if (this.getSinglePatientReports.report_status != "APPROVED") {
      classesData.push("duplicate");
    }
    this.toggleBodyClass("removeClass", classesData.join(" "));
    this.toggleBodyIDKey("removeClass");

    document
      .querySelectorAll(".pagination-display")
      .forEach((el) => el.remove());

    // if (this.$route.query.selectedStatus == "false") {
    //   this.toggleBodyClass("removeClass", "reportPage hide-header hide-footer");
    // } else {
    //   this.toggleBodyClass("removeClass", "reportPage");
    // }
    // this.toggleBodyClass("removeClass", "reportPage");
  },
};
</script>

<style lang="scss" scoped>
#printable-item {
  width: 900px;
  margin: 0 auto !important;
  position: relative;
  overflow: hidden;
  border: none;
}

.inner-nav-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0rem auto 1rem;
  width: 900px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 9;
  padding: 10px 0;
  .el-button {
    padding: 9px 15px;
    min-height: auto;
    color: #e0f3f1;
    line-height: 1;
    font-weight: 400;
    &.approve-btn {
      background-color: #368110;
      border-color: #368110;
    }
    &.print-btn {
      background-color: #08b4b9;
      border-radius: #08b4b9;
    }
    &.verify-btn {
      background-color: #e78915;
      border-color: #e78915;
    }
    &.download-btn {
      background-color: #2f80ed;
      border-color: #2f80ed;
    }
    &.edit-btn {
      background-color: #f94e5e;
      border-color: #f94e5e;
    }
    &.back-btn {
      color: #333;
      background-color: white;
      border: 1px solid #47525d42;

      background-color: transparent;
      &:active,
      &:hover {
        border: 1px solid #47525d42;
      }
    }
  }
}
// @import "/src/assets/scss/components/reports.scss";
@media print {
  .inner-nav-btn {
    display: none;
  }
}
</style>
<style lang="scss">
#previewReport {
  .each-block {
    ul {
      padding-left: 20px !important;
      li {
        list-style-type: disc !important;
      }
    }
  }
}
</style>
