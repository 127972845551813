<template>
  <section id="all-patient-reports">
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="module-filter-navigation">
        <ul class="action-navigation">
          <li>
            <div class="search-filter">
              <el-input
                placeholder="Search Barcode, Visit Id, Patient Name & Client Name"
                @input="onSearch"
                v-model="searchString"
                size="large"
                clearable
              ></el-input>
            </div>
          </li>
          <li>
            <div class="search-filter">
              <el-select
                placeholder="Select Status"
                @change="onSearch"
                v-model="status"
                size="large"
                clearable
              >
                <el-option
                  v-for="status in statusOptions"
                  :key="status"
                  :label="status.label"
                  :value="status.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <!-- <li>
            <div class="search-filter">
              <el-select
                placeholder="Select Centre"
                @change="onSearch"
                v-model="selectedCentre"
                size="large"
                clearable
              >
              <el-option v-for="(lab,index) in this.labOptions" :key="index"
                  :label="lab.name"
                  :value="lab._id"
                  :disabled="checkLab(lab)"
                  >                  
                  </el-option>
              </el-select>
            </div>
          </li> -->
          <li>
            <el-button
              class="default-button add-button"
              @click="addAcknowledgement"
            >
              <span>Add</span>
            </el-button>
          </li>
          <li>
            <el-button class="default-button export-button" @click="exportData">
              Export
            </el-button>
          </li>
        </ul>
      </div>

      <div class="web_version_data hidden-sm-and-down">
        <div class="vue-table">
          <el-table
            :data="reportsData"
            height="calc(100vh - 250px)"
            class="table-list display-web"
          >
            <el-table-column
              label="Barcode No"
              prop="barcode_no"
              min-width="130"
              fixed="left"
            >
              <template #default="scope">
                <span class="date" v-if="scope.row.barcode_no">
                  {{ scope.row.barcode_no }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="Visit ID" min-width="120">
              <template #default="scope">
                <span class="date" v-if="scope.row.patient_details">
                  {{
                    scope.row.patient_details
                      ? scope.row.patient_details.visit_id
                      : "--"
                  }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="Patient Name" min-width="180">
              <template #default="scope">
                <span
                  class="text-eng-orange text-nowrap"
                  v-if="scope.row.patient_details"
                  :title="getTooltipPatient(scope.row)"
                >
                  {{ scope.row.patient_details.patient_name }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="Client Name" min-width="200">
              <template #default="scope">
                <span
                  class="text-eng-orange text-nowrap"
                  v-if="scope.row.client_details"
                  :title="getTooltipClient(scope.row)"
                >
                  {{ scope.row.client_details.client_name }}
                </span>
                <span v-else>--</span>
            </template>
          </el-table-column>          
          <el-table-column label="Client Email" min-width="200">
            <template #default="scope">
              <span class="text-nowrap" v-if="scope.row.client_details">
                {{
                  scope.row.client_details
                    ? scope.row.client_details.client_email
                    : "--"
                }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="Reasons" min-width="220">
            <template #default="scope">
              <span v-if="scope.row.reasons">
                <span v-for="(e, index) of scope.row.reasons" :key="index"
                  >{{ rejectedReasonsMap[e] || e
                  }}<span v-if="index + 1 < scope.row.reasons.length"
                    >, </span
                  ></span
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Status" min-width="130">
            <template #default="scope">
              <div class="column-status cursor" v-if="scope.row.status">
                <el-dropdown
                  @command="
                    (command) => updatedAcknolegementStatus(scope.row, command)
                  "
                  class="status-dropdown"
                >
                  <span class="el-dropdown-link">
                    <el-tag :type="getStatusType(scope.row.status)">{{
                      this.acknowledgmentSampleTypeMap[scope.row.status]
                    }}</el-tag>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu class="status-dropdown-menu">
                      <el-dropdown-item
                        command="ACKNOWLEDGED"
                        :disabled="scope.row.status === 'ACKNOWLEDGED'"
                        >Acknowledged</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="COMPLETED"
                        :disabled="scope.row.status === 'COMPLETED'"
                        >Completed</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="CRITICAL_ALERT"
                        :disabled="scope.row.status === 'CRITICAL_ALERT'"
                        >Critical Alert</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="DELAY_IN_REPORT"
                        :disabled="scope.row.status === 'DELAY_IN_REPORT'"
                        >Delay In Report</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="HOLD"
                        :disabled="scope.row.status === 'HOLD'"
                        >Hold</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="PROVISIONAL_REPORT"
                        :disabled="scope.row.status === 'PROVISIONAL_REPORT'"
                        >Provisional Report</el-dropdown-item
                      >                     
                      <el-dropdown-item
                        command="REJECTED"
                        :disabled="scope.row.status === 'REJECTED'"
                        >Rejected</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="REPEAT"
                        :disabled="scope.row.status === 'REPEAT'"
                        >Repeat</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Created At" prop="created_at" min-width="160">
            <template #default="scope">
              <span class="date" v-if="scope.row.created_at">
                {{ date(scope.row.created_at) }}
                {{ time(scope.row.created_at) }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="Centres" min-width="130">
              <template #default="scope">
                <span
                  class="text-eng-orange text-nowrap"
                  v-if="scope.row.lab_id"
                >
                  {{ scope.row.lab_id.name ? scope.row.lab_id.name : "--" || "--"  }}
                </span>
                <span v-else>--</span>
            </template>
          </el-table-column>
            <el-table-column label="Action" fixed="right" width="300">
              <template #default="{ row }">
                <ul class="default-table-action-items">
                  <li>
                    <el-button
                      class="view-icon"
                      @click="onView(row._id)"
                      title="View Acknowledgement"
                    >
                      <img
                        src="@/assets/images/icons/eye.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                  </li>
                  <li>
                    <el-button
                      class="view-icon"
                      @click="onEdit(row._id)"
                      title="Edit Acknowledgement"
                    >
                      <img
                        src="@/assets/images/icons/pencil.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                  </li>
                  <li>
                    <el-button
                      class="view-icon"
                      @click="onDelete(row._id)"
                      title="Delete Acknowledgement"
                    >
                      <img
                        src="@/assets/images/icons/trash-bin.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                  </li>
                  <li>
                    <el-button
                    class="view-icon"
                      @click="onResendEmail(row)"
                      title="Resend Email"
                    >
                      <img
                        src="@/assets/images/icons/email-resend.svg"
                        alt=""
                        class="img-fluid"
                      />                      
                    </el-button>
                  </li>
                  <li>
                  <el-button
                  class="view-icon"
                    @click="onResendWhatsapp(row)"
                    title="Resend Whatsapp"
                  >
                    <img
                      src="@/assets/images/icons/whatsapp.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                  class="view-icon"
                    @click="onResendSMS(row)"
                    title="Resend SMS"
                  >
                    <img
                      src="@/assets/images/icons/message.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                  class="view-icon"
                    @click="attachmentsPopup(row)"
                    title="Attachments"
                  >
                    <img
                    src="@/assets/images/icons/file.svg"
                      alt=""
                      width="18"
                    />
                  </el-button>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="vue-pagination web_version_pagination hidden-sm-and-down"
        v-if="reportsData && reportsData.length"
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="loadData"
          :page-sizes="[20, 25, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
        >
        </el-pagination>
      </div>
      </div>


      <div class="mobile_table_data hidden-md-and-up">
        <div>
          <div
            v-for="(item, index) in reportsData"
            :key="index"
            class="eachCard"
          >
            <el-row class="bodyPart" :gutter="10">
              <el-col :sm="12" :xs="14">
                <div class="eachItem">
                  <p class="name">Barcode No / Visit ID</p>
                  <p class="value">
                    {{ item.barcode_no ? item.barcode_no : "--" }} /
                    {{
                      item.patient_details
                        ? item.patient_details.visit_id
                        : "--"
                    }}
                  </p>
                </div>
              </el-col>
              <el-col :sm="12" :xs="10">
                <div class="eachItem">
                  <p class="name">Status</p>
                  <!-- <p class="value"> -->
                    <!-- <button>
                      {{ this.acknowledgmentSampleTypeMap[item.status] }}
                    </button> -->
                    <div class="column-status cursor" v-if="item.status">
                  <el-dropdown
                    @command="
                      (command) =>
                        updatedAcknolegementStatus(item, command)
                    "
                    class="status-dropdown"
                  >
                    <span class="el-dropdown-link">
                      <el-tag :type="getStatusType(item.status)">{{
                        this.acknowledgmentSampleTypeMap[item.status]
                      }}</el-tag>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu class="status-dropdown-menu">
                        <el-dropdown-item
                          command="ACKNOWLEDGED"
                          :disabled="item.status === 'ACKNOWLEDGED'"
                          >Acknowledged</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="COMPLETED"
                          :disabled="item.status === 'COMPLETED'"
                          >Completed</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="CRITICAL_ALERT"
                          :disabled="item.status === 'CRITICAL_ALERT'"
                          >Critical Alert</el-dropdown-item
                        >
                        <el-dropdown-item
                        command="DELAY_IN_REPORT"
                        :disabled="item.status === 'DELAY_IN_REPORT'"
                        >Delay In Report</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="HOLD"
                          :disabled="item.status === 'HOLD'"
                          >Hold</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="REJECTED"
                          :disabled="item.status === 'REJECTED'"
                          >Rejected</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                      </div>
                  <!-- </p> -->
                </div>
              </el-col>
              <el-col :sm="12" :xs="24">
                <div class="eachItem">
                  <p class="name">Patient Name / Age</p>
                  <p class="value">
                    {{
                      item.patient_details
                        ? item.patient_details.patient_name
                        : "--"
                    }},                      
                    <span class="age">{{
                      item.patient_details
                        ? item.patient_details.patient_age
                        : "--"
                    }}</span>
                  </p>
                </div>
              </el-col>
              <el-col :sm="12" :xs="24">
                <div class="eachItem">
                  <p class="name">Client Name</p>
                  <p class="value">
                    {{
                      item.client_details
                        ? item.client_details.client_name
                        : "--"
                    }}
                  </p>
                </div>
              </el-col>
            </el-row>

            <div class="footerPart">
              <ul class="default-table-action-items">
                <li>
                  <el-button
                    class="view-icon"
                    @click="onView(item._id)"
                    title="View Acknowledgement"
                  >
                    <img
                      src="@/assets/images/icons/eye.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                    class="view-icon"
                    @click="onEdit(item._id)"
                    title="Edit Acknowledgement"
                  >
                    <img
                      src="@/assets/images/icons/pencil.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                    class="view-icon"
                    @click="onDelete(item._id)"
                    title="Delete Acknowledgement"
                  >
                    <img
                      src="@/assets/images/icons/trash-bin.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                  class="view-icon"
                    @click="onResendEmail(item)"
                    title="Resend Email"
                  >
                    <img
                    src="@/assets/images/icons/email-resend.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                  class="view-icon"
                    @click="onResendWhatsapp(item)"
                    title="Resend Whatsapp"
                  >
                    <img
                      src="@/assets/images/icons/whatsapp.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                  class="view-icon"
                    @click="onResendSMS(item)"
                    title="Resend SMS"
                  >
                    <img
                      src="@/assets/images/icons/message.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button
                  class="view-icon"
                    @click="attachmentsPopup(item)"
                    title="Attachments"
                  >
                    <img
                    src="@/assets/images/icons/attachment.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile_version_pagination hidden-md-and-up">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="loadData"
          :page-sizes="[20, 25, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
        >
        </el-pagination>
      </div>
      <div class="all-patient-reports-popup">
        <div v-if="this.acknowledgementFormData">
          <el-dialog
            v-model="resendEmailPopup"
            title="Resend Email"
            class="email-notification"
            style="width: calc(100% - 20px); max-width: 600px"
          >
            <div class="dialogBody">
              <div class="case-info">
                <div class="each-cell">
                  <p class="label">Barcode</p>
                  <span class="divider">:</span>
                  <p class="value">
                    {{ this.acknowledgementFormData.barcode_no }}
                  </p>
                </div>
                <div class="each-cell">
                  <p class="label">Client Name</p>
                  <span class="divider">:</span>
                  <p
                    class="value"
                    v-if="
                      this.acknowledgementFormData &&
                      this.acknowledgementFormData.client_details &&
                      this.acknowledgementFormData.client_details.client_name
                    "
                  >
                    {{ this.acknowledgementFormData.client_details.client_name }}
                  </p>
                </div>
              </div>
              <div class="email-box">
                <h3 class="sub-title">Email Details</h3>
                <el-form v-model="addAcknowledgementFormData">
                  <el-row :gutter="12">
                    <el-col :sm="12" :xs="24">
                      <el-form-item>
                        <label class="form-label">To Emails</label>
                        <el-select
                          placeholder="Enter Email"
                          size="large"
                          multiple
                          filterable
                          allow-create
                          :reserve-keyword="false"
                          default-first-option
                          v-model="addAcknowledgementFormData.email_to"
                          clearable
                          collapse-tags
                          :max-collapse-tags="3"
                        >
                        </el-select>
                        <FormError errorName="email_to"></FormError>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="12" :xs="24">
                      <el-form-item>
                        <label class="form-label">CC Emails</label>
                        <el-select
                          placeholder="Enter Email"
                          size="large"
                          multiple
                          filterable
                          allow-create
                          :reserve-keyword="false"
                          default-first-option
                          v-model="addAcknowledgementFormData.email_to_cc"
                          clearable
                          collapse-tags
                          :max-collapse-tags="3"
                        >
                          <el-option
                            v-for="(email, index) in this.ccEmails"
                            :key="index"
                            :label="email.email"
                            :value="email.email"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="12" :xs="24">
                      <el-form-item>
                        <label class="form-label">Reply To Emails</label>
                        <el-input
                          placeholder="Enter Email"
                          size="large"
                          v-model="addAcknowledgementFormData.reply_to"
                          clearable
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <div class="dialogFooter">
              <ul>
                <li>
                  <el-button
                    @click="resendEmailPopup = false"
                    class="default-button"
                  >
                    Cancel
                  </el-button>
                  <el-button
                    class="default-button add-button"
                    @click="resendEmail(this.acknowledgementFormData._id)"
                    :loading="resendLoading"
                  >
                    Submit
                  </el-button>
                </li>
              </ul>
            </div>
          </el-dialog>
        </div>
        <div v-if="this.statusUpdatePopup" id="statusUpdateDialog">
          <el-dialog
            v-model="statusUpdatePopup"
            title="Update Status"
            class="email-notification"
            width="500px"
            align-center
          >
            <div class="dialogBody">
              <p class="notes-text">
                Barcode: {{ this.barcodeNo }} status has changed to
                <span
                  >"{{
                    this.acknowledgmentSampleTypeMap[this.acknowledgementStatus]
                  }}</span
                >"
              </p>
              <div class="email-box">
                <el-input
                  type="textarea"
                  rows="3"
                  v-model="reasons"
                  placeholder="Enter Notes"
                ></el-input>
                <div
                  class="mt-20"
                  v-if="
                    this.acknowledgementStatus &&
                    (this.acknowledgementStatus === 'HOLD' ||
                      this.acknowledgementStatus === 'REJECTED' ||
                      this.acknowledgementStatus === 'CRITICAL_ALERT' ||
                      this.acknowledgementStatus === 'REPEAT' ||
                      this.acknowledgementStatus === 'DELAY_IN_REPORT')
                  "
                >
                  <el-select
                    placeholder="Select Reason"
                    size="large"
                    v-model="statusReasons"
                    multiple
                    collapse-tags
                    :max-collapse-tags="3"
                    clearable
                    filterable
                    allow-create
                    default-first-option
                  >
                    <el-option
                      v-for="reason in reasonOptions"
                      :key="reason"
                      :label="reason.reason"
                      :value="reason.reason"
                    ></el-option>
                  </el-select>
                  <FormError errorName="reasons"></FormError>
                </div>
              </div>

              <div class="dialogFooter">
                <ul>
                  <li>
                    <el-button
                      @click="statusUpdatePopup = false"
                      class="default-button cancel-button"
                    >
                      Cancel
                    </el-button>
                  </li>
                  <li>
                    <el-button
                      class="default-button add-button"
                      @click="updateStatus()"
                      :disabled="hadReason"
                      :loading="statusLoading"
                    >
                      Submit
                    </el-button>
                  </li>
                </ul>
              </div>
            </div>
          </el-dialog>
        </div>
        <div v-if="this.attachmentPopup" id="statusUpdateDialog">
          <el-dialog
            v-model="attachmentPopup"
            title="Attachments"
            class="email-notification"
            width="500px"
            align-center
          >
            <div class="dialogBody">
              <el-upload
                action
                :on-change="handleUpload"
                :auto-upload="false"
                :limit="1"
                :on-remove="handleFileRemove"
                >
                <el-button
                  class="el-button default-button add-button button-center"
                >
                  Upload</el-button
                >
              </el-upload>
              <el-divider></el-divider>
              <ul class="attachmentsList" v-for="(attach,index) in this.attachmentsData.ack_proofs" :key="index" >
                <li class="file-name">
                  {{ attach }}
                </li>

                <li>
                  <el-button class="view-icon" @click="downloadAttachment(attach)" title="Download Acknowledgement">
                    <img
                    src="@/assets/images/icons/download.svg"
                      alt="download"
                      width="18"
                    />
                  </el-button>
                </li>
                <li>
                  <el-button class="view-icon" @click="deleteAttachment(this.attachmentsData,attach)" title="Delete Acknowledgement">
                    <img
                      src="@/assets/images/icons/trash-bin.svg"
                      alt="trash bin"
                      width="18"
                    />
                  </el-button>
                </li>
              </ul>
            </div>
          </el-dialog>  
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  acknowledgmentTypeMap,
  rejectedReasonsMap,
} from "@/constants/formConstants";
import FormError from "@/components/FormError";
import { errorNotification } from "@/helpers/notifications";
import axios from "axios";
export default {
  middleware: "auth",
  components: {
    FormError,
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("acknowledgements", [
      "getAllAcknowledgements",
      "getAcknowledgementStatus",
      "getDeleteAcknowledgementStatus",
      "getResendEmail",
      "getSendWhatsappStatus",
      "getRejectedReasons",
      "getClientEmails",
      "getSendSMSStatus",
      "getDeleteAttachmentStatus",
      "getDownloadAttachment",
      "getAttachmentUploadData",
      "getUpdateAttachmentsStatus"
    ]),
    getStatusType() {
      return (statusVal) => {
        const status = statusVal ? statusVal.trim() : statusVal;
        if (status == "COMPLETED") {
          return "success";
        } else if (status == "REJECTED") {
          return "danger";
        } else if (status == "HOLD") {
          return "warning";
        } else if (status == "ACKNOWLEDGED") {
          return "info";
        } else {
          return "default";
        }
      };
    },
    hadReason() {
      if (
        this.reasons &&
        this.reasons.trim() &&
        this.reasons.trim().length &&
        this.reasons.trim().length >= 3
      ) {
        return false;
      }
      return true;
    },
    // ...mapGetters("auth",["getAllowedLabs","getAuthenticatedUser"]),
    // checkLab() {
    //   return (lab) => {
    //     if (
    //       this.getAuthenticatedUser &&
    //       this.getAuthenticatedUser.allowed_labs &&
    //       Array.isArray(this.getAuthenticatedUser.allowed_labs)
    //     ) {
    //       return (
    //         this.getAuthenticatedUser.allowed_labs.findIndex(
    //           (allowedLab) => allowedLab === lab._id
    //         ) === -1
    //       );
    //     }
    //     return true; // Return true to disable the option if any condition is not met
    //   };
    // }
  },
  data() {
    return {
      reportsData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      loadingText: "",
      searchString: "",
      status: "",
      searchReportsLoading: false,
      reportLogs: [],
      statusOptions: [
        {
          label: "Acknowledged",
          value: "ACKNOWLEDGED",
        },
        {
          label: "Completed",
          value: "COMPLETED",
        },
        {
          label: "Critical Alert",
          value: "CRITICAL_ALERT",
        },
        {
          label:"Delay In Report",
          value:"DELAY_IN_REPORT"
        },
        {
          label: "Hold",
          value: "HOLD",
        },
        {
          label: "Provisional Report",
          value: "PROVISIONAL_REPORT",
        },
        {
          label: "Rejected",
          value: "REJECTED",
        },
        {
          label: "Repeat",
          value: "REPEAT",
        },
      ],
      acknowledgmentSampleTypeMap: acknowledgmentTypeMap,
      rejectedReasonsMap: rejectedReasonsMap,
      acknowledgementFormData: {},
      resendEmailPopup: false,
      addAcknowledgementFormData: {
        email_to: [],
        email_to_cc: [],
        reply_to: "",
      },
      acknowledgementStatus: "",
      acknowledgeId: "",
      reasons: "",
      statusUpdatePopup: false,
      statusLoading: false,
      resendLoading: false,
      reasonOptions: [],
      statusReasons:[],
      barcodeNo:"",
      ccEmails:[],
      attachmentsData:{},
      attachmentPopup:false,
      uploadedFileCount:0,
      ack_proofs:"",
      attchId:"",
      // labOptions:[],
      // selectedCentre:""
    };
  },
  async mounted() {
    // await this.fetchLabs()
    this.$store.dispatch("errors/clear");
    await this.setQueryToData();
    await this.fetchAcknowledgements();
    await this.fetchReasons();
    await this.fetchEmails();
  },
  methods: {
    // async fetchLabs(){
    //   await this.$store.dispatch("auth/fetchAllAllowedLabs",{
    //       get_all:true
    //     })  
    //     if(this.getAllowedLabs && this.getAllowedLabs.data){
    //       this.labOptions = this.getAllowedLabs.data
    //     }
    // },
    handleFileRemove() {
      this.uploadedFileCount -= 1;
    },
    async handleUpload(file) {
      try {
      this.uploadedFileCount += 1;
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };
      await this.$store.dispatch("acknowledgements/uploadAttachment", { params, file });

      if (this.getAttachmentUploadData) {
        this.ack_proofs = [this.getAttachmentUploadData.path];
      await this.updateAckAttachment(params,file)
      } else {
        errorNotification(this.getError || "Error while uploading");
      }
      } catch(error){
        console.log(error)
      }
      
    },
    async updateAckAttachment(params,file) {
      try {
        let acknowledgement_id = this.attchId 
        await this.$store.dispatch("acknowledgements/updateAttachment",{acknowledgement_id,params,file})
        if(this.getUpdateAttachmentsStatus){
          this.$notify.success({
            title: "Success",
            message: "Attachment Uploaded Successfully",
          });
          this.attachmentPopup = false
          this.fetchAcknowledgements(this.page)
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while Uploading Attachment",
          });
        }

      } catch(error){
        console.log(error)
      }
    },
    async downloadAttachment(attachment_path){
      try {
        let params = {
          proof:attachment_path
        }
        await this.$store.dispatch("acknowledgements/downloadAttachmentAcknowledge",params)
        if (this.getDownloadAttachment) {       
          let url = this.getDownloadAttachment.download_url
          axios({
                  url: url, //your url
                  method: 'GET',
                  responseType: 'blob', // important
              }).then((response) => {
                  // create file link in browser's memory
                  const href = URL.createObjectURL(response.data);

                  // create "a" HTML element with href to file & click
                  const link = document.createElement('a');
                  link.href = href;
                  link.setAttribute('download', `${attachment_path}`); //or any other extension
                  document.body.appendChild(link);
                  link.click();

                  // clean up "a" element & remove ObjectURL
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
              });
          this.attachmentPopup =false
          this.fetchAcknowledgements(this.page);
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while downloading Attachemt",
          });
        }
      } catch(error){
        console.log(error)
      }
    },
    async deleteAttachment(attachment,attachment_path) {
      this.$confirm("Are you sure want to Delete Acknowledgement?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
      }).then(async () => {
        try {
          await this.deleteAckAttachments(attachment,attachment_path);
        } catch (error) {
          console.error("Error deleting acknowledgement:", error);
        }
      }).catch((error) => {
        console.error("Confirmation error:", error);
      });
    },
    async deleteAckAttachments(attachment,attachment_path){
      try {
        let attachment_id = attachment._id
        let params = {
          proof:attachment_path
        }
        await this.$store.dispatch("acknowledgements/deleteAttachment",{attachment_id,params})
        if (this.getDeleteAttachmentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Attachemt deleted successfully",
          });
          this.attachmentPopup =false
          this.fetchAcknowledgements(this.page);
        }  else {
          this.$notify.error({
            title: "Error",
            message: "Error while deleting Attachemt",
          });
        }
      } catch(error){
        console.log(error)
      }
    },
    attachmentsPopup(attachments){
      this.attachmentsData = attachments
      this.attchId = attachments._id
      this.attachmentPopup = true
    },
    async fetchEmails(){
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "acknowledgements/fetchAllClientEmails",
        params
      );
      if (
        this.getClientEmails &&
        this.getClientEmails.data &&
        this.getClientEmails.data.length
      ) {
        this.ccEmails = this.getClientEmails.data;
      }
    },
    async fetchReasons() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "acknowledgements/fetchAllRejectedReasons",
        params
      );
      if (
        this.getRejectedReasons &&
        this.getRejectedReasons.data &&
        this.getRejectedReasons.data.length
      ) {
        this.reasonOptions = this.getRejectedReasons.data;
      }
    },
    async onResendWhatsapp(id) {
      try {
        this.loading = true;
        this.loadingText = "Resending Whatsapp........";
        let params = {
          acknowledgement_id: id._id,
        };
        await this.$store.dispatch("acknowledgements/resendWhatsapp", params);
        if (this.getSendWhatsappStatus) {
          this.$notify.success({
            title: "Success",
            message: "Whatsapp resend successfully",
          });
          this.fetchAcknowledgements(this.page);
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while resending Whatsapp",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async onResendSMS(id) {
      try {
        this.loading = true;
        this.loadingText = "Resending SMS........";
        let params = {
          acknowledgement_id: id._id,
        };
        await this.$store.dispatch("acknowledgements/reSendSMS", params);
        if (this.getSendSMSStatus) {
          this.$notify.success({
            title: "Success",
            message: "SMS resend successfully",
          });
          this.fetchAcknowledgements(this.page);
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while resending SMS",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    getTooltipPatient(row) {
      return row.patient_details ? row.patient_details.patient_name : "--";
    },
    getTooltipClient(row) {
      return row.client_details ? row.client_details.client_name : "--";
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    onResendEmail(acknowledgementData) {
      this.addAcknowledgementFormData.email_to =
        acknowledgementData.email_to || [];
      this.addAcknowledgementFormData.email_to_cc =
        acknowledgementData.email_to_cc || [];
      this.addAcknowledgementFormData.reply_to =
        acknowledgementData.reply_to || "";
      this.acknowledgementFormData = acknowledgementData;
      this.resendEmailPopup = true;
    },
    async resendEmail(id) {
      try {
        this.resendLoading = true;
        this.loadingText = "Resending Email........";
        let acknowledgement_id = id;
        let params = this.addAcknowledgementFormData;
        await this.$store.dispatch("acknowledgements/resendEmails", {
          params,
          acknowledgement_id,
        });
        if (this.getResendEmail) {
          this.$notify.success({
            title: "Success",
            message: "Email sent successfully",
          });
          this.resendEmailPopup = false;
          this.fetchAcknowledgements(this.page);
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while sending email",
          });
        }
        this.resendLoading = false;
      } catch (error) {
        this.resendLoading = false;
        console.log(error);
      }
    },
    onEdit(acknowledgementId) {
      this.$router.push({
        name: "UpdateAcknowledgement",
        params: { id: acknowledgementId },
      });
    },
    async onDelete(acknowledgementId) {
      this.$confirm("Are you sure want to Delete Acknowledgement?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
      }).then(async () => {
        try {
          await this.onDeleteAcknowledgement(acknowledgementId);
        } catch (error) {
          console.error("Error deleting acknowledgement:", error);
          // Handle the error appropriately, e.g., show a notification to the user.
        }
      }).catch((error) => {
        // Handle any errors that occur during the confirmation dialog interaction.
        console.error("Confirmation error:", error);
      });
    },
    async onDeleteAcknowledgement(acknowledgementId) {
      try {
        this.loading = true;
        this.loadingText = "Deleting Acknowledgement....";
        let params = acknowledgementId;
        await this.$store.dispatch(
          "acknowledgements/deleteAcknowledgement",
          params
        );
        if (this.getDeleteAcknowledgementStatus) {
          this.$notify.success({
            title: "Success",
            message: "Acknowledgement deleted successfully",
          });
          this.fetchAcknowledgements(this.page);
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while deleting acknowledgement",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    updatedAcknolegementStatus(acknowledgement_id, status) {
      if (
        acknowledgement_id &&
        acknowledgement_id.reasons &&
        acknowledgement_id.reasons.length
      ) {
        this.statusReasons = acknowledgement_id.reasons;
      }
      (this.acknowledgeId = acknowledgement_id._id),
        (this.barcodeNo = acknowledgement_id.barcode_no);
      (this.acknowledgementStatus = status), (this.statusUpdatePopup = true);
    },
    async updateStatus() {
      try {
        this.statusLoading = true;
        let params = {
          acknowledgement_id: this.acknowledgeId,
          data: {
            status: this.acknowledgementStatus,
            notes: this.reasons,
            reasons: this.statusReasons,
          },
        };
        await this.$store.dispatch(
          "acknowledgements/updateAcknowledgementStatus",
          params
        );
        if (this.getAcknowledgementStatus) {
          this.$notify.success({
            title: "Success",
            message: "Acknowledgement status updated successfully",
          });
          this.statusUpdatePopup = false;
          this.reasons = "";
          this.statusReasons = [];
          this.fetchAcknowledgements(this.page);
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while updading acknowledgement status",
          });
        }
        this.statusLoading = false;
      } catch (error) {
        this.statusLoading = false;
        console.log(error);
      }
    },
    async exportData() {
      try {
        this.loading = true;
        this.loadingText = "Downloading Acknowledgements...";
        let params = {};
        if (this.searchString) {
          params.search_string = this.searchString;
        }
        if (this.status) {
          params.status = this.status;
        }
        // if(this.selectedCentre){
        //   params.lab_id = this.selectedCentre
        // }
        await this.$store.dispatch(
          "acknowledgements/exportAcknowledgementData",
          params
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    addAcknowledgement() {
      this.$router.push({ name: "AddAcknowledgement" });
    },
    onView(id) {
      this.$router.push({
        name: "ViewAcknowledgement",
        params: {
          id: id,
        },
        query: this.$route.query,
      });
    },
    getReportLogs() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchAcknowledgements();
      }, 600);
    },
    clearReportsLogsSearch() {
      this.searchString = "";
      this.status = "";
      this.fetchAcknowledgements(1);
    },
    async queryReportLogs(query) {
      if (query.length >= 6) {
        this.searchReportsLoading = true;
        let params = {
          search_string: query,
        };
        await this.$store.dispatch(
          "patientReports/fetchAcknowledgements",
          params
        );
        this.reportLogs = this.getAllAcknowledgements.data;
        this.searchReportsLoading = false;
      }
    },
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm A");
    },
    async onSearch() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchAcknowledgements();
      }, 600);
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.searchString) {
        params.search_string = this.searchString;
      }
      if (this.status) {
        params.status = this.status;
      }
      // if(this.selectedCentre){
      //   params.lab_id = this.selectedCentre
      // }

      return params;
    },
    async fetchAcknowledgements() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Acknowledgements...";
        let params = this.prepareParams();
        await this.$store.dispatch(
          "acknowledgements/fetchAllAcknowledgements",
          params
        );
        this.setTableData();
        this.setQueryData(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    setTableData() {
      const tableData = JSON.parse(JSON.stringify(this.getAllAcknowledgements));
      this.reportsData =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.page = tableData.page || 1;
      this.total = tableData.total || 0;
      this.pageSize = tableData.limit || 20;
    },

    setQueryData(data) {
      let params = {
        selectedPage: data.page || 1,
        selectedPageSize: data.limit || 20,
        selectedString: data.search_string || undefined,
        selectedStatus: data.status || undefined,
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...params },
        })
        .catch(() => {});
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPage
          ? parseInt(this.$route.query.selectedPage)
          : 1;
        this.pageSize = this.$route.query.selectedPageSize
          ? parseInt(this.$route.query.selectedPageSize)
          : 20;
        this.searchString = this.$route.query.selectedString || "";
        this.status = this.$route.query.selectedStatus || "";
        // this.selectedCentre = this.$route.query.selectedCentre || ""
      }
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchAcknowledgements();
    },
    async loadData(page) {
      this.page = page;
      await this.fetchAcknowledgements();
    },
  },
  beforeUnmount() {
    this.$store.commit("patientReports/setReportsLogs", null);
  },
};
</script>

<style lang="scss" scoped>

@media (max-width: 576px) {
  .action-navigation {
    display: grid;
    justify-content: stretch;
    align-items: normal;
    li:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 1;
    }
    li:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 2;
    }
    li:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 3;
    }
    li:nth-child(4){
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 3;
    }
    .el-button {
      width: 100%;
      margin-top: 8px;
    }
  }
}

#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background-image: linear-gradient(90deg, #f1f8ff, #ffffff) !important;
        background-color: #f1f8ff !important;
      }
      .status-dropdown .el-dropdown-link:focus {
        outline: none;
      }
    }
  }
  .attachmentsList {
    display : grid;
    grid-template-columns: 325px auto auto;
    align-items: center;
    margin-bottom: 0.5rem;
    column-gap: 10px;
    .el-button {
      margin-left: 5px;
    }
    .file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
    }
  }
  .dialogBody { 
    .button-center {
      display: block;
      margin: 0 auto;
      width: 200px;
    }
  }
  
  

  .default-table-action-items {
    li {
      .send-mail-btn {
        width: 70px;
        height: 32px;
        span {
          padding-left: 2px;
        }
      }
    }
  }
  
  .email-notification {
    .case-info {
      margin: 0 auto 20px;
    }
     .each-cell {
      display: grid;
      grid-template-columns: 100px 5px auto;
      align-items: center;
      column-gap: 8px;
    }
    .value {
      font-weight: 500;
      margin: 8px 0;
    }

    .label {
      color: #18546d;
      margin: 8px 0;
    }

    .button-group {
      text-align: center;
    }
    
    .el-dialog__header {
      background-color: #28b4a6;
      margin: 0;
      padding: 10px 20px;
      .el-dialog__close {
        font-size: 1.25rem;
        color: #ffffff;
      }
      .el-dialog__title {
        color: #ffffff;
      }
    }
  }
}

.filter-navigation {
  li {
    margin-right: 10px;

    .status-filter {
      min-width: 120px;
    }

    .category-filter {
      min-width: 150px;
    }
  }
}

// popup styles
.all-patient-reports-popup {
  .el-dialog {
    border-radius: 10px;
    .notes-text {
      font-weight: 500;
      font-size: 1rem;
      color: #005475;
      margin: 0 0 10px;
    }
    .el-dialog__header {
      font-size: 20px;
      padding: 10px 10px 5px;
      background: #2eaf9f;
      border-radius: 10px 10px 0 0;

      .el-dialog__title {
        color: #ffffff;
        font-weight: 500;
      }
    }

    .el-dialog__headerbtn {
      border: 1px solid #dcdce4;
      border-radius: 3px;
      padding: 0 5px;
      top: 0px !important;
      right: 10px;

      .el-dialog__close {
        color: #ffffff;
        font-weight: 700;
      }
    }

    .el-dialog__body {
      padding: 0;
      padding-bottom: 10px;
      padding-top: 10px;

      .input-d-flex {
        display: grid;
        grid-template-columns: 125px auto;
        align-items: center;
        margin: 10px 0;

        .name {
          color: #000000;
          font-size: 16px;
          margin: 0;
          font-weight: 500;
        }

        .value {
          font-weight: 500;
          color: #219388;
        }

        .el-input__inner {
          width: 80%;
        }
      }
    }

    .dialog-footer {
      .submit-btn {
        background: #0061ec;
        color: #fff;
        font-weight: 500;
        border: none;
      }
    }

    .err {
      color: red;
      white-space: nowrap;
    }
  }
}

.department-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-width: 140px;
  width: 185px;
  padding: 6px;
  color: #00445e;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  background: #f2f2f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
#statusUpdateDialog {
  .el-drawer {
    width: 90% !important;
  }
  .el-dialog__body {
    padding: 0;
  }
  .dialogBody {
    padding: 25px 20px;
    input {
      min-height: 40px;
    }
  }
  .dialogFooter {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 15px 20px;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
        button {
          padding: 7px 15px;
          min-width: 90px;
          color: #ffffff;
          &.cancel-button {
            background: #eb5757;
          }
        }
      }
    }
  }
}
</style>
