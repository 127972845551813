import { createRouter, createWebHistory } from "vue-router"
import AuthMiddleware from "@/middleware/auth"

import Home from "@/views/Home.vue"
import SignIn from "@/views/auth/SignIn.vue"
import UpdatePassword from "@/views/UpdatePassword.vue"
import Settings from "@/views/Settings.vue"
import ProfileView from "@/components/ProfileView"
import ProfleEdit from "@/components/EditProfile"
import RadiologyReport from "@/components/patientReports/RadiologyReport"
import GenomicsReport from "@/components/patientReports/GenomicsReport"
import MolecularGeneticsReport from "@/components/patientReports/MolecularGeneticsReport"
import CytometryReport from "@/components/patientReports/CytometryReport"
import HistoPathologyReport from "@/components/patientReports/HistoPathologyReport"
import BiochemistryReport from "@/components/patientReports/BiochemistryReport"
import BiochemicalGeneticsReport from "@/components/patientReports/BiochemicalGeneticsReport"
import PatientReports from "@/components/patientReports/AllPatientReports"
import ReportTemplates from "@/components/reportsTemplates/AllReportTemplates"
import UpdateTemplate from "@/components/reportsTemplates/UpdateTemplate"
import ReportTemplatePreview from "@/components/reportsTemplates/TemplateView"
import ImmunogeneticsReport from "@/components/patientReports/ImmunogeneticsReport"
import SerologyReport from "@/components/patientReports/Serology"
import ClinicalDetails from "@/components/patientReports/ClinicalDetails"
import PharmacoGenomicsReport from "@/components/patientReports/PharmacoGenomics"
import CytogeneticsReport from "@/components/patientReports/CytogeneticsReport"
import PatientReportView from "@/components/patientReports/ViewPatientReports"
import PatientReportPreview from "@/components/patientReports/PatientReportPreview"
import PatientReportPrint from "@/components/patientReports/PrintReport"
import Dashboard from "@/views/Dashboard"
import PatientReportPreviewPrint from "@/components/patientReports/PatientReportPrint"

import BiochemistryTemplates from "@/components/biochemistryTemplates/AllBiochemistryTemplates"
import AddBiochemistryTemplates from "@/components/biochemistryTemplates/AddBiochemistryTemplate"
import ViewBiochemistryTemplate from "@/components/biochemistryTemplates/ViewTemplate"
import PreviewTemplate from "@/components/biochemistryTemplates/PreviewTemplate"
import PatientReportResultView from "@/components/patientReportResult/PatientReportResult"

import ForgotPassword from "@/views/forgotPassword/ForgotPassword"
import ForgotPasswordVerify from "@/views/forgotPassword/PasswordValidation"
import OtpVerification from "@/views/forgotPassword/OtpVerification"

//result files
import AllResultFiles from "@/components/TMSReports/AllResultFiles.vue"
import UploadResultFiles from "@/components/TMSReports/UploadResultFiles"
import PatientResultFiles from "@/components/TMSReports/PatientResultFiles"
import PatientResultFilesPreview from "@/components/TMSReports/PatientFilePreview"
import PatientResultFilesPrintPreview from "@/components/TMSReports/PatientResultFilePrintPreview"
import ResultFileEdit from "@/components/TMSReports/ResultFileEdit"
import DoubleMarkersResultFileEdit from "@/components/TMSReports/DoubleMarkersResultFileEdit"
import PlgfDoubleMarkersResultFileEdit from "@/components/TMSReports/PlgfDoubleMarkersResultFileEdit"

import TwinDoubleMarkersResultFileEdit from "@/components/TMSReports/TwinDoubleMarkersResultFileEdit"
import UrineOrganicAcidResultFileEdit from "@/components/TMSReports/UrineOrganicAcidResultFileEdit"
import TripleMarkersResultFileEdit from "@/components/TMSReports/TripleMarkersResultFileEdit"
import TwinTripleMarkersResultFileEdit from "@/components/TMSReports/TwinTripleMarkersResultFileEdit"
import QuadrableMarkersResultFileEdit from "@/components/TMSReports/QuadrableMarkersResultFileEdit"
import TwinQuadrableMarkersResultFileEdit from "@/components/TMSReports/TwinQuadrableMarkersResultFileEdit"
import BioResultFileEdit from "@/components/TMSReports/BioResultFileEdit"
import PentaMakersResultFileEdit from "@/components/TMSReports/PentaMarkersResultFileEdit"
import PlasmaResultFileEdit from "@/components/TMSReports/PlasmaResultFileEdit"
import PatientResultsReportPreview from "@/components/TMSReports/PatientResultsPreview"
import UrineGuanidinoResultFileEdit from "@/components/TMSReports/UrineGuanidinoAcetateResultFileEdit"
// import KaryotypingResultFileEdit from "@/components/TMSReports/KaryotypingResultFileEdit"
import SolidFishResultFileEdit from "@/components/TMSReports/SolidFishResultFileEdit"
import HematFishResultFileEdit from "@/components/TMSReports/HematFishResultFileEdit"

// test config
import AllTestConfig from "@/components/testConfig/AllTestConfig"
import UsersList from "@/components/users/UsersList"
import AddUser from "@/components/users/AddUser"
import ReportLogs from "@/components/reportsLogs/ReportLogs"
import Acknowledgements from "@/components/acknowledgements/AllAcknowledgements"
import ViewAcknowledgement from "@/components/acknowledgements/ViewAcknowledgement"
import AddAcknowledgement from "@/components/acknowledgements/AddAcknowledgement"
import CorporateReports from "@/components/patientReports/corporateReports"
import CytopathologyReport from "../components/patientReports/CytopathologyReport.vue"
import MolecularDiagnosticsReport from "../components/patientReports/MolecularDiagnosticsReport.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: AuthMiddleware.user,
        redirect: "/all-patient-reports",
      },
      {
        path: "/update-password",
        name: "UpdatePassword",
        component: UpdatePassword,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/view-profile",
        name: "ViewProfile",
        component: ProfileView,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/edit-profile",
        name: "EditProfile",
        component: ProfleEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/add",
        name: "Report",
        component: RadiologyReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/histo-pathology/add",
        name: "HistoPathologyReport",
        component: HistoPathologyReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/Cytogenetics/edit",
        name: "CytogeneticsReportUpdate",
        component: CytogeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/histo-pathology/edit",
        name: "HistoPathologyReportUpdate",
        component: HistoPathologyReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/bio-chemistry/add",
        name: "BiochemistryReport",
        component: BiochemistryReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/biochemical-genetics/add",
        name: "BiochemicalGeneticsReport",
        component: BiochemicalGeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/biochemical-genetics/:patient_id/edit",
        name: "BiochemicalGeneticsReportUpdate",
        component: BiochemicalGeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/edit",
        name: "PatientReportUpdate",
        component: RadiologyReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/bio-chemistry/:patient_id/edit",
        name: "BiochemistryReportUpdate",
        component: BiochemistryReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/genomics/add",
        name: "GenomicsReport",
        component: GenomicsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/genomics/edit",
        name: "GenomicsReportUpdate",
        component: GenomicsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/molecular-genetics/add",
        name: "MolecularGeneticsReport",
        component: MolecularGeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/molecular-genetics/edit",
        name: "MolecularGeneticsReportUpdate",
        component: MolecularGeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/pharmaco-genomics/add",
        name: "PharmacoGenomicsReport",
        component: PharmacoGenomicsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/pharmaco-genomics/edit",
        name: "PharmacoGenomicsReportUpdate",
        component: PharmacoGenomicsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/cytometry/add",
        name: "CytometryReport",
        component: CytometryReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/cytometry/edit",
        name: "CytometryReportUpdate",
        component: CytometryReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/immuno-genetics/add",
        name: "ImmunogeneticsReport",
        component: ImmunogeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/immuno-genetics/edit",
        name: "ImmunogeneticsReportUpdate",
        component: ImmunogeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/serology-immunology/add",
        name: "SerologyReport",
        component: SerologyReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/iserology-immunology/edit",
        name: "SerologyReportUpdate",
        component: SerologyReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/corporate-reports",
        name: "CorporateReports",
        component: CorporateReports,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/corporate-reports/:patient_id/edit",
        name: "CorporateReportsEdit",
        component: CorporateReports,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-patient-reports",
        name: "PatientReports",
        component: PatientReports,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/view",
        name: "PatientReportView",
        component: PatientReportView,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-report-templates",
        name: "ReportTemplates",
        component: ReportTemplates,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-report-templates/add-template",
        name: "AddTemplate",
        component: UpdateTemplate,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-report-templates/:template_id",
        name: "UpdateTemplate",
        component: UpdateTemplate,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-biochemistry-interpretations",
        name: "BiochemistryTemplates",
        component: BiochemistryTemplates,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/add-biochemistry-interpretation",
        name: "AddBiochemistryTemplates",
        component: AddBiochemistryTemplates,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/molecular-genetics/add",
        name: "MolecularGeneticsReport",
        component: MolecularGeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/molecular-genetics/edit",
        name: "MolecularGeneticsReportUpdate",
        component: MolecularGeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/cytometry/add",
        name: "CytometryReport",
        component: CytometryReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/cytometry/edit",
        name: "CytometryReportUpdate",
        component: CytometryReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/immuno-genetics/add",
        name: "ImmunogeneticsReport",
        component: ImmunogeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/immuno-genetics/edit",
        name: "ImmunogeneticsReportUpdate",
        component: ImmunogeneticsReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/serology-immunology/add",
        name: "SerologyReport",
        component: SerologyReport,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/clinical-details",
        name: "ClinicalDetails",
        component: ClinicalDetails,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/patient-reports/:patient_id/iserology-immunology/edit",
        name: "SerologyReportUpdate",
        component: SerologyReport,
        beforeEnter: AuthMiddleware.user,
      },

      {
        path: "/biochemistry-interpretation/:template_id/view",
        name: "TemplateView",
        component: ViewBiochemistryTemplate,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/update-biochemistry-interpretation/:template_id",
        name: "UpdateBiochemistryTemplate",
        component: AddBiochemistryTemplates,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-result-files",
        name: "ResultFiles",
        component: AllResultFiles,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/upload-result-files",
        name: "ResultFilesUpload",
        component: UploadResultFiles,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-patient-result-files/:result_file_id",
        name: "PatientResultFiles",
        component: PatientResultFiles,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit",
        name: "ResultFileEdit",
        component: ResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/double-markers",
        name: "DoubleMarkersResultFileEdit",
        component: DoubleMarkersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      // {
      //   path: "/result-file/:result_file_id/patient/:patient_id/edit/karyotyping",
      //   name: "KaryotypingResultFileEdit",
      //   component: KaryotypingResultFileEdit,
      //   beforeEnter: AuthMiddleware.user,
      // },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/solidfish",
        name: "SolidFishResultFileEdit",
        component: SolidFishResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/hematfish",
        name: "HematFishResultFileEdit",
        component: HematFishResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/plgf-double-markers",
        name: "PlgfDoubleMarkersResultFileEdit",
        component: PlgfDoubleMarkersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/twin-double-markers",
        name: "TwinDoubleMarkersResultFileEdit",
        component: TwinDoubleMarkersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/quadrable-markers",
        name: "QuadrableMarkersResultFileEdit",
        component: QuadrableMarkersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/twin-quadrable-markers",
        name: "TwinQuadrableMarkersResultFileEdit",
        component: TwinQuadrableMarkersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },

      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/urine-organic-acid",
        name: "UrineOrganicAcidResultFileEdit",
        component: UrineOrganicAcidResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/triple-markers",
        name: "TripleMarkersResultFileEdit",
        component: TripleMarkersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/twin-triple-markers",
        name: "TwinTripleMarkersResultFileEdit",
        component: TwinTripleMarkersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/bio",
        name: "BioResultFileEdit",
        component: BioResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/penta-markers",
        name: "PentaMakersResultFileEdit",
        component: PentaMakersResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/plasma-creatine",
        name: "PlasmaResultFileEdit",
        component: PlasmaResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/result-file/:result_file_id/patient/:patient_id/edit/urine-guadinido-acetate",
        name: "UrineGuanidinoResultFileEdit",
        component: UrineGuanidinoResultFileEdit,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/all-test-config",
        name: "AllTestConfig",
        component: AllTestConfig,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/users",
        name: "UsersList",
        component: UsersList,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/add-user",
        name: "AddUser",
        component: AddUser,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/update-user/:user_id",
        name: "UpdateUser",
        component: AddUser,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/reports-logs",
        name: "ReportLogs",
        component: ReportLogs,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/acknowledgements",
        name: "Acknowledgements",
        component: Acknowledgements,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/acknowledgements/:id",
        name: "ViewAcknowledgement",
        component: ViewAcknowledgement,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/add-acknowledgement",
        name: "AddAcknowledgement",
        component: AddAcknowledgement,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/update-acknowledgement/:id",
        name: "UpdateAcknowledgement",
        component: AddAcknowledgement,
        beforeEnter: AuthMiddleware.user,
      },
    ],
  },
  {
    path: "/patient-reports/:patient_id/print",
    name: "PrintReport",
    component: PatientReportPrint,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/bio-chemistry/preview",
    name: "PreviewTemplate",
    component: PreviewTemplate,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/patient-reports/preview",
    name: "PatientReportPreview",
    component: PatientReportPreview,
  },
  {
    path: "/patient-reports/print",
    name: "PatientReportPreviewPrint",
    component: PatientReportPreviewPrint,
  },
  {
    path: "/patient-results/preview",
    name: "PatientResultsPreview",
    component: PatientResultsReportPreview,
  },

  {
    path: "/patient-report/:report_id",
    name: "PatientReportResultPreview",
    component: PatientReportResultView,
  },
  {
    path: "/patient-reports/template-preview",
    name: "ReportTemplatePreview",
    component: ReportTemplatePreview,
  },
  {
    path: "/patient-result-files/:result_file_id/patients/:patient_id/preview",
    name: "PatientResultFilePreview",
    component: PatientResultFilesPreview,
    meta: { bodyClass: "bg-light" },
  },
  {
    path: "/patient-result-files/:result_file_id/patients/:patient_id/print-preview",
    name: "PatientResultFilePrintPreview",
    component: PatientResultFilesPrintPreview,
    meta: { bodyClass: "bg-light" },
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
    beforeEnter: AuthMiddleware.guest,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: AuthMiddleware.guest,
  },
  {
    path: "/forgot-password/verify",
    name: "ForgotPasswordVerify",
    component: ForgotPasswordVerify,
    beforeEnter: AuthMiddleware.guest,
  },
  {
    path: "/forgot-password/otp/verify",
    name: "OtpVerification",
    component: OtpVerification,
    beforeEnter: AuthMiddleware.guest,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
  {
    path: "/patient-reports/Cytogenetics/add",
    name: "CytogeneticsReport",
    component: CytogeneticsReport,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/patient-reports/Cytopathology/add",
    name: "CytopathologyReport",
    component: CytopathologyReport,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/patient-reports/:patient_id/Cytopathology/edit",
    name: "CytopathologyReportUpdate",
    component: CytopathologyReport,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/patient-reports/MolecularDiagnostics/add",
    name: "MolecularDiagnosticsReport",
    component: MolecularDiagnosticsReport,
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/patient-reports/:patient_id/MolecularDiagnostics/edit",
    name: "MolecularDiagnosticsReportUpdate",
    component: MolecularDiagnosticsReport,
    beforeEnter: AuthMiddleware.user,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
