<template>
  <section id="add-report-view" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
    <div class="reports-title-header">
      <h4 class="report-title">Histo-Pathology - Patient Report Data</h4>
      <div class="display-end">
        <el-button @click="goToReports" class="back-btn"><el-icon class="el-icon-back">
            <Back />
          </el-icon>Back</el-button>
      </div>
    </div>

    <div class="inner-navbar">
      <el-row :gutter="10">
        <el-col :span="12">
          <div>
            <el-autocomplete v-model="searchVisitId" :fetch-suggestions="getAutoCompletePatientsData"
              placeholder="Search VisitID, Patient Name,UHID" :clearable="true" :trigger-on-focus="false" class="el-input"
              @select="handleSelect" @clear="clearNameSearch"></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="8">
          <el-select v-model="testId" placeholder="Select Test Category " :clearable="true" filterable
             @change="fillSampleType">
            <el-option v-for="report in categoryReportTypes" :key="report"
              :label="report.ItemName + '   --  ' + report.TestId" :value="report.TestId"></el-option>
          </el-select>
          <!-- <FormError errorName="report_type"></FormError> -->
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-10">

        <el-col :span="8">
          <el-select v-model="patientInfo.department" allow-create placeholder="Select Department" :clearable="true"
            filterable :loading="loading">
            <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"></el-option>
          </el-select>

          <FormError errorName="department"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select v-model="reportType" placeholder="Select Report Type *" :clearable="true" filterable
            @clear="clearFields" @change="onChangeReportType()">
            <el-option v-for="(report, index) in reportTypes" :key="index" :label="report.label"
              :value="report.value"></el-option>
          </el-select>

          <FormError errorName="report_type"></FormError>
        </el-col>

        <el-col :span="8">
          <el-select v-model="patientInfo.report_template_id" placeholder="Select Report Template" :clearable="true"
            filterable @clear="askRemoveConfirmation" @change="updateTemplateData">
            <el-option v-for="(template, index) in templates" :key="index" :label="template.template_title"
              :value="template._id"></el-option>
          </el-select>
          <FormError errorName="report_template_id"></FormError>
        </el-col>
      </el-row>
    </div>

    <div class="inner">
      <span class="report-header">DEPARTMENT OF HISTOPATHOLOGY</span>
      <div class="patient-information">
        <el-row>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Patient Name" class="mb-05 form-mandatory-field">
              <el-input v-model="patientInfo.patient_name" placeholder="Enter Patient Name" readonly></el-input>
            </el-form-item>
            <FormError errorName="patient_demographics.patient_name"></FormError>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Visit Id">
              <el-input v-model="patientInfo.visit_id" placeholder="Enter Visit Id" readonly></el-input>
              <FormError errorName="visit_id"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Barcode No" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.barcode_no" placeholder="Enter Barcode No" readonly></el-input>
              <FormError errorName="barcode_no"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.ref_doctor" placeholder="Enter Reference Doctor"></el-input>
              <FormError errorName="ref_doctor"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.uhid" placeholder="Enter UHID No" readonly></el-input>
              <FormError errorName="uhid"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Registration Date" class="mb-05 mandatory-field">
              <el-date-picker v-model="patientInfo.registration_date" type="date" placeholder="Pick a Date"
                format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
              </el-date-picker>
              <FormError errorName="registration_date"></FormError>
            </el-form-item>
          </el-col>

          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Client Name" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.client_name" placeholder="Enter Client Name" readonly></el-input>
              <FormError errorName="client_name"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Gender" class="mb-05 mandatory-field">
              <div class="gender-select">
                <el-select v-model="patientInfo.gender" placeholder="Select Gender" disabled>
                  <el-option v-for="(gender, index) in genderTypes" :key="index" :label="gender"
                    :value="gender"></el-option>
                </el-select>
                <FormError errorName="gender"></FormError>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Collected Date" class="mb-05 mandatory-field">
              <el-date-picker v-model="patientInfo.collected_date" type="date" placeholder="Pick a Date"
                format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
              </el-date-picker>
              <FormError errorName="collected_date"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Client Code" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.client_code" placeholder="Enter Client Code" readonly></el-input>
              <FormError errorName="client_code"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.hospital_name" placeholder="Enter Hospital Name" readonly></el-input>
              <FormError errorName="ref_doctor"></FormError>
            </el-form-item>
          </el-col>

          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Received Date" class="mb-05 mandatory-field">
              <el-date-picker v-model="patientInfo.received_date" type="date" placeholder="Pick a Date"
                format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
              </el-date-picker>
              <FormError errorName="received_date"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Client Address" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.client_add" placeholder="Enter Client Address" readonly></el-input>
              <FormError errorName="client_add"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Age" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.age" placeholder="Enter Age" readonly></el-input>
              <FormError errorName="age"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Mobile" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.mobile" placeholder="Enter Mobile Number" readonly></el-input>
              <FormError errorName="mobile"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Phone" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.alternate_mobile" placeholder="Enter Phone Number" readonly></el-input>
              <FormError errorName="alternate_mobile"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Email" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.email" placeholder="Enter Email" readonly></el-input>
              <FormError errorName="email"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Locality" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.locality" placeholder="Enter Locality" readonly></el-input>
              <FormError errorName="locality"></FormError>
            </el-form-item>
          </el-col> 
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Sample Type" class="mb-05 mandatory-field">
              <el-input v-model="patientInfo.sample_type"  placeholder="Enter Sample Type"></el-input>
              <FormError errorName="sample_type"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
            <el-form-item label="Reported Date" class="mb-05 mandatory-field">
              <el-date-picker v-model="patientInfo.reported_date" type="date" placeholder="Pick a Date"
                format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled-date="disabledDate" readonly>
              </el-date-picker>
              <FormError errorName="reported_date"></FormError>
            </el-form-item>
          </el-col>

        </el-row>
      </div>

      <div class="report-sub-title" v-if="showReportType && patientInfo.report_type">
        {{ patientInfo.report_type }}
        <span @click="onEditReportType" class="cursor-pointer">
        </span>
      </div>
      <div class="report-type-edit-info" v-else-if="editReportType">
        <el-input v-model="reportTypeNew"></el-input>
        <el-button type="success" @click="updateReportType" :disabled="isReportTypeUpdateDisable">
          <el-icon>
            <Check />
          </el-icon>
        </el-button>
        <el-button type="danger" @click="cancelUpdateReportType">
          <el-icon>
            <Close />
          </el-icon>
        </el-button>
      </div>
    <div v-if="reportType!='HISTOPATHOLOGY(IHC - 4 MARKERS)' && show">
      <div class="editor">
        <el-row :gutter="10" style="margin-bottom: 15px;">
          <el-col :span="24" class="mb-6">
            <div style="font-size: 12px; font-weight: 500;">Clinical details</div>
            <el-input  type="textarea"  :autosize="{ minRows: 2, maxRows: 5 }" placeholder="Enter clinical details" v-model="Clinical"></el-input>
          </el-col>
        </el-row>
       <el-row :gutter="20">
          <el-col  :span="8" class="mb-6">
            <div style="font-size: 12px; font-weight: 500;">Gross Description</div>
            <el-select @change="onChangeCategory" v-model="grossdescriptiontype" placeholder="Select" :disabled="$route.params.patient_id">
              <el-option label="Others" value="Others"></el-option>
              <el-option label="Amputation" value="AMPUTATION"></el-option>
              <el-option label="Breast (Large)" value="Breast_Large"></el-option>
              <el-option label="Breast lump WLE" value="BREAST_LUMP_WLE"></el-option>
              <el-option label="Colorectal" value="colorectal"></el-option>
              <el-option label="Composite resection" value="Composite resection"></el-option>
              <el-option label="Esophagus ge junction" value="ESOPHAGUS_GE_JUNCTION"></el-option>
              <el-option label="Kidney" value="Kidney"></el-option>
              <el-option label="Lung" value="Lung"></el-option>
              <el-option label="Ovary" value="OVARY"></el-option>
              <el-option label="Ovary FT cancer" value="Ovary_FT_cancer"></el-option>
              <el-option label="Stomach" value="STOMACH"></el-option> 
              <el-option label="Thyroid" value="Thyroid"></el-option>
              <el-option label="Uterus" value="Uterus"></el-option> 
              <el-option label="Urinary bladder" value="URINARY_BLADDER"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" class="mb-6">
            <div style="font-size: 12px; font-weight: 500;">Biopsy Number</div>
            <el-input placeholder="Enter Biopsy Number" v-model="biospyno" @blur="handleBlur"></el-input>
            <div v-if="!biospyno && fieldBlurred" class="error-message">Biopsy number is required!</div>
            <!-- <FormError errorName="biospyno"></FormError> -->
          </el-col>
          <el-col :span="8" class="mb-6">
            <div style="font-size: 12px; font-weight: 500;">Site of Biopsy</div>
            <el-input placeholder="Enter site of Biopsy" v-model="Site"></el-input>
          </el-col>
        </el-row> 
         <hr style="margin-top: 20px;">
     <!-- Composite resection -->
        <el-form v-if="grossdescriptiontype=='Composite resection'">
        <el-row :gutter="9">
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Laterality</div>
              <el-input placeholder="Laterality" v-model="patientInfo.Composite_resection[0].Laterality"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Measurements of Bone (length )</div>
              <el-input placeholder="Measurements of Bone (length )" v-model="patientInfo.Composite_resection[0].Measurements_of_Bone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Mucosa</div>
              <el-input placeholder="Mucosa" v-model="patientInfo.Composite_resection[0].Mucosa"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Salivary gland</div>
              <el-input placeholder="Salivary gland" v-model="patientInfo.Composite_resection[0].Salivary_gland"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Skin flap</div>
              <el-input placeholder="Skin flap" v-model="patientInfo.Composite_resection[0].Skin_flap"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Teeth (number)</div>
              <el-input placeholder="Teeth (number)" v-model="patientInfo.Composite_resection[0].Teeth"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" >
            <el-form-item>
              <div class="sub-title">Site of tumor</div>
              <el-input placeholder="Site of tumor" v-model="patientInfo.Composite_resection[0].Site_of_tumor"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Tumor size</div>
              <el-input placeholder="Tumor size" v-model="patientInfo.Composite_resection[0].Tumor_size"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Appearance</div>
              <el-input placeholder="Appearance" v-model="patientInfo.Composite_resection[0].Appearance"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Macroscopic depth of invasion</div>
              <el-input placeholder="Macroscopic depth of invasion" v-model="patientInfo.Composite_resection[0].Macroscopic_depth_of_invasion"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Distance from margins</div>
              <el-input placeholder="Distance from margins" v-model="patientInfo.Composite_resection[0].Distance_from_margins"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Anterior</div>
              <el-input placeholder="Anterior" v-model="patientInfo.Composite_resection[0].Anterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Posterior</div>
              <el-input placeholder="Posterior" v-model="patientInfo.Composite_resection[0].Posterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Medial</div>
              <el-input placeholder="Medial" v-model="patientInfo.Composite_resection[0].Medial"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Lateral</div>
              <el-input placeholder="Lateral" v-model="patientInfo.Composite_resection[0].Lateral"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Superior</div>
              <el-input placeholder="Superior" v-model="patientInfo.Composite_resection[0].Superior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Deep</div>
              <el-input placeholder="Deep" v-model="patientInfo.Composite_resection[0].Deep"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
       <!-- Composite resection -->

          <!-- Breast (Large) -->
          <el-form v-if="grossdescriptiontype == 'Breast_Large'">
            <el-row :gutter="9">
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Procedure</div>
                  <el-input placeholder="Procedure" v-model="patientInfo.BreastLarge[0].Procedure"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Laterality</div>
                  <el-input placeholder="Laterality" v-model="patientInfo.BreastLarge[0].Laterality"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Total dimensions</div>
                  <el-input placeholder="Total dimensions"
                    v-model="patientInfo.BreastLarge[0].Total_dimensions"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Skin ellipse</div>
                  <el-input placeholder="Skin ellipse" v-model="patientInfo.BreastLarge[0].Skin_ellipse"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Location of tumor</div>
                  <el-input placeholder="Location of tumor"
                    v-model="patientInfo.BreastLarge[0].Location_of_tumor"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Margins of tumor </div>
                  <el-input placeholder="Margins of tumor"
                    v-model="patientInfo.BreastLarge[0].Margins_of_tumor"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Size of largest node</div>
                  <el-input placeholder="Size of largest node"
                    v-model="patientInfo.BreastLarge[0].Size_of_largest_node"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Nipple</div>
                  <el-input placeholder="Nipple" v-model="patientInfo.BreastLarge[0].Nipple"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Deep margin</div>
                  <el-input placeholder="Deep margin" v-model="patientInfo.BreastLarge[0].Deep_margin"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Surgical defect</div>
                  <el-input placeholder="Surgical defect" v-model="patientInfo.BreastLarge[0].Surgical_defect"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Tumor</div>
                  <el-select v-model="patientInfo.BreastLarge[0].Tumor">
                    <el-option label="Single" value="Single"></el-option>
                    <el-option label="multiple" value="multiple"></el-option>
                    <el-option label="Others" value="Others"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Tumour Size</div>
                  <el-input placeholder="Tumour Size" v-model="patientInfo.BreastLarge[0].Tumour_Size"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Distance to nearest</div>
                  <el-input placeholder="Distance to nearest"
                    v-model="patientInfo.BreastLarge[0].Distance_to_nearest"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Muscle</div>
                  <el-select v-model="patientInfo.BreastLarge[0].Muscle">
                    <el-option label="Present" value="Present"></el-option>
                    <el-option label="Absent" value="Absent"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Adjacent Breast</div>
                  <el-input placeholder="Adjacent Breast" v-model="patientInfo.BreastLarge[0].Adjacent_Breast"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <div class="sub-title">Axillary tail</div>
                  <el-input placeholder="Axillary tail" v-model="patientInfo.BreastLarge[0].Axillary_tail"></el-input>
                </el-form-item>
              </el-col>
              <el-col >
                <el-form-item>
                  <div class="sub-title">Blocks</div>
                  <el-input type="textarea" placeholder="Blocks" v-model="patientInfo.BreastLarge[0].Blocks"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- Breast (Large) -->

      <!-- AMPUTATION -->
      <el-form v-if="grossdescriptiontype=='AMPUTATION'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-input placeholder="Procedure" v-model="patientInfo.amputation[0].procedure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen laterality</div>
              <el-select v-model="patientInfo.amputation[0].specimen_laterality" placeholder="select">
                <el-option
                  v-for="item in specimenLateralityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen dimensions</div> 
              <el-input placeholder="Specimen dimensions" v-model="patientInfo.amputation[0].specimen_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Component structures & dimensions</div>
              <el-input placeholder="Component structures & dimensions" v-model="patientInfo.amputation[0].component_structures"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor site</div>
              <el-input placeholder="Tumor site" v-model="patientInfo.amputation[0].tumor_site"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor gross description</div>
              <el-input placeholder="Tumor gross description" v-model="patientInfo.amputation[0].tumor_gross_description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="sub-title">Resection margin description	</div>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Type </div>
              <el-select v-model="patientInfo.amputation[0].type" placeholder="select">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Condition </div>
              <el-select v-model="patientInfo.amputation[0].condition" placeholder="select">
                <el-option
                  v-for="item in conditionOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Bone at margin</div>
              <el-input placeholder="Bone at margin" v-model="patientInfo.amputation[0].bone_at_margin  "></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Skin </div>
              <el-select v-model="patientInfo.amputation[0].optionskin" placeholder="select">
                <el-option
                  v-for="item in skinOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Blood vessels  </div>
              <el-select v-model="patientInfo.amputation[0].blood_vessels" placeholder="select">
                <el-option
                  v-for="item in bloodVesselsOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="sub-title">Distance from respective margins:</div>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Bone</div>
              <el-input placeholder="Bone" v-model="patientInfo.amputation[0].bone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Skin </div>
              <el-input placeholder="Skin" v-model="patientInfo.amputation[0].skin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Soft tissue</div>
              <el-input placeholder="Soft tissue" v-model="patientInfo.amputation[0].soft_tissue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div class="sub-title">Neurovascular bundle</div>
              <el-input placeholder="Neurovascular bundle" v-model="patientInfo.amputation[0].neurovascular_bundle"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- AMPUTATION -->

      <!-- THYROID -->
      <el-form v-if="grossdescriptiontype == 'Thyroid'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Clinical presentation</div>
              <el-input placeholder="Clinical presentation"
                v-model="patientInfo.thyroid[0].Clinical_presentation"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Family history/Exposure to drugs/radiation</div>
              <el-input placeholder="Family history/Exposure to drugs/radiation"
                v-model="patientInfo.thyroid[0].family_history"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">TFT/Auto antibodies</div>
              <el-input placeholder="TFT/Auto antibodies"
                v-model="patientInfo.thyroid[0].tft_auto_antibodies"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Radiology</div>
              <el-select v-model="patientInfo.thyroid[0].radiology" placeholder="select">
                <el-option v-for="item in radiologyOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Radioisotope study</div>
              <el-input placeholder="Radioisotope study"
                v-model="patientInfo.thyroid[0].radioisotope_study"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Presence of Metastases</div>
              <el-select v-model="patientInfo.thyroid[0].presence_of_metastases" placeholder="select">
                <el-option v-for="item in presenceOfMetastasesOptions" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">FNAC</div>
              <el-input placeholder="FNAC" v-model="patientInfo.thyroid[0].FNAC"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Bethesda category</div>
              <el-input placeholder="Bethesda category" v-model="patientInfo.thyroid[0].Bethesda_category"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-select v-model="patientInfo.thyroid[0].procedure" placeholder="select">
                <el-option v-for="item in procedureOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen laterality</div>
              <el-select v-model="patientInfo.thyroid[0].specimen_laterality" placeholder="select">
                <el-option v-for="item in specimenLateralityOptions1" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Parathyroid glands</div>
              <el-select v-model="patientInfo.thyroid[0].parathyroid_glands" placeholder="select">
                <el-option v-for="item in parathyroidGlandsOptions" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen dimensions</div>
              <el-input placeholder="Specimen dimensions"
                v-model="patientInfo.thyroid[0].specimen_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Right lobe</div>
              <el-input placeholder="Right lobe" v-model="patientInfo.thyroid[0].right_lobe"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left lobe</div>
              <el-input placeholder="Left lobe" v-model="patientInfo.thyroid[0].left_lobe"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Isthmus </div>
              <el-input placeholder="Isthmus" v-model="patientInfo.thyroid[0].isthmus"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor focality </div>
              <el-select v-model="patientInfo.thyroid[0].tumor_focality" placeholder="select">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Gross </div>
              <el-input placeholder="Gross" v-model="patientInfo.thyroid[0].Gross"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor right lobe </div>
              <el-select v-model="patientInfo.thyroid[0].right_lobeoption" placeholder="select">
                <el-option v-for="item in rightLobeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor Left lobe </div>
              <el-select v-model="patientInfo.thyroid[0].left_lobeoption" placeholder="select">
                <el-option v-for="item in leftLobeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Color </div>
              <el-select v-model="patientInfo.thyroid[0].color" placeholder="select">
                <el-option v-for="item in colorOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Capsule </div>
              <el-select v-model="patientInfo.thyroid[0].capsule" placeholder="select">
                <el-option v-for="item in capsuleOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distance from nearest capsule inked margin</div>
              <el-input placeholder="Distance from nearest capsule inked margin"
                v-model="patientInfo.thyroid[0].distance_from_nearest_capsule"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Invasion beyond thyroid</div>
              <el-input placeholder="Invasion beyond thyroid"
                v-model="patientInfo.thyroid[0].invasion_beyond"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Degenerative changes</div>
              <el-select v-model="patientInfo.thyroid[0].degenerative_changes" placeholder="select">
                <el-option v-for="item in degenerativechangesOptions" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor in right lobe measures</div>
              <el-input placeholder="Tumor in right lobe measures"
                v-model="patientInfo.thyroid[0].right_lobe_measures"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left lobe measures</div>
              <el-input placeholder="Left lobe measures"
                v-model="patientInfo.thyroid[0].left_lobe_measures"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Isthmus measures</div>
              <el-input placeholder="Isthmus measures" v-model="patientInfo.thyroid[0].isthmus_measures"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Capsular invasion</div>
              <el-input placeholder="Capsular invasion" v-model="patientInfo.thyroid[0].capsular_invasion"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Angiolymphatic invasion</div>
              <el-input placeholder="Angiolymphatic invasion"
                v-model="patientInfo.thyroid[0].angiolymphatic_invasion"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Extrathyroidal extension</div>
              <el-input placeholder="Extrathyroidal extension"
                v-model="patientInfo.thyroid[0].extrathyroidal_extension"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Comment on other lobes</div>
              <el-input placeholder="Comment on other lobes"
                v-model="patientInfo.thyroid[0].comment_on_other_lobes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- THYROID -->

      <!-- Uterus -->
      <el-form v-if="grossdescriptiontype=='Uterus'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Uterus (TAH +BSO)</div>
              <el-input placeholder="Uterus (TAH +BSO)" v-model="patientInfo.uterus[0].uterus"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Uterus dimensions</div>
              <el-input placeholder="Uterus dimensions" v-model="patientInfo.uterus[0].uterus_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Midline  fundal-serosa  to ecto cervix</div>
              <el-input placeholder="Midline  fundal-serosa  to ecto cervix" v-model="patientInfo.uterus[0].midline_fundal_serosa"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Maximum intercornual</div>
              <el-input placeholder="Maximum intercornual" v-model="patientInfo.uterus[0].maximum_intercornual"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Maximum anterior to posterior</div>
              <el-input placeholder="Maximum anterior to posterior" v-model="patientInfo.uterus[0].maximum_anterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Cervix  dimensions</div>
              <el-input placeholder="Cervix  dimensions" v-model="patientInfo.uterus[0].cervix_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Vaginal cuff </div>
              <el-select v-model="patientInfo.uterus[0].vaginal_cuff" placeholder="select">
                <el-option
                  v-for="item in vaginalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Anterior</div>
              <el-input placeholder="Anterior" v-model="patientInfo.uterus[0].anterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Posterior</div>
              <el-input placeholder="Posterior" v-model="patientInfo.uterus[0].posterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Cervix shape</div>
              <el-input placeholder="Cervix shape" v-model="patientInfo.uterus[0].cervix_shape"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Right Ovary Description</div>
              <el-input placeholder="Right Ovary Description" v-model="patientInfo.uterus[0].right_ovary_description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left Ovary Description</div>
              <el-input placeholder="Left Ovary Description" v-model="patientInfo.uterus[0].left_ovary_description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Right Fallopian tube</div>
              <el-input placeholder="Right Fallopian tube" v-model="patientInfo.uterus[0].right_fallopian_tube"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left Fallopian tube</div>
              <el-input placeholder="Left Fallopian tube" v-model="patientInfo.uterus[0].left_fallopian_tube"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Right parametrium</div>
              <el-input placeholder="Right parametrium" v-model="patientInfo.uterus[0].right_parametrium"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left parametrium</div>
              <el-input placeholder="Left parametrium" v-model="patientInfo.uterus[0].left_parametrium"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Endometrium description</div>
              <el-input placeholder="Endometrium description" v-model="patientInfo.uterus[0].endometrium_description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Endometrial thickness</div>
              <el-input placeholder="Endometrial thickness" v-model="patientInfo.uterus[0].endometrial_thickness"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor dimensions</div>
              <el-input placeholder="Tumor dimensions" v-model="patientInfo.uterus[0].tumor_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Endometrial abnormalities</div>
              <el-input placeholder="Endometrial abnormalities" v-model="patientInfo.uterus[0].endometrial_abnormalities"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Location</div>
              <el-input placeholder="Location" v-model="patientInfo.uterus[0].location"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Myometrium description</div>
              <el-input placeholder="Myometrium description" v-model="patientInfo.uterus[0].myometrium_description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Myometrial depth</div>
              <el-input placeholder="Myometrial depth" v-model="patientInfo.uterus[0].myometrial_depth"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Involvement of myometrium by tumor</div>
              <el-input placeholder="Involvement of myometrium by tumor" v-model="patientInfo.uterus[0].involvement_of_myometrium"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Myometrial appearance</div>
              <el-input placeholder="Myometrial appearance" v-model="patientInfo.uterus[0].myometrial_appearance"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Leiomyoma</div>
              <el-input placeholder="Leiomyoma" v-model="patientInfo.uterus[0].leiomyoma"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lower uterine segment</div>
              <el-input placeholder="Lower uterine segment" v-model="patientInfo.uterus[0].lower_uterine_segment"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other abnormalities</div>
              <el-input placeholder="Other abnormalities" v-model="patientInfo.uterus[0].other_abnormalities"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- Uterus -->
      
     <!-- OVARY -->
     <el-form v-if="grossdescriptiontype=='OVARY'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tissue received in  formalin </div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].tissue_received">
                <el-option 
                  v-for="item in tissueOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-input placeholder="Procedure" v-model="patientInfo.ovary[0].procedure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">For each ovary describe the following</div>
              <el-input placeholder="For each ovary describe the following" v-model="patientInfo.ovary[0].describe_following"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen laterality</div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].specimen_laterality">
                <el-option 
                  v-for="item in overySpecimenOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Ovary dimensions</div>
              <el-input placeholder="Ovary dimensions" v-model="patientInfo.ovary[0].ovary_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Fallopian tube (if present) dimensions</div>
              <el-input placeholder="Fallopian tube (if present) dimensions" v-model="patientInfo.ovary[0].fallopian_tube"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Weight of ovary</div>
              <el-input placeholder="Weight of ovary" v-model="patientInfo.ovary[0].weight_ovary"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen integrity (Note if relevant ) </div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].specimen_integrity">
                <el-option 
                  v-for="item in specimenIntegrityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Ovarian capsule integrity </div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].ovarian_capsule_integrity">
                <el-option 
                  v-for="item in ovarianOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Ovarian surface involvement </div>
              <el-input placeholder="Ovarian surface involvement" v-model="patientInfo.ovary[0].ovarian_surface_involvement"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Ovarian cut surface </div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].ovarian_surface">
                <el-option 
                  v-for="item in ovarianCutSurfaceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Solid component(s) for each describe</div>
              <el-input placeholder="Solid component(s) for each describe" v-model="patientInfo.ovary[0].solid_component"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour dimensions</div>
              <el-input placeholder="Tumour dimensions" v-model="patientInfo.ovary[0].tumour_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item>
              <div class="sub-title">pTumour description(texture, colour, consistency, necrosis)</div>
              <el-input placeholder="pTumour description(texture, colour, consistency, necrosis)" v-model="patientInfo.ovary[0].pTumour_description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour site</div>
              <el-input placeholder="Tumour site" v-model="patientInfo.ovary[0].tumour_site"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Cystic component</div>
              <el-input placeholder="Cystic component" v-model="patientInfo.ovary[0].cystic_component"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title"> Type of cyst </div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].type_of_cyst">
                <el-option 
                  v-for="item in typeOfCystOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Internal surface abnormalities</div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].internal_surface_abnormalities">
                <el-option 
                  v-for="item in internalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item>
              <div class="sub-title">Appearance (e.g. papillary excrescences)</div>
              <el-input placeholder="Appearance (e.g. papillary excrescences)" v-model="patientInfo.ovary[0].appearance"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distance to margins, if relevant</div>
              <el-input placeholder="Distance to margins, if relevant" v-model="patientInfo.ovary[0].distance_to_margins"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Involvement of other organs</div>
              <el-input placeholder="Involvement of other organs" v-model="patientInfo.ovary[0].involvement_organs"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Omentum Weight __g</div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].omentum_Weight">
                <el-option 
                  v-for="item in lateralityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Omental involvement by tumour</div>
              <el-input placeholder="Omental involvement by tumour" v-model="patientInfo.ovary[0].omental_involvement"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes</div>
              <el-input placeholder="Lymph nodes" v-model="patientInfo.ovary[0].lymph_nodes"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">If uterus is present, see relevant template</div>
              <el-input placeholder="If uterus is present, see relevant template" v-model="patientInfo.ovary[0].relevant_template"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other relevant macroscopic information</div>
              <el-input placeholder="Other relevant macroscopic information" v-model="patientInfo.ovary[0].other_relevant_macroscopic"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Block identification key</div>
              <el-input placeholder="Block identification key" v-model="patientInfo.ovary[0].block_identification_key"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Block for Molecular work up</div>
              <el-select  placeholder="select" v-model="patientInfo.ovary[0].block_for_Molecular">
                <el-option 
                  v-for="item in tissueOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- OVARY -->

      <!-- BREAST LUMP WLE -->
      <el-form v-if="grossdescriptiontype==='BREAST_LUMP_WLE'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Laterality</div>
              <el-select  placeholder="select" v-model="patientInfo.BREAST_LUMP_WLE[0].laterality">
                <el-option 
                  v-for="item in lateralityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div> 
              <el-input placeholder="Procedure" v-model="patientInfo.BREAST_LUMP_WLE[0].procedure" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen orientation</div>
              <el-select  placeholder="select" v-model="patientInfo.BREAST_LUMP_WLE[0].specimen_orientation">
                <el-option
                  v-for="item in specimenOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen size</div>
              <el-input placeholder="Specimen size" v-model="patientInfo.BREAST_LUMP_WLE[0].specimen_size"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Medial-lateral </div>
              <el-input placeholder="Medial-lateral" v-model="patientInfo.BREAST_LUMP_WLE[0].medial_lateral"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Superficial-deep </div> 
              <el-input placeholder="Superficial-deep" v-model="patientInfo.BREAST_LUMP_WLE[0].superficial_deep"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Superior-inferior </div> 
              <el-input placeholder="Superior-inferior " v-model="patientInfo.BREAST_LUMP_WLE[0].superior_inferior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Skin</div>
              <el-select  v-model="patientInfo.BREAST_LUMP_WLE[0].skin" placeholder="select">
                <el-option
                  v-for="item in skinOptions1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Skin dimensions</div>
              <el-input placeholder="Skin dimensions" v-model="patientInfo.BREAST_LUMP_WLE[0].skin_dimensions" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Skin abnormalities</div>
              <el-select placeholder="select" v-model="patientInfo.BREAST_LUMP_WLE[0].skin_abnormalities">
                <el-option
                  v-for="item in SkinabnormalitiesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Nipple</div>
              <el-select  placeholder="select" v-model="patientInfo.BREAST_LUMP_WLE[0].nipple">
                <el-option
                  v-for="item in nippleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lesion(s)</div>
              <el-select  placeholder="select" v-model="patientInfo.BREAST_LUMP_WLE[0].lesion">
                <el-option
                  v-for="item in Lesionoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Number of macroscopically visible lesions</div>
              <el-input placeholder="Number of macroscopically visible lesions" v-model="patientInfo.BREAST_LUMP_WLE[0].number_of_macroscopically" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">For each lesion (if >1 designate accordingly) </div>
              <el-input placeholder="For each lesion (if >1 designate accordingly)" v-model="patientInfo.BREAST_LUMP_WLE[0].for_each_lesion" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item>
              <div class="sub-title">Lesion description</div>
              <el-select  placeholder="select" v-model="patientInfo.BREAST_LUMP_WLE[0].lesion_description">
                <el-option
                  v-for="item in Lesiondescriptionoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Location</div>
              <el-input placeholder="Location" v-model="patientInfo.BREAST_LUMP_WLE[0].location"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lesion dimensions</div>
              <el-input placeholder="Lesion dimensions" v-model="patientInfo.BREAST_LUMP_WLE[0].neurovascular_bundle"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title"> Distance of lesion to nearest margin</div>
              <el-input placeholder=" Distance of lesion to nearest margin" v-model="patientInfo.BREAST_LUMP_WLE[0].distance_of_lesion_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distance of lesion from margins colour</div>
              <el-input placeholder="Distance of lesion from margins colour" v-model="patientInfo.BREAST_LUMP_WLE[0].distance_of_lesion"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other relevant macroscopic information</div>
              <el-input placeholder="Other relevant macroscopic information" v-model="patientInfo.BREAST_LUMP_WLE[0].other_relevant_macroscopic"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- BREAST LUMP WLE -->

      <!-- LUNG -->
      <el-form v-if="grossdescriptiontype=='Lung'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-input placeholder="Procedure" v-model="patientInfo.Lung[0].procedure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen laterality</div> 
              <el-input placeholder="Specimen laterality" v-model="patientInfo.Lung[0].specimen_laterality"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen integrity</div>
              <el-input placeholder="Specimen integrity" v-model="patientInfo.Lung[0].specimen_integrity"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Site of tumor</div>
              <el-input placeholder="Site of tumor" v-model="patientInfo.Lung[0].site_of_tumor"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Size of tumor</div>
              <el-input placeholder="Size of tumor" v-model="patientInfo.Lung[0].size_of_tumor"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Gross description of tumor</div>
              <el-input placeholder="Gross description of tumor" v-model="patientInfo.Lung[0].gross_description_of_tumor"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Distance from margins</div>
              <el-input placeholder="Distance from margins" v-model="patientInfo.Lung[0].distance_from_margins"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Pleural surface</div>
              <el-input placeholder="Pleural surface" v-model="patientInfo.Lung[0].pleural_surface"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Bronchial cut margin</div>
              <el-input placeholder="Bronchial cut margin" v-model="patientInfo.Lung[0].bronchial_cut_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Vessel cut margin</div>
              <el-input placeholder="Vessel cut margin" v-model="patientInfo.Lung[0].vessel_cut_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item>
              <div class="sub-title">Parenchymal cut margin</div>
              <el-input placeholder="Parenchymal cut margin" v-model="patientInfo.Lung[0].parenchymal_cut_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Adjacent lung</div>
              <el-select placeholder="select" v-model="patientInfo.Lung[0].adjacent_lung">
                <el-option
                  v-for="item in AdjacentOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes</div>
              <el-select  placeholder="select" v-model="patientInfo.Lung[0].lymph_nodes">
                <el-option
                  v-for="item in LymphOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- LUNG -->

      <!-- URINARY BLADDER -->
     <el-form v-if="grossdescriptiontype=='URINARY_BLADDER'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tissue received in</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].tissue_received_in">
                <el-option
                  v-for="item in tissuereceivedinoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-input placeholder="Procedure" v-model="patientInfo.urinary_bladder[0].procedure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Bladder measurements</div>
              <el-input placeholder="Bladder measurements" v-model="patientInfo.urinary_bladder[0].bladder_measurements" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Length of each ureter</div>
              <el-input placeholder="Length of each ureter" v-model="patientInfo.urinary_bladder[0].Length_of_ureter"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Length of urethra</div> 
              <el-input placeholder="Length of urethra" v-model="patientInfo.urinary_bladder[0].length_urethra"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Measurements of each other organ</div>
              <el-input placeholder="E.g. prostate/uterus " v-model="patientInfo.urinary_bladder[0].measurements"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Right seminal vesicle  </div>
              <el-input placeholder="Right seminal vesicle " v-model="patientInfo.urinary_bladder[0].right_seminal"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left seminal vesicle</div>
              <el-input placeholder="Left seminal vesicle" v-model="patientInfo.urinary_bladder[0].left_seminal_vesicle"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].tumour">
                <el-option
                  v-for="item in vaginalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour multifocality</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].tumour_multifocality">
                <el-option
                  v-for="item in skinOptions1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Number of tumours</div>
              <el-input placeholder="Number of tumours" v-model="patientInfo.urinary_bladder[0].number_tumours" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour site(s) in bladder</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].tumou_bladder">
                <el-option
                  v-for="item in Tumour_site_bladder"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor dimension </div>
              <el-input placeholder="Tumor dimension " v-model="patientInfo.urinary_bladder[0].tumor_dimension" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Gross appearance of tumour(s)</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].gross_appearance">
                <el-option
                  v-for="item in gross_appearance"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Macroscopic evidence of tumour invasion</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].macroscopic_invasion">
                <el-option
                  v-for="item in macroscopic_evidence"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Bladder wall</div>
              <el-input placeholder="Bladder wall" v-model="patientInfo.urinary_bladder[0].bladder_wall"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Ureter(s)</div>
              <el-input placeholder="Ureter(s)" v-model="patientInfo.urinary_bladder[0].ureter"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Macroscopic evidence of involvement</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].macroscopic_evidence">
                <el-option
                  v-for="item in macroscopic_evidence_organs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Perivesical tissue</div>
              <el-input placeholder="Perivesical tissue" v-model="patientInfo.urinary_bladder[0].perivesical_tissue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Urethra</div>
              <el-input placeholder="Urethra" v-model="patientInfo.urinary_bladder[0].urethra"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item>
              <div class="sub-title">If present, record organs involved by tumour</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].record_tumour">
                <el-option
                  v-for="item in record_organs_involved"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distance of tumor to closest surgical margin</div>
              <el-input placeholder="Distance of tumor to closest surgical margin" v-model="patientInfo.urinary_bladder[0].distance_of_tumor"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specify margin</div>
              <el-input placeholder="Specify margin" v-model="patientInfo.urinary_bladder[0].specify_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item>
              <div class="sub-title">Macroscopic evidence of margin involvement</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].margin_involvement">
                <el-option
                  v-for="item in macroscopic_evidence1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item>
              <div class="sub-title">involved(choose all that apply)</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].involved">
                <el-option
                  v-for="item in involved"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Peritoneal surface involvement description</div>
              <el-input placeholder="Peritoneal surface involvement description" v-model="patientInfo.urinary_bladder[0].peritoneal_description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Location/specify</div>
              <el-input placeholder="Location/specify" v-model="patientInfo.urinary_bladder[0].location_specify"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
          <el-form-item>
              <div class="sub-title">Appearance of uninvolved bladder</div>
              <el-select  placeholder="select" v-model="patientInfo.urinary_bladder[0].appearance_bladder">
                <el-option
                  v-for="item in appearance_uninvolved"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Number of Lymph nodes isolated</div>
              <el-input placeholder="Number of Lymph nodes isolated" v-model="patientInfo.urinary_bladder[0].Lymph_nodes_isolated" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Size of largest node</div>
              <el-input placeholder="Size of largest node" v-model="patientInfo.urinary_bladder[0].size_of_node"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other relevant macroscopic information</div>
              <el-input placeholder="Other relevant macroscopic information" v-model="patientInfo.urinary_bladder[0].other_relevant_macroscopic" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

       <!-- COLORECTAL -->
      <el-form v-if="grossdescriptiontype=='colorectal'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Received</div>
              <el-select  placeholder="select" v-model="patientInfo.colorectal[0].received">
                <el-option
                  v-for="item in received_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-select  placeholder="select" v-model="patientInfo.colorectal[0].procedure">
                <el-option
                  v-for="item in procedure_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Laterality ( if colon )</div>
              <el-input placeholder="Laterality ( if colon )" v-model="patientInfo.colorectal[0].laterality"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen</div>
              <el-input placeholder="Specimen" v-model="patientInfo.colorectal[0].specimen"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor location</div>
              <el-input placeholder="Tumor location" v-model="patientInfo.colorectal[0].tumor_location"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor type</div>
              <el-select  placeholder="select" v-model="patientInfo.colorectal[0].tumor_type">
                <el-option
                  v-for="item in tumor_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Polypoidal/Mucinous</div>
              <el-input placeholder="Polypoidal/Mucinous" v-model="patientInfo.colorectal[0].polypoidal_Mucinous"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor dimension</div>
              <el-input placeholder="Tumor dimension" v-model="patientInfo.colorectal[0].tumor_dimension"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Peritoneal involvement</div>
              <el-input placeholder="Peritoneal involvement" v-model="patientInfo.colorectal[0].peritoneal_involvement"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Not seen</div>
              <el-input placeholder="Not seen" v-model="patientInfo.colorectal[0].not_seen"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item>
              <div class="sub-title">Involves serosal surface as discrete nodules separate  from the main tumor</div>
              <el-input placeholder="Involves serosal surface as discrete nodules separate  from the main tumor" v-model="patientInfo.colorectal[0].involves_serosal_surface"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Invades peritoneum</div>
              <el-input placeholder="Invades peritoneum" v-model="patientInfo.colorectal[0].invades_peritoneum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Proximal margin</div>
              <el-input placeholder="Proximal margin" v-model="patientInfo.colorectal[0].proximal_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distal margin</div>
              <el-input placeholder="Distal margin" v-model="patientInfo.colorectal[0].distal_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Non- peritonealized  circumferential margin</div>
              <el-input placeholder="Non- peritonealized  circumferential margin" v-model="patientInfo.colorectal[0].neurovascular_bundleon"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Anterior</div>
              <el-input placeholder="Anterior" v-model="patientInfo.colorectal[0].anterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8"> 
            <el-form-item>
              <div class="sub-title">posterior</div>
              <el-input placeholder="Anterior" v-model="patientInfo.colorectal[0].posterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Any other polyps in adjacent mucosa</div>
              <el-input placeholder="Any other polyps in adjacent mucosa" v-model="patientInfo.colorectal[0].any_other_polyps"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Perforation/obstruction</div>
              <el-input placeholder="Perforation/obstruction" v-model="patientInfo.colorectal[0].perforation"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Mesorectum ( For APR & AR)</div>
              <el-select  placeholder="select" v-model="patientInfo.colorectal[0].mesorectum">
                <el-option
                  v-for="item in mesorectum"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Total number of lymph nodes isolated</div>
              <el-input placeholder="Total number of lymph nodes isolated" v-model="patientInfo.colorectal[0].total_number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Largest lymph node dimension</div>
              <el-input placeholder="Largest lymph node dimension" v-model="patientInfo.colorectal[0].largest_lymph"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- COLORECTAL -->

        <!-- ESOPHAGUS GE JUNCTION -->
      <el-form v-if="grossdescriptiontype=='ESOPHAGUS_GE_JUNCTION'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Received</div>
              <el-input placeholder="Received" v-model="patientInfo.esophagus_ge_junction[0].received"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item> 
              <div class="sub-title">Type of resection</div> 
              <el-select  placeholder="select" v-model="patientInfo.esophagus_ge_junction[0].type_of_resection">
                <el-option
                  v-for="item in type_of_resection"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Length of tubular oesophagus</div>
              <el-input placeholder="Length of tubular oesophagus" v-model="patientInfo.esophagus_ge_junction[0].length_of_tubular"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Length of greater curve of stomach</div>
              <el-input placeholder="Length of greater curve of stomach (if present)" v-model="patientInfo.esophagus_ge_junction[0].length_of_greater"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Length of lesser curve of stomach</div>
              <el-input placeholder="Length of lesser curve of stomach (if present)" v-model="patientInfo.esophagus_ge_junction[0].length_of_lesser"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other anatomical component and sizes</div>
              <el-input placeholder="Other anatomical component and sizes" v-model="patientInfo.esophagus_ge_junction[0].other_anatomical"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour</div>
              <el-select  placeholder="select" v-model="patientInfo.esophagus_ge_junction[0].tumour">
                <el-option
                  v-for="item in tumour"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Number of tumours</div>
              <el-input placeholder="Number of tumours" v-model="patientInfo.esophagus_ge_junction[0].number_of_tumours"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">For each tumour</div>
              <el-input placeholder="For each tumour" v-model="patientInfo.esophagus_ge_junction[0].for_each_tumour"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor dimensions</div>
              <el-input placeholder="Tumor dimensions" v-model="patientInfo.esophagus_ge_junction[0].tumor_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Gross appearance of tumour</div>
              <el-select  placeholder="select" v-model="patientInfo.esophagus_ge_junction[0].gross_appearance">
                <el-option
                  v-for="item in gross_appearance_tumour"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour site(choose all that apply)</div>
              <el-input placeholder="Tumour site(choose all that apply)" v-model="patientInfo.esophagus_ge_junction[0].tumour_site"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title lineheight">Proximal edge of tumour to Proximal edge of specimen</div>
              <el-input placeholder="Proximal edge of tumour to Proximal edge of specimen" v-model="patientInfo.esophagus_ge_junction[0].proximal_edge"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title lineheight">Distal edge of tumour to distal edge of specimen</div>
              <el-input placeholder="Distal edge of tumour to distal edge of specimen" v-model="patientInfo.esophagus_ge_junction[0].distal_edge_tumour"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title lineheight">Distance of tumor from Gastro-oesophageal junction</div>
              <el-input placeholder="Distance of tumor from Gastro-oesophageal junction" v-model="patientInfo.esophagus_ge_junction[0].distance_of_tumor_Gastro"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item>
              <div class="sub-title">Distance of tumor from Circumferential/ Serosal margin</div>
              <el-input placeholder="Distance of tumor from Circumferential/ Serosal margin" v-model="patientInfo.esophagus_ge_junction[0].distance_of_tumor_Circumferential"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Involvement of adjacent structures</div>
              <el-select  placeholder="select" v-model="patientInfo.esophagus_ge_junction[0].involvement_of_adjacent">
                <el-option
                  v-for="item in involvement_of_adjacent"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Barrett mucosa	</div>
              <el-input placeholder="Barrett mucosa" v-model="patientInfo.esophagus_ge_junction[0].barrett_mucosa"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Length of Barrett mucosa</div>
              <el-input placeholder="Length of Barrett mucosa" v-model="patientInfo.esophagus_ge_junction[0].length_of_barrett "></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Block for Molecular work up</div>
              <el-input placeholder="Block for Molecular work up" v-model="patientInfo.esophagus_ge_junction[0].block_for_Molecular"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Additional specimens</div>
              <el-input placeholder="Additional specimens" v-model="patientInfo.esophagus_ge_junction[0].additional_specimens"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes</div>
              <el-select  placeholder="select" v-model="patientInfo.esophagus_ge_junction[0].lymph_nodes">
                <el-option
                  v-for="item in lymph_nodes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes along esophagus</div>
              <el-input placeholder="Lymph nodes along esophagus" v-model="patientInfo.esophagus_ge_junction[0].lymph_nodes_along"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes along stomach</div>
              <el-input placeholder="Lymph nodes along stomach" v-model="patientInfo.esophagus_ge_junction[0].nodes_along_stomach"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other relevant macroscopic information</div>
              <el-input placeholder="Other relevant macroscopic information" v-model="patientInfo.esophagus_ge_junction[0].relevant_macroscopic"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- ESOPHAGUS GE JUNCTION -->

         <!-- Kidney -->
          <el-form v-if="grossdescriptiontype=='Kidney'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Received </div>
              <el-input placeholder="Received" v-model="patientInfo.kidney[0].received"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-input placeholder="Procedure" v-model="patientInfo.kidney[0].procedure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen laterality</div>
              <el-input placeholder="Specimen laterality" v-model="patientInfo.kidney[0].specimen_laterality"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen integrity</div>
              <el-input placeholder="Specimen integrity" v-model="patientInfo.kidney[0].specimen_integrity"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen dimensions (entire specimen)</div>
              <el-input placeholder="Specimen dimensions (entire specimen)" v-model="patientInfo.kidney[0].specimen_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Anatomical components and dimensions</div>
              <el-input placeholder="Anatomical components and dimensions" v-model="patientInfo.kidney[0].anatomical_components"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Kidney </div>
              <el-input placeholder="Kidney" v-model="patientInfo.kidney[0].kidney"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Ureter(s) </div>
              <el-input placeholder="Ureter(s)" v-model="patientInfo.kidney[0].ureters"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Adrenal </div>
              <el-input placeholder="Adrenal" v-model="patientInfo.kidney[0].adrenal"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Gerota’s fascia </div>
              <el-input placeholder="Gerota’s fascia" v-model="patientInfo.kidney[0].gerota_fascia"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other, specify </div>
              <el-input placeholder="Other, specify" v-model="patientInfo.kidney[0].other_specify"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Number of tumours</div>
              <el-input placeholder="Number of tumours" v-model="patientInfo.kidney[0].number_of_tumours"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">For each tumour</div>
              <el-input placeholder="For each tumour" v-model="patientInfo.kidney[0].for_each_tumour"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour dimensions</div>
              <el-input placeholder="Tumour dimensions" v-model="patientInfo.kidney[0].tumour_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour site</div> 
              <el-select  placeholder="select" v-model="patientInfo.kidney[0].tumour_site">
                <el-option
                  v-for="item in tumour_site"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour description</div>
              <el-select  placeholder="select" v-model="patientInfo.kidney[0].tumour_description">
                <el-option
                  v-for="item in tumour_description"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumour invasion</div>
              <el-select  placeholder="select" v-model="patientInfo.kidney[0].tumour_invasion">
                <el-option
                  v-for="item in tumour_invasion"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distance of tumour to ureter cut margin</div>
              <el-input placeholder="Distance of tumour to ureter cut margin" v-model="patientInfo.kidney[0].ureter_cut_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item>
              <div class="sub-title">Distance of tumour to periureteric circumferential margin</div>
              <el-input placeholder="Distance of tumour to periureteric circumferential margin" v-model="patientInfo.kidney[0].periureteric_circumferential_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distance of tumour to perinephric fat margin</div>
              <el-input placeholder="Distance of tumour to perinephric fat margin" v-model="patientInfo.kidney[0].perinephric_fat_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes</div>
              <el-input placeholder="Lymph nodes" v-model="patientInfo.kidney[0].lymph_nodes"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Number of lymph nodes isolated</div>
              <el-input placeholder="Number of lymph nodes isolated" v-model="patientInfo.kidney[0].lymph_nodes_isolated"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other relevant macroscopy</div>
              <el-input placeholder="Other relevant macroscopy" v-model="patientInfo.kidney[0].relevant_macroscopy"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Frozen details</div>
              <el-input placeholder="Frozen details" v-model="patientInfo.kidney[0].frozen_details"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- Kidney -->

      <!-- STOMACH -->
      <el-form v-if="grossdescriptiontype=='STOMACH'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item> 
              <div class="sub-title">Fresh tissue received</div> 
              <el-select  placeholder="select" v-model="patientInfo.stomach[0].fresh_tissue_received">
                <el-option
                  v-for="item in specimenLateralityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Received in</div>
              <el-input placeholder="Received in"   v-model="patientInfo.stomach[0].received"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Type of resection</div>
              <el-select  placeholder="select"   v-model="patientInfo.stomach[0].resection">
                <el-option
                  v-for="item in resectionoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Specimen dimensions</div>
              <el-input placeholder="Specimen dimensions"  v-model="patientInfo.stomach[0].specimen_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Length of each anatomical component</div>
              <el-input placeholder="Length of each anatomical component"  v-model="patientInfo.stomach[0].each_anatomical_component"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Stomach greater curvature </div>
              <el-input placeholder="Stomach greater curvature "  v-model="patientInfo.stomach[0].stomach_greater_curvature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Esophagus</div>
              <el-input placeholder="Esophagus"  v-model="patientInfo.stomach[0].esophagus"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Stomach lesser curvature</div>
              <el-input placeholder="Stomach lesser curvature"  v-model="patientInfo.stomach[0].stomach_lesser_curvature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Duodenum</div>
              <el-input placeholder="Duodenum"  v-model="patientInfo.stomach[0].duodenum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Number of tumors</div>
              <el-input placeholder="Number of tumors"  v-model="patientInfo.stomach[0].number_of_tumors"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor dimensions</div>
              <el-input placeholder="Tumor dimensions"  v-model="patientInfo.stomach[0].tumor_dimensions"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Macroscopic tumour site</div>
              <el-select  placeholder="select"  v-model="patientInfo.stomach[0].macroscopic_tumour_site">
                <el-option
                  v-for="item in macroscopic_tumour_site_option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Macroscopic tumour type</div>
              <el-select  placeholder="select"  v-model="patientInfo.stomach[0].macroscopic_tumour">
                <el-option
                  v-for="item in Macroscopic_tumour_type_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distance from tumour to nearest</div>
              <el-input placeholder="Distance from tumour to nearest"  v-model="patientInfo.stomach[0].distance_from_tumour"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Proximal margin</div>
              <el-input placeholder="Proximal margin"  v-model="patientInfo.stomach[0].proximal_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Distal margin</div>
              <el-input placeholder="Distal margin"  v-model="patientInfo.stomach[0].distal_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item>
              <div class="sub-title"> Distance from tumour to the circumferential resection margin</div>
              <el-input placeholder=" Distance from tumour to the circumferential resection margin"  v-model="patientInfo.stomach[0].circumferential_resection_margin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Serosal appearance</div>
              <el-input placeholder="Serosal appearance"  v-model="patientInfo.stomach[0].serosal_appearance"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Omentum along greater curvature</div>
              <el-input placeholder="Omentum along greater curvature"  v-model="patientInfo.stomach[0].omentum_curvature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Omentum along lesser curvature</div>
              <el-input placeholder="Omentum along lesser curvature"  v-model="patientInfo.stomach[0].omentum_lesser_curvature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes along greater curvature</div>
              <el-input placeholder="Lymph nodes along greater curvature"  v-model="patientInfo.stomach[0].lymph_greater_curvature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Lymph nodes along lesser curvature</div>
              <el-input placeholder="Lymph nodes along lesser curvature"  v-model="patientInfo.stomach[0].lymph_lesser_curvature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other relevant macroscopic information</div>
              <el-input placeholder="Other relevant macroscopic information"  v-model="patientInfo.stomach[0].macroscopic_information"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
     <!-- STOMACH -->
     

     <!-- Ovary FT cancer -->
     <el-form v-if="grossdescriptiontype=='Ovary_FT_cancer'">
        <el-row :gutter="9">
          <el-col :span="8">
            <el-form-item> 
              <div class="sub-title">Received in</div> 
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].received">
                <el-option
                  v-for="item in received_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Procedure</div>
              <el-input placeholder="Procedure" v-model="patientInfo.Ovary_ft_cancer[0].procedure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Right ovary</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].right_ovary">
                <el-option
                  v-for="item in right_ovary"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left ovary</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].left_ovary">
                <el-option
                  v-for="item in right_ovary"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Right tube</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].right_tube">
                <el-option
                  v-for="item in right_ovary"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Left tube</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].left_tube">
                <el-option
                  v-for="item in right_ovary"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor location</div>
              <el-input placeholder="Tumor location" v-model="patientInfo.Ovary_ft_cancer[0].tumor_location"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Tumor dimension</div>
              <el-input placeholder="Tumor dimension" v-model="patientInfo.Ovary_ft_cancer[0].tumor_dimension"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Appearance</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].appearance">
                <el-option
                  v-for="item in appearance_option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Capsular integrity</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].capsular_integrity" >
                <el-option
                  v-for="item in CapsularOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Surface involvement</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].surface_involvement" >
                <el-option
                  v-for="item in vaginalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Involvement of other organs</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].involvement_other_organs">
                <el-option
                  v-for="item in involvement_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">fundus to cervix </div>
              <el-input placeholder="fundus to cervix" v-model="patientInfo.Ovary_ft_cancer[0].fundus_to_cervix"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Intercornual</div>
              <el-input placeholder="Intercornual" v-model="patientInfo.Ovary_ft_cancer[0].intercornual"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">anterior to posterior</div>
              <el-input placeholder="anterior to posterior" v-model="patientInfo.Ovary_ft_cancer[0].anterior_to_posterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Ecto-cervix to endo -cervix</div>
              <el-input placeholder="Ecto-cervix to endo -cervix" v-model="patientInfo.Ovary_ft_cancer[0].ecto_cervix"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">transverse</div>
              <el-input placeholder="transverse" v-model="patientInfo.Ovary_ft_cancer[0].transverse"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Cervix appearance</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].cvix_appearance">
                <el-option
                  v-for="item in cervix_appearance_option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Endometrium thickness</div>
              <el-input placeholder="Endometrium thickness" v-model="patientInfo.Ovary_ft_cancer[0].endometrium_thickness"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Endometrial abnormalities</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].endometrial_abnormalities">
                <el-option
                  v-for="item in skinOptions1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Myometrium</div>
              <el-input placeholder="Myometrium" v-model="patientInfo.Ovary_ft_cancer[0].myometrium"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">anterior </div>
              <el-input placeholder="anterior" v-model="patientInfo.Ovary_ft_cancer[0].anterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Posterior </div>
              <el-input placeholder="Posterior"  v-model="patientInfo.Ovary_ft_cancer[0].Posterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">adenomyosis</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].adenomyosis" >
                <el-option
                  v-for="item in vaginalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Leiomyoma</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].leiomyoma">
                <el-option
                  v-for="item in Lesionoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Location</div>
              <el-input placeholder="location" v-model="patientInfo.Ovary_ft_cancer[0].location"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Size</div>
              <el-input placeholder="Size" v-model="patientInfo.Ovary_ft_cancer[0].size"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Parametria</div>
              <el-input placeholder="Parametria" v-model="patientInfo.Ovary_ft_cancer[0].parametria"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Vaginal cuff</div>
              <el-select  placeholder="select" v-model="patientInfo.Ovary_ft_cancer[0].vaginal_cuff">
                <el-option
                  v-for="item in vaginalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Vaginal cuff anterior </div>
              <el-input placeholder=" Vaginal cuff anterior" v-model="patientInfo.Ovary_ft_cancer[0].vaginal_cuff_anterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Vaginal cuff Posterior </div>
              <el-input placeholder="Vaginal cuff Posterior" v-model="patientInfo.Ovary_ft_cancer[0].vaginal_cuff_Posterior"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div class="sub-title">Other relevant macroscopic  findings</div>
              <el-input placeholder="Other relevant macroscopic  findings" v-model="patientInfo.Ovary_ft_cancer[0].other_relevant_macroscopic"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- Ovary FT cancer -->
      
      <div v-if="(grossdescriptiontype==''|| grossdescriptiontype=='Others')&& show"> 
        <ckeditor  :editor="editor" tag-name="div" ref="myEditor" v-model="editorData" :config="editorConfig"></ckeditor>
      </div> 
       <hr style="margin-top: 20px;">
        <!-- <div v-if="show" style="font-weight: 500">Gross Description</div> -->
      </div>
      <div class="editor"  v-if="show">
        <div style="margin-top: 15px;font-weight: 500;">Microscopy</div>
        <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="Microscopydata" :config="editorConfig">
        </ckeditor>
      </div>
      <div class="editor"  v-if="show">
        <div style="margin-top: 15px;font-weight: 500;">Impression</div>
        <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="Impression" :config="editorConfig"></ckeditor>
        <hr style="margin-top: 20px;">
        <el-row :gutter="10" style="margin-top: 15px;">
          <el-col :span="6">
            <el-select placeholder="Malignant / Non Malignant" :clearable="true" :filterable="true" v-model="Malignant"  @change="validateMalignanttype">
              <el-option label="Malignant" value="Malignant"></el-option>
              <el-option label="Non Malignant" value="Non Malignant"></el-option>
              <el-option label="Others" value="Others"></el-option>
            </el-select>
            <div class="text" v-if="!isMalignantValid">Malignant / Non Malignant is required.</div>
          </el-col>
          <el-col :span="6">
            <!-- <div style="font-size: 12px; font-weight: 500;">Block No.</div> -->
            <el-input placeholder="Block for IHC" v-model="BlockType" @keyup="validateblocktype"></el-input>
            <div class="text" v-if="!isblocktypeValid">BlockType is required.</div>
          </el-col>
          <el-col :span="6">
            <el-select placeholder="Preserved / Discarded" :clearable="true" :filterable="true" v-model="storageType" @change="validatestorageTypetype">
              <el-option label="Discarded" value="Discarded"></el-option>
              <el-option label="Preserved" value="Preserved"></el-option>
              <el-option label="A/E" value="A/E"></el-option>
            </el-select>
            <div class="text" v-if="!isstorageTypeValid">StorageType is required.</div>
          </el-col>
        </el-row>
      </div>
      <div class="fileUploadArea">
        <hr style="margin-top: 0px;">
        <el-upload
        :before-upload="beforeUpload"
         :on-change="handleFileChange"
         :on-preview="previewPdf"
         :on-remove="handleRemove"
         :file-list="normalizedUploadpdf" 
        >
    <el-button size="small" type="primary">Upload additional files</el-button>
  </el-upload>
  <!-- <div v-if="$route.params.patient_id">
    <qrcode-vue :value="value"></qrcode-vue>
  </div>-->
  <FormError errorName="critical_error"></FormError>
  <el-row class="mt-20">
          <el-col :span="6">
            <el-checkbox label="Need to add Addendum" v-model="isaddendumChecked" @change="handleCheckedCitiesChange" />
          </el-col>
          <el-col  :span="6">
            <el-checkbox label="Additional approval required" v-model="patientInfo.is_required_signature" @change="handleCheckedChange" 
            :disabled="this.patientInfo.report_status ==='APPROVED' && this.patientInfo.fulfilled_signatures === 2"/> 
          </el-col>
   </el-row>
      </div>
      <div v-if="isaddendumChecked" class="editor" >
        <div style="font-weight: 500;">ADDENDUM</div>
        <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="addendum" :config="editorConfig"></ckeditor>
      </div>
    </div>

    <div v-if="reportType == 'HISTOPATHOLOGY(IHC - 4 MARKERS)' && show">
        <div class="ihcReport">
          <h4 class="reportTitle">IHC - 4 MARKERS</h4>
          <div class="editor">
            <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>IHC NO</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            <el-col :span="24">
              <el-form-item>
                <el-input placeholder="Enter the IHC NO" v-model="patientInfo.histo_ihc_no"></el-input>
              </el-form-item>
            </el-col>
            <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Clinical Details</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the Clinical Details" v-model="patientInfo.clinical_details"></el-input>
                </el-form-item>
              </el-col>
            <!-- <table>
              <tbody>
                <tr>
                  <td style="width: 160px;">
                    <p>Clinical Details</p>
                  </td>
                  <td>
                    <el-form-item class="mb-0">
                      <el-input placeholder="Enter the clinical details" v-model="patientInfo.clinical_details"></el-input>
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table> -->
            <div class="importantNote">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h5>Immunohistochemistry Report:</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="edit-box ihcTable mt-10">
              <el-table border style="width: 100%" :data="ihcDetails">
                <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                <el-table-column label="IHC Marker" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-select placeholder="Select IHC Marker" v-model="scope.row.ihc_marker" @change="handleDropdownChange(scope.row)">
                          <el-option v-for="item in sortedIHCtypes" :key="item.ANTIBODIESNAME" :label="item.ANTIBODIESNAME"
                            :value="item.ANTIBODIESNAME">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Clone" width="300" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24" >
                        <el-input placeholder="Enter the clone" v-model="scope.row.ihc_clone">
                        </el-input>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Result" width="200" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-input placeholder="Enter the result" v-model="scope.row.ihc_result">
                        </el-input>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope">
                  <el-row :gutter="12">
                    <el-col :span="6">
                      <el-buttton class="c-pointer" @click="onAddItem">
                        <el-icon :size="20">
                          <CirclePlus />
                        </el-icon></el-buttton>
                    </el-col>
                    <el-col :span="6">
                      <el-buttton>
                        <el-icon :size="19" @click="onDeleteItem(scope.row)">
                          <Delete style="color: red;" />
                        </el-icon></el-buttton>
                    </el-col>
                  </el-row>
                </el-table-column>
              </el-table>
            </div>
            <div class="importantNote">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h5>Final Diagnosis</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <el-col :span="24">
              <el-form-item>
                <el-input placeholder="Enter final diagnosis" v-model="patientInfo.ihc_final_diagnosis"></el-input>
              </el-form-item>
            </el-col>
            <div class="importantNote">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h5>Comment</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <el-col :span="24">
              <el-form-item>
                <el-input placeholder="Enter the comment" v-model="patientInfo.ihc_comment"></el-input>
              </el-form-item>
            </el-col>
          </div>
        </div>
        <el-row class="mt-20" style="margin-left:10px">
          <!-- <el-col :span="6">
            <el-checkbox label="Need to add Addendum" v-model="isaddendumChecked" @change="handleCheckedCitiesChange" />
          </el-col> -->
          <el-col  :span="6">
            <el-checkbox label="Additional approval required" v-model="patientInfo.is_required_signature" @change="handleCheckedChange" 
            :disabled="this.patientInfo.report_status ==='APPROVED' && this.patientInfo.fulfilled_signatures === 2"/> 
          </el-col>
        </el-row>
      </div>      
      <ul class="action-buttons">
        <!-- <li>
          <el-button @click="clearForm" class="submit-button"
            >Clear Form</el-button
          >
        </li>-->
        <li>
          <el-button @click="printReport" class="preview-button print-button">Print</el-button>
        </li>
        <li>
          <el-button @click="previewReport" class="preview-button">Preview</el-button>
        </li>       
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button">Submit</el-button>
        </li>
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button">Save as Draft</el-button>
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button">Update Draft</el-button>
        </li>
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button">Make Complete</el-button>
        </li>
        <li v-if="isEditForm && !isDraft">
          <el-button @click="savePatientInfo(this.patientInfo.report_status)" class="submit-button">Update</el-button>
        </li>

        <li>
          <el-button @click="saveAsTemplate" class="submit-button">Save As Template</el-button>
        </li>
      </ul>
    </div>

    <div v-if="templateTitleDialogVisible" class="template-title-dialog">
      <el-dialog width="30%" v-model="templateTitleDialogVisible" title="New Template" :before-close="handleClose"
        class="template-title-dialog">
        <el-input placeholder="Please add a title for template" v-model="templateTitle" @keyup="validatenewtemplate" @blur="validatenewtemplateOnBlur"></el-input>
        <div class="text" style="padding-top: 10px;"  v-if="istemplateTitleValid">Template title is required!</div>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="medium" class="el-white" type="info" plain @click="handleClose">Cancel</el-button>
            <el-button size="medium" type="primary" @click="addNewTemplate" :loading="loading" :disabled="!templateTitle">Submit</el-button>
          </span>
        </template>

        <FormError errorName="template_title"></FormError>
        <FormError errorName="Microscopy"></FormError>
        <FormError errorName="Impression"></FormError>
      </el-dialog>
    </div>
    <ResonForChange v-if="takeReasonDialog" v-on:close="takeReasonDialog = false" v-on:reasonSubmit="updateReport">
    </ResonForChange>
  </section>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import axios from "@/config/axios";
//radiologyReportTypes
import { genderTypes, histopathologyTypesMaps, IHCHistopathology } from "@/constants/formConstants";
import { ElMessageBox,ElMessage } from "element-plus";
import { mapGetters } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import FormError from "@/components/FormError";
import moment from "moment";
import EditorConfig from "@/config/editorConfig";
import ResonForChange from "@/components/ReasonForChange"
export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
    ResonForChange
  },

  data() {

    return {
      fieldBlurred: false,
      categoryReportTypes:[],
      params:[],
      ihcDetails: [{  sno: 1, ihc_marker: '', ihc_clone: '', ihc_result: '' }], // Initial row
      matchingIHCArray: [],
      synaptoOptions: [],     
      someCondition: false, // Set your condition here
      isExpanded: false,
      askSaveForm: false,
      templateTitleDialogVisible: false,
      templateTitle: "",
      value: "",
      reportTypes: histopathologyTypesMaps,
      IHCtypes: IHCHistopathology,
      filterIHCtypes: IHCHistopathology,
      genderTypes: genderTypes,
      options: ["Department of HistoPathology"],
      templates: [],
      testId: "",
      thyroid:[],
      amputation:[],
      Composite_resection:[],
      BreastLarge:[],
      uterus:[],
      BREAST_LUMP_WLE:[],
      Lung:[],
      urinary_bladder:[],
      colorectal:[],
      esophagus_ge_junction:[],
      kidney:[],
      ovary:[],
      stomach:[],
      patientInfo: {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        hospital_name: "",
        registration_date: "",
        reported_date: "",
        received_date: "",
        client_name: "",
        client_add: "",
        client_code: "",
        department: "Department of HistoPathology",
        report_type: "",
        email: "",
        alternate_mobile: "",
        mobile: "",
        locality: "",
        category: "HISTO_PATHOLOGY",
        report_status: "DRAFT",
        // umr_no: "",
        barcode_no: "",
        req_no: "",
        sample_type: "",
        test_name: "",
        Microscopy: "",
        Impression: "",
        reportType: "",
        Malignant: "",
        storageType: "",
        BlockType: "",
        Clinical: "",
        Site: "",
        uploadpdf:[],
        typedby:"",
        is_amendment:false,
        addendum:"",
        isaddendumChecked:false,
        amendment_history:[],
        grossdescriptiontype:'Others',
        is_required_signature:false,
        fulfilled_signatures:0,
        thyroid:[
          {
            Clinical_presentation: '',
            family_history: '',
            tft_auto_antibodies: '',
            radioisotope_study: '',
            FNAC: '',
            Bethesda_category: '',
            specimen_dimensions: '',
            right_lobe: '',
            left_lobe: '',
            isthmus: '',
            Gross: '',
            distance_from_nearest_capsule: '',
            invasion_beyond: '',
            tumor_in_right_lobe: '',
            Left_lobe_measures: '',
            isthmus_measures: '',
            capsular_invasion: '',
            angiolymphatic_invasion: '',
            extrathyroidal_extension: '',
            comment_on_other_lobes: '',
            radiology: '',
            presence_of_metastases: '',
            procedure: '',
            specimen_laterality: '',
            parathyroid_glands: '',
            tumor_focality: '',
            right_lobeoption: '',
            left_lobeoption: '',
            color: '',
            capsule: '',
            degenerative_changes: '',
            right_lobe_measures: '',
            left_lobe_measures: '',
          }
        ],
        amputation: [
          {
            procedure: '',
            specimen_laterality: '',
            specimen_dimensions: '',
            component_structures: '',
            tumor_site: '',
            tumor_gross_description: '',
            type: '',
            condition: '',
            bone_at_margin: '',
            blood_vessels: '',
            bone: '',
            skin: '',
            soft_tissue: '',
            neurovascular_bundle: '',
            optionskin: ''
          }
        ],
        Composite_resection: [
          {
            Laterality: '',
            Measurements_of_Bone: '',
            Mucosa: '',
            Salivary_gland: '',
            Skin_flap: '',
            Teeth: '',
            Site_of_tumor: '',
            Tumor_size: '',
            Appearance: '',
            Macroscopic_depth_of_invasion: '',
            Distance_from_margins: '',
            Anterior: '',
            Posterior: '',
            Medial: '',
            Lateral: '',
            Superior: '',
            Deep: ''
          }
        ],
        BreastLarge: [
          {
            Procedure: 'Wide local excision Mastectomy Modified radical mastectomy, post NACT',
            Laterality: '',
            Total_dimensions: 'Medial to lateral -- Superficial to Deep -- Superior to Inferior --',
            Skin_ellipse: '',
            Location_of_tumor: '',
            Margins_of_tumor: '',
            Size_of_largest_node: '',
            Nipple: 'Normal Paget’s disease Peau de Orange Absent',
            Deep_margin: '',
            Surgical_defect: '',
            Tumor: '',
            Tumour_Size: '',
            Distance_to_nearest: '',
            Muscle: '',
            Adjacent_Breast: '',
            Axillary_tail: '',
            Blocks:'',
          }
        ],
    uterus:[
       {
           uterus:'',
           uterus_dimensions:'',
           midline_fundal_serosa:'',
           maximum_intercornual:'',
           maximum_anterior:'',
           cervix_dimensions:'',
           vaginal_cuff:'',
           anterior:'',
           posterior:'',
           cervix_shape:'',
           right_ovary_description:'',
           left_ovary_description:'',
           right_fallopian_tube:'',
           left_fallopian_tube:'',
           right_parametrium:'',
           left_parametrium:'',
           endometrium_description:'',
           endometrial_thickness:'',
           tumor_dimensions:'',
           endometrial_abnormalities:'',
           location:'',
           myometrium_description:'',
           myometrial_depth:'',
           involvement_of_myometrium:'',
           myometrial_appearance:'',
           leiomyoma:'',
           lower_uterine_segment:'',
           other_abnormalities:'',
        }
      ],
      BREAST_LUMP_WLE:[
        {
          laterality:'',
          procedure:'',
          specimen_orientation:'',
          specimen_size:'',
          medial_lateral:'',
          superficial_deep:'',
          superior_inferior:'',
          skin:'',
          skin_dimensions:'',
          skin_abnormalities:'',
          nipple:'',
          lesion:'',
          number_of_macroscopically:'',
          for_each_lesion:'',
          lesion_description:'',
          location:'',
          neurovascular_bundle:'',
          distance_of_lesion_margin:'',
          distance_of_lesion:'',
          other_relevant_macroscopic:'',
        }
      ],
      Lung:[
        {
          procedure:'',
          specimen_laterality:'',
          specimen_integrity:'',
          site_of_tumor:'',
          size_of_tumor:'',
          gross_description_of_tumor:'',
          distance_from_margins:'',
          pleural_surface:'',
          bronchial_cut_margin:'',
          vessel_cut_margin:'',
          parenchymal_cut_margin:'',
          adjacent_lung:'',
          lymph_nodes:'',
        }
      ],  
      urinary_bladder: [
        {
          tissue_received_in:'',
          procedure:'',
          bladder_measurements:'',
          Length_of_ureter:'',
          length_urethra:'',
          measurements:'',
          right_seminal:'',
          left_seminal_vesicle:'',
          tumour:'',
          tumour_multifocality:'',
          number_tumours:'',
          tumou_bladder:'',
          tumor_dimension:'',
          gross_appearance:'',
          macroscopic_invasion:'',
          bladder_wall:'',
          ureter:'',
          macroscopic_evidence:'',
          perivesical_tissue:'',
          urethra:'',
          record_tumour:'',
          distance_of_tumor:'',
          specify_margin:'',
          margin_involvement:'',
          involved:'',
          peritoneal_description:'',
          location_specify:'',
          appearance_bladder:'',
          Lymph_nodes_isolated:'',
          size_of_node:'',
          other_relevant_macroscopic:''
        }
      ],
      colorectal:[
        {
          received:'',
          procedure:'',
          laterality:'',
          specimen:'',
          tumor_location:'',
          tumor_type:'',
          polypoidal_Mucinous:'',
          tumor_dimension:'',
          peritoneal_involvement:'',
          not_seen:'',
          involves_serosal_surface:'',
          invades_peritoneum:'',
          proximal_margin:'',
          distal_margin:'',
          neurovascular_bundleon:'',
          anterior:'',
          posterior:'',
          any_other_polyps:'',
          perforation:'',
          mesorectum:'',  
          total_number:'',
          largest_lymph:'',
        }
      ],
      esophagus_ge_junction:[
      {
      received:'',
      type_of_resection:'',
      length_of_tubular:'',
      length_of_greater:'',
      length_of_lesser :'',
      other_anatomical:'',  
      tumour:'',
      number_of_tumours:'',
      for_each_tumour:'',
      tumor_dimensions:'',
      gross_appearance:'',
      tumour_site:'',
      proximal_edge:'',
      distal_edge_tumour:'',
      distance_of_tumor_Gastro:'',
      distance_of_tumor_Circumferential:'',
      involvement_of_adjacent:'',
      barrett_mucosa:'',
      length_of_barrett :'',
      block_for_Molecular:'',
      additional_specimens:'',
      lymph_nodes:'',
      lymph_nodes_along:'',
      nodes_along_stomach:'',
      relevant_macroscopic:''
      }
     ],
  kidney: [
      {
        received:'',
        procedure:'',
        specimen_laterality:'',
        specimen_integrity:'',
        specimen_dimensions:'',
        anatomical_components:'',
        kidney:'',
        ureters:'',
        adrenal:'',
        gerota_fascia:'',
        other_specify:'',
        number_of_tumours:'',
        for_each_tumour:'',
        tumour_dimensions:'',
        tumour_site:'',      
        tumour_description:'',
        tumour_invasion:'',
        ureter_cut_margin:'',
        periureteric_circumferential_margin:'',
        perinephric_fat_margin:'',
        lymph_nodes:'',
        lymph_nodes_isolated:'',
        relevant_macroscopy:'',
        frozen_details:''
      }
    ],
    ovary:[
      {
        tissue_received:'',
        procedure:'',
        describe_following:'',
        specimen_laterality:'',
        ovary_dimensions:'',
        fallopian_tube:'',
        weight_ovary:'',
        specimen_integrity:'',
        ovarian_capsule_integrity:'',
        ovarian_surface_involvement:'',
        ovarian_surface:'',
        solid_component:'',
        tumour_dimensions:'',
        pTumour_description:'',
        tumour_site:'',
        cystic_component:'',
        type_of_cyst:'',
        internal_surface_abnormalities:'',
        appearance:'',
        distance_to_margins:'',
        omentum_Weight:'',
        omental_involvement:'',
        lymph_nodes:'',
        relevant_template:'',
        other_relevant_macroscopic:'',       
        block_identification_key:'',
        block_for_Molecular:'',
        involvement_organs:''
      }
    ],
   stomach:[
      {
        fresh_tissue_received:'',
        received:'',
        resection:'',       
        specimen_dimensions:'',
        each_anatomical_component:'',
        stomach_greater_curvature:'',
        esophagus:'',
        stomach_lesser_curvature:'',
        duodenum:'', 
        number_of_tumors:'',
        tumor_dimensions:'',
        macroscopic_tumour_site:'', 
        macroscopic_tumour:'',
        distance_from_tumour:'',
        proximal_margin:'',
        distal_margin:'',
        circumferential_resection_margin:'',
        serosal_appearance:'',
        omentum_curvature:'',
        omentum_lesser_curvature:'',
        lymph_greater_curvature:'',
        lymph_lesser_curvature:'',
        macroscopic_information:'',
      }
    ],
    Ovary_ft_cancer:[
      {
        received:'',
        procedure:'',
        right_ovary:'',
        left_ovary:'',
        right_tube:'',
        left_tube:'',
        tumor_location:'',
        tumor_dimension:'',
        appearance:'',
        capsular_integrity:'',
        surface_involvement:'',
        involvement_other_organs:'',
        fundus_to_cervix:'',
        intercornual:'',
        anterior_to_posterior:'',
        ecto_cervix:'',
        transverse:'',
        cvix_appearance:'',
        endometrium_thickness:'',
        endometrial_abnormalities:'',
        myometrium:'',
        anterior:'',
        Posterior:'',
        adenomyosis:'',    
        leiomyoma:'',
        parametria:'',
        vaginal_cuff:'',
        vaginal_cuff_anterior:'',
        vaginal_cuff_Posterior:'',
        other_relevant_macroscopic:'',
        location:'',
        size:''
      }
    ],
      clinical_details:'',
      histo_ihc_no:''
      },
      input: '',
      Required_Signatures: [
        {
          value: 'Yes',
          label: 'Yes',
        },
        {
          value: 'No',
          label: 'No',
        }
      ],

      specimenLateralityOptions: [
        {
          value: 'Yes',
          label: 'Yes'
        },
        {
          value: 'No',
          label: 'No'
        },

      ],
      typeOptions: [
        {
          value: 'Amputation',
          label: 'Amputation'
        },
        {
          value: 'Disarticulation',
          label: 'Disarticulation'
        },
      ],
      conditionOptions: [
        {
          value: 'Smooth',
          label: 'Smooth'
        },
        {
          value: 'Ragged',
          label: 'Ragged'
        },
      ],
      skinOptions: [
        {
          value: 'Normal',
          label: 'Normal'
        },
        {
          value: 'Ulceration',
          label: 'Ulceration'
        },
        {
          value: 'Discolouration',
          label: 'Discolouration'
        },
        {
          value: 'Swelling',
          label: 'Swelling'
        },
        {
          value: 'Trauma',
          label: 'Trauma'
        },
      ],
      bloodVesselsOptions: [
        {
          value: 'Normal',
          label: 'Normal'
        },
        {
          value: 'Abnormal',
          label: 'Abnormal'
        },
        {
          value: 'Occlusion (%)',
          label: 'Occlusion (%)'
        },
        {
          value: 'Atheroma',
          label: 'Atheroma'
        },
        {
          value: 'Thrombosis',
          label: 'Thrombosis'
        },
        {
          value: 'Other',
          label: 'Other'
        },
      ],
      radiologyOptions: [
        {
          value: 'US',
          label: 'US'
        },
        {
          value: 'MRI',
          label: 'MRI'
        },
      ],
      presenceOfMetastasesOptions: [
        {
          value: 'LN',
          label: 'LN'
        },
        {
          value: 'Distant',
          label: 'Distant'
        },
      ],
      procedureOptions: [
        {
          value: 'Lobectomy',
          label: 'Lobectomy'
        },
        {
          value: 'Hemithyroidectomy',
          label: 'Hemithyroidectomy'
        },
        {
          value: 'Subtotal thyroidectomy',
          label: 'Subtotal thyroidectomy'
        },
        {
          value: 'Total thyroidectomy',
          label: 'Total thyroidectomy'
        },
      ],
      specimenLateralityOptions1: [
        {
          value: 'Left',
          label: 'Left'
        },
        {
          value: 'Right',
          label: 'Right'
        },
        {
          value: 'Unorientated',
          label: 'Unorientated'
        },
      ],
      parathyroidGlandsOptions: [
        {
          value: 'Absent (or unidentified)',
          label: 'Absent (or unidentified)'
        },
        {
          value: 'Present',
          label: 'Present'
        },
      ],
      TumorFocalityOptions: [
        {
          value: 'Unifocal',
          label: 'Unifocal'
        },
        {
          value: 'Multifocal',
          label: 'Multifocal'
        },
      ],
      rightLobeOptions: [
        {
          value: 'Superior pole',
          label: 'Superior pole'
        },
        {
          value: 'Inferior pole',
          label: 'Inferior pole'
        },
        {
          value: 'Central',
          label: 'Central'
        },
        {
          value: 'Measuring',
          label: 'Measuring'
        },
      ],
      leftLobeOptions: [
        {
          value: 'Superior pole',
          label: 'Superior pole'
        },
        {
          value: 'Inferior pole',
          label: 'Inferior pole'
        },
        {
          value: 'Central',
          label: 'Central'
        },
        {
          value: 'Measuring',
          label: 'Measuring'
        },
      ],
      colorOptions: [
        {
          value: 'Grey white',
          label: 'Grey white'
        },
        {
          value: 'Yellow',
          label: 'Yellow'
        },
        {
          value: 'Brown',
          label: 'Brown'
        },
        {
          value: 'Encapsulated',
          label: 'Encapsulated'
        },
        {
          value: 'Well defined',
          label: 'Well defined'
        },
        {
          value: 'Poorly defined',
          label: 'Poorly defined'
        },
      ],
      capsuleOptions: [
        {
          value: 'Thick',
          label: 'Thick'
        },
        {
          value: 'Irregular',
          label: 'Irregular'
        },
        {
          value: 'Thin',
          label: 'Thin'
        },
      ],
      degenerativechangesOptions: [
        {
          value: 'cyst',
          label: 'cyst'
        },
        {
          value: 'Hemorrhage',
          label: 'Hemorrhage'
        },
        {
          value: 'fibrosis',
          label: 'fibrosis'
        },
        {
          value: 'Calcification',
          label: 'Calcification'
        },
      ],
      // synaptoOptions: [
      //   {
      //     value: 'Synapto1',
      //     label: 'Synapto1',
      //   },
      //   {
      //     value: 'Synapto2',
      //     label: 'Synapto2',
      //   }
      // ],
      CapsularOptions:[
        {
          value: 'intact',
          label: 'intact',
        },
        {
          value: 'breached',
          label: 'breached',
        }
      ],
      vaginalOptions: [
        {
          value: 'Absent',
          label: 'Absent',
        },
        {
          value: 'Present',
          label: 'Present',
        }
      ],
      tissuereceivedinoption:[
        {
          value: 'Formalin ',
          label: 'Formalin ',
        },
        {
          value: 'Fresh',
          label: 'Fresh',
        },
      ],
      lateralityOptions: [
        {
          value: 'Left',
          label: 'Left'
        },
        {
          value: 'Right',
          label: 'Right'
        },
      ],
      specimenOptions :[
        {
          value: 'Orientated ',
          label: 'Orientated '
        },
        {
          value: 'Not orientated ',
          label: 'Not orientated '
        },
      ],  
      skinOptions1 :[
        {
          value: 'Absent',
          label: 'Absent'
        },
        {
          value: 'Present',
          label: 'Present'
        },
      ],
      SkinabnormalitiesOptions :[
        {
          value: 'Absent',
          label: 'Absent'
        },
        {
          value: 'Present',
          label: 'Present'
        },
        {
          value: 'Ulceration ',
          label: 'Ulceration '
        },
        {
          value: 'Other',
          label: 'Other'
        },
        {
          value: 'specify',
          label: 'specify'
        },
      ],
      nippleOptions:[
        {
          value: 'Absent',
          label: 'Absent'
        },
        {
          value: 'Present',
          label: 'Present'
        },
        {
          value: 'Normal',
          label: 'Normal'
        },
        {
          value: 'Other3',
          label: 'Other3'
        },
        {
          value: 'specify',
          label: 'specify'
        },
      ],
      Lesionoptions:[
        {
          value: 'Absent',
          label: 'Absent'
        },
        {
          value: 'Present',
          label: 'Present'
        },
      ],
      Lesiondescriptionoptions:[
        {
          value: 'Absent',
          label: 'Absent'
        },
        {
          value: 'Cyst',
          label: 'Cyst'
        },
        {
          value: 'Calcification',
          label: 'Calcification'
        },
        {
          value: 'Fibrosis',
          label: 'Fibrosis'
        },
        {
          value: 'Other',
          label: 'Other'
        },
        {
          value: 'specify',
          label: 'specify'
        },
      ],
      LymphOptions:[
      {
          value: 'Present',
          label: 'Present'
        },
        {
          value: 'Absent',
          label: 'Absent'
        },
      ],
      AdjacentOptions:[
      {
          value: 'Atelectasis',
          label: 'Atelectasis'
        },
        {
          value: 'Obstructive pneumonia',
          label: 'Obstructive pneumonia'
        },
      ],
      Tumour_site_bladder:[
        {
          value: 'Trigone',
          label: 'Trigone'
        },
        {
          value: 'Right lateral wall',
          label: 'Right lateral wall'
        },
        {
          value: 'Left lateral wall ',
          label: 'Left lateral wall '
        },
        {
          value: 'Anterior wall',
          label: 'Anterior wall'
        },
        {
          value: 'Posterior wall',
          label: 'Posterior wall'
        },
        {
          value: 'dome',
          label: 'dome'
        },
        {
          value: 'Other',
          label: 'Other'
        },
        {
          value: 'specify',
          label: 'specify'
        },
      ],
      gross_appearance:[
        {
          value:'Polypoid',label:'Polypoid'
        },
        {
          value:'Papillary',label:'Papillary '
        },
        {
          value:'Ulcerated ',label:'Ulcerated '
        },
        {
          value:'Fungating',label:'Fungating '
        },
        {
          value:'Solid',label:'Solid'
        },
        {
          value:'Other',label:'Other'
        },
        {
          value:'specify',label:'specify'
        }
      ],
      macroscopic_evidence :[
        {
          value:'Absent',label:'Absent',
        },
        {
          value:'Present',label:'Present'
        }
      ],
      macroscopic_evidence_organs:[
        {
          value:'Absent',label:'Absent',
        },
        {
          value:'Present',label:'Present'
        }
      ],
      record_organs_involved :[
        {
          value:'Prostate',label:'Prostate'
        },
        {
          value:'Rectum',label:'Rectum'
        },
        {
          value:'Ovaries',label:'Ovaries'
        },
        {
          value:'Vagina',label:'Vagina'
        },
        {
          value:'Seminal vesicles',label:'Seminal vesicles'
        },
        {
          value:'Uterus',label:'Uterus'
        },
        {
          value:'Cervix',label:'Cervix'
        },
        {
          value:'Fallopian tube(s)',label:'Fallopian tube(s)'
        },
        {
          value:'others ',label:'others '
        },
        {
          value:'specify',label:'specify'
        }
      ],
      involved:[
        {
          value:'Perivesical fat ',label:'Perivesical fat '
        },
        {
          value:'Peritoneal surface',label:'Peritoneal surface'
        },
        {
          value:'Ureteric',label:'Ureteric'
        },
        {
          value:'Urethral',label:'Urethral'
        }
      ],
      appearance_uninvolved:[
        {
          value:'Normal',label:'Normal'
        },
        {
          value:'Ulcerated',label:'Ulcerated'
        },
        {
          value:'Erythematous',label:'Erythematous'
        },
        {
          value:'If other',label:'If other'
        },
        {
          value:'specify ',label:'specify '
        },
      ],
      macroscopic_evidence1:[
      {
        value:'Absent ',label:'Absent'
      },
      {
        value:'Present ',label:'Present'
      }
      ],
      received_options:[
      {
          value:'Fresh ',label:'Fresh' 
        },
        {
          value:'formalin ',label:'formalin' 
        }

      ],
      procedure_options:[
        {
          value:'APR ',label:'APR'
        },
        {
          value:'AR',label:'AR'
        },
        {
          value:'Total colectomy',label:'Total colectomy'
        },
        {
          value:'Hemicolectomy',label:'Hemicolectomy'
        }
      ],
      tumor_type:[
        {
          value:'Ulcerative ',label:'Ulcerative' 
        },
        {
          value:'Ulceroproliferative ',label:'Ulceroproliferative' 
        },
        {
          value:'Noduloulcerative ',label:'Noduloulcerative' 
        },
        {
          value:'Polypoidal ',label:'Polypoidal' 
        },
        {
          value:'Mucinous ',label:'Mucinous' 
        },
      ],
      mesorectum :[
        {
          value:'Intact ',label:'Intact' 
        },
        {
          value:'Complete ',label:'Complete' 
        },
        {
          value:'Nearly complete ',label:'Nearly complete' 
        },
        {
          value:'Incomplete ',label:'Incomplete' 
        }
      ],
      tissueOptions: [
        {
          value: 'No', label: 'No'
        },
        {
          value: 'Yes', label: 'Yes'
        }
      ],
      overySpecimenOptions: [
        {
          value: 'Left', label: 'Left'
        },
        {
          value: 'Right', label: 'Right'
        },
        {
          value: 'Unspecified', label: 'Unspecified'
        }
      ],
      specimenIntegrityOptions: [
        {
          value: 'Fragmented ', label: 'Fragmented '
        },
        {
          value: 'Other', label: 'Other'
        }
      ],
      ovarianOptions: [
        {
          value: 'Intact', label: 'Intact'
        },
        {
          value: 'Ruptured', label: 'Ruptured'
        }
      ],
      ovarianCutSurfaceOptions: [
        {
          value: 'Cystic', label: 'Cystic'
        },
        {
          value: 'Solid/cystic', label: 'Solid/cystic'
        },
        {
          value: 'Solid', label: 'Solid'
        }
      ],
      typeOfCystOptions: [
        {
          value: 'Unilocular', label: 'Unilocular'
        },
        {
          value: 'Multilocular', label: 'Multilocular'
        },
        {
          value: 'Cyst contents', label: 'Cyst contents'
        },
        {
          value: 'Describe', label: 'Describe'
        },
        {
          value: 'Blood-filled/stained', label: 'Blood-filled/stained'
        },
        {
          value: 'Clear', lable: 'Clear'
        },
        {
          value: 'Watery', lable: 'Watery'
        },
        {
          value: 'Serous', lable: 'Serous'
        },
        {
          value: 'Seromucinous', lable: 'Seromucinous'
        },
        {
          value: 'Gelatinous', lable: 'Gelatinous'
        },
        {
          value: 'Hair', lable: 'Hair'
        },
        {
          value: 'Sebum', lable: 'Sebum'
        },
        {
          value: 'Teeth (teratoma)', lable: 'Teeth (teratoma)'
        },
         {
          value: 'Mucoid ', label: 'Mucoid '
        },
      ],
      internalOptions: [
        {
          value: 'Absent', label: 'Absent'
        },
        {
          value: 'Present', label: 'Present'
        }
      ],
      tumour_site: [
        {
          value: 'Renal pelvis', label: 'Renal pelvis'
        },
        {
          value: 'Calyx', label: 'Calyx'
        },
        {
          value: 'Pelviureteric junction ', label: 'Pelviureteric junction '
        },
        {
          value: 'upper pole', label: 'upper pole'
        },
        {
          value: 'Middle pole', label: 'Middle pole'
        },
        {
          value: 'Lower  pole', label: 'Lower pole'
        },
        {
          value: 'ureter', label: 'ureter'
        }
      ],
      tumour_description :[
        {
          value: 'Polypoidal', label: 'Polypoidal'
        },
        {
          value: 'Solid & indurated Fungating', label: 'Solid & indurated Fungating'
        },
        {
          value: 'Ulcerated ', label: 'Ulcerated '
        },
        {
          value: 'Scarring', label: 'Scarring'
        },
        {
          value: 'fibrosis', label: 'fibrosis'
        },
      ],
      tumour_invasion:[
        {
          value: 'Periureteric tissue', label: 'Periureteric tissue'
        },
        {
          value: 'Renal parenchyma', label: 'Renal parenchyma'
        },
        {
          value: 'Renal Perinephric fat', label: 'Renal Perinephric fat'
        },
        {
          value: 'Gerota’s fascia', label: 'Gerota’s fascia'
        },
        {
          value: 'Adrenal', label: 'Adrenal'
        },
        {
          value: 'Other', label: 'Other'
        }
      ],
      type_of_resection:[
        {
          value: 'Pharyngolaryngo-oesophagectomy', label: 'Pharyngolaryngo-oesophagectomy'
        },
        {
          value: 'Oesophago-gastrectomy', label: 'Oesophago-gastrectomy'
        },
        {
          value: 'Esophagectomy', label: 'Esophagectomy'
        },
        {
          value: 'Other', label: 'Other'
        }
      ],
      tumour:[
        {
         value:'Discernible',label:'Discernible'
        },
        {
          value:'Not discernible',label:'Not discernible'
        }
      ],
      gross_appearance_tumour:[
        {
          value:'Polypoid',label:'Polypoid'
        },
        {
          value:'Ulcer',label:'Ulcer'
        },
        {
          value:'Thickening',label:'Thickening  '
        },
        {
          value:'Other',label:'Other'
        },
        {
          value:'specify',label:'specify'
        },
      ],
      involvement_of_adjacent:[
       {
          value:'Pleura ',label:'Pleura '
        },
        {
          value:'Peritoneum ',label:'Peritoneum '
        },
        {
          value:'Pericardium  ',label:'Pericardium  '
        },
        {
          value:'Diaphragm ',label:'Diaphragm '
        },
        {
          value:'Aorta ',label:'Aorta '
        },
        {
          value:'Carotid  vessel',label:'Carotid  vessel'
        },
        {
          value:'Azygos vein',label:'Azygos vein'
        },
        {
          value:'Trachea  ',label:'Trachea'
        },
        {
          value:'Left main bronchus ',label:'Left main bronchus'
        },
        {
          value:'Right main bronchus ',label:'Right main bronchus'
        },
        {
          value:'Vertebral body',label:'Vertebral body'
        },
        {
          value:'Perioesophageal tissue',label:'Perioesophageal tissue'
        },
        {
          value:'Other',label:'Other'
        }
      ],
      lymph_nodes:[
        {
          value:'Not received',label:'Not received'
        },
        {
          value:'Received',label:'Received'
        },
        {
          value:'number per cassette',label:'number per cassette'
        },
      ],
      resectionoptions:[
        {
          value:'Oesophago-gastrectomy ',label:'Oesophago-gastrectomy'
        },
        {
          value:'Subtotal gastrectomy',label:'Subtotal gastrectomy'
        },
        {
          value:'proximal',label:'proximal'
        },
        {
          value:'Total gastrectomy',label:'Total gastrectomy'
        },
        {
          value:'Subtotal gastrectomy',label:'Subtotal gastrectomy'
        },
        {
          value:'distal',label:'distal'
        }
      ],
      macroscopic_tumour_site_option:[
        {
          value:'Cardia',label:'Cardia'
        },
        {
          value:'Body',label:'Body'
        },
        {
          value:'Lesser curve',label:'Lesser curve'
        },
        {
          value:'Fundus',label:'Fundus'
        },
        {
          value:'Pylorus',label:'Pylorus'
        },
        {
          value:'Anterior wall',label:'Anterior wall'
        },
        {
          value:'Antrum',label:'Antrum'
        },
        {
          value:'Greater curve',label:'Greater curve'
        },
        {
          value:'Posterior wall',label:'Posterior wall'
        },
      ],
      Macroscopic_tumour_type_options:[
        {
          value:'Polypoid',label:'Polypoid'
        },
        {
          value:'Fungating ',label:'Fungating '
        },
        {
          value:'Ulcerated',label:'Ulcerated'
        },
        {
          value:'Infiltrative',label:'Infiltrative'
        },
      ],
      
  appearance_option:[
        {
          value:'solid',label:'solid'
        },
        {
          value:'cystic ',label:'cystic '
        },
        {
          value:'solid cystic',label:'solid cystic'
        },
        {
          value:'papillary',label:'papillary'
        },
        {
          value:'variegated',label:'variegated'
        }
      ],
      involvement_options:[
        {
          value:'contra lateral ovary',label:'contra lateral ovary'
        },
        {
          value:'right fallopian tube',label:'right fallopian tube'
        },
        {
          value:'left fallopian tube',label:'left fallopian tube'
        },
        {
          value:'broad ligament',label:'broad ligament'
        },
        {
          value:'vesico- vaginal fold',label:'vesico- vaginal fold'
        },
        {
          value:'recto-uterine fold',label:'recto-uterine fold'
        },
        {
          value:'others',label:'others'
        }
      ],
      right_ovary:[
        {
          value:'absent',label:'absent'
        },
        {
          value:'unidentifiable ',label:'unidentifiable '
        },
        {
          value:'present',label:'present'
        },
        {
          value:'Measures',label:'Measures'
        }
      ],    
      cervix_appearance_option:[
        {
          value:'normal',label:'normal'
        },
        {
          value:'hypertrophied',label:'hypertrophied'
        },
        {
          value:'elongated',label:'elongated'
        }
      ],  
      unSavedChanges: false,
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      histopathologyTypesMap: histopathologyTypesMaps,
      show: false,
      editorData: "",
      Microscopydata: "",
      Impression: "",
      searchVisitId: "",
      reportType: "",
      Microscopy: "",
      Malignant: "",
      addendum: "",
      isMalignantValid: true,
      storageType: "",
      isstorageTypeValid: true,
      BlockType: "",
      isblocktypeValid: true,
      istemplateTitleValid:false,
      isFocused: false,
      Site: "",
      Clinical: "",
      biospyno:"",
      uploadpdf: [],
      DocumentList: [],
      selectedFile: "",
      showReportType: true,
      editReportType: false,
      isaddendumChecked: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      editorConfig: EditorConfig,
      showAddTemplate: false,
      reportTypeNew: "",
      listOfDepartments: [],
      takeReasonDialog: false,
      reasonStatus: "",
      is_required_signature: false,
      grossdescriptiontype: 'Others'
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
      "getDepartments",
      "getReportTypes",
    ]),

    ...mapGetters("reportTemplates", [
      "getAllReportTemplates",
      "getReportTemplateAddStatus",
      "getSingleReportTemplate",
      "getuploadTemplate",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    isReportTypeUpdateDisable() {
      return this.reportTypeNew &&
        this.reportTypeNew.trim() &&
        this.reportTypeNew.trim().length
        ? false
        : true;
    },
    isDisableDepartment() {
      if (
        this.searchVisitId &&
        this.listOfDepartments &&
        this.listOfDepartments.length
      ) {
        return false;
      }
      return true;
    },
    isDisableReportType() {
      if (
        this.searchVisitId &&
        this.listOfDepartments &&
        this.listOfDepartments.length &&
        this.reportTypes &&
        this.reportTypes.length
      ) {
        return false;
      }
      return true;
    },

    isEditForm() {
      return this.$route.params && this.$route.params.patient_id ? true : false;
    },
    extractedSampleType() {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = this.patientInfo.sample_type;
    let extractedText = tempDiv.textContent || tempDiv.innerText || '';
    return extractedText;
   },
    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },
    normalizedUploadpdf() {
      return this.uploadpdf.map(item => ({
        name: item.name || null,
        url: item.url || null
      }));
    },
    noContent() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      return data.length ? false : true;
    },
    flexibleInputClass() {
      return {
        'flexible-input': true,
        'expand': this.isExpanded,
      };
    },
    sortedIHCtypes() {
      // Sort the IHCtypes array alphabetically
      return this.IHCtypes.slice().sort((a, b) => {
        // Assuming ANTIBODIESNAME is a string property
        return a.ANTIBODIESNAME.localeCompare(b.ANTIBODIESNAME);
      });
    }
  },
  async mounted() {
    await this.$store.dispatch("errors/clear", {}, { root: true });
    if (this.$route.params.patient_id) {
      await this.fetchSinglePatientInfo();
      await this.getVisitData();

    }
    await this.fetchReportTemplates();
    setTimeout(() => {
      this.show = true;
    });
  },

  methods: {
    handleBlur() {
      this.fieldBlurred = true
    },

    handleDropdownChange(row) {     
      const stringValue = this.filterIHCtypes.filter(item =>item.ANTIBODIESNAME === row.ihc_marker).map(item=> item.Clone)
      row.ihc_clone =stringValue ? stringValue[0]:''     
    },
   
    removeRow(index) {
      this.ihcDetails.splice(index, 1);
    },

    onAddItem() {
      // this.IHCtypes = this.filterIHCtypes.filter(item => {
      //   // Check if the label of the current item is not present in any item of filterIHCtypes
      //   return !this.matchingIHCArray.some(option => option.value === item.label);
      // });
      this.ihcDetails.push({
        sno: this.ihcDetails?.length + 1,
        ihc_marker: '',
        ihc_clone: '',
        ihc_result: ''
      });
    },

    onDeleteItem(index) {
      // if(index.value!=""){
      //  const removedData = this.filterIHCtypes.filter(item =>item.label === index.value);
      //   this.IHCtypes.push(...removedData)
      //   console.log(this.IHCtypes)
      // }
      if(this.ihcDetails.length > 1){
      this.ihcDetails.splice(index.sno - 1, 1)
      this.ihcDetails.forEach((data, index) => {
        data.sno = index + 1
      })
    }
    },
    async handleCheckedChange(event) {
      this.patientInfo.is_required_signature = event;
    },
    validateblocktype() {
      if (this.BlockType.length == 0) {
        this.isblocktypeValid = false;
      } else {
        this.isblocktypeValid = true;
      }
    },
    validatenewtemplate() {
      if (this.templateTitle.length === 0) {
        this.istemplateTitleValid = true;
      } else {
        this.istemplateTitleValid = false;
      }
    },
    validatenewtemplateOnBlur() {
      this.isFocused = false;
      this.validatenewtemplate();
    },
    validatestorageTypetype() {
      if (this.storageType.length == 0) {
        this.isstorageTypeValid = false;
      } else {
        this.isstorageTypeValid = true;
      }
    },
    validateMalignanttype() {
      if (this.Malignant.length == 0) {
        this.isMalignantValid = false;
      } else {
        this.isMalignantValid = true;
      }
    },
    expandInput() {
      this.isExpanded = true;
    },
    collapseInput() {
      this.isExpanded = false;
    },

    async updateReport(reason) {
      this.updatePatientInfo(reason);
    },
    askReason() {
      this.takeReasonDialog = true;
    },
    onEditReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = true;
      this.showReportType = false;
    },
    updateReportType() {
      this.patientInfo.report_type = this.reportTypeNew;
      this.editReportType = false;
      this.showReportType = true;
    },
    cancelUpdateReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = false;
      this.showReportType = true;
    },
    onChangeReportType() {
      this.reportSubCategory = this.reportType;
      this.patientInfo.report_type = this.reportType;
      this.patientInfo.report_template_id = "";

      this.patientInfo.test_name =
        this.histopathologyTypesMap[this.reportType];
      this.fetchReportTemplates();
    },
    askSaveConfirmation() {
      this.askSaveForm = true;
    },
    async getAutoCompletePatientsData(queryString, cb) {
      if (queryString && queryString.length > 2) {
        await this.$store.dispatch("patientReports/fetchAllPatients", {
          search_string: queryString,
          get_all: true,
        });

        const patientsData = [];

        (this.getPatientDetails.data || []).forEach((el) => {
          patientsData.push({
            value: el.VisitiID + " - " + el.PatientName,
            ...el,
          });
        });

        cb(patientsData || []);
      } else {
        cb([]);
      }
    },
    async getVisitData() {
      try {
        this.searchVisitId =
          this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
        // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
      } catch (error) {
        console.log(error);
      }
    },
    async handleSelect(data) {
      this.patientInfo.visit_id = data.VisitiID;
      const age = data.Age.split(" ");
      const years = parseInt(age[0]);
      const months = parseInt(age[2]);
      const days = parseInt(age[4]);
      if (years > 0 && months > 0 && days > 0 || years > 0 && months > 0) {
        this.patientInfo.age = `${years} Y ${months} M`;
      } else if (years === 0 && months > 0 && days > 0) {
        this.patientInfo.age = `${months} M ${days} D`;
      } else if (years === 0 && months === 0 && days > 0) {
        this.patientInfo.age = `${days} D`;
      }else if ( months > 0) {
        this.patientInfo.age = `${months} M`;
      }
      else if(years>0){
        this.patientInfo.age = `${years} Y`;
      }
      this.patientInfo.gender = data.Gender.toUpperCase();
      this.patientInfo.barcode_no = data.BarcodeNo;
      this.patientInfo.hospital_name = data.HospitalName;
      this.patientInfo.client_add = data.ClientAdd;
      this.patientInfo.client_code = data.ClientCode;
      this.patientInfo.client_name = data.ClientName;
      this.patientInfo.patient_name = data.PatientName;
      this.patientInfo.ref_doctor = data.RefDoctor && !data.RefDoctor.startsWith('Dr.')
    ? `Dr. ${data.RefDoctor}`
    : data.RefDoctor || '';
      this.patientInfo.uhid = data["UHID/MR NO"];
      this.patientInfo.registration_date = data.RegisterationDate
        ? moment(data.RegisterationDate).utc().format()
        : null;
      this.patientInfo.reported_date = data.ReportedDate
        ? moment(data.ReportedDate).utc().format()
        : null;
      this.patientInfo.received_date = data.ReceivedDate
        ? moment(data.ReceivedDate).utc().format()
        : null;
      this.patientInfo.collected_date = data.CollectionDate
        ? moment(data.CollectionDate).utc().format()
        : null;

      this.patientInfo.email = data.Email;
      this.patientInfo.mobile = data.Mobile;
      this.patientInfo.locality = data.Locality;
      this.patientInfo.alternate_mobile = data.Phone;
      // this.patientInfo.umr_no = data.umr_no;
      this.patientInfo.client_name = data.ClientName;
      this.patientInfo.req_no = data.req_no;
      if (data.test_name) {
        this.patientInfo.test_name = data.TestName;
      }
      this.patientInfo.sample_type = data.SampleTypeName;

      await this.fetchPatientDepartments();
    },

    getSelectedReportType(departmentType) {
      const JavaScriptApplicants = this.listOfDepartments.filter((e) =>
        e.department.includes(departmentType)
      );

      JavaScriptApplicants.forEach(
        (x) => (this.reportTypes = x.test_categories)
      );
    },
    clearNameSearch() {
      let patientInfo = {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_add: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        client_name: "",
        hospital_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        email: "",
        alternate_mobile: "",
        mobile: "",
        locality: "",
        sample_type: "",
      };
      this.patientInfo = { ...this.patientInfo, ...patientInfo };
    },
    async fetchSinglePatientInfo() {
      this.loading = true;
      this.loadingText = "Fetching data...";
      try {
        await this.$store.dispatch(
          "patientReports/fetchSinglePatientReports",
          this.$route.params.patient_id
        );
        await this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async setPatientData() {
      this.patientInfo = { ...this.getSinglePatientReports };
      this.ihcDetails=this.patientInfo.IHCResultTable
      await this.fetchPatientDepartments();
      this.editorData = this.getSinglePatientReports.test_details;
      this.Microscopydata = this.getSinglePatientReports.Microscopy;
      this.Impression = this.getSinglePatientReports.Impression;
      this.storageType = this.getSinglePatientReports.storageType;
      this.BlockType = this.getSinglePatientReports.BlockType;
      this.Malignant = this.getSinglePatientReports.Malignant;
      this.Clinical = this.getSinglePatientReports.Clinical;
      this.uploadpdf = this.getSinglePatientReports.uploadpdf;
      this.Site = this.getSinglePatientReports.Site;
      this.grossdescriptiontype = this.getSinglePatientReports.grossdescriptiontype?this.getSinglePatientReports.grossdescriptiontype:'Others';
      this.addendum = this.getSinglePatientReports.addendum;
      this.isaddendumChecked = this.getSinglePatientReports.isaddendumChecked;
      this.biospyno=this.getSinglePatientReports.biospyno;
      this.reportType = this.patientInfo.sub_category;
      this.reportName = this.patientInfo.report_type;
      if (this.getDepartments && this.getDepartments.data) {
        this.departmentsData = this.getDepartments.data;
      }
      //console.log(this.patientInfo.department, this.getDepartments);
      const JavaScriptApplicants = this.departmentsData.filter((e) =>
        e.department.includes(this.patientInfo.department)
      );
      // console.log(JavaScriptApplicants);
      JavaScriptApplicants.forEach(
        (x) => (this.reportTypes = x.test_categories)
      );
      this.testId = this.getSinglePatientReports.result_test_id;
    },
    async fetchPatientDepartments() {
      this.loading = true;
      let params = {};
      if (this.patientInfo.visit_id) {
        params.visit_id = this.patientInfo.visit_id;
        params.department = "histopathology";
      }
      await this.$store.dispatch("patientReports/fetchAllDepartments", params);
      if (
        this.getDepartments &&
        this.getDepartments.data &&
        this.getDepartments.data.length
      ) {
        this.listOfDepartments = this.getDepartments.data;

        this.listOfDepartments.forEach(
          (x) => (this.categoryReportTypes = x.test_categories)
        );
        if (this.categoryReportTypes.length == 1) {
          this.testId = this.categoryReportTypes[0].TestId
          this.testName = this.categoryReportTypes[0].ItemName
          this.reportType= this.categoryReportTypes[0].ItemName
        }
        else {
          this.testId = ''
        }
      } else {
        this.listOfDepartments = [];
      }
      
      this.loading = false;
    },
    async clearForm() {
      await ElMessageBox.confirm(
        "Are you sure to clear the form. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          await this.clearPatientForm();
        })
        .catch(() => { });
    },
    async handleClose() {
      this.istemplateTitleValid=false;
      this.templateTitle = "";
      // this.Microscopy = "";
      // this.Impression = "";
      this.templateTitleDialogVisible = false;
      await this.$store.dispatch("errors/clear", {}, { root: true });
    },

    async addNewTemplate() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        const Microscopy = dd[1]?.innerHTML || "";
        const Impression = dd[2]?.innerHTML || "";
        this.validatenewtemplate();
        this.patientInfo.sub_category = this.patientInfo.report_type;
        this.patientInfo.template_body = data;
        this.patientInfo.Microscopy = Microscopy ? Microscopy : undefined;
        this.patientInfo.Impression = Impression ? Impression : undefined;
        this.patientInfo.template_title = this.templateTitle;
        this.patientInfo.test_details = data;
        let params = { ...this.patientInfo };
        if (params.age) {
          params.age = params.age.toString();
        }
        if (!dd[0] || !dd[0].innerHTML) {
          this.$message("No Content to Save Template");
          return;
        }
        this.loading = true;
        await this.$store.dispatch("reportTemplates/addReportTemplate", params);
        console.log(this.getReportTemplateAddStatus, 'ABC')
        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });
          this.templateTitleDialogVisible = false;
          this.loading = false;
          await this.fetchReportTemplates();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Creating new Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    saveAsTemplate() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      stringContent = stringContent.trim();
      if (!stringContent) {
        ElMessage.warning("Report content is not given");
        return;
      }
      this.templateTitleDialogVisible = true;
    },
    clearPatientForm(clearPatientData = true) {
      if (clearPatientData) {
        this.patientInfo = {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          hospital_name: "",
          registration_date: "",
          reported_date: "",
          received_date: "",
          barcode_no: "",
          client_name: "",
        };
      }

      window.document.getElementsByClassName("ck-content")[0].innerText = "";
      window.document.getElementsByClassName("ck-content").innerHTML = "";
      // this.editorData = "";
    },
    async previewReport() {
      try {
        if(this.patientInfo.report_type !== 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){
        let dd = window.document.getElementsByClassName("ck-content");
        const removeEmptyPTagsAndSpaces = (html) => {
          const cleanedHtml = html.replace(/<p[^>]*>(?:\s|&nbsp;|<br\s*\/?>|<br data-cke-filler="true">)*<\/p>/gi, '')
                             .replace(/<br\s*\/?>/gi, '')
                             .replace(/&nbsp;/g, ' ')
                             .replace(/\s+/g, ' ')
                             .trim();
        return cleanedHtml.replace(/\s+/g, ' ');
        };
        const test_details = this.grossdescriptiontype == 'Others' ? dd[0].innerHTML : "";
        const data= removeEmptyPTagsAndSpaces(test_details);
        const microscopy = this.grossdescriptiontype === 'Others' ? dd[1].innerHTML : (dd[0].innerHTML ? dd[0].innerHTML : "");
        const Microscopy= removeEmptyPTagsAndSpaces(microscopy);
        const impression = this.grossdescriptiontype === 'Others' ? dd[2].innerHTML : (dd[1].innerHTML ? dd[1].innerHTML : "");
        const Impression= removeEmptyPTagsAndSpaces(impression);
        const Addendum = this.isaddendumChecked && this.grossdescriptiontype === 'Others' ? dd[3].innerHTML : this.isaddendumChecked && dd[2].innerHTML ? dd[2].innerHTML : "";
        const addendum= removeEmptyPTagsAndSpaces(Addendum);
        // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        // stringContent = stringContent.trim();
        // if (!stringContent) {
        //   ElMessage.warning("Report content is not given");
        //   return;
        // }
        this.setPatientinfo();
        this.loading = true;
        this.loadingText = "Loading..";
        this.patientInfo.BlockType = this.BlockType
        this.patientInfo.storageType = this.storageType
        this.patientInfo.Malignant = this.Malignant
        this.patientInfo.Clinical = this.Clinical
        this.patientInfo.isaddendumChecked = this.isaddendumChecked
        this.patientInfo.Site = this.Site
        this.patientInfo.grossdescriptiontype = this.grossdescriptiontype
        this.patientInfo.biospyno=this.biospyno;
        let params = { ...this.patientInfo };
        params.sub_category = this.reportType;
        params.test_details = data;
        params.Microscopy = Microscopy ? Microscopy : '';
        params.Impression = Impression ? Impression : '';
        params.addendum = addendum ? addendum : '';
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      }
      if(this.patientInfo.report_type == 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){
        let params = { ...this.patientInfo };
        params.sub_category = this.reportType;
        params.IHCResultTable = this.ihcDetails.filter(detail => detail.ihc_marker !== '') 
        // params.test_details = data;     
        //params.addendum = addendum ? addendum : undefined;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
      }
      } catch (err) {
        console.log(err);
      }
    },
    async printReport() {
      try {
        if(this.patientInfo.report_type !== 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){
          let dd = window.document.getElementsByClassName("ck-content");
          const removeEmptyPTagsAndSpaces = (html) => {
          const cleanedHtml = html.replace(/<p[^>]*>(?:\s|&nbsp;|<br\s*\/?>|<br data-cke-filler="true">)*<\/p>/gi, '')
                             .replace(/<br\s*\/?>/gi, '')
                             .replace(/&nbsp;/g, ' ')
                             .replace(/\s+/g, ' ')
                             .trim();
        return cleanedHtml.replace(/\s+/g, ' ');
        };
        const test_details = this.grossdescriptiontype == 'Others' ? dd[0].innerHTML : "";
        const data= removeEmptyPTagsAndSpaces(test_details);
        const microscopy = this.grossdescriptiontype === 'Others' ? dd[1].innerHTML : (dd[0].innerHTML ? dd[0].innerHTML : "");
        const Microscopy= removeEmptyPTagsAndSpaces(microscopy);
        const impression = this.grossdescriptiontype === 'Others' ? dd[2].innerHTML : (dd[1].innerHTML ? dd[1].innerHTML : "");
        const Impression= removeEmptyPTagsAndSpaces(impression);
        const Addendum = this.isaddendumChecked && this.grossdescriptiontype === 'Others' ? dd[3].innerHTML : this.isaddendumChecked && dd[2].innerHTML ? dd[2].innerHTML : "";
        const addendum= removeEmptyPTagsAndSpaces(Addendum);
        // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        // stringContent = stringContent.trim();
        // if (!stringContent) {
        //   ElMessage.warning("Report content is not given");
        //   return;
        // }
        this.setPatientinfo();
        this.loading = true;
        this.loadingText = "Loading..";
        this.patientInfo.BlockType = this.BlockType
        this.patientInfo.storageType = this.storageType
        this.patientInfo.Malignant = this.Malignant
        this.patientInfo.Clinical = this.Clinical
        this.patientInfo.isaddendumChecked = this.isaddendumChecked
        this.patientInfo.Site = this.Site
        this.patientInfo.grossdescriptiontype = this.grossdescriptiontype
        this.patientInfo.biospyno=this.biospyno;
        let params = { ...this.patientInfo };
        params.sub_category = this.reportType;
        params.test_details = data;
        params.Microscopy = Microscopy ? Microscopy : '';
        params.Impression = Impression ? Impression : '';
        params.addendum = addendum ? addendum : '';
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreviewPrint",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to print report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      }
      if(this.patientInfo.report_type == 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){
        let params = { ...this.patientInfo };
        params.sub_category = this.reportType;
        params.IHCResultTable = this.ihcDetails.filter(detail => detail.ihc_marker !== '')  
        // params.test_details = data;     
        //params.addendum = addendum ? addendum : undefined;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreviewPrint",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
      }
      } catch (err) {
        console.log(err);
      }
    },
    async goToReports() {
      if (this.$route.params.patient_id) {
        this.$router.push({
          name: "PatientReportView",
          query: this.$route.query,
          params: { status: this.$route.params.status },
        });
      } else {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      }
    },

    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "HISTO_PATHOLOGY",
        };

        if (this.reportType) {
          const selected_category = this.histopathologyTypesMap.find(type => type.value === this.reportType);

          if (selected_category) {
            params.sub_category = selected_category.value;
          }
        }

        await this.$store.dispatch("reportTemplates/fetchAllReportTemplates", params);

        this.templates = this.getAllReportTemplates.data;


        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async beforeUpload(file) {
      try {
        console.log(file)
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async handleFileChange(file) {
      try {
        const selectedFile = file.raw;
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post("patient-reports/upload-pdf", formData, file.raw);
        const pdf = response.data.data.imageUrl;
        const name = response.data.data.name;
        this.uploadpdf.push({ name: name, url: pdf });
      } catch (error) {
        console.error(error);
      }
    },
    previewPdf(file) {
      if (file) {
        window.open(file.url);
      } else {
        console.error("No PDF file selected.");
      }
    },
    savePatientInfo(status) {
      this.reasonStatus = status;
      this.handleBlur();
      this.validateblocktype();
      this.validateMalignanttype();
      this.validatestorageTypetype();
      this.setPatientinfo();
      if ((this.isblocktypeValid && this.isMalignantValid && this.isstorageTypeValid && this.biospyno!='') || this.patientInfo.report_type === 'HISTOPATHOLOGY(IHC - 4 MARKERS)') {
        if (this.$route.params.patient_id && status === 'APPROVED') {
          this.askReason()
        } else if (this.$route.params.patient_id) {
          this.updatePatientInfo();
        } else {
          this.addPatientInfo();
        }
      } else {
        console.error('Form validation failed');
      }
    },
    async fetchSingleReportTemplate(templateId) {
      try {
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: templateId }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async updateTemplateData(templateId) {
      await this.fetchSingleReportTemplate(templateId);

      let templateData = this.templates.find((x) => x._id == templateId);
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.template_body
      ) {
        templateData.template_body = this.getSingleReportTemplate.template_body;
      }
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.test_details
      ) {
        templateData.test_details = this.getSingleReportTemplate.test_details;
        templateData.Microscopy = this.getSingleReportTemplate.Microscopy;
        templateData.Impression = this.getSingleReportTemplate.Impression;
        templateData.Clinical = this.getSingleReportTemplate.Clinical;
        templateData.Site = this.getSingleReportTemplate.Site;
      }
      if (templateData && templateData.report_type!="HISTOPATHOLOGY(IHC - 4 MARKERS)") {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML || "";
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (stringContent) {
          ElMessageBox.confirm(
            "Are you want to update template data. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "No",
              type: "warning",
            }
          )
            .then(async () => {
              await this.updateEditorData(templateData);
            })
            .catch(() => { });
        } else {
          this.updateEditorData(templateData);
        }
      }else{
        this.updateEditorData(templateData);
      }
    },
    updateEditorData(templateData) {
      this.show = false;
      this.loadingEditor = true;
      if(templateData.report_type=="HISTOPATHOLOGY(IHC - 4 MARKERS)"){
        setTimeout(() => {  
        this.show = true;
        this.loadingEditor = false;
        this.patientInfo.clinical_details = templateData?.clinical_details || "";
        this.patientInfo.histo_ihc_no = templateData?.histo_ihc_no || "";
        this.patientInfo.ihc_final_diagnosis = templateData?.ihc_final_diagnosis || "";
        this.patientInfo.ihc_comment = templateData?.ihc_comment || "";
        this.ihcDetails= templateData?.IHCResultTable.length > 0?templateData?.IHCResultTable:this.ihcDetails; 
          }, 100);
      } 
      if(templateData.report_type!="HISTOPATHOLOGY(IHC - 4 MARKERS)"){
      setTimeout(() => {
        this.loadingEditor = false;
        this.show = true;
        this.editorData = templateData?.template_body || "";
        this.Microscopydata = templateData?.Microscopy || "";
        this.Impression = templateData?.Impression || "";
        this.addendum = templateData?.addendum || "";
        this.storageType = templateData?.storageType || "";
        this.BlockType = templateData?.BlockType || "";
        this.Malignant = templateData?.Malignant || "";
        this.patientInfo.report_type = this.reportType || "";
        this.Site = templateData.Site || "";
        this.Clinical = templateData.Clinical || "";
      }, 100);
    }
    },
    async addPatientInfo() {
      try {
        if(this.patientInfo.report_type !== 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){       
        let dd = window.document.getElementsByClassName("ck-content");
         const removeEmptyPTagsAndSpaces = (html) => {
          const cleanedHtml = html.replace(/<p[^>]*>(?:\s|&nbsp;|<br\s*\/?>|<br data-cke-filler="true">)*<\/p>/gi, '')
                             .replace(/<br\s*\/?>/gi, '')
                             .replace(/&nbsp;/g, ' ')
                             .replace(/\s+/g, ' ')
                             .trim();
        return cleanedHtml.replace(/\s+/g, ' ');
        };
        const test_details = this.grossdescriptiontype == 'Others' ? dd[0].innerHTML : "";
        const data= removeEmptyPTagsAndSpaces(test_details);
        const microscopy = this.grossdescriptiontype === 'Others' ? dd[1].innerHTML : (dd[0].innerHTML ? dd[0].innerHTML : "");
        const Microscopy= removeEmptyPTagsAndSpaces(microscopy);
        const impression = this.grossdescriptiontype === 'Others' ? dd[2].innerHTML : (dd[1].innerHTML ? dd[1].innerHTML : "");
        const Impression= removeEmptyPTagsAndSpaces(impression);
        const Addendum = this.isaddendumChecked && this.grossdescriptiontype === 'Others' ? dd[3].innerHTML : this.isaddendumChecked && dd[2].innerHTML ? dd[2].innerHTML : "";
        const addendum= removeEmptyPTagsAndSpaces(Addendum);
        // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        // stringContent = stringContent.trim();
        // if (!stringContent) {
        //   ElMessage.warning("Report content is not given");
        //   return;
        // }
        this.loading = true;
        this.loadingText = "Loading...";
        this.patientInfo.BlockType = this.BlockType;
        this.patientInfo.storageType = this.storageType;
        this.patientInfo.Malignant = this.Malignant;
        this.patientInfo.Site = this.Site;
        this.patientInfo.Clinical = this.Clinical;
        this.patientInfo.uploadpdf = this.uploadpdf;
        this.patientInfo.typedby = this.getProfileDetails.first_name;
        this.patientInfo.isaddendumChecked = this.isaddendumChecked;
        this.patientInfo.grossdescriptiontype = this.grossdescriptiontype; 
        this.patientInfo.biospyno=this.biospyno;             
        this.params = { ...this.patientInfo };
        this.params.Microscopy = Microscopy ? Microscopy : undefined;
        this.params.Impression = Impression ? Impression : undefined;
        this.params.addendum = addendum ? addendum : undefined;
        // params.report_type = this.reportName;
        if (this.testId) {
          this.params.result_test_id = this.testId;
        }
        if ( this.params.client_code &&  this.params.client_code != "") {
          const code =  this.params.client_code.toString();
          this.params.client_code = code;
        }

        this.params.test_details = data;
        if (this.reasonStatus) {
          this.params.report_status = this.reasonStatus;
        }
        this.params.sub_category = this.reportType;
        if (! this.params.report_template_id) {
          this.params.report_template_id = null;
        }
       }
       if(this.patientInfo.report_type === 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){
        if (this.reasonStatus) {
          this.patientInfo.report_status = this.reasonStatus;
        }
         this.patientInfo.typedby = this.getProfileDetails.first_name;
         this.patientInfo.IHCResultTable= this.ihcDetails.filter(detail => detail.ihc_marker !== '')
          this.patientInfo.sub_category = this.reportType;
          this.params = { ...this.patientInfo };
        }
        await this.$store.dispatch("patientReports/addPatientReport",  this.params);
        if (this.getPatientAddStatus) {
          this.$notify.success({
            title: "Success",
            message: this.reasonStatus == "DRAFT" ? "Patient Report Drafted Successfully" : "Patient Report Submitted Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async updatePatientInfo(reason) {
      try {
        if(this.patientInfo.report_type !== 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){
        let dd = window.document.getElementsByClassName("ck-content");
        const removeEmptyPTagsAndSpaces = (html) => {
          const cleanedHtml = html.replace(/<p[^>]*>(?:\s|&nbsp;|<br\s*\/?>|<br data-cke-filler="true">)*<\/p>/gi, '')
                             .replace(/<br\s*\/?>/gi, '')
                             .replace(/&nbsp;/g, ' ')
                             .replace(/\s+/g, ' ')
                             .trim();
        return cleanedHtml.replace(/\s+/g, ' ');
        };
        const test_details = this.grossdescriptiontype == 'Others' ? dd[0]?.innerHTML : "";
        const data=removeEmptyPTagsAndSpaces(test_details?test_details:'');
        const microscopy = this.grossdescriptiontype === 'Others' ? dd[1]?.innerHTML : (dd[0]?.innerHTML ? dd[0]?.innerHTML : "");
        const Microscopy= removeEmptyPTagsAndSpaces(microscopy?microscopy:'');
        const impression = this.grossdescriptiontype === 'Others' ? dd[2]?.innerHTML : (dd[1]?.innerHTML ? dd[1]?.innerHTML : "");
        const Impression= removeEmptyPTagsAndSpaces(impression?impression:'');
        const Addendum = this.isaddendumChecked && this.grossdescriptiontype === 'Others' ? dd[3]?.innerHTML : this.isaddendumChecked && dd[2]?.innerHTML ? dd[2]?.innerHTML : "";
        const addendum= removeEmptyPTagsAndSpaces(Addendum?Addendum:'');
        // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        // stringContent = stringContent.trim();
        // if (!stringContent) {
        //   ElMessage.warning("Report content is not given");
        //   return;
        // }
        this.loading = true;
        this.loadingText = "Loading...";
        this.patientInfo.BlockType = this.BlockType
        this.patientInfo.storageType = this.storageType
        this.patientInfo.Malignant = this.Malignant
        this.patientInfo.Site = this.Site
        this.patientInfo.Clinical = this.Clinical
        this.patientInfo.uploadpdf = this.uploadpdf
        this.patientInfo.isaddendumChecked = this.isaddendumChecked;
        this.patientInfo.biospyno=this.biospyno;
        let params = { ...this.patientInfo };
        params.Microscopy = Microscopy ? Microscopy : '';
        params.Impression = Impression ? Impression : '';
        params.addendum = addendum ? addendum : '';
        // params.report_type = this.reportName;
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        if (params.age == null) {
          params.age = "";
        }
        if (params.reported_date == null) {
          params.reported_date = "";
        }
        if (params.received_date == null) {
          params.received_date = "";
        }
        if (this.reasonStatus) {
          params.report_status = this.reasonStatus;
        }
        params.test_details = data;
        params.sub_category = this.reportType;
        let patientId = this.$route.params.patient_id;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (reason && reason.reason) {
          const amendmentReason = reason.reason;
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString();
          this.patientInfo.amendment_history.push({
            amendment_reason: amendmentReason,
            edited_by: this.getProfileDetails.first_name,
            edited_date: formattedDate
          });
          params.amendment_reason = reason.reason;
        }
        params.amendment_history = this.patientInfo.amendment_history;
        if (reason && reason.is_amendment) {
          params.is_amendment = reason.is_amendment
        }
        await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      }
      if(this.patientInfo.report_type === 'HISTOPATHOLOGY(IHC - 4 MARKERS)'){
         if (this.reasonStatus) {
          this.patientInfo.report_status = this.reasonStatus;
          }
        // params.report_type = this.reportName;
        if (this.testId) {
          this.patientInfo.result_test_id = this.testId;
        }
        if (this.patientInfo.client_code && this.patientInfo.client_code != "") {
          const code = this.patientInfo.client_code.toString();
          this.patientInfo.client_code = code;
        }
        if (this.patientInfo.age == null) {
          this.patientInfo.age = "";
        }
        if (this.patientInfo.reported_date == null) {
          this.patientInfo.reported_date = "";
        }
        if (this.patientInfo.received_date == null) {
          this.patientInfo.received_date = "";
        }
        if (this.reasonStatus) {
          this.patientInfo.report_status = this.reasonStatus;
        }
          this.patientInfo.IHCResultTable = this.ihcDetails.filter(detail => detail.ihc_marker !== '') 
          this.patientInfo.sub_category = this.reportType;
          let patientId = this.$route.params.patient_id;
          let params = { ...this.patientInfo };
          await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      }   
      } catch (err) {
        console.log(err);
      }
    },
    setPatientinfo(){
      if(this.grossdescriptiontype!='Thyroid'){
          this.patientInfo.thyroid = this.thyroid ;
        }
        if (this.grossdescriptiontype!='Uterus'){
          this.patientInfo.uterus =this.uterus;
        }
        if (this.grossdescriptiontype!='AMPUTATION'){
          this.patientInfo.amputation =this.amputation;
        }
         if (this.grossdescriptiontype!='Breast_Large'){
          this.patientInfo.BreastLarge =this.BreastLarge;
        }
         if (this.grossdescriptiontype!='BREAST_LUMP_WLE'){
          this.patientInfo.BREAST_LUMP_WLE  = this.BREAST_LUMP_WLE;
        }
         if (this.grossdescriptiontype!='colorectal'){
          this.patientInfo.colorectal =this.colorectal;
        }
          if (this.grossdescriptiontype!='Composite resection'){
          this.patientInfo.Composite_resection =this.Composite_resection;
        }
         if (this.grossdescriptiontype!='ESOPHAGUS_GE_JUNCTION'){
          this.patientInfo.esophagus_ge_junction =this.esophagus_ge_junction;
        }
         if (this.grossdescriptiontype!='Kidney'){
          this.patientInfo.kidney = this.kidney;
        }
         if (this.grossdescriptiontype!='Lung'){
          this.patientInfo.Lung =this.Lung;
        }
         if (this.grossdescriptiontype!='OVARY'){
          this.patientInfo.ovary =this.ovary;
        }
         if (this.grossdescriptiontype!='Ovary_FT_cancer'){
          this.patientInfo.Ovary_ft_cancer =this.Ovary_ft_cancer;
        }
         if (this.grossdescriptiontype!='STOMACH'){
          this.patientInfo.stomach =this.stomach;
        }
         if (this.grossdescriptiontype!='URINARY_BLADDER'){
          this.patientInfo.urinary_bladder =this.urinary_bladder;
        }
    },
    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.updateEditorData({});
        })
        .catch(() => { });
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    handleRemove(file) {
      const index = this.uploadpdf.findIndex(item => item.name === file.name && item.url === file.url);
      if (index !== -1) {
        this.uploadpdf.splice(index, 1);
      }
    },
    onChangeCategory(selectedvalue) {
      this.grossdescriptiontype = selectedvalue;
    },
    handleCheckedCitiesChange(value) {
      if (value == true) {
        this.isaddendumChecked = true
      } else {
        this.isaddendumChecked = false
      }
    },
  },
  fillSampleType(data) {
        let matchedReportTypes = [];
      let Testdeatils =this.categoryReportTypes.filter((testDetails)=>testDetails.TestId == data)
        Testdeatils.forEach(category => {
          this.reportTypesdata.forEach(reportType => {
            if (category.ItemName.includes(reportType.value)) {
              matchedReportTypes.push(reportType);
            }
          });
        });
          this.reportType = matchedReportTypes[0]?.value
          this.onChangeReportType()
       const JavaScriptApplicants = this.categoryReportTypes.filter(
        (e) => e.TestId == data
      );
      this.testName = JavaScriptApplicants[0]?.ItemName
      if (JavaScriptApplicants[0]?.SampleTypeName) {
        this.patientInfo.sample_type = JavaScriptApplicants[0]?.SampleTypeName;
      }
      if (JavaScriptApplicants[0]?.BarcodeNo) {
        this.patientInfo.barcode_no = JavaScriptApplicants[0]?.BarcodeNo;
      }
    },
  };
</script>

<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 20px auto 0 !important;

  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 10px;

    .display-end {
      text-align: end;
    }

    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .inner-navbar {
    margin-bottom: 10px;

    .filter-navigation {
      display: flex;
      justify-content: space-between;

      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .inner {
    margin-bottom: 100px !important;

    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;

    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }

      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 500;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }

      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;

        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }

    .report-sub-title {
      color: #43454b;
      font-size: 18px;
      text-align: center;
      margin-top: -1px;
      padding-top: 1rem;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);

      font-weight: 500;
    }

    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }

    .editor {
      margin-top: 0.5em;
      padding: 10px;

      .ck-editor__editable {
        min-height: 320px;
      }
    }

    .image__caption_highlighted {
      min-height: auto !important;
    }

    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;

      li {
        &:not(:last-child) {
          margin-right: 20px;
        }

        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;

          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 500;
          }

          &.print-button {
            background: #f56c6c !important;
          }

          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }
  }

  figure {
    &.table {
      br {
        display: none;
      }

      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }

  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;

      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }

  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 500;
  //   }
  // }
}

.cursor-pointer {
  cursor: pointer;
}

.template-title-dialog {
  .el-dialog__header {
    background-color: #a09b9b6b;

    .el-dialog__close {
      font-size: 20px;
      color: red;
    }
  }
}

.lineheight{
  line-height: 20px;
}
.flexible-input {
  &.expand {
    z-index: 3;
  }

  &.collapse {
    z-index: 0;
    display: none;
    /* Optionally hide the collapsed version */
  }
}

.fileUploadArea {
  padding: 10px;
}

.text {
  color: red;
  font-size: smaller;
}

.error-message {
  color: red;
  text-align: start;
  margin: 0;
  font-size: 14px;
  padding: 5px;
}

/** IHC CSS **/
.ihcReport {
  .reportTitle {
    padding: 3px 5px;
    margin-top: 15px;
    text-align: center;
    font-weight: 600;
    background: #2caf9f;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.3;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      background: #e0f3f1;
      color: #333;
      text-align: center;
      border: 1px solid #2eaf9f;
      font-weight: 600;
      font-size: 14px;
    }

    td {
      border: 1px solid #2eaf9f;
      text-align: left;
      line-height: 1.3;
      padding: 3px 10px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }

  .importantNote {
    margin-top: 20px;

    td {
      border: 0;
      padding: 0;
    }

    h5 {
      color: #2eaf9f;
      font-weight: 600;
      margin-bottom: 5px;
    }

    
  }

}
.el-upload-list__item .el-icon--close-tip {
    display: none !important;
}

.template-title-dialog .el-dialog__header{
  background-color: white !important;
}

/** IHC CSS **/
</style>
