<template>
  <section class="tms-results-edit">
    <el-dialog v-model="openResults" width="70%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="tms-results-header">
        <h3>RESULTS EDIT</h3>
      </div>
      <div class="tms-report-editor-pop-table" v-if="results && Object.keys(results) && Object.keys(results).length">
        <div class="data-table" v-if="acylCarnitines && acylCarnitines.length">
          <h3 class="heading">
            <span class="bold"> Acyl Carnitines
            </span><br /><span class="semi-bold">(Fatty Acid Oxidation defects& Organic Acid Disorders)
            </span>
          </h3>
          <el-table :data="acylCarnitines" :row-class-name="tableRowClassName">
            <el-table-column label="Analyte" class="result" prop="fullname" width="135">
            </el-table-column>
            <el-table-column label="Conc.(uM)" width="120">
              <template #default="scope">
                <el-input type="text" v-model="scope.row.value"
                  @input="checkConfirmationTestValues(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="155" label="Biochemical Reference Ranges(uM)" class="result">
              <template #default="scope" style="color: blue">
                {{
                  scope.row.lower_limit.toFixed(2) +
                    " - " +
                    scope.row.upper_limit.toFixed(2)
                }}
              </template>
            </el-table-column>

          </el-table>
        </div>

        <div class="data-table" v-if="aminoAcids && aminoAcids.length">
          <h3 class="heading">
            <span class="bold"> Amino Acids
            </span><br /><span class="semi-bold">(Amino Acid Disorders & Urea Cycle Disorders)
            </span>
          </h3>
          <el-table :data="aminoAcids" :row-class-name="tableRowClassName">
            <el-table-column label="Analyte" class="result" prop="fullname" width="135">
            </el-table-column>
            <el-table-column label="Conc.(uM)" width="120">
              <template #default="scope">
                <el-input type="text" v-model="scope.row.value"
                  @input="checkConfirmationTestValues(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="155" label="Biochemical Reference Ranges(uM)" class="result">
              <template #default="scope" style="color: blue">
                {{
                  scope.row.lower_limit.toFixed(2) +
                    " - " +
                    scope.row.upper_limit.toFixed(2)
                }}
              </template>
            </el-table-column>

          </el-table>
        </div>
        <div class="data-table" v-if="graphResults && graphResults.length">
          <h3 class="heading">
            <span class="bold"> Acyl Carnitines</span><br /><span class="semi-bold">Show in Graph
            </span>
          </h3>
          <el-table :data="graphResults" :row-class-name="tableRowClassName">
            <el-table-column label="Analyte" class="result" prop="test" width="135">
            </el-table-column>
            <el-table-column label="Conc.(uM)" width="120">
              <template #default="scope">
                <el-input type="text" v-model="scope.row.value"
                  @input="checkConfirmationTestValues(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="155" label="Biochemical Reference Ranges(uM)" class="result">
              <template #default="scope" style="color: blue">
                {{
                  scope.row.lower_limit.toFixed(2) +
                    " - " +
                    scope.row.upper_limit.toFixed(2)
                }}
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="okay-btn cancel" @click="handleClose">CANCEL</el-button>
          <el-button class="okay-btn" @click="saveResults">SAVE</el-button>
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
export default {
  props: ["resultsEdit"],
  data() {
    return {
      openResults: true,
      results: null,
      graphResults: [],
      acylCarnitines: [],
      aminoAcids: [],
    };
  },
  mounted() {

    this.results = this.resultsEdit;

    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i] && this.results[i].results) {
        this.results[i].results.forEach((category) => {
          if (category.test_category_id == 'ACYL_CARNITINES' && !category.show_in_graph) {
            this.acylCarnitines.push(category)
          }
        });
        this.results[i].results.forEach((category) => {
          if (category.test_category_id == 'AMINO_ACIDS' && !category.show_in_graph) {
            this.aminoAcids.push(category)
          }
        })
        this.results[i].results.forEach((el) => {
          if (el.show_in_graph) {
            this.graphResults.push(el);
          }
        });
      }
    }
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.flag == "UPPER_ABNORMAL") {
        return "warning-row";
      } else if (row.flag == "LOWER_ABNORMAL") {
        return "success-row";
      }
      return "";
    },
    checkConfirmationTestValues(test) {
      if (test.value) {
        if (test.value && test.value != "-") {
          if (
            test.test_category &&
            test.test_category == "Amino Acids" &&
            test.test != "Phe/Tyr"
          ) {
            if (test.value > Math.floor(test.upper_limit)) {
              test.flag = "UPPER_ABNORMAL";
            } else if (test.value < Math.floor(test.lower_limit)) {
              test.flag = "LOWER_ABNORMAL";
            } else {
              test.flag = "NORMAL";
            }
          } else {
            let valData = parseFloat(test.value).toFixed(2);
            let upperLimit = test.upper_limit.toFixed(2);
            let lowerLimit = test.lower_limit.toFixed(2);
            if (parseFloat(valData) > parseFloat(upperLimit)) {
              test.flag = "UPPER_ABNORMAL";
            } else if (parseFloat(valData) < parseFloat(lowerLimit)) {
              test.flag = "LOWER_ABNORMAL";
            } else {
              test.flag = "NORMAL";
            }
          }
        } else {
          test.flag = "NORMAL";
        }
        return test;
      }
    },
    saveResults() {
      this.$emit("updatedResults", this.resultsEdit);
    },
    async handleClose() {
      this.$emit("handleClose", false);
    },
  },
};
</script>

<style lang="scss">
.tms-results-edit {
  .el-dialog__headerbtn {
    visibility: hidden;
  }
}

.tms-results-header {
  text-align: center;
  margin-bottom: 5px;
  background: #f5f5f5;

  padding: 10px 0;
  border-radius: 10px 10px 0 0;
  color: #273480;
}

#tms-add-report-view {
  max-width: 900px;
  margin: 0 auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .el-dialog {
    margin-top: 5vh !important;
    height: 590px;
    border-radius: 10px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      background: #ffffff;
    }

    .el-dialog__footer {
      /* margin-top: 16px; */
      padding: 15px;
      background: #f5f5f5;

      border-radius: 0 0 10px 10px;
    }
  }

  .el-table .warning-row {
    font-weight: bold;
    background: #b7d2ec;
  }

  .el-table .success-row {
    font-weight: bold;
    color: black;
  }

  .tms-report-divider {
    width: 100%;
    height: 3px;
    background: #b7d2ec;
    margin: 15px 0;
  }

  @media print {
    .tms-report-divider {
      display: none;
    }
  }

  .tms-report-editor-pop-table {
    padding: 10px 10px 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    min-height: 550px;
    max-height: 70vh;
    overflow: auto;

    // .el-table--fit {
    //   border-right: 0.5px solid #dddddd;
    // }
    .data-table {
      margin-bottom: 20px;

      .heading {
        background: #2eaf9f;
        color: #fff;

        text-align: center !important;
        // min-height: 25px;
        font-weight: 500;
        padding: 3px 10px;
        // height: 25px;
        vertical-align: middle;

        .bold {
          font-weight: 700 !important;
          font-size: 16px !important;
        }

        .semi-bold {
          font-weight: 500 !important;
          font-size: 16px !important;
        }
      }

      tr {
        border: 1px solid #dddddd;

        th,
        td {
          border: 1px solid #dddddd;
          padding: 2px 10px;
          font-size: 13px;
          text-align: left !important;
          color: #000;
        }

        th,
        td:last-child {
          border-left: none !important;
        }

        th {
          .cell {
            line-height: 20px;
            padding: 0;
            font-size: 15px;
            // text-align: center;
          }
        }
      }

      .el-input__inner {
        height: 30px;
        padding: 0 5px;
      }

      thead {
        tr {
          th {
            padding: 2px 10px;
            font-size: 13px;
            text-align: left !important;

            &:not(:last-child) {
              // border-right: 1px solid #dddddd;
            }
          }
        }
      }

      table {
        border-collapse: collapse;
        width: 100% !important;

        th,
        td {
          padding: 5px 10px !important;
          font-size: 15px;
        }

        th {
          background: #f5f5f5;
          font-weight: 600;
        }

        .table-head {
          background: #28b4a6;
          color: #fff;
          text-align: center !important;
          font-size: 15px !important;

          .cell {
            line-height: 20px !important;
          }
        }
      }
    }
  }
}

.dialog-footer {
  .okay-btn {
    height: 30px;
    min-height: 30px;
    background-color: #273480;
    color: #fff;
    line-height: 1px;
    width: 100px;
    border: none;

    &:hover {
      height: 30px;
      min-height: 30px;
      line-height: 1px;
      background-color: #273480;
      color: #fff;
      width: 100px;
    }

    &.cancel {
      background-color: #eb5757;
    }
  }
}
</style>
