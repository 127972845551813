import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    allTestsConfig: null,
    updateTestsConfigStatus: null,
  };
};

const state = initialState();

const getters = {
  getAllTestsConfig: (state) => state.allTestsConfig,
  getTestConfigUpdateStatus: (state) => state.updateTestsConfigStatus,
};

const mutations = {
  setAllTestsConfig(state, data) {
    state.allTestsConfig = data;
  },
  setConfigUpdateStatus(state, data) {
    state.updateTestsConfigStatus = data;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  async fetchAllTestsConfig({ commit, dispatch }, params) {
    try {
      commit("setAllTestsConfig", null);
      const response = await axios.get(
        `/test-config/${params.department}/${params.category}/${params.sub_category}`
      );

      commit("setAllTestsConfig", response.data.data);
    } catch (err) {
      commit("setAllTestsConfig", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async updateAllTestsConfig({ commit, dispatch }, params) {
    try {
      let testId = params.test_id;
      delete params.test_id;
      commit("setConfigUpdateStatus", null);
      const response = await axios.put(`/test-config/${testId}`, params);

      commit("setConfigUpdateStatus", response.data);
    } catch (err) {
      commit("setConfigUpdateStatus", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  reset: function ({ commit }) {
    commit("reset", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
