<template>
  <section
    id="auth-page"
    class="auth-page"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <div class="auth-card">
      <div class="auth-card-title">
        <h3 class="title">Update Password</h3>
        <p class="description">
          Enter a new password below to <br />
          update your password
        </p>
      </div>
      <div class="auth-card-body">
        <el-form
          :model="updatePasswordForm"
          label-width="120px"
          label-position="top"
        >
          <div class="form-group">
            <el-form-item>
              <label for="">Current Password</label>
              <el-input
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                v-model="updatePasswordForm.current_password"
                placeholder="Current Password"
                class="item-group"
              >
                <template>
                  <el-button
                    @click="isCurrentPasswordVisible = false"
                    v-if="isCurrentPasswordVisible"
                    circle
                  >
                    <img src="@/assets/images/visibility.png" />
                  </el-button>
                  <el-button
                    @click="isCurrentPasswordVisible = true"
                    v-else
                    circle
                  >
                    <el-icon><View /></el-icon>
                  </el-button>
                </template>
              </el-input>
              <FormError errorName="current_password"></FormError>
            </el-form-item>
            <el-form-item>
              <label for="">New Password</label>
              <el-input
                :type="isPasswordVisible ? 'text' : 'password'"
                v-model="updatePasswordForm.new_password"
                placeholder="New Password"
                class="item-group"
              >
                <template>
                  <el-button
                    @click="isPasswordVisible = false"
                    v-if="isPasswordVisible"
                    circle
                  >
                    <img src="@/assets/images/visibility.png" />
                  </el-button>
                  <el-button @click="isPasswordVisible = true" v-else circle>
                    <el-icon><View /></el-icon>
                  </el-button>
                </template>
              </el-input>
              <FormError errorName="new_password"></FormError>
            </el-form-item>
          </div>
          <ul class="submit-action-group">
            <li>
              <el-button
                class="cancel-button"
                type="danger"
                @click="goToDashboard"
                >Cancel</el-button
              >
            </li>
            <li>
              <el-button
                @click="updatPassword"
                :disable="loading"
                class="submit-button"
                type="primary"
                >Submit</el-button
              >
              <FormError errorName="critical_error"></FormError>
            </li>
          </ul>
        </el-form>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";

export default {
  layout: "admin",
  middleware: "auth",
  components: {
    FormError,
  },

  data() {
    return {
      loading: false,
      updatePasswordForm: {
        current_password: "",
        new_password: "",
      },

      isCurrentPasswordVisible: false,
      isPasswordVisible: false,
      loadingText: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getPasswordUpdateStatus"]),
    ...mapGetters("errors", ["getErrors"]),
  },
  async mounted() {},
  methods: {
    async updatPassword() {
      try {
        this.loading = true;
        this.loadingText = "Updating Password...";
        await this.$store.dispatch(
          "auth/updatePassword",
          this.updatePasswordForm
        );
        if (this.getPasswordUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Password Updated Successfully",
          });
          await this.$store.dispatch("globalReset");
          this.$router.push({ name: "signin" });
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error in Update Password",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    goToDashboard() {
      this.$router.push({
        name: "PatientReports",
        query: this.$route.query,
      });
    },
  },
  head: {
    title: "Update Password",
  },
};
</script>
<style lang="scss" scoped>
@import "/src/assets/scss/components/authentication.scss";
#auth-page {
  min-height: 70vh !important;
}
</style>
