<template>
  <section id="all-patient-result-files">
    <div class="navigation-navbar">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          :to="{ path: '/all-result-files', query: $route.query }"
          >All Result files</el-breadcrumb-item
        >

        <el-breadcrumb-item v-if="resultFileData">
          {{ resultFileData ? resultFileData.name : "" }}</el-breadcrumb-item
        >
      </el-breadcrumb>
      <div>
        <el-select
          v-model="selectedStatus"
          class="m-2"
          placeholder="Select Status"
          @change="loadData(1)"
          :clearable="true"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
    </div>

    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="vue-table">
        <el-table
          :data="resultFilesData"
          style="width: 100%"
          height="calc(100vh - 250px)"
          class="table-list display-web"
          @sort-change="changeSort"
        >
          <el-table-column
            prop="barcode"
            label="Barcode"
            min-width="250"
            sortable="custom"
          >
            <template #default="scope">
              <span class="date" v-if="scope.row.barcode">
                {{ scope.row.barcode }}
                <span
                  v-if="
                    scope.row.patient_demographics &&
                    scope.row.patient_demographics.patient_name
                  "
                  class="patient-name-highlight"
                  ><br />{{ scope.row.patient_demographics.patient_name }}</span
                >
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="report_type"
            label="Sub Category"
            min-width="250"
            sortable="custom"
            v-if="resultFileData && resultFileData.test_category == 'BIO'"
          >
            <template #default="scope">
              <div class="column-status">
                {{ scope.row.lis_test_name ? scope.row.lis_test_name : "--" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="report_status"
            label="Status"
            min-width="100"
            sortable="custom"
          >
            <template #default="scope">
              <div class="column-status">
                <el-tag :type="getStatusTagType(scope.row.report_status)">
                  {{ scope.row.report_status ? scope.row.report_status : "--" }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="message"
            label="Message"
            min-width="150"
            sortable="custom"
          >
            <template #default="scope">
              {{ scope.row.message ? scope.row.message : "--" }}
            </template>
          </el-table-column>
          <el-table-column
            label="Ready To Approve"
            width="150"
            v-if="isAbleToReadyToApprove"
          >
            <template #default="{ row }">
              <el-dropdown
                @command="(command) => readyToApproveReport(row, command)"
              >
                <div class="column-status">
                  <el-tag
                    :type="row.ready_to_approve ? 'success' : 'warning'"
                    >{{ row.ready_to_approve ? "Yes" : "No" }}</el-tag
                  >
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :command="true"
                      :disabled="row.ready_to_approve"
                    >
                      Yes
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="false"
                      :disabled="!row.ready_to_approve"
                    >
                      No
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column
            label="Descrete Reporting"
            width="150"
            v-if="
              resultFileData && resultFileData.test_category == 'DOUBLE_MARKERS'
            "
          >
            <template #default="{ row }">
              <ul class="table-action-buttons default-table-action-items">
                <li
                  v-if="
                    row.test_category == 'DOUBLE_MARKERS' &&
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    row.descrete_values_path
                  "
                >
                  <el-upload
                    action
                    :on-change="handleDescreteUpload"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button
                      class="btn values-button type-warning"
                      type="success"
                      @click="attachDescreteValuesToResultFile(row)"
                    >
                      Replace</el-button
                    >
                  </el-upload>
                </li>
                <li
                  v-if="
                    row.test_category == 'DOUBLE_MARKERS' &&
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    !row.descrete_values_path
                  "
                >
                  <el-upload
                    action
                    :on-change="handleDescreteUpload"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button
                      class="btn values-button type-success"
                      type="success"
                      @click="attachDescreteValuesToResultFile(row)"
                    >
                      Attach</el-button
                    >
                  </el-upload>
                </li>
                <li
                  v-if="
                    row.test_category == 'DOUBLE_MARKERS' &&
                    row.descrete_values_path &&
                    !row.merged_report_path &&
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE')
                  "
                >
                  <el-button
                    class="btn values-button type-danger"
                    type="success"
                    @click="removeReport(row)"
                  >
                    Remove</el-button
                  >
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="350">
            <template #default="{ row }">
              <ul class="default-table-action-items">
                <li
                  v-if="
                    row.report_status == 'COMPLETED' ||
                    row.report_status == 'ACTIVE'
                  "
                >
                  <el-button class="each-icon view-icon" @click="onView(row)">
                    <img
                      src="@/assets/images/icons/eye.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_category == 'TMS'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    (row.test_sub_category == 'TRIPLE_MARKER' ||
                      row.test_sub_category == 'TRIPLE_MARKERS')
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editTripleMarkerReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_sub_category == 'TWIN_TRIPLE_MARKER'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editTwinTripleMarkersReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_sub_category == 'QUADRUPLE_MARKER'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editQuadrableMarkersReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_sub_category == 'TWIN_QUADRUPLE_MARKER'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editTwinQuadrableMarkersReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_sub_category == 'DOUBLE_MARKER'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editDoubleMarkersReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>

                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_sub_category == 'PLGF_DOUBLE_MARKER'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editPlgfDoubleMarkersReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_sub_category == 'TWIN_DOUBLE_MARKER' &&
                    row.test_category == 'DOUBLE_MARKERS'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editTwinDoubleMarkersReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_category == 'URINE_ORGANIC_ACID'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editUrineOrganicAcidReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_category == 'BIO'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editBioReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_category == 'PENTA_MARKERS'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editPentaMarkersReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="row.test_category == 'KARYOTYPING'"
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editKaryotypingReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>

                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_category == 'GUANIDINO_ACETATE'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editPlasmaReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE') &&
                    isAbleToAccessEdit &&
                    row.test_category == 'URINE_GUANIDINO_ACETATE'
                  "
                >
                  <el-button
                    class="each-icon edit-icon"
                    @click="editUrineGuanidinoAcetateReport(row)"
                  >
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    !row.is_finalized &&
                    isAbleToFinalize &&
                    row.report_status == 'COMPLETED'
                  "
                >
                  <el-button
                    class="each-icon finalize"
                    @click="finalizeReport(row)"
                  >
                    Finalize
                  </el-button>
                </li>
                <li class="d-flex align-center" v-if="row.is_finalized == true">
                  <el-button class="btn finalized" disabled
                    >Finalized</el-button
                  >
                </li>
                <li v-if="row.test_category == 'TMS'">
                  <el-button
                    class="each-icon values-button"
                    type="warning"
                    @click="onViewTestResultValues(row)"
                  >
                    Values
                  </el-button>
                </li>
                <li v-if="row.report_status == 'ACTIVE'">
                  <el-button
                    class="each-icon completed-button"
                    type="success"
                    @click="onMakeComplete(row)"
                  >
                    Mark as Complete
                  </el-button>
                </li>
                <li v-if="row.report_status == 'COMPLETED'">
                  <el-dropdown
                    @command="
                      (command) => downloadProvisionalReport(row, command)
                    "
                  >
                    <el-button class="view-icon">
                      <!-- @/assets/images/icons/download_before.svg -->
                      <img
                        src="@/assets/images/icons/download.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="includeHeader"
                          >With Header And Footer</el-dropdown-item
                        >
                        <el-dropdown-item command="excludeHeader"
                          >Without Header And Footer</el-dropdown-item
                        >
                        <el-dropdown-item command="includeHeaderNonColor"
                          >Black and White With Header And
                          Footer</el-dropdown-item
                        >
                        <el-dropdown-item command="excludeHeaderNonColor"
                          >Black and White Without Header And
                          Footer</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </li>
                <li v-if="row.regenerate_graph_status">
                  <el-button
                    class="btn values-button type-success"
                    type="success"
                    @click="regenerateGraph(row)"
                  >
                    Regenerate
                  </el-button>
                </li>
                <li
                  v-if="
                    hasPatientControl &&
                    row.test_category == 'URINE_ORGANIC_ACID' &&
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE')
                  "
                >
                  <el-upload
                    action
                    :on-change="handleUpload"
                    :show-file-list="false"
                    :auto-upload="false"
                    accept="image/png, image/jpeg"
                  >
                    <el-button
                      class="btn values-button type-warning"
                      type="success"
                      @click="attachGraphdataToResultFile(row)"
                    >
                      Replace</el-button
                    >
                  </el-upload>
                </li>
                <li
                  v-if="
                    hasPatientControl &&
                    row.test_category == 'URINE_ORGANIC_ACID' &&
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE')
                  "
                >
                  <el-button
                    class="btn values-button type-danger"
                    type="success"
                    @click="removeGraph(row)"
                  >
                    Remove</el-button
                  >
                </li>
                <li
                  v-else-if="
                    row.test_category == 'URINE_ORGANIC_ACID' &&
                    (row.report_status == 'COMPLETED' ||
                      row.report_status == 'ACTIVE')
                  "
                >
                  <el-upload
                    action
                    :on-change="handleUpload"
                    :show-file-list="false"
                    :auto-upload="false"
                    accept="image/png, image/jpeg"
                  >
                    <el-button
                      class="btn values-button type-success"
                      type="success"
                      @click="attachGraphdataToResultFile(row)"
                    >
                      Attach Image</el-button
                    >
                  </el-upload>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div
        class="vue-pagination"
        v-if="resultFilesData && resultFilesData.length"
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="loadData"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>

    <div class="result-values-dialog">
      <el-dialog
        v-model="showReportTestResultValuesModal"
        title="Result Values"
        v-loading="loading"
        width="60%"
        :destroy-on-close="true"
      >
        <PatientReportResultValues
          v-if="selectedReport && showReportTestResultValuesModal"
          :patientReport="selectedReport"
        ></PatientReportResultValues>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { reportStatus } from "@/constants/formConstants";
import moment from "moment";
// import { Search } from "@element-plus/icons-vue";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";

import PatientReportResultValues from "@/components/TMSReports/PatientReportResultValues";
export default {
  middleware: "auth",
  components: {
    PatientReportResultValues,
  },
  mixins: [userRolesPermissionsMixin],
  computed: {
    getStatusTagType() {
      return (status) => {
        if (status && this.tagByStatus && this.tagByStatus[status]) {
          return this.tagByStatus[status];
        }
        return;
      };
    },
    ...mapGetters("resultFiles", [
      "getAllPatientResultFiles",
      "getFinalizeReportStatus",
      "getResultFileReportDownloadStatus",
      "getPatientResultUpdateStatus",
      "getRegenerateGraphStatus",
      "getReadyToApproveReportStatus",
      "getImageUploadData",
      "getResultsAttachStatus",
      "getGraphAttachStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getAllUsers"]),

    resultFileId() {
      return this.$route.params.result_file_id;
    },
    isAbleToFinalize() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "RADIOLOGIST", "DOCTOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return true;
      }
      return false;
    },
    isAbleToReadyToApprove() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "DATA_OPERATOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      statusOptions: [
        "ACTIVE",
        "TO-START",
        "PROCESSING",
        "FAILED",
        "COMPLETED",
      ],
      selectedStatus: "",

      selectedReport: {},
      showReportTestResultValuesModal: false,
      reportStatus: reportStatus,
      dialogVisible: false,
      headerFooter: "",
      userRoleDept: [],
      resultFilesData: [],
      resultFileData: null,
      page: 1,
      pageSize: 10,
      total: 0,
      orderBy: "",
      orderType: "",

      sortCodeMap: {
        ascending: "asc",
        descending: "desc",
      },
      searchString: "",
      searchStatus: "",

      tagByStatus: {
        COMPLETED: "success",
        FAILED: "danger",
        "TO-START": "primary",
        DRAFT: "info",
        PENDING: "warning",
      },

      downloadParams: {
        includeHeader: {
          include_header_footer: true,
          is_color_variant: true,
        },
        excludeHeader: {
          include_header_footer: false,
          is_color_variant: true,
        },
        includeHeaderNonColor: {
          include_header_footer: true,
          is_color_variant: false,
        },
        excludeHeaderNonColor: {
          include_header_footer: false,
          is_color_variant: false,
        },
      },
      loading: false,
      loadingText: "",
      imageUpload: false,
      graphPatient: "",
      patientRowId: "",
      hasPatientControl: false,
      helthyGraph: [],
    };
  },
  async mounted() {
    await this.setQueryToData();

    await this.fetchAllPatientResultFiles();
    await this.$store.dispatch("errors/clear");
  },
  methods: {
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm a");
    },

    goToResultFiles() {
      this.$router.push("/all-result-files");
    },
    async fetchAllPatientResultFiles() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Patient Result Files...";
        let params = this.prepareParams();
        if (
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.user_type == "DOCTOR"
        ) {
          params.ready_to_approve = true;
        }
        await this.$store.dispatch(
          "resultFiles/fetchAllPatientResultFiles",
          params
        );
        this.setTableData();
        this.setQueryData(params);
        this.checkPatientImage();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async readyToApproveReport(patientReport, status) {
      try {
        this.loading = true;
        this.loadingText = "Updating result file approve status...";
        let params = {
          patient_id: patientReport._id,
          result_file_id: this.resultFileId,
          params: { ready_to_approve: status },
        };

        await this.$store.dispatch("resultFiles/readyToApproveReport", params);
        if (this.getReadyToApproveReportStatus) {
          this.$notify.success({
            title: "Success",
            message: "Result file approve status updated successfully",
          });
          await this.fetchAllPatientResultFiles();
        } else {
          this.$notify.error({
            title: "Error",
            message:
              this.getError || `Failed to update result file approve status`,
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    setTableData() {
      const tableData = JSON.parse(
        JSON.stringify(this.getAllPatientResultFiles)
      );
      this.resultFilesData =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.resultFileData =
        tableData && tableData.result_file_data
          ? tableData.result_file_data
          : null;
      this.page = tableData.page ? parseInt(tableData.page) : 1;
      this.total = tableData.total || 0;
      this.pageSize = tableData.limit || 10;
    },
    checkPatientImage() {
      this.resultFilesData.forEach((item) => {
        item.test_graphs_data.forEach((graph) => {
          if (graph.graph_type === "PATIENT_CONTROL") {
            this.hasPatientControl = true;
          } else {
            this.hasPatientControl = false;
          }
        });
      });
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.resultFileId) {
        params.result_file_id = this.resultFileId;
      }
      if (this.selectedStatus) {
        params.report_status = this.selectedStatus;
      }
      return params;
    },

    setQueryData(data) {
      let params = {
        selectedResultPage: data.page || 1,
        selectedResultPageSize: data.limit || 10,
        selectedResultString: data.search_string || undefined,
        selectedResultStatus: data.report_status || undefined,
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...this.$route.query, ...params },
        })
        .catch(() => {});
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedResultPage
          ? parseInt(this.$route.query.selectedResultPage)
          : 1;
        this.pageSize = this.$route.query.selectedResultPageSize
          ? parseInt(this.$route.query.selectedResultPageSize)
          : 10;
        this.searchString = this.$route.query.selectedResultString || "";
        this.searchStatus = this.$route.query.selectedResultStatus || "";
      }
    },
    changeSort({ prop, order }) {
      this.orderBy = prop;
      this.orderType = this.sortCodeMap[order];
      this.page = 1;
      this.fetchAllPatientResultFiles();
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchAllPatientResultFiles();
    },
    async loadData(page) {
      this.page = page;

      await this.fetchAllPatientResultFiles();
    },

    async onMakeComplete(patient) {
      try {
        this.loading = true;
        this.loadingText = "Updating Patient Result Status...";
        let params = {
          patientId: patient._id,
          resultFileId: this.resultFileId,
          params: { status: "COMPLETED" },
        };
        await this.$store.dispatch(
          "resultFiles/updatePatientReportStatus",
          params
        );
        if (this.getPatientResultUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Status Updated Successfully",
          });
          await this.fetchAllPatientResultFiles();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    onView(patient) {
      // this.dialogVisible = true;
      // this.loading = true;
      // let routerData =
      this.$router.push({
        name: "PatientResultFilePreview",
        params: {
          patient_id: patient._id,
          result_file_id: this.resultFileId,
        },
        query: { ...this.$route.query },
      });
      // window.open(routerData.href, "_blank");
      // this.loading = false;
    },
    goToUploadResultFile() {
      this.$router.push({ name: "ResultFilesUpload" });
    },
    async editReport(patient) {
      try {
        this.$router.push({
          name: "ResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editDoubleMarkersReport(patient) {
      try {
        this.$router.push({
          name: "DoubleMarkersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },

    async editPlgfDoubleMarkersReport(patient) {
      try {
        this.$router.push({
          name: "PlgfDoubleMarkersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },

    async editTwinDoubleMarkersReport(patient) {
      try {
        this.$router.push({
          name: "TwinDoubleMarkersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editQuadrableMarkersReport(patient) {
      try {
        this.$router.push({
          name: "QuadrableMarkersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editTwinQuadrableMarkersReport(patient) {
      try {
        this.$router.push({
          name: "TwinQuadrableMarkersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editUrineOrganicAcidReport(patient) {
      try {
        this.$router.push({
          name: "UrineOrganicAcidResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editTripleMarkerReport(patient) {
      try {
        this.$router.push({
          name: "TripleMarkersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editTwinTripleMarkersReport(patient) {
      try {
        this.$router.push({
          name: "TwinTripleMarkersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editBioReport(patient) {
      try {
        this.$router.push({
          name: "BioResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editPentaMarkersReport(patient) {
      try {
        this.$router.push({
          name: "PentaMakersResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editPlasmaReport(patient) {
      try {
        this.$router.push({
          name: "PlasmaResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editKaryotypingReport(patient) {
      try {
        this.$router.push({
          name: "KaryotypingResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },

    async editUrineGuanidinoAcetateReport(patient) {
      try {
        this.$router.push({
          name: "UrineGuanidinoResultFileEdit",
          params: {
            patient_id: patient._id,
            result_file_id: this.resultFileId,
          },
          query: { ...this.$route.query, fromRoute: this.$route.name },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async finalizeReport(data) {
      try {
        this.loading = true;
        let params = {
          patient_id: data._id,
          result_file_id: this.resultFileId,
        };
        await this.$store.dispatch("resultFiles/finalizeReport", params);
        if (this.getFinalizeReportStatus) {
          successNotification(this.getFinalizeReportStatus.message);
          await this.fetchAllPatientResultFiles(this.page);
        } else {
          errorNotification(this.getError || "Error while finalizing");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async downloadProvisionalReport(patient, status) {
      try {
        let params = {
          patientId: patient._id,
          resultFileId: patient.test_result_file_id,
        };

        if (this.downloadParams[status]) {
          params.params = { ...this.downloadParams[status] };
        }
        // params.include_header_footer = status;
        this.loading = true;
        this.loadingText = "Downloading Report...";
        await this.$store.dispatch(
          "resultFiles/downloadPatientResultFileReport",
          params
        );

        if (this.getResultFileReportDownloadStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async regenerateGraph(patient) {
      try {
        this.loading = true;
        let params = {
          patient_id: patient._id,
          result_file_id: this.resultFileId,
        };
        await this.$store.dispatch("resultFiles/regenerateGraph", params);
        if (this.getRegenerateGraphStatus) {
          successNotification(this.getRegenerateGraphStatus.message);
          await this.fetchAllPatientResultFiles(this.page);
        } else {
          errorNotification(this.getError || "Error while regenerating graph");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    onViewTestResultValues(report) {
      this.selectedReport = { ...report };
      this.showReportTestResultValuesModal = true;
    },
    async removeReport(patient) {
      this.patientRowId = patient._id;
      let reportPath = null;
      this.attachDescreteReportToResultFile(reportPath);
    },
    async mergeReport(patient) {
      try {
        this.loading = true;
        let params = {
          descrete_values_path: patient.descrete_values_path,
          patient_id: patient._id,
          result_file_id: this.resultFileId,
        };
        await this.$store.dispatch("resultFiles/uploadDescreateValues", params);
        this.loading = false;
        if (this.getResultsAttachStatus) {
          successNotification("Result graphs updated successfully");
          await this.fetchAllPatientResultFiles();
        } else {
          errorNotification(this.getError || "Error while updating graph");
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    attachDescreteValuesToResultFile(patient) {
      this.patientRowId = patient._id;
    },
    async handleDescreteUpload(file) {
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };

      await this.$store.dispatch("resultFiles/uploadImage", { params, file });
      if (this.getImageUploadData) {
        let reportPath = this.getImageUploadData.path;
        this.attachDescreteReportToResultFile(reportPath);
      } else {
        errorNotification(this.getError || "Error while regenerating graph");
      }
      console.log(file);
    },
    async attachDescreteReportToResultFile(reportPath) {
      this.loading = true;
      this.loadingText = "Loading...";
      let params = {
        descrete_values_path: reportPath,
      };
      let patientId = this.patientRowId;
      let resultFileId = this.resultFileId;
      await this.$store.dispatch("resultFiles/updatePatientResults", {
        params,
        patientId,
        resultFileId,
      });
      if (this.getPatientResultUpdateStatus) {
        successNotification("Patient Report Status Updated Successfully");
        await this.fetchAllPatientResultFiles();
      } else {
        errorNotification(this.getError || "Error while updating status");
      }
      this.loading = false;
    },
    async handleUpload(file) {
      this.loading = true;
      this.loadingText = "Uploading image...";
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };

      await this.$store.dispatch("resultFiles/uploadImage", { params, file });
      if (this.getImageUploadData) {
        let imagePath = this.getImageUploadData.path;
        this.attachImageToResultFile(imagePath);
      } else {
        errorNotification(this.getError || "Error while regenerating graph");
      }
      this.loading = false;
    },
    attachGraphdataToResultFile(patient) {
      const searchString = "HEALTHY_CONTROL";
      let checkGraph = [];
      if (patient && patient.test_graphs_data) {
        checkGraph = patient.test_graphs_data;
      }
      const foundObject = checkGraph.find(
        (obj) => obj.graph_type === searchString
      );
      if (foundObject) {
        this.helthyGraph.push(foundObject);
      }

      this.graphPatient = patient._id;
    },
    async attachImageToResultFile(image) {
      let patientImage = { graph_type: "PATIENT_CONTROL", graph_path: image };
      this.helthyGraph.push(patientImage);
      // this.helthyGraph.push(patientImage);
      let graph = {
        test_graphs_data: this.helthyGraph,
      };
      let params = {
        patient_id: this.graphPatient,
        result_file_id: this.resultFileId,
        params: graph,
      };
      await this.$store.dispatch("resultFiles/attachGraphdata", params);
      if (this.getGraphAttachStatus) {
        successNotification("Result graphs updated successfully");
        await this.fetchAllPatientResultFiles();
        await this.checkPatientImage();
      } else {
        errorNotification(this.getError || "Error while updating graph");
      }
    },
    async removeGraph(patient) {
      this.loading = true;
      this.loadingText = "Loading...";

      const filteredData = patient.test_graphs_data.filter(
        (obj) => obj.graph_type !== "PATIENT_CONTROL"
      );
      // let patientImage = { graph_type: "PATIENT_CONTROL", graph_path: image };
      // test_graphs_data_prepare.push(patientImage);
      let graph = {
        test_graphs_data: filteredData,
      };
      let params = {
        patient_id: patient._id,
        result_file_id: this.resultFileId,
        params: graph,
      };
      await this.$store.dispatch("resultFiles/attachGraphdata", params);
      if (this.getGraphAttachStatus) {
        successNotification("Result graphs removed successfully");
        await this.fetchAllPatientResultFiles();
        await this.checkPatientImage();
      } else {
        errorNotification(this.getError || "Error while removing graph");
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#all-patient-result-files {
  .navigation-navbar {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-breadcrumb__item {
      font-size: 13px;
    }

    .el-breadcrumb__inner {
      color: blue;
      text-transform: uppercase;
      letter-spacing: 0;
    }
    .is-link {
      color: #333;
      font-weight: 500;
    }
  }
  .vue-table table tbody td {
    padding: 6px 0 !important;
  }
  .default-table-action-items {
    .el-button {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .finalize {
      background: #186695;
      border-color: #186695;
      color: #fff;
      min-width: 70px;
    }
    .finalized {
      background: #109616;
      border-color: #109616;
      color: #fff;
      min-width: 70px;
    }
    .values-button {
      background: #587185;
      border-color: #587185;
      color: #fff;
      width: 100%;
      &.type-warning {
        background: #ff9900;
        border-color: #ff9900;
      }
      &.type-danger {
        background: #d22528;
        border-color: #d22528;
      }
      &.type-success {
        background: green;
        border-color: green;
        min-width: 70px;
      }
    }
  }
  .patient-name-highlight {
    color: #eb5757;
  }
}
.result-values-dialog {
  .el-dialog__header {
    background: #f2f2f2;
  }
  .el-dialog__close {
    font-size: 22px;
    color: #333 !important;
    font-weight: 500;
  }
  .el-dialog__body {
    height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 10px;
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #333333;
    }
  }
}
</style>
