<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    class="patient-report"
  >
    <div v-if="getPatientReportURL && getPatientReportURL.data">
      <div class="inner-header-details">
        <div class="heading" v-if="getPatientDetails && getPatientDetails.data">
          <div class="accession-id">
            <h3>{{ getPatientDetails.data.report_id.visit_id || "" }}</h3>
          </div>
        </div>
        <div class="download-strip">
          <el-button @click="reportDownload" class="download-report"
            >Download</el-button
          >
        </div>
      </div>

      <VuePdf
        v-for="i in numPages"
        :key="i"
        :src="getPatientReportURL.data"
        :page="i"
        style="display: block; width: 80%; margin: 0 10% 20px"
      ></VuePdf>
    </div>
    <div v-else-if="!loading">
      <div class="report-error-display">
        <h3 class="color-red">{{ getError || "Error in loading data" }}</h3>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import { successMessage, errorMessage } from "@/helpers/messages";

// import pdf from "vue3-pdf";
import { VuePdf, createLoadingTask } from "vue3-pdfjs/esm";
export default {
  components: {
    VuePdf,
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading...",
      showForm: true,

      otp: null,
      reportLab: "",
      numPages: 0,
      formData: { date_of_birth: "" },

      verifyDOB: false,
      countDown: 0,
    };
  },
  mounted() {
    this.fetchReportInfo();
    this.fetchReportSecureURL();
  },
  computed: {
    ...mapGetters("patientReportResult", [
      "getPatientDetails",
      "getPatientReportURL",
    ]),
    ...mapGetters("errors", ["getError", "getErrors"]),
  },

  methods: {
    async fetchReportInfo() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "patientReportResult/fetchPatientReport",
          this.$route.params.report_id
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async fetchReportSecureURL() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "patientReportResult/fetchPatientReportURL",
          this.$route.params.report_id
        );
        if (this.getPatientReportURL && this.getPatientReportURL.data) {
          await this.fetchPageCountofReport();
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    reportDownload() {
      try {
        const link = document.createElement("a");
        link.href = this.getPatientReportURL.data;

        link.setAttribute("download", ""); //or any other extension
        document.body.appendChild(link);
        link.click();
        successMessage("Report downloaded successfully");
      } catch (err) {
        errorMessage("Error in report download");
      }
    },
    async fetchPageCountofReport() {
      const pdfUrl = await createLoadingTask(this.getPatientReportURL.data);
      await pdfUrl.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>

<style lang="scss">
.inner-header-details {
  display: flex;
  justify-content: space-between;
  padding: 20px 10% 15px;
}
.patient-report {
  .otp-card-group {
    max-width: 450px;
    margin: 0 auto;
  }
  .otp-card {
    background: #fff;
    padding: 30px;
    margin: 0 15px;
    border-radius: 10px;
    text-align: center;
    // margin: 0 15px;
    @media (max-width: 510px) {
      max-width: 97% !important;
    }
    .opt-input-group {
      justify-content: center;
      > div {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
      .otp-input {
        height: 35px;
        max-width: 45px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        @media (max-width: 300px) {
          max-width: 35px;
        }
        @media (max-width: 250px) {
          max-width: 30px;
        }
      }
    }
    .heading {
      margin-bottom: 25px;
      .accession-id {
        margin-bottom: 5px;
      }
    }
    .verify-button {
      margin-top: 2em;
      padding: 10px 25px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .download-strip {
    background: #f2f2f2;
    // padding: 10px 0;
    // display: flex;
    // justify-content: flex-end;
    // margin: 0 10% 10px;

    // margin-bottom: 10px;
  }
  .report-pdf {
    display: block;
    width: 80%;
    margin: 0 10% 20px;
    @media (max-width: 500px) {
      width: 90%;
      margin: 0 5% 20px;
    }
  }
  .download-report {
    color: #fff;
    background-color: #1a78da;
    border-color: #409eff;
    font-weight: 600;
    padding: 7px 10px;
    font-size: 13px;
    @media (max-width: 500px) {
      font-size: 11px;
    }
  }
}
.report-error-display {
  text-align: center;
  margin-top: 45vh;
  .color-red {
    color: red;
  }
}
</style>
