import axios from "@/config/axios"

// initial state
const initialState = () => {
  return {
    user: null,
    authenticationStatus: false,
    authenticationDetails: {},
    signupCompleted: null,
    passwordUpdateStatus: false,
    adminSettingsUpdateStatus: false,
    profleDetails: null,
    profileUpdateStatus: false,
    allUsers: null,
    adminSettings: null,
    forgotPasswordUsername: null,
    otpSentStatus: false,
    passwordResetStatus: false,
    otpVerificationStatus: false,
    usersList: null,
    addNewUser: false,
    userById: null,
    updateUser: false,
    updateUserStatus: false,
    resetUserPassword: false,
    allowedLabs: null,
  }
}

const state = initialState()

const getters = {
  getAuthenticatedUser: (state) => state.user,
  isSignupCompleted: (state) => state.signupCompleted,
  getAuthenticationStatus: (state) => state.authenticationStatus,
  getAuthenticationDetails: (state) => state.authenticationDetails,
  getPasswordUpdateStatus: (state) => state.passwordUpdateStatus,
  getAdminSettingsUpdateStatus: (state) => state.adminSettingsUpdateStatus,
  getProfileDetails: (state) => state.profleDetails,
  getProfileUpdateStatus: (state) => state.profileUpdateStatus,
  getAllUsers: (state) => state.allUsers,
  getAdminSettings: (state) => state.adminSettings,
  getForgotPasswordUsername: (state) => state.forgotPasswordUsername,
  getOtpSentStatus: (state) => state.otpSentStatus,
  getPasswordResetStatus: (state) => state.passwordResetStatus,
  getOtpVerificationStatus: (state) => state.otpVerificationStatus,
  getUsersList: (state) => state.usersList,
  getAddNewUser: (state) => state.addNewUser,
  getUserById: (state) => state.userById,
  getUpdateUser: (state) => state.updateUser,
  getUpdateUserStatus: (state) => state.updateUserStatus,
  getResetUserPasswordStatus: (state) => state.resetUserPassword,
  getAllowedLabs: (state) => state.allowedLabs,
}

const mutations = {
  setSignupStatus: (state, status) => {
    state.signupCompleted = status
  },
  setAuthenticatedUser: (state, user) => {
    state.user = user
  },
  setAuthenticatedStatus: (state, status) => {
    state.authenticationStatus = status
  },
  setAuthenticationDetails: (state, tokens) => {
    state.authenticationDetails = tokens
  },
  setGeneralErrorMessage: (state, message) => {
    state.generalErrorMessage = message
  },
  setPasswordUpdateStatus: (state, message) => {
    state.passwordUpdateStatus = message
  },
  setAdminSettingsUpdateStatus: (state, message) => {
    state.adminSettingsUpdateStatus = message
  },

  setProfileData(state, data) {
    state.profleDetails = data
  },
  setProfileUpdateStatus(state, status) {
    state.profileUpdateStatus = status
  },
  setAllUsers(state, data) {
    state.allUsers = data
  },
  setAdminSettings(state, data) {
    state.adminSettings = data
  },
  setForgotPasswordUpdateStatus: (state, message) => {
    state.passwordResetStatus = message
  },
  setUsername(state, data) {
    state.forgotPasswordUsername = data
  },
  setOtpSentStatus(state, status) {
    state.otpSentStatus = status
  },
  setOtpVerificationStatus(state, status) {
    state.otpVerificationStatus = status
  },
  setUsersList(state, data) {
    state.usersList = data
  },
  setAddNewUser(state, data) {
    state.addNewUser = data
  },
  setUserById(state, data) {
    state.userById = data
  },
  setUpdateUser(state, data) {
    state.updateUser = data
  },
  setUpdateUserStatus(state, data) {
    state.updateUserStatus = data
  },
  setResetUserPassword(state, data) {
    state.resetUserPassword = data
  },
  setAllowedLabs(state, data) {
    state.allowedLabs = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  async signin({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true })

      commit("setAuthenticatedStatus", false)
      const response = await axios.post("/signin", params)
      let userDetails = response.data.user_details
      let tokens = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      }
      commit("setAuthenticatedUser", userDetails)
      commit("setProfileData", userDetails)

      commit("setAuthenticationDetails", tokens)
      commit("setAuthenticatedStatus", true)
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, { root: true })
    }
  },
  async updatePassword({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setPasswordUpdateStatus", false)
      await axios.patch("/password/update", params)
      commit("setPasswordUpdateStatus", true)
    } catch (err) {
      commit("setPasswordUpdateStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async updateAdminSettings({ commit, dispatch }, params) {
    try {
      let settingsId = params.settingsId
      delete params.settingsId
      commit("errors/clear", null, { root: true })
      commit("setAdminSettingsUpdateStatus", false)
      await axios.patch(`/settings/${settingsId}`, params)
      commit("setAdminSettingsUpdateStatus", true)
    } catch (err) {
      commit("setAdminSettingsUpdateStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async getProfile({ commit }) {
    try {
      const response = await axios.get("/profile")
      commit("setProfileData", response.data.data)
    } catch (err) {
      console.log(err)
    }
  },
  async updateProfile({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setProfileUpdateStatus", false)
      await axios.patch("/profile", params)
      commit("setProfileUpdateStatus", true)
    } catch (err) {
      commit("setProfileUpdateStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async fetchAllUsers({ commit }, params) {
    try {
      commit("setAllUsers", null)
      commit("setUsersList", null)
      const response = await axios.get("/users", { params })
      commit("setAllUsers", response.data.data)
      commit("setUsersList", response.data)
    } catch (err) {
      console.log(err)
    }
  },
  async addUserAccount({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setAddNewUser", false)
      await axios.post("/users", params)
      commit("setAddNewUser", true)
    } catch (err) {
      commit("setAddNewUser", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async fetchUserById({ commit }, params) {
    try {
      commit("setUserById", null)
      const response = await axios.get("/users/" + params.user_id, { params })
      commit("setUserById", response.data.data)
    } catch (err) {
      console.log(err)
    }
  },
  async updateUserAccount({ commit, dispatch }, params) {
    try {
      let userId = params.user_id
      delete params.userId
      commit("errors/clear", null, { root: true })
      commit("setUpdateUser", false)
      await axios.put("/users/" + userId, params)
      commit("setUpdateUser", true)
    } catch (err) {
      commit("setUpdateUser", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async updateUserStatus({ commit, dispatch }, params) {
    try {
      let userId = params.user_id
      delete params.userId
      commit("errors/clear", null, { root: true })
      commit("setUpdateUserStatus", false)
      await axios.put("/users/" + userId + "/status", params)
      commit("setUpdateUserStatus", true)
    } catch (err) {
      commit("setUpdateUserStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async fetchAdminSettings({ commit }, params) {
    try {
      commit("setAdminSettings", null)
      const response = await axios.get("/settings", { params })
      commit("setAdminSettings", response.data.data)
    } catch (err) {
      console.log(err)
    }
  },
  async sendForgotPasswordVerification({ commit, dispatch }, params) {
    try {
      commit("setUsername", null)
      commit("setOtpSentStatus", null)
      const { data } = await axios.post("/forgot-password", params)
      commit("setOtpSentStatus", data)
      commit("setUsername", params.username)
    } catch (err) {
      commit("setUsername", null)
      commit("setOtpSentStatus", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async verify({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setOtpVerificationStatus", false)
      await axios.post("/forgot-password/verify", params)
      commit("setOtpVerificationStatus", true)
    } catch (err) {
      commit("setOtpVerificationStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async resetForgotPassword({ commit, dispatch }, params) {
    try {
      commit("errors/clear", null, { root: true })
      commit("setForgotPasswordUpdateStatus", false)
      await axios.post("/forgot-password/update-password", params)
      commit("setForgotPasswordUpdateStatus", true)
    } catch (err) {
      commit("setForgotPasswordUpdateStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async resetUserPassword({ commit, dispatch }, params) {
    try {
      let user_id = params.user_id
      delete params.user_id
      commit("errors/clear", null, { root: true })
      commit("setResetUserPassword", false)
      await axios.post("/users/" + user_id + "/reset-password", params)
      commit("setResetUserPassword", true)
    } catch (err) {
      commit("setResetUserPassword", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  async fetchAllAllowedLabs({ commit }, params) {
    try {
      commit("setAllowedLabs", null)
      const response = await axios.get("/labs", { params })
      commit("setAllowedLabs", response.data)
    } catch (err) {
      console.log(err)
    }
  },
  reset: function (context) {
    context.dispatch("errors/clear", [], { root: true })
    context.commit("auth/reset", null, { root: true })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
