<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    id="previewReport"
  >
    <div class="inner-nav-btn" v-if="getSinglePatientReports">
      <el-button @click="goToReports" class="back-btn mr-5">
        <el-icon class="el-icon-back"><Back /></el-icon>Back
      </el-button>

      <el-button
        @click="savePatientInfo('PENDING')"
        class="approve-btn mr-5"
        v-if="isDraft"
        >Make Complete</el-button
      >

      <el-button
        @click="goToEditReport(selectedReportCategory)"
        class="edit-btn mr-5"
        v-if="isAbleToEdit && selectedReportCategory"
      >
        <el-icon><Edit /></el-icon>
        Edit 
      </el-button>

      <el-button
        class="approve-btn mr-5"
        v-if="
         isAbleToApprove &&
          getSinglePatientReports &&
          getSinglePatientReports.report_status == 'PENDING'
          && !getSinglePatientReports.approved_by
        "
        @click="approveReport()"
      >
        <el-icon><SuccessFilled /></el-icon>
        Approve
      </el-button>

      <!-- secondary approve only for histo pathology and molecualr genomics -->
      <el-popover
        placement="top-start"
        title=""
        :width="200"
        trigger="hover"
        content="Previously you are approved this report"
        v-if="
          isAbleToSecondaryApprove &&
          (isHistopathologyReport ||
          isCorporateReport||
          isCytopathologyReport ||
          istransplantImmunoGeneticsReport ||
            isGenomicsReport ||
            isMolecularGeneticsReport ||
            isPharmacoGenomicsReport ||isCytogenetics || isBiochemicalGeneticsReport) &&
          getSinglePatientReports &&
          
          getSinglePatientReports.approved_by == getAuthenticatedUser._id
        "
      >
        <template #reference>
          <el-button class="approve-btn disabled-status mr-5">
            <el-icon><SuccessFilled /></el-icon>
            Approve
          </el-button>
        </template>
      </el-popover>

      <el-button
        class="approve-btn mr-5"
        v-else-if="
          isAbleToSecondaryApprove &&
          (isHistopathologyReport ||
          isCorporateReport ||
          isCytopathologyReport ||
          istransplantImmunoGeneticsReport ||
            isGenomicsReport ||
            isMolecularGeneticsReport ||
            isPharmacoGenomicsReport ||isCytogenetics || isBiochemicalGeneticsReport) &&
          getSinglePatientReports &&
          getSinglePatientReports.approved_by
        "
        @click="approveReport()"
      >
        <el-icon><SuccessFilled /></el-icon>
        Approve
      </el-button>

      <!-- tertiary approve only for histo pathology and molecualr genomics -->
      <el-popover
        placement="top-start"
        title=""
        :width="200"
        trigger="hover"
        content="Previously you are approved this report"
        v-if="
          isAbleToTertiaryApprove &&
          isGenomicsReport &&
          getSinglePatientReports &&
          (getSinglePatientReports.report_status == 'FA PENDING' || getSinglePatientReports.report_status == 'APPROVED') &&
          (getSinglePatientReports.approved_by == getAuthenticatedUser._id ||
            getSinglePatientReports.secondary_approved_by ==
              getAuthenticatedUser._id)
        "
      >
        <template #reference>
          <el-button class="approve-btn disabled-status mr-5">
            <el-icon><SuccessFilled /></el-icon>
            Approve</el-button
          >
        </template>
      </el-popover>

      <el-button
        class="approve-btn mr-5"
        v-else-if="
          isAbleToTertiaryApprove &&
          isGenomicsReport &&
          !isWholeExomeReport &&
          getSinglePatientReports &&
          (getSinglePatientReports.report_status == 'FA PENDING' || getSinglePatientReports.report_status == 'APPROVED')
        "
        @click="approveReport()"
      >
        <el-icon><SuccessFilled /></el-icon>
        Approve
      </el-button>

      <!-- qurter approve for genomics -->
      <el-popover
        placement="top-start"
        title=""
        :width="200"
        trigger="hover"
        content="Previously you are approved this report"
        v-if="
          isAbleToQuaternaryApprove &&
          isGenomicsReport &&
          getSinglePatientReports &&
          (getSinglePatientReports.report_status == 'FA PENDING' || getSinglePatientReports.report_status == 'APPROVED') &&
          (getSinglePatientReports.approved_by == getAuthenticatedUser._id ||
            getSinglePatientReports.secondary_approved_by ==
              getAuthenticatedUser._id ||
            getSinglePatientReports.tertiary_approved_by ==
              getAuthenticatedUser._id)
        "
      >
        <template #reference>
          <el-button class="approve-btn disabled-status mr-5">
            <el-icon><SuccessFilled /></el-icon>
            Approve</el-button
          >
        </template>
      </el-popover>
      <el-button
        class="approve-btn mr-5"
        v-else-if="
          isAbleToQuaternaryApprove &&
          isGenomicsReport &&
          !isWholeExomeReport &&
          getSinglePatientReports &&
          (getSinglePatientReports.report_status == 'FA PENDING' || getSinglePatientReports.report_status == 'APPROVED')
        "
        @click="approveReport()"
      >
        <el-icon><SuccessFilled /></el-icon>
        Approve
      </el-button>

      <!-- <el-button
        @click="verifyReport"
        icon=" el-icon-check"
        class="verify-btn mr-5"
        v-if="
          isAbleToVerify &&
          getSinglePatientReports &&
          getSinglePatientReports.report_status == 'PENDING' &&
          !getSinglePatientReports.verified_by && 
          isHistopathologyReport
        "
      >
        Verify
      </el-button> -->
      <!-- &&
          getSinglePatientReports.download_ready === true-->

      <el-dropdown
        v-if="getSinglePatientReports"
        @command="(command) => downloadProvisionalReport(command)"
      >
        <el-button class="download-btn mr-5">
          <el-icon><Download /></el-icon>
          Download
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="includeHeader"
              >With Header And Footer</el-dropdown-item
            >
            <el-dropdown-item command="excludeHeader">
              Without Header And Footer
            </el-dropdown-item>
            <el-dropdown-item command="includeHeaderNonColor">
              Black and White With Header And Footer
            </el-dropdown-item>
            <el-dropdown-item command="excludeHeaderNonColor">
              Black and White Without Header And Footer
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <el-button
        class="download-btn mr-5"
        v-else-if="
          getSinglePatientReports &&
          getSinglePatientReports.report_generate_status === 'PROCESSING'
        "
        >PROCESSING</el-button
      > -->
      <!-- <el-button class="print-btn" icon="el-icon-printer" @click="printReport"
        >Print</el-button
      > -->
      <!--  @command="(command) => printReport(command)" -->

      <el-dropdown>
        <el-button class="print-btn">
          <el-icon><Printer /></el-icon>
          Print
        </el-button>
        <template #dropdown>
          <el-dropdown-menu class="print-options-btn">
            <el-dropdown-item command="true">
              <router-link
                :to="{
                  name: 'PrintReport',
                  params: {
                    patient_id: this.$route.params.patient_id,
                  },
                  query: { selectedStatus: true, isColorPrint: true },
                }"
                class="print-option-link"
                target="_blank"
              >
                With Header And Footer
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item command="false">
              <router-link
                :to="{
                  name: 'PrintReport',
                  params: {
                    patient_id: this.$route.params.patient_id,
                  },
                  query: { selectedStatus: false, isColorPrint: true },
                }"
                class="print-option-link"
                target="_blank"
              >
                Without Header And Footer
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item command="true">
              <router-link
                :to="{
                  name: 'PrintReport',
                  params: {
                    patient_id: this.$route.params.patient_id,
                  },
                  query: { selectedStatus: true, isColorPrint: false },
                }"
                class="print-option-link"
                target="_blank"
              >
                Black and white With Header And Footer
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item command="false">
              <router-link
                :to="{
                  name: 'PrintReport',
                  params: {
                    patient_id: this.$route.params.patient_id,
                  },
                  query: { selectedStatus: false, isColorPrint: false },
                }"
                class="print-option-link"
                target="_blank"
              >
                Black and White Without Header And Footer
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div v-html="getPatientReportPreview"></div>
    <div class="print-button">
      <!-- <button type="button" onclick="window.print();">Download</button> -->
    </div>
    <el-dialog
      v-model="approveDialogue"
      width="30%"
      title="REPORT APPROVED TYPE"
      custom-class="approve-type-dialog"
    >
      <el-form>
        <el-radio v-model="approve_type" label="UPDATED">UPDATED</el-radio>
        <el-radio v-model="approve_type" label="APPEND">APPEND</el-radio>

        <!-- <el-row :gutter="15">
          <el-col :offset="12" :xl="4" :lg="4" :md="4" :sm="10" :xs="10">
            <el-button>Cancel</el-button>
          </el-col>
          <el-col :offset="2" :xl="6" :lg="4" :md="4" :sm="10" :xs="10">
            <el-button @click="approveReportView">Submit</el-button>
          </el-col>
        </el-row> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="approveDialogue = false">Cancel</el-button>
          <el-button type="primary" @click="approveReportView"
            >Submit</el-button
          >
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
// import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import { mapGetters } from "vuex";

import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
export default {
  data() {
    return {
      approveDialogue: false,
      approve_type: "APPEND",
      loading: false,
      profileDataloading: false,
      patientId: "",
      loadingText: "",
      include_header_footer: "",
      showAddTemplate: false,
      downloadParams: {
        includeHeader: {
          include_header_footer: true,
          is_color_variant: true,
        },
        excludeHeader: {
          include_header_footer: false,
          is_color_variant: true,
        },
        includeHeaderNonColor: {
          include_header_footer: true,
          is_color_variant: false,
        },
        excludeHeaderNonColor: {
          include_header_footer: false,
          is_color_variant: false,
        },
      },
    };
  },
  mixins: [userRolesPermissionsMixin],
  computed: {
    ...mapGetters("patientReports", [
      "getSinglePatientReports",
      "getReportApproveStatus",
      "getReportApproveStatuses",
      "getReportVerifyStatus",
      "getReportDownloadUrl",
      "getPatientReportPreview",
      "getReportDownloadData",
      "getReportDownloadStatus",
      "getPatientUpdateStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    isDraft() {
      if (
        this.getSinglePatientReports.report_status &&
        this.getSinglePatientReports.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },
    isAbleToVerify() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "LAB_DIRECTOR", "LAB_PHYSICIAN", "DOCTOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return true;
      }
      return false;
    },
    isAbleToApprove() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "LAB_DIRECTOR", "RADIOLOGIST", "DOCTOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return true;
      }
      return false;
    },
    isAbleToSecondaryApprove() {
      if (
        this.isAbleToApprove &&
        this.getSinglePatientReports &&
        this.getSinglePatientReports.approved_by &&
        !this.getSinglePatientReports.secondary_approved_by &&
        this.getSinglePatientReports.required_signatures !=
          this.getSinglePatientReports.fulfilled_signatures
      ) {
        return true;
      }
      return false;
    },
    isAbleToTertiaryApprove() {
      if (
        this.isAbleToApprove &&
        this.getSinglePatientReports &&
        this.getSinglePatientReports.approved_by &&
        this.getSinglePatientReports.secondary_approved_by &&
        !this.getSinglePatientReports.tertiary_approved_by &&
        this.getSinglePatientReports.required_signatures !=
          this.getSinglePatientReports.fulfilled_signatures
      ) {
        return true;
      }
      return false;
    },

    isAbleToQuaternaryApprove() {
      if (
        this.isAbleToApprove &&
        this.getSinglePatientReports &&
        this.getSinglePatientReports.approved_by &&
        this.getSinglePatientReports.secondary_approved_by &&
        this.getSinglePatientReports.tertiary_approved_by &&
        !this.getSinglePatientReports.quaternary_approved_by &&
        this.getSinglePatientReports.required_signatures !=
          this.getSinglePatientReports.fulfilled_signatures
      ) {
        return true;
      }
      return false;
    },
    isAbleToEdit() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["FRONT_DESK", "HELP_DESK"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return false;
      } else if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["ADMIN", "LAB_DIRECTOR", "RADIOLOGIST", "DOCTOR"].indexOf(
          this.getAuthenticatedUser.user_type
        ) == -1 &&
        this.getSinglePatientReports &&
        this.getSinglePatientReports.report_status &&
        this.getSinglePatientReports.report_status == "APPROVED"
      ) {
        return false;
      }
      return true;
    },
    isBioChemicalReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "BIO_CHEMISTRY"
        ? true
        : false;
    },
    isHistopathologyReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "HISTO_PATHOLOGY"
        ? true
        : false;
    },
    isCytogenetics() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "CYTOGENETICS"
        ? true
        : false;
    },
    isCorporateReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "CORPORATE"
        ? true
        : false;
    },
    isGenomicsReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "GENOMICS"
        ? true
        : false;
    },
    isMolecularGeneticsReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "MOLECULAR_GENETICS"
        && 
        (this.getSinglePatientReports.sub_category == 'BCR_ABL_QUALITATIVE'
        || this.getSinglePatientReports.sub_category == 'JAK2_Gene_Exon_14_V617F_Mutation_Detection' 
        ||
        this.getSinglePatientReports.sub_category == 'BCR_ABL_QUANTITATIVE'
        ||
        this.getSinglePatientReports.sub_category == 'SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR')
        ? true
        : false;
    },
    isCytometryReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "FLOW_CYTOMETRY"
        ? true
        : false;
    },
    istransplantImmunoGeneticsReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "TRANSPLANT_IMMUNOGENETICS"
        ? true
        : false;
    },
    isCytopathologyReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "CYTOPATHOLOGY"
        ? true
        : false;
    },
    isSerologyReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "SEROLOGY_IMMUNOLOGY"
        ? true
        : false;
    },
    isPharmacoGenomicsReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "PHARMACO_GENOMICS"
        ? true
        : false;
    },
    isBiochemicalGeneticsReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "BIOCHEMICAL_GENETICS" &&
        (this.getSinglePatientReports.sub_category == "Carnitine" ||
          this.getSinglePatientReports.sub_category === "Hemoglobinopathies" ||
          this.getSinglePatientReports.sub_category ===
            "GAUNDICATE AND CREATININE" ||
          this.getSinglePatientReports.sub_category === "CSF GLYCENE" ||
          this.getSinglePatientReports.sub_category === "CSF AMINO ACIDS" ||
          this.getSinglePatientReports.sub_category ===
            "INTEGRATED QUADRUPLE MARKER" ||
          this.getSinglePatientReports.sub_category === "OEMGA 3 AND OMEGA 6" ||
          this.getSinglePatientReports.sub_category === "PLASMA_AMINO_ACIDS" ||
          this.getSinglePatientReports.sub_category === "Plasma_Glycine" ||
          this.getSinglePatientReports.sub_category === "Fabry_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Leukocytes_NCL2" ||
          this.getSinglePatientReports.sub_category === "Krabbe_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Gaucher_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "MPS I_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "GM1_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "MLD_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Glycoasparginase_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Alpha_Fucosidosis_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Alpha_Mannosidosis_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Beta_Mannosidosis_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Biotinidase_Plasma" ||
          this.getSinglePatientReports.sub_category === "MPS II_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "MPS IIIA_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "MPS IIIB_Leukocytes"||
          this.getSinglePatientReports.sub_category === "MPS IIIC_Leukocytes"||
          this.getSinglePatientReports.sub_category === "MPS IIID_Leukocytes"||
          this.getSinglePatientReports.sub_category === "MPS IVA_Leukocytes"||
          this.getSinglePatientReports.sub_category === "MPS VII_Leukocytes"||
          this.getSinglePatientReports.sub_category === "PAP SMEAR - CONVENTIONAL"||
          this.getSinglePatientReports.sub_category === "PAP SMEAR ABOVE 40" ||
          this.getSinglePatientReports.sub_category === "MPS VI_Leukocytes" ||
          this.getSinglePatientReports.sub_category === "Chitotriosidase_Plasma"
          )
        ? true
        : false;
    },
    isWholeExomeReport() {
      return this.getSinglePatientReports &&
        this.getSinglePatientReports.category &&
        this.getSinglePatientReports.category == "GENOMICS" &&
        this.getSinglePatientReports.sub_category == "Whole Exome Sequencing"
        ? true
        : false;
    },
    selectedReportCategory() {
      if (
        this.getSinglePatientReports &&
        this.getSinglePatientReports.category
      ) {
        const category = this.getSinglePatientReports.category.toUpperCase();
        switch (category) {
          case "RADIOLOGY":
          case "BIO_CHEMISTRY":
          case "HISTO_PATHOLOGY":
          case "GENOMICS":
          case "MOLECULAR_GENETICS":
          case "FLOW_CYTOMETRY":
          case "TRANSPLANT_IMMUNOGENETICS":
          case "SEROLOGY_IMMUNOLOGY":
          case "BIOCHEMICAL_GENETICS":
          case "PHARMACO_GENOMICS":
          case "CYTOGENETICS":
          case "CORPORATE":
          case "CYTOPATHOLOGY":
            return category;
          default:
            return null;
        }
      } else {
        return null;
      }
    },
    disableHeaderReportDownloadOption() {
      return (report) => {
        if (report && report.report_path) return false;
        return true;
      };
    },
    disableWithoutHeaderReportDownloadOption() {
      return (report) => {
        if (report && report.exclude_header_report_path) return false;
        return true;
      };
    },
    disableBlackWhiteHeaderReportDownloadOption() {
      return (report) => {
        if (report && report.non_color_report_path) return false;
        return true;
      };
    },
    disableBlackWhiteWithoutHeaderReportDownloadOption() {
      return (report) => {
        if (report && report.non_color_exclude_header_report_path) return false;
        return true;
      };
    },
  },
  mounted() {
    this.loadInitData();
  },
  methods: {
    async loadInitData() {
      try {
        this.loading = true;
        this.loadingText = "Fecthing Patient Report...";
        this.patientId = this.$route.params.patient_id;
        this.include_header_footer = true;
        await Promise.all([
          this.fetchSinglePatientInfo(),
          this.fetchSinglePatientPreview(),
          this.fetchProfile(),
        ]);
        let classesData = ["reportPage"];
        if (this.$route.query.selectedStatus == "false") {
          classesData.push("hide-header", "hide-footer");
          // this.toggleBodyClass("addClass", "reportPage hide-header hide-footer");
        }
        if (this.getSinglePatientReports.report_status != "APPROVED") {
          classesData.push("duplicate");
        }
        this.toggleBodyClass("addClass", classesData.join(" "));
        // this.toggleBodyID("addClass");
        // this.addPageNumbers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async savePatientInfo(status) {
      try {
        let patientId = this.$route.params.patient_id;
        let params = { ...this.getSinglePatientReports };
        params.report_status = status;

        await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.loadInitData();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    // addPageNumbers() {
    //   var totalPages = Math.ceil(document.body.scrollHeight / 1123);
    //   for (var i = 1; i <= totalPages; i++) {
    //     var pageNumberDiv = document.createElement("div");
    //     pageNumberDiv.classList.add("pagination-display");
    //     pageNumberDiv.classList.add("mt-40-" + i);
    //     var pageNumber = document.createTextNode(
    //       "Page " + i + " of " + totalPages
    //     );
    //     pageNumberDiv.style.position = "absolute";
    //     if (
    //       document.body.className.match(
    //         "hide-header",
    //         "hide-footer",
    //         "letter-head"
    //       )
    //     ) {
    //       pageNumberDiv.style.top = "calc((" + i + " * (297mm - 20px)))";
    //     } else {
    //       pageNumberDiv.style.top = "calc((" + i + " * (297mm + 135px)))";
    //     }

    //     pageNumberDiv.style.height = "16px";
    //     pageNumberDiv.appendChild(pageNumber);
    //     let paginationBody = document.getElementById("pagination-body");

    //     if (paginationBody) {
    //       paginationBody.appendChild(pageNumberDiv);
    //     }
    //   }
    // },
    async fetchSinglePatientInfo() {
      try {
        await this.$store.dispatch(
          "patientReports/fetchSinglePatientReports",
          this.patientId
        );
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSinglePatientPreview() {
      try {
        let params = {
          patientId: this.patientId,
          include_header_footer: this.include_header_footer,
          is_color_variant: true,
        };

        await this.$store.dispatch(
          "patientReports/fetchPatientPreview",
          params
        );

        // if (this.getPatientReportPreview) {
        //   document.write(this.getPatientReportPreview);
        // }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchProfile() {
      try {
        this.profileDataloading = true;
        this.loadingText = "Fetching Data...";
        await this.$store.dispatch("auth/getProfile");

        this.profileDataloading = false;
      } catch (error) {
        this.profileDataloading = false;
        console.log(error);
      }
    },

    saveAsTemplate() {
      this.showAddTemplate = true;
    },
    goToReports() {
      this.$router.push({ name: "PatientReports", query: this.$route.query });
    },

    goToEditReport(reportType) {
      switch (reportType) {
        case "CYTOGENETICS":
          this.$router.push({
            name: "CytogeneticsReportUpdate",
            params: {
              patient_id: this.patientId,
            }
          });
          break;
        case "BIO_CHEMISTRY":
          // navigate to edit bioChemical report page
          this.$router.push({
            name: "BiochemistryReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        case "BIOCHEMICAL_GENETICS":
          this.$router.push({
            name: "BiochemicalGeneticsReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        case "HISTO_PATHOLOGY":
          // navigate to edit histopathology report page
          this.$router.push({
            name: "HistoPathologyReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
          case "CORPORATE":
          // navigate to edit histopathology report page
          this.$router.push({
            name: "CorporateReportsEdit",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        case "GENOMICS":
          this.$router.push({
            name: "GenomicsReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          // navigate to edit genomics report page
          break;
        case "MOLECULAR_GENETICS":
          this.$router.push({
            name: "MolecularGeneticsReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        // add more cases for other report types
        case "FLOW_CYTOMETRY":
          this.$router.push({
            name: "CytometryReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        case "TRANSPLANT_IMMUNOGENETICS":
          this.$router.push({
            name: "ImmunogeneticsReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
          case "CYTOPATHOLOGY":
          this.$router.push({
            name: "CytopathologyReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        case "SEROLOGY_IMMUNOLOGY":
          this.$router.push({
            name: "SerologyReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        case "PHARMACO_GENOMICS":
          this.$router.push({
            name: "PharmacoGenomicsReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
        default:
          // handle invalid report type
          this.$router.push({
            name: "PatientReportUpdate",
            params: {
              patient_id: this.patientId,
            },
            query: this.$route.query,
          });
          break;
      }
    },
    goToEditReports() {
      this.$router.push({
        name: "PatientReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditBioChemicalReports() {
      this.$router.push({
        name: "BiochemistryReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditHistopathologyReports() {
      this.$router.push({
        name: "HistoPathologyReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditGenomicsReports() {
      this.$router.push({
        name: "GenomicsReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditMolecularGeneticsReports() {
      this.$router.push({
        name: "MolecularGeneticsReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditCytometryReports() {
      this.$router.push({
        name: "CytometryReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditTransplantImmunoGeneticsReports() {
      this.$router.push({
        name: "ImmunogeneticsReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditCytopathologyReports() {
      this.$router.push({
        name: "CytopathologyReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditSerologyReports() {
      this.$router.push({
        name: "SerologyReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    goToEditBiochemicalGeneticsReports() {
      this.$router.push({
        name: "BiochemicalGeneticsReportUpdate",
        params: {
          patient_id: this.patientId,
        },
        query: this.$route.query,
      });
    },
    approveReportDialog() {
      this.approveDialogue = true;
    },
    async approveReport() {
      try {
        let params = {
          patientId: this.patientId,
          includeHeader: true,
        };

        this.loading = true;

        await this.$store.dispatch(
          "patientReports/approvePatientReport",
          params
        );
        if (this.getReportApproveStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Approved Successfully",
          });
          await this.loadInitData();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Approving",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async approveReportView() {
      try {
        let params = {
          patientId: this.patientId,
          includeHeader: true,
          approve_type: this.approve_type,
        };

        this.loading = true;

        await this.$store.dispatch(
          "patientReports/approvePatientReports",
          params
        );
        if (this.getReportApproveStatuses) {
          this.$notify.success({
            title: "Success",
            message: "Report Approved Successfully",
          });
          this.approveDialogue = false;
          await this.loadInitData();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Approving",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async tertiaryApproveReport() {
      try {
        let params = {
          patientId: this.patientId,
          includeHeader: true,
          approve_type: this.approve_type,
        };

        this.loading = true;

        await this.$store.dispatch(
          "patientReports/tertiaryApprovePatientReport",
          params
        );
        if (this.getReportApproveStatuses) {
          this.$notify.success({
            title: "Success",
            message: "Report Approved Successfully",
          });
          this.approveDialogue = false;
          await this.loadInitData();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Approving",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async quaternaryApproveReport() {
      try {
        let params = {
          patientId: this.patientId,
          includeHeader: true,
          approve_type: this.approve_type,
        };

        this.loading = true;

        await this.$store.dispatch(
          "patientReports/quaternaryApprovePatientReport",
          params
        );
        if (this.getReportApproveStatuses) {
          this.$notify.success({
            title: "Success",
            message: "Report Approved Successfully",
          });
          this.approveDialogue = false;
          await this.loadInitData();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Approving",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async verifyReport() {
      try {
        this.loading = true;
        this.loadingText = "Verifying Report...";
        await this.$store.dispatch(
          "patientReports/verifyPatientReport",
          this.patientId
        );
        if (this.getReportVerifyStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Verified Successfully",
          });
          await this.loadInitData();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Error While Verifiying",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async downloadProvisionalReport(status) {
      try {
        let patient = this.getSinglePatientReports;
        let params = {
          patientId: patient._id,
        };

        if (this.downloadParams[status]) {
          params = { ...params, ...this.downloadParams[status] };
        }
        // params.include_header_footer = status;
        this.loading = true;
        this.loadingText = "Downloading Report...";
        await this.$store.dispatch(
          "patientReports/downloadProvosionalPatientReport",
          params
        );

        if (this.getReportDownloadStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async downloadReport(status) {
      try {
        this.loading = true;
        this.loadingText = "Downloading....";
        let params = {
          patientId: this.patientId,
        };

        if (this.downloadParams[status]) {
          params = { ...params, ...this.downloadParams[status] };
        }
        // params.include_header_footer = status;

        await this.$store.dispatch(
          "patientReports/downloadPatientReports",
          params
        );
        this.headerFooter = "";
        if (
          this.getReportDownloadUrl &&
          this.getReportDownloadUrl.success &&
          this.getReportDownloadUrl.data
        ) {
          var tmpLink = document.createElement("a");
          tmpLink.href = this.getReportDownloadUrl.data;

          // temporarily add link to body and initiate the download
          document.body.appendChild(tmpLink);
          tmpLink.click();
          document.body.removeChild(tmpLink);

          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async printReport(status) {
      let routerData = this.$router.resolve({
        name: "PrintReport",
        params: {
          patient_id: this.patientId,
        },
        query: { selectedStatus: status },
      });
      window.open(routerData.href, "_blank");
    },
    async toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      let classNames = className.split(" ");
      if (addRemoveClass === "addClass") {
        for (let i = 0; i < classNames.length; i++) {
          el.classList.add(classNames[i]);
        }
      } else {
        for (let i = 0; i < classNames.length; i++) {
          el.classList.remove(classNames[i]);
        }
      }
    },
    async toggleBodyID(addRemoveClass) {
      const el = document.body;
      if (addRemoveClass === "addClass") {
        el.id = "pagination-body";
      } else {
        el.id = "";
      }
    },
  },
  unmounted() {
    let classesData = ["reportPage"];
    if (this.$route.query.selectedStatus == "false") {
      classesData.push("hide-header", "hide-footer");
    }
    if (
      this.getSinglePatientReports &&
      this.getSinglePatientReports.report_status != "APPROVED"
    ) {
      classesData.push("duplicate");
    }
    this.toggleBodyClass("removeClass", classesData.join(" "));
    this.toggleBodyID("removeClass");

    // if (this.$route.query.selectedStatus == "false") {
    //   this.toggleBodyClass("removeClass", "reportPage hide-header hide-footer");
    // } else {
    //   this.toggleBodyClass("removeClass", "reportPage");
    // }
    // this.toggleBodyClass("removeClass", "reportPage");
  },
};
</script>

<style lang="scss" scoped>
.inner-nav-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0rem auto 1rem;
  width: 900px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 9;
  padding: 10px 0;
  .el-button {
    padding: 9px 15px;
    min-height: auto;
    color: #e0f3f1;
    line-height: 1;
    font-weight: 400;
    &.approve-btn {
      background-color: #368110;
      border-color: #368110;
      &.disabled-status {
        opacity: 0.6;
      }
    }
    &.print-btn {
      background-color: #08b4b9;
      border-radius: #08b4b9;
    }
    &.verify-btn {
      background-color: #e78915;
      border-color: #e78915;
    }
    &.download-btn {
      background-color: #2f80ed;
      border-color: #2f80ed;
    }
    &.edit-btn {
      background-color: #f94e5e;
      border-color: #f94e5e;
    }
    &.back-btn {
      color: #333;
      background-color: white;
      border: 1px solid #47525d42;

      background-color: transparent;
      &:active,
      &:hover {
        border: 1px solid #47525d42;
      }
    }
  }
}
// @import "/src/assets/scss/components/reports.scss";
@media print {
  .inner-nav-btn {
    display: none;
  }
}
</style>
<style lang="scss">
#previewReport {
  .each-block {
    ul {
      padding-left: 20px !important;
      li {
        list-style-type: disc !important;
      }
    }
  }
}
.footer-inner-last-page {
  height: 100% !important;
}
.verified-by {
  height: 100% !important;
}
.print-options-btn {
  .print-option-link {
    display: block;
    color: #000;
  }
  a.print-option-link:hover,
  a.print-option-link:focus,
  a.print-option-link:visited,
  a.print-option-link:focus-within {
    color: #000;
  }
}

.approve-type-dialog {
  .el-dialog__header {
    background: #bdbfbf;
    .el-dialog__title {
      font-weight: 500;
    }
    .el-dialog__close {
      font-size: 20px;
      color: red;
    }
  }
}
</style>
