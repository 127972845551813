<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    id="previewReport"
    class="bio-chemistry-view-template"
  >
    <div class="inner-nav-btn" v-if="getReportPreview">
      <el-button @click="goToTemplates" class="back-btn"
        ><el-icon class="el-icon-back"><Back /></el-icon>Back</el-button
      >
      <el-button
        @click="goToEditBioChemicalReports"
        class="edit-btn mr-5"
        v-if="isAbleToAccessEdit"
      >
        <el-icon><Edit /></el-icon>
        Edit</el-button
      >
    </div>
    <div class="inner-content" v-if="getSingleBiochemistryTemplate">
      <ul class="template-details">
        <li>
          <span class="name">Department</span>
          <span class="value">{{
            getSingleBiochemistryTemplate.department
          }}</span>
        </li>
        <li>
          <span class="name">Category</span>
          <span class="value">{{
            getSingleBiochemistryTemplate.category
          }}</span>
        </li>
        <li>
          <span class="name">Sub Category</span>
          <span class="value">{{
            getSingleBiochemistryTemplate.sub_category
          }}</span>
        </li>
      </ul>
      <div class="template-data">
        <h2 class="title">Template data</h2>
        <div v-html="getReportPreview"></div>
      </div>
    </div>
  </section>
</template>

<script>
// import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import { mapGetters } from "vuex";

import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
export default {
  data() {
    return {
      loading: false,
      patientInfo: "",
      loadingText: "",
    };
  },
  mixins: [userRolesPermissionsMixin],
  computed: {
    ...mapGetters("biochemistryTemplates", [
      "getReportPreview",
      "getSingleBiochemistryTemplate",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
  },
  async mounted() {
    const template_id = this.$route.params.template_id;
    this.loading = true;
    this.loadingText = "Fetching Report Template....";
    await this.$store.dispatch(
      "biochemistryTemplates/fetchTemplatePreview",
      template_id
    );
    await this.fetchSingletemplateForm();
    this.loading = false;
  },
  methods: {
    goToTemplates() {
      this.$router.push({
        name: "BiochemistryTemplates",
        query: this.$route.query,
      });
    },
    goToEditBioChemicalReports() {
      try {
        this.$router.push({
          name: "UpdateBiochemistryTemplate",
          params: {
            template_id: this.$route.params.template_id,
          },
          query: this.$route.query,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSingletemplateForm() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        await this.$store.dispatch(
          "biochemistryTemplates/fetchSingleBiochemistryTemplate",
          this.$route.params.template_id
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.bio-chemistry-view-template {
  max-width: 900px;
  margin: 0 auto;
  .inner-nav-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0rem auto 1rem;
    width: 900px;
    position: sticky;
    top: 0;
    z-index: 9;
    padding: 10px 10px;
    background: #fff;
    border-radius: 5px;
    .el-button {
      padding: 9px 15px;
      min-height: auto;
      color: #e0f3f1;
      line-height: 1;
      font-weight: 400;

      &.approve-btn {
        background-color: #368110;
        border-color: #368110;
      }
      &.print-btn {
        background-color: #08b4b9;
        border-radius: #08b4b9;
      }
      &.verify-btn {
        background-color: #e78915;
        border-color: #e78915;
      }
      &.download-btn {
        background-color: #2f80ed;
        border-color: #2f80ed;
      }
      &.edit-btn {
        background-color: #f94e5e;
        border-color: #f94e5e;
      }
      &.back-btn {
        color: #333;
        background-color: white;
        border: 1px solid #47525d42;

        background-color: transparent;
        &:active,
        &:hover {
          border: 1px solid #47525d42;
        }
      }
    }
  }
  .inner-content {
    background: #fff;
    margin-top: 20px;
    padding: 20px;
    .template-details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
      margin-bottom: 15px;
      li {
        span {
          display: block;
          &.name {
            color: #6a6a6a;
            font-size: 14px;
            margin-bottom: 2px;
          }
          &.value {
            color: #000;
            background: #f7f3f3;
            padding: 5px 10px;
            font-weight: 500;
            border-radius: 5px;
            font-size: 14px;
          }
        }
      }
    }
    .template-data {
      margin-top: 25px;
      .title {
        font-size: 16px;
        margin-bottom: 10px;
        color: #2eaf9f;
        font-weight: 600;
      }
      #defaultReportPage {
        width: 100% !important;
        .body-info {
          margin-top: 0;
        }
        .section-body {
          padding: 10px;
        }
      }
    }
  }
}
</style>
