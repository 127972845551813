<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <!-- <h3 v-if="getPatientReportData.template_title">
      Template - {{ getPatientReportData.template_title }}
    </h3>-->
    <!-- <div v-html="getPatientReportPreviewData"></div> -->
    <div v-if="showError">
      <h3 style="color: red">{{ getError || "Error in report preview" }}</h3>
    </div>
  </section>
</template>

<script>
// import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      loadingText: "",
      patientInfo: "",
      showError: false,
    };
  },
  computed: {
    ...mapGetters("patientReports", [
      "getPatientReportPreviewData",
      "getPatientReportData",
      "getPatientReportPreview",
    ]),
    ...mapGetters("errors", ["getError"]),
    ...mapGetters("reportTemplates", ["getReportTemplatePreview"]),
  },
  async mounted() {
    await this.fetchSinglePatientPreview();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.showError = false;
        this.loadingText = "Fetching Report Preview..";
        const reportData = await this.$store.dispatch(
          "patientReports/addPatientReportPreview",
          this.getPatientReportData
        );
        if (reportData && reportData.data && reportData.type == "Buffer") {
          const arrayBuffer = reportData.data;
          const pdfBuffer = new Uint8Array(arrayBuffer);
          var blob = new Blob([pdfBuffer], { type: "application/pdf" });
          var blobURL = URL.createObjectURL(blob);
          let iframe =
            "<iframe src='" + blobURL + "' style='height:100%;width:100%'/>";
          // window.location.href = blobURL;
          document.write(iframe);
        } else {
          this.showError = true;
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.showError = true;
      }
    },
    async fetchSinglePatientPreview() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Report Preview..";

        await this.$store.dispatch(
          "reportTemplates/addReportTemplatePreview",
          this.getPatientReportData
        );

        if (this.getReportTemplatePreview) {
          document.write(this.getReportTemplatePreview);
          // setTimeout(() => {
          //   window.print();
          // }, 2500);
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/components/reports.scss";
.patient-report-preview {
  h3 {
    text-align: center;
    margin-bottom: 20px;
    padding: 5px 0;
    background: #fff;
  }
  @media print {
    h3 {
      display: none;
    }
  }
}
</style>
