<template>
  <section id="all-patient-reports" class="all-result-files-page">
    <div class="page-navigation">
      <el-row>
        <el-col :span="24">
          <ul class="action-navigation">
            <li>
              <div class="status-filter">
                <el-select
                  placeholder="Select Category"
                  v-model="searchStatus"
                  @change="loadData(1)"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="status in reportStatus"
                    :key="status"
                    :label="status.label"
                    :value="status.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="isAbleToAccess('ADD_PATIENT_REPORT')">
              <el-button
                @click="goToUploadResultFile"
                class="default-button add-button"
                >Upload Result File</el-button
              >
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="vue-table all-result-files-table">
        <el-table
          :data="resultFilesData"
          style="width: 100%"
          height="calc(100vh - 250px)"
          class="table-list display-web"
          @sort-change="changeSort"
        >
          <el-table-column
            prop="name"
            label="Name"
            width="350"
            sortable="custom"
          >
            <template #default="scope">
              <span class="date" v-if="scope.row.name">
                {{ scope.row.name }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="test_category"
            label="Category"
            width="190"
            sortable="custom"
          >
            <template #default="scope">
              <span class="date" v-if="scope.row.test_category">
                {{
                  `${
                    scope.row.test_category
                      ? resultFileCategory[scope.row.test_category]
                      : scope.row.test_category || "--"
                  }`
                }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="Status"
            width="150"
            sortable="custom"
          >
            <template #default="scope">
              <div class="column-status">
                <el-tag :type="getStatusTagType(scope.row.status)">
                  {{ scope.row.status ? scope.row.status : "--" }}
                </el-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="created_at"
            label="Date"
            min-width="180"
            sortable="custom"
          >
            <template #default="scope">
              <span class="date" v-if="scope.row.created_at">
                {{ date(scope.row.created_at) }}
                {{ time(scope.row.created_at) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Progress"
            width="150"
            prop="status"
            sortable="custom"
          >
            <template #default="scope">
              <el-button
                class="process-btn make-process"
                @click="processReport(scope.row)"
                v-if="
                  scope.row.file_processed == false &&
                  isAbleToAccess('ADD_PATIENT_REPORT')
                "
                >Process File
              </el-button>
              <el-button
                class="process-btn generate-file"
                @click="generateReport(scope.row)"
                v-if="
                  scope.row.status == 'TO-START' &&
                  scope.row.file_processed &&
                  isAbleToAccess('ADD_PATIENT_REPORT')
                "
                >Generate Report
              </el-button>
              <el-button
                class="process-btn"
                v-if="
                  scope.row.status !== 'TO-START' && scope.row.file_processed
                "
                @click="viewReports(scope.row)"
                >View Progress
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div
        class="vue-pagination"
        v-if="resultFilesData && resultFilesData.length"
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="loadData"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
// import { Search } from "@element-plus/icons-vue";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
import { resultFileCategoryMap } from "@/constants/formConstants";
export default {
  middleware: "auth",
  mixins: [userRolesPermissionsMixin],
  computed: {
    getStatusTagType() {
      return (status) => {
        if (status && this.tagByStatus && this.tagByStatus[status]) {
          return this.tagByStatus[status];
        }
        return;
      };
    },
    ...mapGetters("resultFiles", [
      "getAllResultFiles",
      "getFileProcessStatus",
      "getFileGenerateStatus",
      "getReportDownloadStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  data() {
    return {
      dialogVisible: false,
      headerFooter: "",
      userRoleDept: [],
      resultFilesData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      orderBy: "",
      orderType: "",
      // searchIcon: Search,
      sortCodeMap: {
        ascending: "asc",
        descending: "desc",
      },
      searchString: "",
      searchStatus: "",
      resultFileCategory: resultFileCategoryMap,
      tagByStatus: {
        COMPLETED: "success",
        TOSTART: "primary",
        INITIATE: "primary",
        DRAFT: "info",
        PENDING: "warning",
      },
      reportStatus: [
        {
          label: "All",
          value: "",
        },
        {
          label: "BIO",
          value: "BIO",
        },
        { label: "TMS", value: "TMS" },
        {
          label: "DOUBLE MARKERS",
          value: "DOUBLE_MARKERS",
        },
        {
          label: "QUADRABLE MARKERS",
          value: "QUADRUPLE_MARKERS",
        },
        {
          label: "TRIPLE MARKERS",
          value: "TRIPLE_MARKERS",
        },
        {
          label: "URINE ORGANIC ACID",
          value: "URINE_ORGANIC_ACID",
        },
        {
          label: "PENTA MARKERS",
          value: "PENTA_MARKERS",
        },
        { label: "EARLY QUADRUPLE MARKERS", value: "EARLY_QUADRUPLE_MARKERS" },
        {
          label: "INTEGRATED QUADRUPLE MARKERS",
          value: "INTEGRATED_QUADRUPLE_MARKERS",
        },
        {
          label: "PLASMA CREATINE AND GUINIDINO ACETIC ACID",
          value: "GUANIDINO_ACETATE",
        },
        {
          label: "Urine Creatine & Guanidino Acetic Acid Levels",
          value: "URINE_GUANIDINO_ACETATE",
        },
        {
          label: "Karyotyping",
          value: "KARYOTYPING",
        },
        {
          label: "Solidfish",
          value: "SOLIDFISH",
        },
        {
          label: "fish",
          value: "FISH",
        },


      ],
      loading: false,
      loadingText: "",
    };
  },
  async mounted() {
    await this.setQueryToData();

    await this.fetchAllResultFiles();
    await this.$store.dispatch("errors/clear");
  },
  methods: {
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm a");
    },

    goToTemplates() {
      this.$router.pus("/all-report-temaples");
    },
    async fetchAllResultFiles() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Patient Reports...";
        let params = this.prepareParams();

        await this.$store.dispatch("resultFiles/fetchAllResultFiles", params);
        this.setTableData();
        this.setQueryData(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async processReport(report) {
      try {
        this.loading = true;
        await this.$store.dispatch("resultFiles/processReport", report._id);
        if (this.getFileProcessStatus) {
          successNotification(this.getFileProcessStatus.message);
          await this.fetchAllResultFiles(this.page);
        } else {
          errorNotification(this.getError || "Error While processig file");
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        errorNotification("Error While processig file");
      }
    },
    async generateReport(report) {
      try {
        this.loading = true;
        await this.$store.dispatch("resultFiles/generateReport", report._id);
        this.loading = false;
        if (this.getFileGenerateStatus) {
          successNotification(this.getFileGenerateStatus.message);
          await this.fetchAllResultFiles();
        } else {
          errorNotification(this.getError || "Error While generating file");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async downloadReport(report) {
      try {
        let params = {
          reportId: report._id,
        };

        // params.include_header_footer = status;
        this.loading = true;
        this.loadingText = "Downloading Report...";
        await this.$store.dispatch("resultFiles/downloadReport", params);

        if (this.getReportDownloadStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Downloaded Successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getError || "Download Failed",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async viewReports(resultFile) {
      if (resultFile.file_processed) {
        this.$router.push({
          name: "PatientResultFiles",
          params: {
            result_file_id: resultFile._id,
          },
          query: this.$route.query,
        });
      }
    },

    setTableData() {
      const tableData = JSON.parse(JSON.stringify(this.getAllResultFiles));

      this.resultFilesData =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.page = tableData.page || 1;
      this.total = tableData.total || 0;
      this.pageSize = tableData.limit || 10;
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }

      if (this.searchString) {
        params.search_string = this.searchString;
      }
      if (this.searchStatus) {
        params.test_category = this.searchStatus;
      }

      return params;
    },

    setQueryData(data) {
      let params = {
        selectedPage: data.page || 1,
        selectedPageSize: data.limit || 10,
        selectedString: data.search_string || undefined,
        selectedStatus: data.test_category || undefined,
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...params },
        })
        .catch(() => {});
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPage
          ? parseInt(this.$route.query.selectedPage)
          : 1;
        this.pageSize = this.$route.query.selectedPageSize
          ? parseInt(this.$route.query.selectedPageSize)
          : 10;
        this.searchString = this.$route.query.selectedString || "";
        this.searchStatus = this.$route.query.selectedStatus || "";
      }
    },
    changeSort({ prop, order }) {
      this.orderBy = prop;
      this.orderType = this.sortCodeMap[order];
      this.page = 1;
      this.fetchAllResultFiles();
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchAllResultFiles();
    },
    async loadData(page) {
      this.page = page;

      await this.fetchAllResultFiles();
    },
    fetchData() {
      this.searchCategory = "";
      this.fetchAllResultFiles(1);
    },

    goToUploadResultFile() {
      this.$router.push({ name: "ResultFilesUpload" });
    },
  },
  beforeUnmount() {
    this.$store.commit("resultFiles/setAllResultFiles", null);
  },
};
</script>

<style lang="scss">
.all-result-files-page {
  .all-result-files-table,
  .vue-pagination,
  .page-navigation {
    @media (min-width: 1000px) {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
  .page-navigation {
    margin-bottom: 15px;
  }
  .vue-table {
    table tbody td {
      padding: 8px 0 !important;
    }
    .status {
      .el-tag {
        padding: 2px 10px;
        height: auto;
        min-height: auto;
        line-height: 20px;
        display: inline-block;
        font-weight: 500;
        font-size: 12px !important;
        // &.el-tag--success {
        //   background: #9ff1a3;
        //   border-color: #9ff1a3;
        //   color: green;
        // }
        // &.el-tag--light {
        //   background: #b7cfe8;
        //   border-color: #b7cfe8;
        //   color: #409eff;
        // }
      }
    }
  }

  .process-btn {
    background: #0a8a90;
    padding: 5px 10px;
    height: auto;
    min-height: auto;
    color: #fff;
    font-weight: 500;
    font-size: 14px !important;
    min-width: 115px;
    text-align: center;
    &:hover {
      background: #0a8a90;
      color: #fff;
    }
    &.make-process {
      background: #0061ec;
      &:hover {
        background: #0061ec;
      }
    }
    &.generate-file {
      background: #118f17;
      &:hover {
        background: #118f17;
      }
    }
  }
}
</style>
