<template>
    <div class="PusForCytology">
        <div class="edit-box mb-20" v-if="subcategory !=='Liquid Based Cytology - Pap Smear' && this.subcategory !=='Liquid_Based_Cytology'">
            <span class="title">Cytology Type</span>
            <el-input placeholder="Please enter Cytology Type"  v-model="cytology_type"/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LAB. REF. NO.</span> 
            <ckeditor :editor="editor" v-model="lab_ref_no" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20" v-if="(this.patientInfo.report_type==='Liquid Based Cytology - Pap Smear' || this.patientInfo.report_type==='Liquid_Based_Cytology')" >
            <span class="title">CLINICAL DIAGNOSIS</span>
            <ckeditor :editor="editor" v-model="clinical_Diagnosis" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20" v-if="(this.patientInfo.report_type==='Fnac Slides Reporting' || this.patientInfo.report_type==='Fnac_Slides_Reporting')">
            <span class="title">CLINICAL DETAILS</span>
            <ckeditor :editor="editor" v-model="clinical_Details" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">TYPE OF SPECIMEN</span>
            <ckeditor :editor="editor" v-model="specimen_type" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20" v-if="(this.patientInfo.report_type==='Liquid Based Cytology - Pap Smear' || this.patientInfo.report_type==='Liquid_Based_Cytology')">
            <span class="title">SPECIMEN ADEQUACY</span>
            <ckeditor :editor="editor" v-model="specimen_Adequacy" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">MICROSCOPY</span>
            <ckeditor :editor="editor" v-model="microscopy" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20" v-if="(this.patientInfo.report_type==='Liquid Based Cytology - Pap Smear' || this.patientInfo.report_type==='Liquid_Based_Cytology')">
            <span class="title">ORGANISMS</span>
            <ckeditor :editor="editor" v-model="organisms" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20" v-if="(this.patientInfo.report_type==='Liquid Based Cytology - Pap Smear' || this.patientInfo.report_type==='Liquid_Based_Cytology')">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" v-model="interpretation" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20" v-if="(this.patientInfo.report_type==='Fnac Slides Reporting' || this.patientInfo.report_type==='Fnac_Slides_Reporting')">
            <span class="title">IMPRESSION</span>
            <ckeditor :editor="editor" v-model="impression" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" v-model="note" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props:{
        PusForCytology_Array:{
            type:Array,
            required:true
        },
        patientInfo:{
            type: String, 
        }
    },

    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            lab_ref_no:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].lab_ref_no:'',
            clinical_Diagnosis:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].clinical_Diagnosis:'',
            clinical_Details:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].clinical_Details:'',
            specimen_type:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].specimen_type:'',
            specimen_Adequacy:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].specimen_Adequacy:'',
            microscopy:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].microscopy:'',
            organisms:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].organisms:'',
            interpretation:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].interpretation:'',
            impression:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].impression:'',
            note:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].note:'',
            subcategory:this.patientInfo.report_type,
            cytology_type:this.PusForCytology_Array?.length==1?this.PusForCytology_Array[0].cytology_type:'',
        }
        
    },
    methods:{
        childMethod() {
            let dd = window.document.getElementsByClassName("ck-content");
            const lab_ref_no = dd[0]?.innerHTML; 
            const clinical_Diagnosis = (this.subcategory ==='Liquid Based Cytology - Pap Smear' || this.subcategory ==='Liquid_Based_Cytology') ? dd[1].innerHTML : '';
            const clinical_Details= (this.subcategory ==='Fnac Slides Reporting' || this.subcategory ==='Fnac_Slides_Reporting') ? dd[1].innerHTML : '';
            const specimen_type = (this.subcategory ==='Pus For Cytology' || this.subcategory ==='Pus_For_Cytology') ? dd[1].innerHTML : dd[2].innerHTML; 
            const specimen_Adequacy=(this.subcategory ==='Liquid Based Cytology - Pap Smear' || this.subcategory ==='Liquid_Based_Cytology') ? dd[3].innerHTML : '';
            const microscopy = (this.subcategory ==='Pus For Cytology' || this.subcategory ==='Pus_For_Cytology') ? dd[2].innerHTML: (this.subcategory ==='Liquid Based Cytology - Pap Smear' || this.subcategory ==='Liquid_Based_Cytology') ? dd[4].innerHTML:dd[3].innerHTML; 
            const organisms=(this.subcategory ==='Liquid Based Cytology - Pap Smear' || this.subcategory ==='Liquid_Based_Cytology') ? dd[5].innerHTML : '';
            const interpretation=(this.subcategory ==='Liquid Based Cytology - Pap Smear' || this.subcategory ==='Liquid_Based_Cytology') ? dd[6].innerHTML : '';
            const impression= (this.subcategory ==='Fnac Slides Reporting' || this.subcategory ==='Fnac_Slides_Reporting') ?dd[4]?.innerHTML:'';
            const note = (this.subcategory ==='Pus For Cytology' || this.subcategory ==='Pus_For_Cytology') ? dd[3].innerHTML :(this.subcategory ==='Fnac Slides Reporting' || this.subcategory ==='Fnac_Slides_Reporting') ? dd[5].innerHTML:dd[7].innerHTML; 
            const result = [{
                lab_ref_no:lab_ref_no,
                clinical_Diagnosis:clinical_Diagnosis,
                clinical_Details:clinical_Details,
                specimen_type:specimen_type,
                specimen_Adequacy:specimen_Adequacy,
                microscopy:microscopy,
                organisms:organisms,
                interpretation:interpretation,
                impression:impression,
                note:note,
                cytology_type:this.cytology_type
            }]
            this.$emit('childMethod', result);
        }
    }
}
</script>


<style lang="scss" scoped>
.PusForCytology {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>