<template>
  <section id="auth-page">
    <div class="auth-card">
      <div class="auth-card-title">
        <div class="icon">
          <img
            src="@/assets/images/icons/lock.svg"
            alt="icon"
            class="img-fluid"
          />
        </div>
        <h3 class="title">Reset Password</h3>
        <p class="description">Enter your new Password</p>
        <p v-if="getForgotPasswordUsername">
          Email: {{ getForgotPasswordUsername }}
        </p>
      </div>
      <div class="auth-card-body">
        <el-form
          class="form-inputs"
          :model="passwordForm"
          :rules="loginFormRules"
          ref="passwordForm"
          @keyup.enter.prevent="submitForm('passwordForm')"
        >
          <div class="form-items">
            <div class="form-group">
              <el-form-item prop="password">
                <el-input
                  class="item-group"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  placeholder="Enter New password"
                  v-model="passwordForm.password"
                >
                  <template #suffix>
                    <img
                      src="@/assets/images/Open.png"
                      @click="isPasswordVisible = true"
                      v-if="!isPasswordVisible"
                    />
                    <img
                      src="@/assets/images/Close.png"
                      @click="isPasswordVisible = false"
                      v-if="isPasswordVisible"
                    />
                  </template>
                </el-input>
                <FormError errorName="password"></FormError>
              </el-form-item>
            </div>
            <div class="form-group">
              <el-form-item prop="confirm_password">
                <el-input
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  placeholder="Enter Confirm Password"
                  v-model="passwordForm.confirm_password"
                  class="item-group"
                >
                  <template #suffix>
                    <img
                      src="@/assets/images/Open.png"
                      @click="isConfirmPasswordVisible = true"
                      v-if="!isConfirmPasswordVisible"
                    />
                    <img
                      src="@/assets/images/Close.png"
                      @click="isConfirmPasswordVisible = false"
                      v-if="isConfirmPasswordVisible"
                    />
                  </template>
                </el-input>
                <FormError errorName="confirm_password"></FormError>
              </el-form-item>
            </div>
          </div>
          <div>
            <ul class="submit-action-group mt-40">
              <li>
                <el-button
                  class="cancel-button"
                  type="danger"
                  @click="goToSignIn"
                  >Cancel
                </el-button>
              </li>
              <li>
                <el-button
                  class="submit-button"
                  type="primary"
                  @click="submitForm('passwordForm')"
                  :loading="loading"
                  >Submit
                </el-button>
              </li>
            </ul>
          </div>
          <div class="invalid-message">
            <FormError errorName="critical_error"></FormError>
          </div>
        </el-form>
      </div>
    </div>
    <div class="logo">
      <img src="@/assets/images/yoda-logo.svg" alt="logo" class="img-fluid" />
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  layout: "auth",
  middleware: "guest",
  computed: {
    ...mapGetters("auth", [
      "getForgotPasswordUsername",
      "getPasswordResetStatus",
      "getOtpSentStatus",
      "getOtpVerificationStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  data() {
    return {
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      passwordForm: {
        username: "",
        password: "",
        confirm_password: "",
      },
      userForm: {
        username: "",
      },
      loading: false,
      loginFormRules: {
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: "Confirm password is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    FormError,
  },
  async mounted() {
    this.$store.dispatch("errors/clear");
    if (this.getForgotPasswordUsername && this.getOtpVerificationStatus) {
      this.passwordForm.username = this.getForgotPasswordUsername;
      this.userForm.username = this.getForgotPasswordUsername;
    } else {
      this.goToPreviousStep();
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.resetPassword();
        } else {
          return false;
        }
      });
    },
    async resetPassword() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "auth/resetForgotPassword",
          this.passwordForm
        );
        this.loading = false;
        if (this.getPasswordResetStatus) {
          successNotification("Password Updated Successfully");

          this.$store.commit("auth/setUsername", null);
          this.goToSignIn();
        } else if (!this.getErrors) {
          errorNotification(this.getError || "Error in update password");
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    goToPreviousStep() {
      this.$router.push("/forgot-password");
    },
    goToSignIn() {
      this.$router.push("/signin");
    },
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/components/authentication.scss";
</style>
