import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "/src/assets/scss/app.scss";
import "/src/assets/scss/index.scss";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueSignaturePad from "vue-signature-pad";
import "element-plus/theme-chalk/index.css";
import "@element-plus/theme-chalk/dist/index.css";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/theme-chalk/src/common/var.scss";

const app = createApp(App);

app.use(ElementPlus);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(store);
app.use(router);
app.use(CKEditor);
app.use(VueSignaturePad);
app.mount("#app");
