<template>
  <section id="auth-page" class="auth-page">
    <div class="auth-card">
      <div class="auth-card-title">
        <h3 class="title">Log In</h3>
        <p class="description">
          <span>Welcome back!</span> Please enter your details
        </p>
      </div>
      <div class="auth-card-body">
        <el-form
          class="form-inputs"
          :model="loginForm"
          :rules="loginFormRules"
          ref="loginForm"
          @keyup.enter.prevent="submitForm('loginForm')"
        >
          <div class="form-items">
            <div class="form-group">
              <el-form-item prop="username">
                <el-input
                  class="item-group convert-lower"
                  placeholder="Enter Username"
                  v-model="loginForm.username"
                ></el-input>
                <FormError errorName="username"></FormError>
              </el-form-item>
            </div>
            <div class="form-group">
              <el-form-item prop="password">
                <el-input
                  :type="isPasswordVisible ? 'text' : 'password'"
                  placeholder="Enter Password"
                  v-model="loginForm.password"
                  class="item-group"
                >
                </el-input>
                <FormError errorName="password"></FormError>
              </el-form-item>
            </div>
          </div>
          <div class="action-buttons">
            <el-button
              type="primary"
              @click="submitForm('loginForm')"
              :loading="loading"
              class="login-button"
              >LogIn
              <el-icon><Right /></el-icon>
            </el-button>
          </div>

          <div class="forgot-password-navigation">
            <router-link to="/forgot-password">Forgot password?</router-link>
          </div>
          <div class="error-center" v-if="getSessionError">
            <p class="error" v-if="getSessionError">{{ getSessionError }}</p>
          </div>
          <div class="error-center" v-else>
            <FormError errorName="critical_error"></FormError>
          </div>
        </el-form>
      </div>
    </div>
    <div class="logo">
      <img src="@/assets/images/yoda-logo.svg" alt="logo" class="img-fluid" />
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";

export default {
  layout: "auth",
  middleware: "guest",
  computed: {
    ...mapGetters("auth", ["getAuthenticationStatus","getProfileDetails"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  data() {
    return {
      isPasswordVisible: false,
      loginForm: {
        username: "",
        password: "",
      },
      loading: false,
      loginFormRules: {
        username: [
          {
            required: true,
            message: "User Name is required",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    FormError,
  },
  async mounted() {
    if (
      (this.getErrors &&
        this.getErrors["critical_error"] &&
        this.getErrors["critical_error"] != "Session Timeout") ||
      !this.getErrors["critical_error"]
    ) {
      this.$store.dispatch("errors/clear");
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.signIn();
        } else {
          return false;
        }
      });
    },
    async signIn() {
      try {
        this.loading = true;
        await this.$store.dispatch("auth/signin", this.loginForm);
        this.loading = false;
        if (this.getAuthenticationStatus) {
          this.$notify.success({
            title: "Success",
            message: "Signin Successfully",
          });
          this.goToDashboard();
        } else if (!this.getError) {
          this.$notify.error({
            title: "Error",
            message: "Error in signin",
          });
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    goToDashboard() {
      let query = this.$route.query;
      if (
        this.getProfileDetails &&
        this.getProfileDetails.user_type &&
        this.getProfileDetails.user_type === "SAMPLE_PROCESS_MANAGER"
      ) {
        this.$router.push({name:"Acknowledgements"})
      } else if (query) {
        let redirect = query.redirect;
        let routerParams = query.params ? JSON.parse(query.params) : {};
        let routerQuery = query.query ? JSON.parse(query.query) : {};
        if (redirect) {
          this.$router.push({
            name: redirect,
            query: routerQuery,
            params: routerParams,
          });
        } else {
          this.$router.push({ name: "PatientReports" });
        }
      } else {
        this.$router.push("/all-patient-reports");
      }
    },
    async toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/components/authentication.scss";
</style>
