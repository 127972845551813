/* eslint-disable */
<template>
  <section id="header">
    <div class="container">
      <el-row type="flex" align="middle">
        <!-- Just Logo -->
        <el-col :xs="10" :sm="4" v-if="isSampleManager">
          <div class="logo">
              <img
                src="@/assets/images/yoda-logo.svg"
                alt="logo"
                class="img-fluid"
              />
          </div>
        </el-col>
        <!-- Logo Link -->
        <el-col :xs="12" :sm="4" v-if="!isSampleManager">
          <div class="logo">
            <router-link to="/">
              <img
                src="@/assets/images/yoda-logo.svg"
                alt="logo"
                class="img-fluid"
              />
            </router-link>
          </div>
        </el-col>
        <!-- Web Menu -->
        <el-col :xs="12" :sm="20" class="hidden-md-and-down">
          <ul class="menu-links">
            <li v-if="!isSampleManager">
              <router-link to="/all-patient-reports"
                >Patient Reports</router-link
              >
            </li>
            <!-- <li v-if="!isSampleManager">
              <router-link to="/corporate-reports"
                >Corporate Reports</router-link
              >
            </li> -->
            <li v-if="isAbleToEdit && !isSampleManager">
              <router-link to="/all-report-templates">Templates</router-link>
            </li>
            <li v-if="isAbleToAddBiochemistry">
              <router-link to="/all-biochemistry-interpretations"
                >Biochemistry Interperations</router-link
              >
            </li>
            <li v-if="isAbleToAddBiochemicalGenetics">
              <router-link to="/all-result-files">Result Files</router-link>
            </li>
            <!-- <li v-if="isAbleToAddBiochemicalGenetics">
              <router-link to="/all-test-results">Test Results</router-link> 
            </li> -->
            <li v-if="isAbleToAddBiochemicalGenetics">
              <router-link to="patient-reports/clinical-details">Clinical Details</router-link>
            </li>
            <li v-if="isAbleToAddBiochemicalGenetics">
              <router-link
                :to="{
                  name: 'AllTestConfig',
                }"
                >Test Config</router-link
              >
            </li>
            <li v-if="isAdmin">
              <router-link
                :to="{
                  name: 'UsersList',
                }"
                >Users</router-link
              >
            </li>
            <li v-if="hasAdmin">
              <router-link
                :to="{
                  name: 'ReportLogs',
                }"
                >Reports Logs</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Acknowledgements',
                }"
                >Acknowledgements</router-link
              >
            </li>
            <li v-if="getProfileDetails && getProfileDetails.first_name">
              <div
                class="web-user-log remove-highlight"
                style="display: flex; align-items: center"
              >
                <span class="name" style="display: flex; align-items: center">
                  <el-icon style="margin-right: 5px">
                    <User />
                  </el-icon>
                  {{ getProfileDetails.first_name }}
                </span>
                <el-menu
                  class="el-menu-navigation web-user-log"
                  mode="horizontal"
                  @select="handleSelect"
                  popper-append-to-body="true"
                >
                  <el-submenu index="1">
                    <template #title>
                      <el-icon>
                        <User />
                      </el-icon>
                      <span class="name">
                        {{ getProfileDetails.first_name }}
                      </span>
                    </template>
                    <el-menu-item index="1-0">
                      <el-icon>
                        <User />
                      </el-icon>
                      View Profile
                    </el-menu-item>
                    <el-menu-item index="1-1">
                      <el-icon><Key /></el-icon>
                      Update Password</el-menu-item
                    >
                    <el-menu-item index="1-3">
                      <el-icon><Setting /></el-icon>Settings</el-menu-item
                    >
                    <el-menu-item index="1-2"
                      ><el-icon><SwitchButton /></el-icon> Logout</el-menu-item
                    >
                  </el-submenu>
                </el-menu>
              </div>
            </li>
          </ul>
        </el-col>
        <!-- Mobile Menu -->
        <el-col :xs="12" :sm="20">
          <div class="mobileToggleButton hidden-lg-and-up">
            <ul>
              <li v-if="getProfileDetails && getProfileDetails.first_name">
                <div
                  class="web-user-log remove-highlight"
                  style="display: flex; align-items: center"
                >
                  <span class="name" style="display: flex; align-items: center">
                    <el-icon style="margin-right: 5px">
                      <User />
                    </el-icon>
                    {{ getProfileDetails.first_name }}
                  </span>
                  <el-menu
                    class="el-menu-navigation web-user-log"
                    mode="horizontal"
                    @select="handleSelect"
                    popper-append-to-body="true"
                  >
                    <el-submenu index="1">
                      <template #title>
                        <el-icon>
                          <User />
                        </el-icon>
                        <span class="name">
                          {{ getProfileDetails.first_name }}
                        </span>
                      </template>
                      <el-menu-item index="1-0">
                        <el-icon>
                          <User />
                        </el-icon>
                        View Profile
                      </el-menu-item>
                      <el-menu-item index="1-1">
                        <el-icon><Key /></el-icon>
                        Update Password</el-menu-item
                      >
                      <el-menu-item index="1-3">
                        <el-icon><Setting /></el-icon>Settings</el-menu-item
                      >
                      <el-menu-item index="1-2"
                        ><el-icon><SwitchButton /></el-icon>
                        Logout</el-menu-item
                      >
                    </el-submenu>
                  </el-menu>
                </div>
              </li>
              <li @click="drawer = true" class="hamburgerIcon">
                <el-icon><Menu /></el-icon>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>

      <div class="mobileMenuDrawer">
        <el-drawer
          v-model="drawer"
          :direction="direction"
        >
          <ul class="menu-links">
            <li v-if="!isSampleManager">
              <router-link to="/all-patient-reports"
                >Patient Reports</router-link
              >
            </li>
            <li v-if="isAbleToEdit && !isSampleManager">
              <router-link to="/all-report-templates">Templates</router-link>
            </li>
            <li v-if="isAbleToAddBiochemistry">
              <router-link to="/all-biochemistry-interpretations"
                >Biochemistry Interperations</router-link
              >
            </li>
            <li v-if="isAbleToAddBiochemicalGenetics">
              <router-link to="/all-result-files">Result Files</router-link>
            </li>
            <!-- <li v-if="isAbleToAddBiochemicalGenetics">
              <router-link to="/all-test-results">Test Results</router-link>
            </li> -->
            <li v-if="isAbleToAddBiochemicalGenetics">
              <router-link
                :to="{
                  name: 'AllTestConfig',
                }"
                >Test Config</router-link
              >
            </li>
            <li v-if="isAdmin">
              <router-link
                :to="{
                  name: 'UsersList',
                }"
                >Users</router-link
              >
            </li>
            <li v-if="hasAdmin">
              <router-link
                :to="{
                  name: 'ReportLogs',
                }"
                >Reports Logs</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Acknowledgements',
                }"
                >Acknowledgements</router-link
              >
            </li>
          </ul>
        </el-drawer>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { userTypes } from "@/constants/formConstants";
export default {
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getProfileDetails"]),
    isAbleToEdit() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["FRONT_DESK", "HELP_DESK"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return false;
      }
      return true;
    },
    isAbleToAddBiochemistry() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.departments &&
        this.getProfileDetails.departments.indexOf("BIO_CHEMISTRY") != -1 &&
        this.isAbleToEdit
      ) {
        return true;
      }
      return false;
    },
    isAbleToAddBiochemicalGenetics() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.departments &&
        this.getProfileDetails.departments.indexOf("BIOCHEMICAL_GENETICS") !=
          -1 &&
        this.isAbleToEdit
      ) {
        return true;
      }
      return false;
    },
    isAdmin() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.user_type &&
        this.getProfileDetails.user_type === "SUPER_ADMIN"
      ) {
        return true;
      }
      return false;
    },
    hasAdmin() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        ["FRONT_DESK", "HELP_DESK", "ADMIN"].indexOf(
          this.getAuthenticatedUser.user_type
        ) != -1
      ) {
        return true;
      }
      return false;
    },
    isSampleManager() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.user_type &&
        this.getProfileDetails.user_type === "SAMPLE_PROCESS_MANAGER"
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      drawer: false,
      userTypes: userTypes,

      navbarDrawer: false,
      direction: "ltr",
      userData: {},
      defaultUserRoleTypes: {
        SALES_PERSON: "Sales Person",
        ADMIN: "Admin",
      },
      profileLoadingRoutes: ["ViewProfile", "EditProfile", "PatientReportView"],
      profileDataloading: false,
    };
  },
  methods: {
    async logout() {
      await this.resetStore();
      this.$router.push({ name: "signin" });
    },
    handleSelect(key) {
      if (key == "1-0") {
        this.$router.push({ path: "/view-profile" });
      } else if (key == "1-1") {
        this.$router.push({ path: "/update-password" });
      } else if (key == "1-2") {
        this.logout();
      } else if (key == "1-3") {
        this.$router.push({ path: "/settings" });
      }
    },
    async resetStore() {
      await this.$store.dispatch("globalReset");
    },
    async fetchProfile() {
      try {
        this.profileDataloading = true;
        await this.$store.dispatch("auth/getProfile");
        this.profileDataloading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (val) {
        if (this.profileLoadingRoutes.indexOf(val.name) == -1) {
          this.fetchProfile();
        }
      },
    },
  },
};
</script>

<style lang="scss">
#header {
  background: #fff;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  .logo {
    display: flex;
    a {
      display: inline-block;
    }
    img {
      width: 280px;
    }
    .img-fluid {
        max-width: 100%;
    }
  }

  .nuxt-link-active {
    color: #219388 !important;
  }

  .el-drawer {
    width: 300px !important;
    @media (max-width: 300px) {
      width: 100% !important;
    }
  }
  .menu-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
      a {
        font-size: 15px;
        color: #333;
        &:hover {
          color: #219388 !important;
        }
        &.router-link-exact-active {
          color: #219388 !important;
        }
      }
    }
  }
  .el-menu-navigation {
    &:before {
      content: "";
      display: none;
    }
    &:hover {
      background: transparent;
    }
    // display: grid;
    // grid-template-columns: auto auto 80px;
    // padding: 0;
    // align-items: center;
    // justify-content: end;
    &.el-menu.el-menu--horizontal {
      border: none;
      background: transparent;
    }

    .el-submenu__title i {
      color: #242424;
    }
    .location-count {
      padding: 0.75em 0.75em;
      background-color: #215fad;
      color: #ffffff;
      border-radius: 5px;
      font-size: 15px;
      .count {
        background-color: #ffffff;
        color: #242424;
        padding: 3px 6px;
        margin-left: 10px;
        border-radius: 4px;
      }
    }
    .profile-name {
      padding-left: 1em;
      font-size: 14px;
      text-align: right;
      span {
        color: #215fad;
        padding-top: 3px;
        display: block;
      }
    }
    li {
      .page-namer {
        text-transform: uppercase;
        display: flex;
        letter-spacing: 0.3px;
        font-size: 1.3em;
        color: #009585;
      }
      &.user-dropdown {
        &:hover {
          cursor: pointer;
        }
        .el-dropdown {
          .el-icon-user {
            padding-right: 5px;
          }
          span {
            font-size: 17px;
            color: #000;
          }
        }
      }
      @media (max-width: 768px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  .el-dropdown-link {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    &:hover {
      color: #219388 !important;
      cursor: pointer;
    }
    .el-icon-arrow-down {
      color: #333;
      font-weight: 500;
    }
  }
  .mobile-menu {
    display: flex;
    justify-content: flex-end;
    @media (min-width: 991px) {
      display: none !important;
    }
    button {
      padding: 0;
      background: transparent;
      border: none;
    }
    img {
      width: 35px;
    }
  }
  .mobile-menu-navigation {
    .el-menu {
      .el-menu-item {
        padding: 0 !important;
        height: auto;
        line-height: 1;
        img {
          width: 17px;
          margin-right: 10px;
          margin-top: -3px;
        }
      }
      .el-menu-item-group__title {
        display: none;
      }
      .el-submenu__title {
        height: auto;
        line-height: 1;
        padding: 15px 25px !important;
        .el-submenu__icon-arrow {
          font-size: 18px;
          color: #000;
        }
      }
      .el-submenu {
        padding: 0 !important;
        height: auto;
        line-height: 1;
        img {
          width: 18px;
          margin-right: 5px;
        }
      }
    }
  }
  .el-drawer__header {
    margin-bottom: 15px !important;
    display: none;
  }
  .top-header {
    background: #f9f6f6;
    padding: 15px 20px !important;
    margin-bottom: 0 !important;
    .user-details {
      display: flex;
      align-items: center;
      .icon {
        padding-right: 10px;
        span {
          font-size: 20px;
        }
      }
      .text {
        span {
          display: block;
          &.name {
            font-size: 16px;
            line-height: 15px;
            color: #00d1bb;
            font-weight: 500;
          }
          &.role {
            font-size: 12px;
            color: #6a6a6a;
          }
        }
      }
    }
    .close-button {
      display: flex;
      justify-content: flex-end;
      .el-button {
        background: #00d1bb;
        border-radius: 3px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
  #el-drawer__title {
    background: #f9f6f6;
    padding: 15px 20px !important;
  }
  .el-drawer__body {
    ul {
      li {
        border-bottom: 1px solid rgba(0, 209, 188, 0.2);
        a,
        .logout-btn {
          font-weight: 500;
          color: rgb(94, 91, 91);
          font-size: 1em;
          padding: 15px 25px;
          display: block;
          transition: all 0.2s ease-in-out;
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
  }
  .el-drawer__close-btn {
    background: #00d1bb;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: #fff;
      font-weight: 800;
    }
  }
}

.logout-button {
  font-size: 1.02em;
  color: #333;
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: left;
  padding: 15px 25px;
  &:hover {
    color: #333;
  }
}
.logout-button-web {
  font-size: 1.02em;
  color: #333;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: 0;
  &:hover {
    color: #00d1bb;
  }
}
.remove-highlight {
  .el-submenu__title {
    border-bottom: none !important;
  }
}
</style>
