<template>
  <section class="profile-view" id="settingsView">
    <div class="card" v-loading="loading" :element-loading-text="loadingText">
      <div class="cardHeader">
        <h3>Settings</h3>
      </div>
      <div class="cardBody">
        <ul>
          <li>
            <h4>Send Report to Patient Whatsapp on Approve</h4>
            <el-switch
              v-model="sendReport"
              :disabled="isAdmin"
              @change="updateAdminPanelSettings"
            >
            </el-switch>
          </li>
          <li>
            <h4>Send Email on Approve Report</h4>
            <el-switch
              v-model="sendEmailStatus"
              :disabled="isAdmin"
              @change="updateAdminPanelSettings"
            >
            </el-switch>
          </li>
          <li>
            <h4>Send SMS on Approve Report</h4>
            <el-switch
              v-model="sendSmsStatus"
              :disabled="isAdmin"
              @change="updateAdminPanelSettings"
            >
            </el-switch>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["getAdminSettings", "getAdminSettingsUpdateStatus"]),
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", ["getProfileDetails"]),
    isAdmin() {
      if (
        this.getProfileDetails &&
        this.getProfileDetails.user_type &&
        this.getProfileDetails.user_type == "ADMIN"
      ) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      sendReport: "",
      sendSmsStatus: "",
      sendEmailStatus: "",
      loading: false,
      loadingText: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("errors/clear", {}, { root: true });

    await this.fetchAdminSettings();
  },
  methods: {
    async fetchAdminSettings() {
      try {
        this.loading = true;
        await this.$store.dispatch("auth/fetchAdminSettings");
        if (this.getAdminSettings) {
          this.sendReport =
            this.getAdminSettings.send_report_to_patient_whatsapp_on_approve;
          this.sendSmsStatus = this.getAdminSettings.sms_sent_on_approve_report;
          this.sendEmailStatus =
            this.getAdminSettings.email_sent_on_approve_report;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async updateAdminPanelSettings() {
      try {
        this.loading = true;
        this.loadingText = "Updating Settings";
        let params = {
          settingsId: this.getAdminSettings._id,
          send_report_to_patient_whatsapp_on_approve: this.sendReport,
          email_sent_on_approve_report: this.sendEmailStatus,
          sms_sent_on_approve_report: this.sendSmsStatus,
        };
        await this.$store.dispatch("auth/updateAdminSettings", params);
        if (this.getAdminSettingsUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Settings updated successfully",
          });
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error in updating Settings",
          });
        }
        this.loading = false;
        this.loadingText = "";
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#settingsView {
  .card {
    border-radius: 10px;
    margin: auto;
    border: 1px solid #efefef;
    background-color: white;
    max-width: 600px;
    .cardHeader {
      padding: 20px 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      h3 {
        font-size: 1.05em;
        font-weight: 500;
        color: rgb(24, 84, 109);
        line-height: 1;
      }
    }
    .cardBody {
      padding: 20px 30px;
      li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        h4 {
          font-weight: 400;
        }
      }
    }
  }
}
</style>
