<template>
  <section id="auth-page" class="auth-page forgot-password">
    <div class="auth-card">
      <div class="auth-card-title">
        <div class="icon">
          <img
            src="@/assets/images/icons/lock.svg"
            alt="icon"
            class="img-fluid"
          />
        </div>
        <h3 class="title">Forgot Password</h3>
        <p class="description">
          Provide your account's email for which you <br />
          want to reset your password
        </p>
      </div>
      <div class="auth-card-body">
        <div class="form-group">
          <el-form-item>
            <label for="">Email Address</label>
            <el-input
              class="item-group convert-lower"
              placeholder="Enter Email"
              v-model="userForm.username"
            ></el-input>
            <!-- <div class="invalid-message"> -->
            <FormError errorName="username"></FormError>
            <FormError errorName="critical_error"></FormError>
            <!-- </div> -->
          </el-form-item>
        </div>
        <ul class="submit-action-group">
          <li>
            <el-button class="cancel-button" type="danger" @click="goToSignIn"
              >Cancel
            </el-button>
          </li>
          <li>
            <el-button
              class="submit-button"
              type="primary"
              @click="verifyUsername"
              :loading="loading"
              >Submit
            </el-button>
          </li>
        </ul>
        <!-- <div class="invalid-message">
          <FormError errorName="critical_error"></FormError>
        </div> -->
      </div>
    </div>
    <div class="logo">
      <img src="@/assets/images/yoda-logo.svg" alt="logo" class="img-fluid" />
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";

import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  layout: "auth",
  middleware: "guest",
  computed: {
    ...mapGetters("auth", ["getOtpSentStatus"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  data() {
    return {
      userForm: {
        username: "",
      },
      loading: false,
      loginFormRules: {
        username: [
          {
            required: true,
            message: "User Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    FormError,
  },
  async mounted() {
    this.$store.commit("auth/setOtpVerificationStatus", false, { root: true });
    this.$store.dispatch("errors/clear");
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.verifyUsername();
        } else {
          return false;
        }
      });
    },
    async verifyUsername() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "auth/sendForgotPasswordVerification",
          this.userForm
        );
        this.loading = false;
        if (this.getOtpSentStatus && this.getOtpSentStatus.success) {
          successNotification("OTP sent to email successfully");
          this.goToOtpValidation();
        } else if (!this.getErrors) {
          errorNotification(this.getError || "Error while sending OTP");
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    goToOtpValidation() {
      this.$router.push({
        name: "OtpVerification",
        path: "/forgot-password/otp/verify",
      });
    },
    goToSignIn() {
      this.$router.push("/signin");
    },
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/components/authentication.scss";
</style>
