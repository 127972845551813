<template>
  <section
    id="all-test-configuration"
    :element-loading-text="loadingText"
    v-loading.fullscreen.lock="loading"
  >
    <div
      class="inner"
      v-if="
        testConfig && Object.keys(testConfig) && Object.keys(testConfig).length
      "
    >
      <div
        class="each-test-table"
        v-for="(test, index) of testConfig"
        :key="index"
      >
        <h3 class="test-name">{{ index }}</h3>
        <div class="vue-table">
          <el-table
            :data="test"
            style="width: 100%"
            height="calc(100vh - 250px)"
            class="table-list display-web"
          >
            <el-table-column
              fixed
              label="Full Name"
              min-width="160"
              class="result"
            >
              <template #default="scope">
                <el-input
                  v-model="test[scope.$index].fullname"
                  placeholder="Enter full name"
                  :disabled="!isAbleToAccessEdit"
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column label="Test" width="180">
              <template #default="scope">
                <el-input
                  v-model="test[scope.$index].test"
                  :disabled="true"
                  placeholder="Enter test name"
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column label="Lower Limit" min-width="80">
              <template #default="scope">
                <el-input
                  v-model="test[scope.$index].lower_limit"
                  placeholder="Enter lower limit"
                  :disabled="!isAbleToAccessEdit"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Upper Limit" min-width="80">
              <template #default="scope">
                <el-input
                  v-model="test[scope.$index].upper_limit"
                  placeholder="Enter upper limit"
                  :disabled="!isAbleToAccessEdit"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="UNITS" min-width="40">
              <template #default="scope">
                <el-input
                  v-model="test[scope.$index].units"
                  placeholder="Units"
                  :disabled="!isAbleToAccessEdit"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Show In Report" width="130">
              <template #default="scope">
                <el-switch
                  v-model="test[scope.$index].show_in_report"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :disabled="!isAbleToAccessEdit"
                />
              </template>
            </el-table-column>
            <el-table-column label="Description" min-width="200">
              <template #default="scope">
                <el-input
                  :disabled="true"
                  type="textarea"
                  :rows="2"
                  v-model="test[scope.$index].test_category_description"
                  placeholder="Enter category description"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="action-buttons" v-if="isAbleToAccessEdit">
      <el-button type="primary" @click="updateResultsData">Save</el-button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
export default {
  mixins: [userRolesPermissionsMixin],
  data() {
    return {
      activeName: "",

      loading: false,

      testResults: [],
      testConfig: {},
      loadingText: "",
    };
  },
  async mounted() {
    await this.fetchAllTestConfig();
  },
  computed: {
    ...mapGetters("testConfig", [
      "getAllTestsConfig",
      "getTestConfigUpdateStatus",
    ]),
  },

  methods: {
    async fetchAllTestConfig() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Test Config...";
        let params = {
          department: "BIOCHEMICAL-GENETICS",
          category: "TMS",
          sub_category: "TMS",
        };

        await this.$store.dispatch("testConfig/fetchAllTestsConfig", params);
        if (this.getAllTestsConfig && this.getAllTestsConfig.tests);
        {
          this.testResults = this.getAllTestsConfig.tests;
          // this.testResults = this.getCaseInformation.test_results;
          if (this.testResults && this.testResults.length) {
            let group = this.testResults.reduce((r, a) => {
              r[a.test_category] = [...(r[a.test_category] || []), a];
              return r;
            }, {});
            this.testConfig = group;
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async updateResultsData() {
      try {
        this.loading = true;

        let params = {
          tests: this.testResults,
          test_id: this.getAllTestsConfig._id,
        };

        await this.$store.dispatch("testConfig/updateAllTestsConfig", params);
        this.loading = false;

        if (this.getTestConfigUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Test Config Updated Successfully",
          });
          await this.fetchAllTestConfig();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error on updating test config",
          });
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#all-test-configuration {
  position: relative;
  .inner {
    margin-bottom: 50px;
    .each-test-table {
      background: #fff;
      padding: 20px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      .test-name {
        color: #333;
        font-size: 1.1em;
        margin-bottom: 10px;
      }
      .vue-table {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        overflow: hidden;
        .el-input__inner {
          height: 30px;
        }
      }
    }
  }

  .action-buttons {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background: #ffff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .el-button {
      margin-right: 40px;
      padding: 8px 30px;
      min-height: auto;
      background: #0061ec;
      color: #fff;
      font-weight: 600;
    }
  }
}
</style>
