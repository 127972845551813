<template>
  <section
    id="add-report-view"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
  >
    <div class="reports-title-header">
      <h4 class="report-title">Biochemical Genetics - Patient Report Data</h4>
      <div class="display-end">
        <el-button @click="goToReports" class="back-btn"
          ><el-icon class="el-icon-back"><Back /></el-icon>Back</el-button
        >
      </div>
    </div>

    <div class="inner-navbar">
      <el-row :gutter="10">
        <el-col :span="8">
          <div>
            <el-autocomplete
              v-model="searchVisitId"
              :fetch-suggestions="getAutoCompletePatientsData"
              placeholder="Search VisitID, Patient Name,UHID"
              :clearable="true"
              :trigger-on-focus="false"
              class="el-input"
              @select="handleSelect"
              @clear="clearNameSearch"
            ></el-autocomplete>
          </div>
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="reportType"
            placeholder="Select Report Type *"
            :clearable="true"
            filterable
            @clear="clearFields"
            @change="onChangeReportType()"
          >
            <el-option
              v-for="(report, index) in reportTypes"
              :key="index"
              :label="report.label"
              :value="report.value"
            ></el-option>
          </el-select>
          <FormError errorName="report_type"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="testId"
            placeholder="Select Test Category "
            :clearable="true"
            filterable
            @clear="askRemoveConfirmation"
            @change="fillSampleType"
          >
            <el-option
              v-for="report in categoryReportTypes"
              :key="report"
              :label="report.ItemName + '   --  ' + report.TestId"
              :value="report.TestId"
            ></el-option>
          </el-select>

          <FormError errorName="report_type"></FormError>
        </el-col>
      </el-row>
      <hr />
      <el-row :gutter="10">
        <el-col :span="8">
          <el-select
            v-model="patientInfo.department"
            allow-create
            placeholder="Select Department"
            :clearable="true"
            filterable
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>

          <FormError errorName="department"></FormError>
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="patientInfo.report_template_id"
            placeholder="Select Report Template"
            :clearable="true"
            filterable
            @clear="askRemoveConfirmation"
            @change="updateTemplateData"
          >
            <el-option
              v-for="(template, index) in templates"
              :key="index"
              :label="template.template_title"
              :value="template._id"
            ></el-option>
          </el-select>

          <FormError errorName="report_template_id"></FormError>
        </el-col>
      </el-row>
    </div>
    <div class="inner">
      <span class="report-header">{{ patientInfo.department }}</span>
      <div class="patient-information">
        <el-form :model="patientInfo">
          <el-row class="mb-10">
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Patient Name"
                class="mb-05 form-mandatory-field"
              >
                <el-input
                  v-model="patientInfo.patient_name"
                  placeholder="Enter Patient Name"
                ></el-input>
              </el-form-item>
              <FormError errorName="patient_name"></FormError>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Visit Id">
                <el-input
                  v-model="patientInfo.visit_id"
                  placeholder="Enter Visit Id"
                  disabled
                ></el-input>
                <FormError errorName="visit_id"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Barcode No">
                <el-input
                  v-model="patientInfo.barcode_no"
                  placeholder="Enter Barcode No"
                ></el-input>
                <FormError errorName="barcode_no"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Ref. Doctor" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.ref_doctor"
                  placeholder="Enter Reference Doctor"
                ></el-input>
                <FormError errorName="ref_doctor"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="UHID/MR No" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.uhid"
                  placeholder="Enter UHID No"
                ></el-input>
                <FormError errorName="uhid"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Gender" class="mb-05 mandatory-field">
                <div class="gender-select">
                  <el-select
                    v-model="patientInfo.gender"
                    placeholder="Select Gender"
                  >
                    <el-option
                      v-for="(gender, index) in genderTypes"
                      :key="index"
                      :label="gender"
                      :value="gender"
                    ></el-option>
                  </el-select>
                  <FormError errorName="gender"></FormError>
                </div>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Age" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.age"
                  placeholder="Enter Age"
                ></el-input>
                <FormError errorName="age"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Mobile" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.mobile"
                  placeholder="Enter Mobile Number"
                ></el-input>
                <FormError errorName="mobile"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Phone" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.alternate_mobile"
                  placeholder="Enter Phone Number"
                ></el-input>
                <FormError errorName="alternate_mobile"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Email" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.email"
                  placeholder="Enter Email"
                ></el-input>
                <FormError errorName="email"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Locality" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.locality"
                  placeholder="Enter Locality"
                ></el-input>
                <FormError errorName="locality"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Hospital Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.hospital_name"
                  placeholder="Enter Hospital Name"
                ></el-input>
                <FormError errorName="hospital_name"></FormError>
              </el-form-item>
            </el-col>

            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Registration Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.registration_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="registration_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Received Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.received_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="received_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Reported Date" class="mb-05 mandatory-field">
                <el-date-picker
                  v-model="patientInfo.reported_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="reported_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item
                label="Collected Date"
                class="mb-05 mandatory-field"
              >
                <el-date-picker
                  v-model="patientInfo.collected_date"
                  type="datetime"
                  placeholder="Pick a Date"
                  format="DD-MM-YYYY hh:mm A"
                  :disabled-date="disabledDate"
                ></el-date-picker>
                <FormError errorName="collected_date"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Test Name" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.test_name"
                  placeholder="Enter Test Name"
                ></el-input>
                <FormError errorName="test_name"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Sample Type" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.sample_type"
                  placeholder="Enter Sample Type"
                ></el-input>
                <FormError errorName="sample_type"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Code" class="mb-05 mandatory-field">
                <el-input
                  v-model="patientInfo.client_code"
                  placeholder="Enter Client Code"
                ></el-input>
                <FormError errorName="client_code"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Address" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_add" placeholder="Enter Client Address" readonly></el-input>
                <FormError errorName="client_add"></FormError>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24">
              <el-form-item label="Client Name" class="mb-05 mandatory-field">
                <el-input v-model="patientInfo.client_name" placeholder="Enter Client Name" readonly></el-input>
                <FormError errorName="client_name"></FormError>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div
        class="report-sub-title"
        v-if="showReportType && patientInfo.report_type"
      >
        {{ patientInfo.report_type }}
        <span @click="onEditReportType" class="cursor-pointer">
          <el-icon><Edit /></el-icon>
        </span>
      </div>
      <div class="report-type-edit-info" v-else-if="editReportType">
        <el-input v-model="reportTypeNew"></el-input>
        <el-button
          type="success"
          @click="updateReportType"
          :disabled="isReportTypeUpdateDisable"
        >
          <el-icon><Check /></el-icon>
        </el-button>
        <el-button type="danger" @click="cancelUpdateReportType">
          <el-icon><Close /></el-icon>
        </el-button>
      </div>
      <div class="editor" v-if="show && checkCKEditorContent()" v-loading="loadingEditor">
        <ckeditor
          :editor="editor"
          tag-name="div"
          ref="myEditor"
          v-model="editorData"
          :config="editorConfig"
        ></ckeditor>
      </div>
      <!-- FabryLeukocytes -->
      <div v-if="(this.onCheckbioSubCategory()) && this.patientInfo.report_template_id && show">
        <FabryLeukocytes :Fabry_Leukocytes_Array="patientInfo.Fabry_Leukocytes" :patientInfo="this.patientInfo" ref="childComponentRef" @childMethod="SavefabricData"></FabryLeukocytes>
        </div>
      <!-- FabryLeukocytes -->

      <!-- MPSILeukocytes -->
     <div v-if="(reportType==='MPS I_Leukocytes' || reportType==='MLD_Leukocytes') && this.patientInfo.report_template_id && show">
        <MPSILeukocytes :Fabry_Leukocytes_Array="patientInfo.Fabry_Leukocytes" :patientInfo="this.patientInfo" ref="childComponentRef" @childMethod="SavefabricData"></MPSILeukocytes>
      </div>
    <!-- MPSILeukocytes -->

    <!-- MLDLeukocytes not using this component-->
      <div v-if="reportType==='test MLDLeukocytes'">
        <MLDLeukocytes></MLDLeukocytes>
       </div>
    <!-- MLDLeukocytes -->

         <!-- KrabbeLeukocytes  not using this component-->
     <div v-if="reportType==='testKrabbeLeukocytes'">
        <KrabbeLeukocytes></KrabbeLeukocytes>
      </div>
    <!-- KrabbeLeukocytes -->

         <!-- LeukocytesNCL2 not using this component-->
     <div v-if="reportType==='testLeukocytesNCL2'">
        <LeukocytesNCL2></LeukocytesNCL2>
      </div>
    <!-- LeukocytesNCL2 -->

        <!-- GaucherLeukocytes this component not using -->
        <div v-if="reportType==='testGaucherLeukocytes'">
        <GaucherLeukocytes></GaucherLeukocytes>
      </div>
    <!-- GaucherLeukocytes -->

    <!-- GlycoasparginaseLeukocytes this component not using-->
    <div v-if="reportType==='test GlycoasparginaseLeukocytes'">
        <GlycoasparginaseLeukocytes></GlycoasparginaseLeukocytes>
      </div>
    <!-- GlycoasparginaseLeukocytes -->

    <!-- GM1Leukocytes -->
    <div v-if="reportType==='GM1_Leukocytes' && this.patientInfo.report_template_id && show">
      <GM1Leukocytes :GM1_Leukocytes_Array="patientInfo.GM1_Leukocytes" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="SaveGM1Data"></GM1Leukocytes>
      </div>
    <!-- GM1Leukocytes -->

     <!-- MPSIILeukocytes --> 
      <div v-if="reportType==='MPS II_Leukocytes' && this.patientInfo.report_template_id && show">
        <MPSIILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIILeukocytes>
      </div>
    <!-- MPSIILeukocytes -->

    <!-- MPSIIIALeukocytes -->
    <div v-if="reportType==='MPS IIIA_Leukocytes' && this.patientInfo.report_template_id && show ">
        <MPSIIIALeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes" ></MPSIIIALeukocytes>
      </div>
    <!-- MPSIIIALeukocytes -->

    <!-- MPSIIIBLeukocytes -->
      <div v-if="reportType==='MPS IIIB_Leukocytes' && this.patientInfo.report_template_id && show">
        <MPSIIIBLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes" ></MPSIIIBLeukocytes>
      </div>
    <!-- MPSIIIBLeukocytes -->

    <!-- MPSIIICLeukocytes -->
    <div v-if="reportType==='MPS IIIC_Leukocytes' && this.patientInfo.report_template_id && show">
        <MPSIIICLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIICLeukocytes>
      </div>
    <!-- MPSIIICLeukocytes -->

    
    <!-- MPSIIIDLeukocytes -->
    <div v-if="reportType==='MPS IIID_Leukocytes' && this.patientInfo.report_template_id && show">
        <MPSIIIDLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIIDLeukocytes>
      </div>
    <!-- MPSIIIDLeukocytes -->
      
    <!-- MPSIVALeukocytes -->
    <div v-if="reportType==='MPS IVA_Leukocytes' && this.patientInfo.report_template_id && show">
        <MPSIVALeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIVALeukocytes>
      </div>
    <!-- MPSIVALeukocytes -->

   <!-- MPSVILeukocytes -->
       <div v-if="reportType==='MPS VI_Leukocytes' && this.patientInfo.report_template_id && show">
        <MPSVILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSVILeukocytes>
      </div>
    <!-- MPSVILeukocytes -->

    <!-- MPSVIILeukocytes -->
    <div v-if="reportType==='MPS VII_Leukocytes' && this.patientInfo.report_template_id && show">
        <MPSVIILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" :patientInfo="this.patientInfo" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSVIILeukocytes>
      </div>

    <!-- MPSVIILeukocytes -->
    <ResonForChange v-if="takeReasonDialog" v-on:close="takeReasonDialog = false" v-on:reasonSubmit="updateReport">
    </ResonForChange>

    <div v-if="onCheckSubCategory() && patientInfo.report_template_id && onShowChildComponemts">
        <BioChemicalGeneticsGlobal :reportDetails="this.patientInfo" @savePatientDetails="savePatientDetails" ref="childComponentRef"></BioChemicalGeneticsGlobal>
        </div>
        <div v-if="this.patientInfo.sub_category=='URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION(SAMRAKSHA)' || this.patientInfo.sub_category=='URINARY_GLYCOSAMINOGLYCANS_GAG_QUANTIFICATION_SS' && show">
          <UrinaryGlycosaminoglycansQuantification :gagArray="this.patientInfo.GagList" ref="childComponentRef" @childMethod="saveFilteredData"></UrinaryGlycosaminoglycansQuantification>
        </div>
        <el-row  style="margin-left:10px" v-if="!this.checkCKEditorContent()">
          <!-- <el-col :span="6">
            <el-checkbox label="Need to add Addendum" v-model="isaddendumChecked" @change="handleCheckedCitiesChange" />
          </el-col> -->
          <el-col :span="6">
            <el-checkbox label="Additional approval required" v-model="this.patientInfo.is_required_signature" @change="handleCheckedChange" 
            :disabled="this.patientInfo.report_status === 'APPROVED' && this.patientInfo.fulfilled_signatures === 2"
            /> 
          </el-col>
       </el-row>      
      <!-- <div v-if="$route.params.patient_id">
        <qrcode-vue :value="value"></qrcode-vue>
      </div>-->
        <div class="p-10">
        <!-- <el-row v-if="patientInfo.report_template_id">
              <el-col :span="6">
                <el-checkbox label="Additional approval required" v-model="patientInfo.is_required_signature"
                  @change="handleCheckedCitiesChange"
                  :disabled="this.patientInfo.report_status === 'APPROVED' && this.patientInfo.fulfilled_signatures === 2" />
              </el-col>
            </el-row> -->
          </div>
   
     
      <FormError errorName="critical_error"></FormError>

      <ul class="action-buttons">
        <!-- <li>
          <el-button @click="clearForm" class="submit-button"
            >Clear Form</el-button
          >
        </li>-->
        <li>
          <el-button @click="printReport" class="preview-button print-button"
            >Print</el-button
          >
        </li>
        <li>
          <el-button @click="previewReport" class="preview-button"
            >Preview</el-button
          >
        </li>
        <!-- <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Save as Draft</el-button
          >
        </li>-->
        <li v-if="!isEditForm">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button"
            >Submit</el-button
          >
        </li>
        <!-- <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('DRAFT')" class="submit-button"
            >Update Draft</el-button
          >
        </li>-->
        <li v-if="isEditForm && isDraft">
          <el-button @click="savePatientInfo('PENDING')" class="submit-button"
            >Make Complete</el-button
          >
        </li>
        <li v-if="isEditForm && !isDraft">
          <el-button
            @click="savePatientInfo(this.patientInfo.report_status)"
            class="submit-button"
            >Update</el-button
          >
        </li>

        <li>
          <el-button @click="saveAsTemplate" class="submit-button"
            >Save As Template</el-button
          >
        </li>
      </ul>
    </div>

    <div v-if="templateTitleDialogVisible" class="template-title-dialog">
      <el-dialog
        width="30%"
        v-model="templateTitleDialogVisible"
        title="New Template"
        :before-close="handleClose"
        class="template-title-dialog"
      >
        <el-input
          placeholder="Please add a title for Template "
          v-model="templateTitle"
        ></el-input>

        <template #footer>
          <span class="dialog-footer">
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="handleClose"
              >Cancel</el-button
            >
            <el-button
              size="medium"
              type="primary"
              @click="addNewTemplate"
              :loading="loading"
              >Submit</el-button
            >
          </span>
        </template>

        <FormError errorName="template_title"></FormError>
      </el-dialog>
    </div>

   
    

  </section>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import {
  genderTypes,
  biochemicalGeneticsTypes,
  biochemicalGeneticsTypeMap,
} from "@/constants/formConstants";
import { ElMessageBox, ElMessage } from "element-plus";
import { mapGetters } from "vuex";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import FormError from "@/components/FormError";
import moment from "moment";
import EditorConfig from "@/config/editorConfig";
import FabryLeukocytes from "../bioChemicalGenetics/FabryLeukocytes.vue";
import MPSIILeukocytes from "../bioChemicalGenetics/MPSIILeukocytes.vue";
import GaucherLeukocytes from "../bioChemicalGenetics/GaucherLeukocytes.vue";
import GlycoasparginaseLeukocytes from "../bioChemicalGenetics/GlycoasparginaseLeukocytes.vue";
import GM1Leukocytes from "../bioChemicalGenetics/GM1Leukocytes.vue";
import KrabbeLeukocytes from "../bioChemicalGenetics/KrabbeLeukocytes.vue";
import LeukocytesNCL2 from "../bioChemicalGenetics/LeukocytesNCL2.vue";
import MLDLeukocytes from "../bioChemicalGenetics/MLDLeukocytes.vue";
import MPSILeukocytes from "../bioChemicalGenetics/MPSILeukocytes.vue";
import MPSIIIALeukocytes from "../bioChemicalGenetics/MPSIIIALeukocytes.vue";
import MPSIIIBLeukocytes from "../bioChemicalGenetics/MPSIIIBLeukocytes.vue";
import MPSIIICLeukocytes from "../bioChemicalGenetics/MPSIIICLeukocytes.vue";
import MPSIIIDLeukocytes from "../bioChemicalGenetics/MPSIIIDLeukocytes.vue";
import MPSIVALeukocytes from "../bioChemicalGenetics/MPSIVALeukocytes.vue";
import MPSVILeukocytes from "../bioChemicalGenetics/MPSVILeukocytes.vue";
import MPSVIILeukocytes from "../bioChemicalGenetics/MPSVIILeukocytes.vue";
import BioChemicalGeneticsGlobal from "../BioChemicalGeneticsGlobal.vue";
import ResonForChange from "@/components/ReasonForChange"
export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
    FabryLeukocytes,
    MPSIILeukocytes,
    GaucherLeukocytes,
    GlycoasparginaseLeukocytes,
    GM1Leukocytes,
    KrabbeLeukocytes,
    LeukocytesNCL2,
    MLDLeukocytes,
    MPSILeukocytes,
    MPSIIIALeukocytes,
    MPSIIIBLeukocytes,
    MPSIIICLeukocytes,
    MPSIIIDLeukocytes,
    MPSIVALeukocytes,
    MPSVILeukocytes,
    MPSVIILeukocytes,
    BioChemicalGeneticsGlobal,
    ResonForChange,
  },

  data() {
    return {
      askSaveForm: false,
      templateTitleDialogVisible: false,
      templateTitle: "",
      biochemicalGeneticsTypeMap: biochemicalGeneticsTypeMap,
      value: "",
      reportTypes: biochemicalGeneticsTypes,
      genderTypes: genderTypes,
      options: ["Department of Biochemical Genetics"],
      templates: [],
      takeReasonDialog: false,
      patientInfo: {
        Leukocyte_Enzyme_Activity:[],
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        age: "",
        gender: "",
        hospital_name: "",
        client_add: "",
        client_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",
        is_required_signature: false,
        mobile: "",
        email: "",
        locality: "",
        department: "Department of Biochemical Genetics",
        report_type: "",
        category: "BIOCHEMICAL_GENETICS",
        report_status: "DRAFT",
        sample_type: "",
        test_name: "",
        clinical_details: '',
        note: '',
        method: '',
        recommendations: "",
        Interpretation: "",
        References: "",
        fulfilled_signatures:0,
        Remarks:''
      },
      unSavedChanges: false,
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      editorData: "",
      searchVisitId: "",
      reportType: "",
      showReportType: true,
      editReportType: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      editorConfig: EditorConfig,
      showAddTemplate: false,
      reportTypeNew: "",
      reportSubCategory: "",
      categoryReportTypes: [],
      listOfDepartments: [],
      testId: "",
      onShowChildComponemts:false
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
      "getDepartments",
      "getAllPatientReports"
    ]),

    ...mapGetters("reportTemplates", [
      "getAllReportTemplates",
      "getReportTemplateAddStatus",
      "getSingleReportTemplate",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    isReportTypeUpdateDisable() {
      return this.reportTypeNew &&
        this.reportTypeNew.trim() &&
        this.reportTypeNew.trim().length
        ? false
        : true;
    },

    isEditForm() {
      return this.$route.params && this.$route.params.patient_id ? true : false;
    },
    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },

    noContent() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      return data.length ? false : true;
    },    
  },
  async mounted() {
    if (this.$route.params.patient_id) {
      await this.fetchSinglePatientInfo();
      await this.getVisitData();
    }

    await this.fetchReportTemplates();
    await this.$store.dispatch("errors/clear", {}, { root: true });
   // await this.fetchAllReports()
    setTimeout(() => {
      this.show = true;
    });
  },

  methods: {
    async handleCheckedChange(event) {
      this.patientInfo.is_required_signature = event;
    },
    clearFields() {
      this.templates = [];
    },
    onEditReportType() {
      this.reportTypeNew = this.patientInfo.reporonChangeReportTypet_type;
      this.editReportType = true;
      this.showReportType = false;
    },
    updateReportType() {
      this.patientInfo.report_type = this.reportTypeNew;
      this.editReportType = false;
      this.showReportType = true;
    },
    cancelUpdateReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = false;
      this.showReportType = true;
    },
    onChangeReportType() {
      this.reportSubCategory = this.reportType;
      this.patientInfo.report_type = this.reportType;
      this.patientInfo.report_template_id = "";
      this.patientInfo.test_name =
      this.biochemicalGeneticsTypeMap[this.reportType];
      this.fetchReportTemplates();
    },
    askSaveConfirmation() {
      this.askSaveForm = true;
    },
    SavefabricData(result){
    this.patientInfo.Fabry_Leukocytes = result;
   },
   SaveGM1Data(result){
    this.patientInfo.GM1_Leukocytes = result
   },
    handlempsLeukocytes(result){
    this.patientInfo.BiochemicalGenitics = result;
   },
   savePatientDetails(result) {
      this.patientInfo.method = result.method
      this.patientInfo.References = result.References
      this.patientInfo.result = result.result
      this.patientInfo.Interpretation = result.Interpretation
      this.patientInfo.recommendations = result.recommendations
      this.patientInfo.note = result.note
      this.Leukocyte_Enzyme_Activity = result.Leukocyte_Enzyme_Activity
      this.patientInfo.clinical_details = result.clinical_details
      this.patientInfo.Remarks= result.Remarks
    },
   handleBGMLPlama(event) {
    this.patientInfo.Mucolipidosis_Plasma = event;
   },
   async getAutoCompletePatientsData(queryString, cb) {
      // eslint-disable-next-line no-debugger
      if (queryString) {
        await this.$store.dispatch("patientReports/fetchAllPatients", {
          search_string: queryString,
          get_all: true,
        });
        const patientsData = [];
        (this.getPatientDetails.data || []).forEach((el) => {
          patientsData.push({
            value: el.VisitiID + " - " + el.PatientName,
            ...el,
          });
          console.log(patientsData);
        });

        cb(patientsData || []);
        console.log(cb(patientsData || []));
      } else {
        cb([]);
      }
    },
    async getVisitData() {
      try {
        this.searchVisitId =
          this.patientInfo.visit_id + "-" + this.patientInfo.patient_name;
        // await this.getAutoCompletePatientsData(this.patientInfo.visit_id);
      } catch (error) {
        console.log(error);
      }
    },
    async handleSelect(data) {
      this.patientInfo.visit_id = data.VisitiID;
      this.patientInfo.patient_name = data.PatientName;
      this.patientInfo.ref_doctor = data.RefDoctor && !data.RefDoctor.startsWith('Dr.')
    ? `Dr. ${data.RefDoctor}`
    : data.RefDoctor || '';
      this.patientInfo.client_code = data.ClientCode;
      this.patientInfo.uhid = data["UHID/MR NO"];
      const age = data.Age.split(" ");
      const years = parseInt(age[0]);
      const months = parseInt(age[2]);
      const days = parseInt(age[4]);
      if (years > 0 && months > 0 && days > 0 || years > 0 && months > 0) {
        this.patientInfo.age = `${years} Y ${months} M`;
      } else if (years === 0 && months > 0 && days > 0) {
        this.patientInfo.age = `${months} M ${days} D`;
      } else if (years === 0 && months === 0 && days > 0) {
        this.patientInfo.age = `${days} D`;
      }else if ( months > 0) {
        this.patientInfo.age = `${months} M`;
      }
      else if(years>0){
        this.patientInfo.age = `${years} Y`;
      }
      this.patientInfo.gender = data.Gender.toUpperCase();
      this.patientInfo.hospital_name = data.HospitalName;
      this.patientInfo.client_add = data.ClientAdd;
      this.patientInfo.client_name = data.ClientName;
      this.patientInfo.barcode_no = data.BarcodeNo;

      this.patientInfo.registration_date = data.RegisterationDate
        ? moment(data.RegisterationDate).utc().format()
        : null;
      this.patientInfo.collected_date = data.CollectionDate
        ? moment(data.CollectionDate).utc().format()
        : null;
      this.patientInfo.reported_date = data.ReportedDate
        ? moment(data.ReportedDate).utc().format()
        : null;
      this.patientInfo.received_date = data.ReceivedDate
        ? moment(data.ReceivedDate).utc().format()
        : null;

      this.patientInfo.email = data.Email;
      this.patientInfo.mobile = data.Mobile;
      this.patientInfo.locality = data.Locality;
      this.patientInfo.alternate_mobile = data.Phone;

      this.patientInfo.sample_type = data.SampleTypeName;
      if (data.TestName) {
        this.patientInfo.test_name = data.TestName;
      }
      await this.fetchPatientDepartments();
    },
    async fetchPatientDepartments() {
      this.loading = true;
      let params = {};
      if (this.patientInfo.visit_id) {
        params.visit_id = this.patientInfo.visit_id;
        params.department = "biochemical-genetics";
      }
      await this.$store.dispatch("patientReports/fetchAllDepartments", params);
      if (
        this.getDepartments &&
        this.getDepartments.data &&
        this.getDepartments.data.length
      ) {
        this.listOfDepartments = this.getDepartments.data;

        this.listOfDepartments.forEach(
          (x) => (this.categoryReportTypes = x.test_categories)
        );
      } else {
        this.listOfDepartments = [];
      }
      this.loading = false;
    },
    fillSampleType(data) {
      const JavaScriptApplicants = this.categoryReportTypes.filter(
        (e) => e.TestId == data
      );
      if (JavaScriptApplicants[0].SampleTypeName) {
        this.patientInfo.sample_type = JavaScriptApplicants[0].SampleTypeName;
      }
      if (JavaScriptApplicants[0].BarcodeNo) {
        this.patientInfo.barcode_no = JavaScriptApplicants[0].BarcodeNo;
      }
    },
    clearNameSearch() {
      let patientInfo = {
        visit_id: "",
        patient_name: "",
        ref_doctor: "",
        client_add: "",
        client_code: "",
        uhid: "",
        mr_no: "",
        gender: "",
        age: "",
        client_name: "",
        hospital_name: "",
        barcode_no: "",
        registration_date: "",
        collected_date: "",
        reported_date: "",
        received_date: "",

        email: "",
        alternate_mobile: "",
        mobile: "",
        locality: "",
        sample_type: "",
      };
      this.patientInfo = { ...this.patientInfo, ...patientInfo };
    },
    async fetchSinglePatientInfo() {
      this.loading = true;
      this.loadingText = "Fetching data...";
      try {
        await this.$store.dispatch(
          "patientReports/fetchSinglePatientReports",
          this.$route.params.patient_id
        );
        this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async setPatientData() {
      this.onShowChildComponemts=true;
      this.patientInfo = { ...this.getSinglePatientReports };
      this.reportType=this.patientInfo.report_type
      this.patientInfo.from='AddPatientReport'
      if(this.onCheckSubCategory()){
          this.patientInfo.method =this.getSinglePatientReports.method
          this.patientInfo.References=this.getSinglePatientReports.References
          this.patientInfo.result=this.getSinglePatientReports.result
          this.patientInfo.interpretation=this.getSinglePatientReports.interpretation
          this.patientInfo.recommendations=this.getSinglePatientReports.recommendations
          this.patientInfo.note=this.getSinglePatientReports.note
          this.patientInfo.Leukocyte_Enzyme_Activity=this.getSinglePatientReports.Leukocyte_Enzyme_Activity
        }
      this.reportType = this.patientInfo.sub_category;
      this.editorData = this.getSinglePatientReports.test_details;
      this.reportSubCategory = this.patientInfo.sub_category;
      await this.fetchPatientDepartments();
      if (this.getSinglePatientReports.result_test_id) {
        this.testId = this.getSinglePatientReports.result_test_id;
      }
    },
    async clearForm() {
      await ElMessageBox.confirm(
        "Are you sure to clear the form. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          await this.clearPatientForm();
        })
        .catch(() => {});
    },
    async handleClose() {
      this.templateTitle = "";
      this.templateTitleDialogVisible = false;
      await this.$store.dispatch("errors/clear", {}, { root: true });
    },

    async addNewTemplate() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.onCheckbioSubCategory() || this.reportSubCategory=='MPS I_Leukocytes'|| this.reportSubCategory=='MLD_Leukocytes' || this.reportSubCategory=='GM1_Leukocytes'){
          this.$refs.childComponentRef.childMethod();
        }
        if(this.onCheckSubCategory()){
          this.$refs.childComponentRef.savePatientDetails();
        } 
        if(this.onCheckmpsSubCategory()){
          this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        }
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        params.template_title = this.templateTitle;
        params.test_details = data;

        if (!data?.length && !this.onCheckSubCategory()) {
          this.$message("No Content to Save Template");
          return;
        }
        params.template_body = data;

        this.loading = true;
        params.sub_category = this.reportSubCategory;
        if (params.age) {
          params.age = params.age.toString();
        }

        await this.$store.dispatch("reportTemplates/addReportTemplate", params);

        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });

          this.templateTitleDialogVisible = false;
          this.templateTitle = "";

          await this.fetchReportTemplates();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Creating new Template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    saveAsTemplate() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0]?.innerHTML;
      if(this.checkCKEditorContent()){
      let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      stringContent = stringContent.trim();
      if (!stringContent) {
        ElMessage.warning("Report content is not given");
        return;
      }
    }
      this.templateTitleDialogVisible = true;
    },
    clearPatientForm(clearPatientData = true) {
      if (clearPatientData) {
        this.patientInfo = {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          hospital_name: "",
          registration_date: "",
          collected_date: "",
          reported_date: "",
          received_date: "",
          sample_type: "",
        };
      }

      window.document.getElementsByClassName("ck-content")[0].innerText = "";
      window.document.getElementsByClassName("ck-content").innerHTML = "";
      // this.editorData = "";
    },
    async previewReport() {
      try {
          let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.checkCKEditorContent()){
        let stringContent = data?.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent?.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
      }
      if(this.onCheckmpsSubCategory()){
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
      }
      if(this.onCheckSubCategory()){
          this.$refs.childComponentRef.savePatientDetails();
        }
        if(this.onCheckbioSubCategory()|| this.reportSubCategory=='MPS I_Leukocytes' || this.reportSubCategory=='MLD_Leukocytes' || this.reportSubCategory=='GM1_Leukocytes'){
          this.$refs.childComponentRef.childMethod();
        }
        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };
        params.sub_category =  this.reportSubCategory;
        params.test_details = data;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreview",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to preview report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }      
     
   
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async printReport() {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.checkCKEditorContent()){
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
      }

        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...this.patientInfo };

        params.test_details = data;
        if (
          this.patientInfo.patient_name &&
          this.patientInfo.department &&
          this.patientInfo.report_type
        ) {
          this.$store.dispatch("patientReports/addPatientData", params);

          let routerData = this.$router.resolve({
            name: "PatientReportPreviewPrint",
          });

          window.open(routerData.href, "_blank");
        } else {
          ElMessageBox.alert(
            "Please Fill mandatory Fields to print report",
            "Alert",
            {
              confirmButtonText: "OK",
            }
          );
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async goToReports() {
      if (this.$route.params.patient_id) {
        this.$router.push({
          name: "PatientReportView",
          query: this.$route.query,
          params: { status: this.$route.params.status },
        });
      } else {
        this.$router.push({ name: "PatientReports", query: this.$route.query });
      }
    },

    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "BIOCHEMICAL_GENETICS",
        };

        if (this.reportType) {
          params.sub_category =
            this.biochemicalGeneticsTypeMap[this.reportType];          
        }      
        await this.$store.dispatch(
          "reportTemplates/fetchAllReportTemplates",
          params
        );

        this.templates = this.getAllReportTemplates.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    savePatientInfo(status) {
      this.patientInfo.report_status = status
      if(this.onCheckSubCategory()){
          this.$refs.childComponentRef.savePatientDetails();    
        } 
      if (this.$route.params.patient_id && status === 'APPROVED' && (this.reportType == 'Fabry_Leukocytes' || this.reportType == 'Gaucher_Leukocytes' || this.reportType == 'Leukocytes_NCL2'
        || this.reportType == 'MPS II_Leukocytes' || this.reportType == 'MPS IIIA_Leukocytes' || this.reportType == 'MPS IIIB_Leukocytes' || this.reportType == 'MPS IIIC_Leukocytes'
        || this.reportType == 'MPS IIID_Leukocytes' || this.reportType==='Krabbe_Leukocytes' || this.reportType=='Glycoasparginase_Leukocytes' || this.reportType=='MPS I_Leukocytes'
        ||  this.reportType == 'MPS IVA_Leukocytes' || this.reportSubCategory=='MLD_Leukocytes' || this.onCheckSubCategory() || this.reportType=='GM1_Leukocytes'  || this.reportType == 'MPS VI_Leukocytes' || this.reportType == 'MPS VII_Leukocytes')) {
        this.askReason()
      }
       else if (this.$route.params.patient_id) {
        this.updatePatientInfo(status);
      } else {
        this.addPatientInfo(status);
      }
    },
    async fetchSingleReportTemplate(templateId) {
      try {
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: templateId }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async updateTemplateData(templateId) {
      await this.fetchSingleReportTemplate(templateId);
      let templateData = this.templates.find((x) => x._id == templateId);
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.template_body
      ) {
        templateData.template_body = this.getSingleReportTemplate.template_body;
      }
      if (
        this.getSingleReportTemplate &&
        this.getSingleReportTemplate.test_details
      ) {
        templateData.test_details = this.getSingleReportTemplate.test_details;
      }
      if (templateData) {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML || "";
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (stringContent) {
          ElMessageBox.confirm(
            "Are you want to update template data. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "No",
              type: "warning",
            }
          )
            .then(async () => {
              await this.updateEditorData(templateData);
            })
            .catch(() => {});
        } else {
          this.updateEditorData(templateData);
        }
      }
    },
    updateEditorData(templateData) {
      this.show = false;
      this.loadingEditor = true;
      this.onShowChildComponemts=false
      setTimeout(() => {
        this.loadingEditor = false;
        this.show = true;
        this.editorData = templateData.template_body || "";
        this.patientInfo.report_type = templateData.report_type || "";
        this.reportType = templateData.sub_category || "";
        this.patientInfo.BiochemicalGenitics=templateData.BiochemicalGenitics || [];
        this.patientInfo.Fabry_Leukocytes=templateData.Fabry_Leukocytes ||[];
        this.patientInfo.GM1_Leukocytes=templateData.GM1_Leukocytes ||[];
        this.patientInfo.from='AddPatientReport'
        if(this.onCheckSubCategory()){
          this.patientInfo.method =templateData.method
          this.patientInfo.References=templateData.References
          this.patientInfo.result=templateData.result
          this.patientInfo.interpretation=templateData.interpretation
          this.patientInfo.recommendations=templateData.recommendations
          this.patientInfo.note=templateData.note
          this.patientInfo.clinical_details = templateData.clinical_details
          this.patientInfo.Leukocyte_Enzyme_Activity=templateData.Leukocyte_Enzyme_Activity
          this.onShowChildComponemts=true
          this.patientInfo.Interpretation=templateData.Interpretation
          this.patientInfo.Remarks = templateData.Remarks
        }     
        this.patientInfo.sub_category=templateData.sub_category || ""
      }, 100);
    },
    async addPatientInfo(status) {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML || "";
        if(this.checkCKEditorContent()){
        let stringContent = data?.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
      }
        if(this.onCheckbioSubCategory() || this.reportSubCategory=='MPS I_Leukocytes' || this.reportSubCategory=='MLD_Leukocytes' || this.reportSubCategory=='GM1_Leukocytes'){
          this.$refs.childComponentRef.childMethod();
        }
        if(this.onCheckmpsSubCategory()){
          this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        }
        this.loading = true;
        this.loadingText = "Loading...";
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          
          const code = params.client_code.toString();
          params.client_code = code;
        }
        params.test_details = data;
        params.report_status = status;
        params.sub_category = this.reportSubCategory;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        params.typedby=this.getProfileDetails.first_name  +' ' + this.getProfileDetails.last_name
        await this.$store.dispatch("patientReports/addPatientReport", params);
        if (this.getPatientAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Added Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async updateReport(status) {
      this.updatePatientInfo(status);
    },
    askReason() {
      this.takeReasonDialog = true;
    },
    async updatePatientInfo(status) {
      try {
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0]?.innerHTML;
        if(this.checkCKEditorContent()){ 
        let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
        stringContent = stringContent.trim();
        if (!stringContent) {
          ElMessage.warning("Report content is not given");
          return;
        }
      }
        if(this.onCheckbioSubCategory() || this.reportSubCategory=='MPS I_Leukocytes' || this.reportSubCategory=='MLD_Leukocytes' || this.reportSubCategory=='GM1_Leukocytes'){
          this.$refs.childComponentRef.childMethod();
        }
         if(this.onCheckmpsSubCategory()){
          this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        }
        this.loading = true;
        this.loadingText = "Loading...";
        let params = { ...this.patientInfo };
        if (params.client_code && params.client_code != "") {
          const code = params.client_code.toString();
          params.client_code = code;
        }
        if (params.age == null) {
          params.age = "";
        }
        if (params.reported_date == null) {
          params.reported_date = "";
        }
        if (params.received_date == null) {
          params.received_date = "";
        }
        params.report_status = this.patientInfo.report_status;
        params.test_details = data;
        params.sub_category = this.reportSubCategory;
        let patientId = this.$route.params.patient_id;
        if (!params.report_template_id) {
          params.report_template_id = null;
        }
        if (this.testId) {
          params.result_test_id = this.testId;
        }
        if (status && status?.is_amendment) {
          params.amendment_reason = status.reason;
          params.is_amendment = status.is_amendment
        }
        await this.$store.dispatch("patientReports/updatePatientReport", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReports();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.updateEditorData({});
        })
        .catch(() => {});
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    checkCKEditorContent() {
      const excludedSubcategories = [
        "Alpha_Fucosidosis_Leukocytes", 
        "Alpha-Fucosidosis_Leukocytes",
        "Alpha_Mannosidosis_Leukocytes", 
        "Alpha-Mannosidosis_Leukocytes",
        'MPS IIIA_Leukocytes',
        'MPS IIIB_Leukocytes',
        'MPS II_Leukocytes',
        'MPS IIIC_Leukocytes',
        'MPS IIID_Leukocytes',
        'MPS VI_Leukocytes',
        'MPS VII_Leukocytes',
        'MPSVIILeukocytes',
        'Fabry_Leukocytes',
        'MPS IVA_Leukocytes',
        'Leukocytes_NCL2',
        'Krabbe_Leukocytes',
        "Beta-Mannosidosis_Leukocytes", "Beta_Mannosidosis_Leukocytes",
        "Biotinidase_Plasma",
        "Chitotriosidase_Plasma",
           "Glycoasparginase_Leukocytes",
           "MPS I_Leukocytes",
           "MLD_Leukocytes",
           "Gaucher_Leukocytes",
           "GM1_Leukocytes",
      ];
      return !excludedSubcategories.includes(this.reportType);
    },
    onCheckSubCategory() {
      const subCategorys = [
        "Alpha_Fucosidosis_Leukocytes", "Alpha-Fucosidosis_Leukocytes",
        "Alpha_Mannosidosis_Leukocytes", "Alpha-Mannosidosis_Leukocytes",
        "Beta-Mannosidosis_Leukocytes","Beta_Mannosidosis_Leukocytes",
        "Biotinidase_Plasma",
        "Chitotriosidase_Plasma"
      ];
      return subCategorys.includes(this.reportType);
    },
    onCheckbioSubCategory() {
      const subCategorys = [
      "Fabry_Leukocytes",
      "Gaucher_Leukocytes",
      "Leukocytes_NCL2",
      "Krabbe_Leukocytes",
      "Glycoasparginase_Leukocytes",
      ];
      return subCategorys.includes(this.reportType);
   },
    onCheckmpsSubCategory() {
      const subCategorys = [
      "MPS II_Leukocytes",
      "MPS IIIA_Leukocytes",
      "MPS IIIB_Leukocytes",
      "MPS IIIC_Leukocytes",
      "MPS IIID_Leukocytes",
      "MPS IVA_Leukocytes",
      "MPS VII_Leukocytes",
      "MPS VI_Leukocytes"
      ];
      return subCategorys.includes(this.reportType);
   },
 }
};
</script>

<style lang="scss">
#add-report-view {
  max-width: 900px;
  margin: 0 auto;
  .reports-title-header {
    display: grid;
    grid-template-columns: 88% auto;
    margin-bottom: 10px;
    .display-end {
      text-align: end;
    }
    .report-title {
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }

    .back-btn {
      padding: 9px 15px;
      min-height: auto;
      line-height: 1;
      color: #333;
      border: 1px solid #47525d42;
      background-color: white;

      border-radius: 5px;

      font-weight: 500;
      .el-icon-back {
        margin-right: 4px;
      }
      &:active,
      &:hover,
      &:focus {
        border: 1px solid #47525d42;
      }
    }
  }

  .inner-navbar {
    margin-bottom: 10px;
    .filter-navigation {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .inner {
    margin-bottom: 50px;
    .report-header {
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      background: #2eaf9f;
      text-align: center;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    border: 1px solid rgba(7, 162, 149, 0.6);
    border-radius: 10px;
    padding: 0;
    background: #fff;
    overflow: hidden;
    .patient-information {
      .el-form-item {
        margin-bottom: 0;
      }
      .form-mandatory-field {
        .el-form-item__label:after {
          content: "*";
          color: red !important;
          font-size: 15px;
          line-height: 1;
          padding-left: 3px;
        }
      }

      .el-form-item__label {
        min-width: 40%;
        text-align: left;
        background: #e0f3f1;
        padding-left: 10px;
        border-bottom: 1px solid #e0f3f1;
        font-weight: 600;
        font-size: 11px;
        color: #000;
      }

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      .el-input__inner {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-radius: 0;
        color: #000;
        font-weight: 500;
        // text-transform: uppercase;
        &::placeholder {
          color: #5a5a5a;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }
    .report-sub-title {
      height: 39px;
      line-height: 39px;
      color: #fff !important ;
      font-size: 18px;
      text-align: center;
      margin-top: 0px !important;
      padding-top: 0rem !important;
      border-top: 1.2px solid rgba(0, 0, 0, 0.2);
      font-weight: 500;
    }
    .report-type-edit-info {
      display: flex;
      margin-top: 0.5em;
      padding: 0 10px;
      gap: 5px;
    }
    .editor {
      margin-top: 0.5em;
      padding: 10px;
      .ck-editor__editable {
        min-height: 320px;
      }
    }
    .image__caption_highlighted {
      min-height: auto !important;
    }
    .ck-dropdown__panel {
      max-height: 230px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .ck-placeholder {
      min-height: auto !important;
    }

    .ck-dropdown__panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    .ck-dropdown__panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2eaf9f;
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        .el-button {
          padding: 9px 15px;
          min-height: auto;
          line-height: 1;
          &.preview-button {
            background: #28b4a6;
            color: #fff;
            font-weight: 600;
          }
          &.print-button {
            background: #f56c6c !important;
          }
          &.submit-button {
            background: #0061ec;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  figure {
    &.table {
      br {
        display: none;
      }
      br[data-cke-filler="true"] {
        display: block !important;
      }
    }
  }
  .ck {
    ul {
      list-style: disc;
      padding-left: 30px;
      li {
        padding-left: 5px;
        list-style-type: disc !important;
      }
    }
  }
  // .input-error {
  //   .el-input__inner {
  //     border: 1.5px solid red !important;
  //   }
  //   ::placeholder {
  //     color: red !important;
  //     font-weight: 600;
  //   }
  // }
}
.cursor-pointer {
  cursor: pointer;
}
.template-title-dialog {
  .el-dialog__header {
    background-color: #a09b9b6b;
    .el-dialog__close {
      font-size: 20px;
      color: red;
    }
  }
}
</style>
