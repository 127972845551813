<template>
    <div class="MPSILeukocytes">
        <div class="edit-box mb-20">
            <span class="title">{{this.patientInfo.report_type=='MPS I_Leukocytes'?'CLINICAL HISTORY':'HISTORY'}}</span>
            <ckeditor :editor="editor" v-model="indication" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
             <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
             <el-table :data="leukocyte_enzyme_activity" style="width: 100%">
                 <el-table-column prop="test" label="Test (Units)" width="240">
                    <template v-slot="scope">
                         <el-input type="textarea" v-model="scope.row.test"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="disorder" label="Disorder" width="220">
                    <template v-slot="scope">
                         <el-input type="textarea" v-model="scope.row.disorder"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Results" width="100">
                     <template v-slot="scope">
                         <el-input type="textarea" v-model="scope.row.results"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column prop="reference" label="Reference Interval(Mean ± SD)" width="230">
                    <template v-slot="scope">
                         <el-input type="textarea" v-model="scope.row.reference"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Status" width="150">
                    <template v-slot="scope">
                         <el-input type="textarea" v-model="scope.row.status"></el-input>
                     </template>
                 </el-table-column>

                 <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope" v-if="this.patientInfo?.from==undefined">
                                <el-row :gutter="12">
                                    <el-col :span="6" v-if="scope.row.sno === leukocyte_enzyme_activity?.length">
                                        <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                                                <CirclePlus />
                                            </el-icon></el-buttton>
                                    </el-col>
                                    <el-col :span="6" v-if="leukocyte_enzyme_activity?.length > 1"><el-buttton
                                            @click="onDeleteItem(scope.row)"><el-icon :size="19">
                                                <Delete style="color: red;" />
                                            </el-icon></el-buttton>
                                    </el-col>
                                </el-row>
                    </el-table-column>

             </el-table>
         </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" v-model="method" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" v-model="results" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" v-model="interpretation" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">{{this.patientInfo.report_type=='MPS I_Leukocytes'?'RECOMMENDATIONS':'COMMENT'}}</span>
            <ckeditor :editor="editor" v-model="recommendations" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" v-model="note" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props: {
    Fabry_Leukocytes_Array: {
      type: Array
    },
    patientInfo:{
        type: String, 
    }
},
mounted(){
  },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comments: "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            recommendations:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].recommendations:'',
            indication:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].indication:'',
            method:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].method:'', 
            results:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].results:'',
            interpretation:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].interpretation:'',
            note:this.Fabry_Leukocytes_Array?.length==1?this.Fabry_Leukocytes_Array[0].note:
            'It is presumed that the specimen used to perform the test belongs to the patient specified above, such verification having been carried out at the collection level of sample. Please correlate with clinical findings and other test findings.',
            subcategory:this.patientInfo.report_type,
            leukocyte_enzyme_activity:this.Fabry_Leukocytes_Array?.length == 1 ? this.Fabry_Leukocytes_Array[0].leukocyte_enzyme_activity:[
                {
                   sno: 1,
                   test:this.patientInfo.report_type=='MPS I_Leukocytes'?'α-iduronidase(nm/h/mg)':'Arylsulphatase A(nmol/17h/mg)',
                   disorder: this.patientInfo.report_type=='MPS I_Leukocytes'?'Hurler (MPS I)':'MLD',
                   results:'',
                   reference:this.patientInfo.report_type=='MPS I_Leukocytes'?'23 – 49(29.8 ± 6.5)':'61 - 249(180 ± 49)',
                   status:''
            },
              { 
                   sno: 2,
                   test:'* β-galactosidase(nmol/h/mg)',
                   disorder: this.patientInfo.report_type=='MPS I_Leukocytes'?'GM1 gangliosidosis(MPS IVB)':'GM1 gangliosidosis',
                   results:'',
                   reference:'85 – 300(142 ± 59)',
                   status:''
               }
           ]
        }  
    },
    methods:{
     childMethod() {
        let dd = window.document.getElementsByClassName("ck-content");
        const indication = dd[0]?.innerHTML;
        const method = dd[1]?.innerHTML;  
        const results = dd[2]?.innerHTML;  
        const interpretation = dd[3]?.innerHTML;  
        const recommendations = dd[4]?.innerHTML;
        const note = dd[5]?.innerHTML;
        const result = [{
            indication:indication,
            leukocyte_enzyme_activity:this.leukocyte_enzyme_activity,
            method:method,
            results: results,
            interpretation: interpretation, 
            recommendations:recommendations,
            note:note,
          }] 
       this.$emit('childMethod', result);
    },
    onDeleteItem(index) {
            this.leukocyte_enzyme_activity?.splice(index.sno - 1, 1)
            this.leukocyte_enzyme_activity?.forEach((data, index) => {
                data.sno = index + 1
            })
        },
    onAddItem() {
        this.leukocyte_enzyme_activity?.push({
            sno: this.leukocyte_enzyme_activity?.length + 1,
            test: '',
            disorder: '',
            results: '',
            reference: '',
            status:''
        })
    },
  }
}
</script>


<style lang="scss" scoped>
.MPSILeukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>