<template>
    <div class="AlphaFucosidosisLeukocytes">
        <div class="edit-box mb-20">
            <span class="title">CLINICAL HISTORY</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LEUKOCYTE ENZYME ACTIVITY</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">METHOD</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RESULT</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">INTERPRETATION</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">RECOMMENDATIONS</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
        }
        
    },
}
</script>


<style lang="scss" scoped>
.AlphaFucosidosisLeukocytes {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>