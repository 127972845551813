export const genderTypes = ["MALE", "FEMALE", "OTHERS"]
export const rejectedReasons = [
  { label: "Clotted", value: "CLOTTED" },
  { label: "Early collection", value: "EARLY_COLLECTION" },
  { label: "Hemolysis", value: "HEMOLYSIS" },
  { label: "Improper test selection", value: "IMPROPER_TEST_SELECTION" },
  { label: "Improper gestations age", value: "IMPROPER_GESTATIONS_AGE" },
  {
    label: "Improper time of collection",
    value: "IMPROPER_TIME_OF_COLLECTION",
  },
  { label: "Incomplete TRF", value: "INCOMPLETE_TRF" },
  { label: "Insufficient volume", value: "INSUFFICIENT_VOLUME" },
  { label: "On Medication", value: "ON_MEDICATION" },
  { label: "No Clinical history", value: "NO_CLINICAL_HISTORY" },
  // { label: "REPORT Ready", value: "REPORT_READY" },
  {
    label: "Sample collected from IV line",
    value: "SAMPLE_COLLECTED_FROM_IV_LIVE",
  },
  {
    label: "Specimen site not mentioned (Biopsy)",
    value: "SPECIMEN_SITE_NOT_MENTIONED_BIOPSY",
  },
  { label: "Sample contamination", value: "SAMPLE_CONTAMINATION" },
  { label: "Transit delay", value: "TRANSIT_DELAY" },
  { label: "Wrong container", value: "WRONG_CONTAINER" },
  { label: "Others", value: "OTHERS" },
]
export const departmentEmails = [
  {
    department_name: "BIOCHEMICAL GENETICS",
    to_reply: "biochemicalgenetics@yodalifeline.in",
  },
  {
    department_name: "BIOCHEMISTRY",
    to_reply: "biochemistry@yodalifeline.in",
  },
  {
    department_name: "CARDIOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "CLINICAL PATHOLOGY",
    to_reply: "",
  },
  {
    department_name: "CYTOGENETICS",
    to_reply: "cytogenetics@yodalifeline.in",
  },
  {
    department_name: "CYTOPATHOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "DENTAL",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "FLOW CYTOMETRY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "HAEMATOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "HISTOPATHOLOGY",
    to_reply: "histopathology@yodalifeline.in",
  },
  {
    department_name: "HORMONE ASSAYS",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "IHC-HISTOPATHOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MAMMOGRAPHY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MICROARRAY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MICROBIOLOGY",
    to_reply: "microbiology@yodalifeline.in",
  },
  {
    department_name: "MISCELLANEOUS , AMB, ANAESTHESIA CHARGES",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MOLECULAR DIAGNOSTICS",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "MOLECULAR GENETICS",
    to_reply: "moleculargenetics@yodalifeline.in",
  },
  {
    department_name: "MOLECULAR PATHOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "NEUROLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "PFT",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "PHARMACOGENOMICS",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "RADIOLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "SEROLOGY",
    to_reply: "info@yodalifeline.in",
  },
  {
    department_name: "SEROLOGY / IMMUNOLOGY",
    to_reply: "info@yodalifeline.in",
  },
]
export const rejectedReasonsMap = {
  CLOTTED: "Clotted",
  EARLY_COLLECTION: "Early collection",
  HEMOLYSIS: "Hemolysis",
  IMPROPER_TEST_SELECTION: "Improper test selection",
  IMPROPER_GESTATIONS_AGE: "Improper gestations age",
  MPROPER_TIME_OF_COLLECTION: "Improper time of collection",
  IMPROPER_TIME_OF_COLLECTION: "Improper time of collection",

  INCOMPLETE_TRF: "Incomplete TRF",
  INSUFFICIENT_VOLUME: "Insufficient volume",
  OTHERS: "Others",
  ON_MEDICATION: "On Medication",
  NO_CLINICAL_HISTORY: "No Clinical history",
  REPORT_READY: "REPORT Ready",
  SAMPLE_COLLECTED_FROM_IV_LIVE: "Sample collected from IV line",

  SPECIMEN_SITE_NOT_MENTIONED_BIOPSY: "Specimen site not mentioned (Biopsy)",
  SAMPLE_CONTAMINATION: "Sample contamination",
  value: "SAMPLE_CONTAMINATION",
  TRANSIT_DELAY: "Transit delay",
  WRONG_CONTAINER: "Wrong container",
}

export const centers = [
  {
    name: "ALL",
    _id: "ALL",
    status: "true"
  },
]

export const users = [
  {
    name: "Data Operator",
    value: "DATA_OPERATOR",
  },
  {
    name: "Doctor",
    value: "DOCTOR",
  },
  {
    name: "Front Desk",
    value: "FRONT_DESK",
  },
  {
    name: "Help Desk",
    value: "HELP_DESK",
  },
  {
    name: "Radiologist",
    value: "RADIOLOGIST",
  },
  {
    name: "Sample Process Manager",
    value: "SAMPLE_PROCESS_MANAGER",
  },
]
export const genderTypesMap = {
  MALE: "Male",
  FEMALE: "Female",
}

export const userTypes = {
  ADMIN: "Admin",
  LAB_DIRECTOR: "Lab Director",
  LAB_PHYSICIAN: "Lab Physician",
  LAB_ASSISTANT: "Lab Assistant",
  RADIOLOGIST: "Radiologist",
  DOCTOR: "Doctor",
  DATA_OPERATOR: "Data Operator",
  FRONT_DESK: "Front Desk",
  HELP_DESK: "Help Desk",
  SAMPLE_PROCESS_MANAGER: "Sample Process Manager",
}

export const userRolesByPermission = [
  {
    role: "ADMIN",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      "EDIT_APPROVE_PATIENT_REPORT",
      "FINALIZE_REPORT",
      "EDIT_TEMPLATE",
      "DELETE_TEMPLATE",
    ],
  },
  {
    role: "RADIOLOGIST",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      "EDIT_APPROVE_PATIENT_REPORT",
      "FINALIZE_REPORT",
      // "EDIT_TEMPLATE",
      // "DELETE_TEMPLATE",
    ],
  },
  {
    role: "DOCTOR",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      "EDIT_APPROVE_PATIENT_REPORT",
      "FINALIZE_REPORT",
      "EDIT_TEMPLATE",
      // "DELETE_TEMPLATE",
    ],
  },
  {
    role: "DATA_OPERATOR",
    permissions: [
      "ADD_PATIENT_REPORT",
      "EDIT_NON_APPROVE_PATIENT_REPORT",
      // "EDIT_TEMPLATE",
      // "DELETE_TEMPLATE",
    ],
  },
  {
    role: "FRONT_DESK",
    permissions: [],
  },
  {
    role: "HELP_DESK",
    permissions: [],
  },
  {
    role: "SAMPLE_PROCESS_MANAGER",
    permissions: [],
  },
  {
    role: "SUPER_ADMIN",
    permissions: ["MOVE_TO_LIVE"],
  },
]

export const radiologyReportTypes = [
  "Xray",
  "Ultrasound",
  "Mammogram",
  "CT Scan",
  "MRI",
  "Miscellaneous",
]
export const genomicsReportTypes = [
  'ATN1 GENE TRIPLET REPEAT ANALYSIS FOR DRPLA',
  "Beta Thalassemia Screening",
  "BCR-ABL KD MUTATION ANALYSIS (IRMA)",
  "BCR-ABL QUALITATIVE TEST",
  "BCR-ABL IS -QUANTITATIVE TEST",
  "BK Virus Quantitative PCR",
  "BK Virus Qualitative -PCR",
  "Chlamydia Trachomatis Qualitative Test",
  "CLOPIDOGREL/PRASUGREL/TICAGRELOR",
  "Congenital adrenal hyperplasia CYP21A2",
  "Cystic Fibrosis",
  "Chromosomal Micro array",
  "DMD Analysis by MLPA",
  "DPYD",
  "Fragile-X-PCR",
  "Factor- II Prothrombin Mutation",
  "Factor- V Leiden Mutation Screening",
  "FRIEDREICH ATAXIA",
  "GILBERT SYNDROME",
  // "HEPATITIS -C VIRUS (HCV) GENOTYPE",
  "HSV-1/2 Quantitative -PCR",
  "HLA B51 TYPING",
  "HUNTINGTON - CAG REPEAT ANALYSIS",
  "JAK2 E-14 MUTATION ANALYSIS",
  "JAK2 (EXONS 12-15) MUTATION ANALYSIS",
  "JAK2 REFLEX PANEL",
  "Maternal Cell Contamination Testing Report (MCC)",
  "MTHFR Screening",
  "MTHFR, Factor V and II screening",
  "MPN Panel",
  "MYOTONIC DYSTROPHY 1&2",
  "NIPT",
  "NIPT with Micro Deletions",
  "Parental Carrier Testing",
  "Prothrombotic Profile",
  "PML-RARA QUALITATIVE TEST",
  "PML-RARA QUANTITATIVE TEST",
  "QF PCR",
  "Rubella Pcr",
  "SCA Panel Testing",
  "SMA analysis by RFLP",
  // "SPERM DNA FRAGMENTATION TEST",
  "Spinal Muscular Atrophy Deletion & Duplication Analysis (SMN_MLPA)",
  // "SMA Analysis - Negative",
  // "DMD Analysis - Negative",
  "Tacrolimus",
  "Targeted Testing",
  "Targeted Sanger Sequencing",
  "TPMT",
  // "Urine Organic Acid (GC-MS)",
  "Whole Exome Sequencing",
  "Y Chromosome Microdeletion",
  "Factor- II Prothrombin Mutation",
  "Factor- V Leiden Mutation Screening",
]
export const molecularGeneticsReportTypes = [
  "ADENOVIRUS QUALITATIVE REAL TIME PCR",
  "BCR-ABL QUALITATIVE",
  "BCR-ABL QUANTITATIVE",
  "BK POLYOMA QUANTITATIVE PCR",
  "BK VIRUS - BLOOD",
  "CHLAMYDIA TRACHOMATIS DNA QUALITATIVE TEST",
  "COVID-19 VIRUS QUALITATIVE PCR",
  "CYTOMEGALO VIRUS (CMV) QUANTITATIVE- PCR",
  "CYTOMEGALOVIRUS (CMV) QUALITATIVE - PCR",
  "EPSTEIN BAR VIRUS QUALITATIVE - BY REAL TIME PCR",
  "HBV DNA QUALITATIVE PCR",
  "HBV DNA QUANTITATIVE REAL TIME PCR",
  "HCV RNA QUALITATIVE REAL TIME PCR",
  "HCV RNA QUANTITATIVE REAL TIME PCR",
  "HEPATITIS -C VIRUS (HCV) GENOTYPE",
  "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUANTITATIVE",
  "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUALITATIVE",
  "HIV 1 RNA QUALITATIVE REAL TIME PCR",
  "HIV-1 VIRAL LOAD QUANTITATIVE -PCR",
  "HIV-1 DNA, Qualitative (Proviral DNA)",
  "HIV-2 RNA QUANTITATIVE REAL TIME PCR",
  "HLA B27 BY RT PCR (QUALITATIVE)",
  "H1N1/SWINE FLU BY REAL TIME PCR",
  "HUMAN PAPILLOMA VIRUS (HPV) QUALITATIVE",
  "INFLUENZA H3N2 by REAL TIME PCR",
  "JAK2 GENE (Exon 14) V617F MUTATION DETECTION",
  "JAPANESE ENCEPHALITIS VIRUS (JEV) QUALITATIVE PCR",
  "JC VIRUS DNA DETECTION",
  "MUMPS VIRUS QUALITATIVE",
  "MYCOBACTERIUM TUBERCULOSIS PCR (TB-PCR)",
  "NEISSERIA GONORRHOEA DNA QUALITATIVE",
  "PML-RARA QUANTITATIVE",
  "PML-RARA QUALITATIVE",
  "RESPIRATORY PANEL - BY REAL TIME PCR",
  'RICKETSSIA PCR',
  "RUBELLA PCR QUALITATIVE",
  "SPERM DNA FRAGMENTATION",
  "VIRAL MENINGITIS PANEL - BY MULTIPLEX REAL TIME PCR",
  "WEST NILE VIRUS PCR",
  'TROPICAL FEVER PANEL',
  'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'
]
export const cytometryReportTypes = [
  "CD3,CD4,CD8 COUNT-FLOWCYTOMETRY",
  "CD4,CD8 COUNT - FLOWCYTOMETRY",
  "CD-4 COUNT",
  "HLA B27 - FLOWCYTOMETRY",
  "SPERM DNA FRAGMENTATION",
]
export const transplantImmunoGenetics = [
  "CDC CROSS MATCH",
  "Donor Specific Antibody(DSA-IgG) Test",
  "HLA B * 51 / B * 52",
  "HLA - CLASS II DQ TYPING",
  "HLA TYPING CLASS 1 & 2 CLASS TYPE",
  "HLA FLOW CYTOMETRY T & B CELL CROSSMATCH",
  "PRA CLASS I& CLASS II QUANTITATIVE",
  "HLA TYPING A B DRB1",
  "HLA SAB CLASS I II TYPING",
]

export const histopathologyTypes = ["HISTOPATHOLOGY(LARGE SPECIMEN)","HISTOPATHOLOGY(BIOPSY)"]
export const tmsTypes = [
  "CARNITINE",
  "CSF AMINO ACIDS",
  "CSF GLYCENE",
  "HEMOGLOBINOPATHIES",
  "INTEGRATED QUADRUPLE MARKER",
  "NEWBORN SCREENING (BIO-1)",
  "NEWBORN SCREENING (BIO-2)",
  "NEWBORN SCREENING (BIO-3)",
  "NEWBORN SCREENING (BIO-4)",
  "NEWBORN SCREENING (BIO-5)",
  "NEWBORN SCREENING (BIO-6)",
  "NEWBORN SCREENING (BIO-7)",
  "OEMGA 3 AND OMEGA 6",
  "PLASMA AMINO ACIDS",
  "PLASMA GLYCINE",
  "PLGF",
  "TMS",
  "URINE AMINO ACIDS",
  "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
  "VERY LONG CHAIN FATTY ACID",
  "VITAMIN - A  ALL TRANSRETINOL",
  "VITAMIN - B1",
  "VITAMIN - B2",
  "VITAMIN - B3",
  "VITAMIN - B5",
  "VITAMIN - B6",
  "VITAMIN - B7",
  "VITAMIN - B9",
  "VITAMIN - C TOTAL ASCORBIC",
  "VITAMIN - E ALPHATOCOPHEROL",
  "VITAMIN - K1",
  "Fabry_Leukocytes",
  "MPS II_Leukocytes",
  "Gaucher_Leukocytes",
  "Glycoasparginase_Leukocytes",
  "GM1_Leukocytes",
  "Krabbe_Leukocytes",
  "Leukocytes_NCL2",
  "MLD_Leukocytes",
  "MPS I_Leukocytes",
  "MPS IIIA_Leukocytes",
  "MPS IIIB_Leukocytes",
  "MPS IIIC_Leukocytes",
  "MPS IIID_Leukocytes",
  "MPS IVA_Leukocytes",
  "MPS VI_Leukocytes",
  "MPS VII_Leukocytes",
  "Alpha-Fucosidosis_Leukocytes",
  "Alpha-Mannosidosis_Leukocytes",
  "Beta-Mannosidosis_Leukocytes",
  "Biotinidase_Plasma",
  "Chitotriosidase_Plasma"
] //BIOCHEMICAL_GENETICS
export const doubleMarkersReportTypeMap = {
  DOUBLE_MARKERS: "DOUBLE MARKERS",
  'MPS II_Leukocytes': "MPS II_Leukocytes",
  'MPS IIIA_Leukocytes': "MPS IIIA_Leukocytes",
  'MPS IIIB_Leukocytes': "MPS IIIB_Leukocytes",
  'MPS IIIC_Leukocytes': "MPS IIIC_Leukocytes",
  'MPS IIID_Leukocytes': "MPS IIID_Leukocytes",
  'MPS IVA_Leukocytes':"MPS IVA_Leukocytes",
  'MPS VI_Leukocytes':"MPS VI_Leukocytes",
  'MPS VII_Leukocytes':"MPS VII_Leukocytes",
  URINE_ORGANIC_ACID: "URINE ORGANIC ACID",
  TMS: "TMS",
  TRIPLE_MARKERS: "TRIPLE MARKERS",
  QUADRUPLE_MARKERS: "QUADRUPLE MARKERS",
  Plasma_Glycine: "PLASMA GLYCINE",
  PLASMA_AMINO_ACIDS: "PLASMA AMINO ACIDS",
  OEMGA_3_AND_OMEGA_6: "OEMGA 3 AND OMEGA 6",
  INTEGRATED_QUADRUPLE_MARKERS: "INTEGRATED QUADRUPLE MARKERS",
  EARLY_QUADRUPLE_MARKERS: "EARLY QUADRUPLE MARKERS",
  PLASMA_CREATINE_GUANIDINO_ACETIC_ACID_LEVELS:
    "PLASMA CREATINE & GUANIDINO ACETIC ACID LEVELS",
  CSF_AMINO_ACIDS: "CSF AMINO ACIDS",
  CSF_GLYCENE: "CSF GLYCENE",
  Carnitine: "CARNITINE",
  Hemoglobinopathies: "HEMOGLOBINOPATHIES",
  PLGF: "PLGF",
  URINE_AMINO_ACIDS: "URINE AMINO ACIDS",
  Urinary_Glycosaminoglycans_GAG_Quantification:
    "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
  VERY_LONG_CHAIN_FATTY_ACID: "VERY LONG CHAIN FATTY ACID",
  NEWBORN_SCREENING_BIO_1: "NEWBORN SCREENING (BIO-1)",
  NEWBORN_SCREENING_BIO_2: "NEWBORN SCREENING (BIO-2)",
  NEWBORN_SCREENING_BIO_3: "NEWBORN SCREENING (BIO-3)",
  NEWBORN_SCREENING_BIO_4: "NEWBORN SCREENING (BIO-4)",
  NEWBORN_SCREENING_BIO_5: "NEWBORN SCREENING (BIO-5)",
  NEWBORN_SCREENING_BIO_6: "NEWBORN SCREENING (BIO-6)",
  NEWBORN_SCREENING_BIO_7: "NEWBORN SCREENING (BIO-7)",
  VITAMIN_A_ALL_TRANSRETINOL: "VITAMIN - A  ALL TRANSRETINOL",
  VITAMIN_B1: "VITAMIN - B1",
  VITAMIN_B2: "VITAMIN - B2",
  VITAMIN_B3: "VITAMIN - B3",
  VITAMIN_B6: "VITAMIN - B6",
  VITAMIN_B7: "VITAMIN - B7",
  VITAMIN_B9: "VITAMIN - B9",
  VITAMIN_C_TOTAL_ASCORBIC: "VITAMIN - C TOTAL ASCORBIC",
  VITAMIN_E_ALPHATOCOPHEROL: "VITAMIN - E ALPHATOCOPHEROL",
  VITAMIN_K1: "VITAMIN - K1",
  URINE_GUANIDINO_ACETIC_ACID_LEVELS:
    "URINE CREATINE & GUANIDINO ACETIC ACID LEVELS",
  Alpha_Fucosidosis_Leukocytes: "Alpha-Fucosidosis_Leukocytes",
  Alpha_Mannosidosis_Leukocytes: "Alpha-Mannosidosis_Leukocytes",
  Beta_Mannosidosis_Leukocytes: "Beta-Mannosidosis_Leukocytes",
  Biotinidase_Plasma: "Biotinidase_Plasma",
  Chitotriosidase_Plasma: "Chitotriosidase_Plasma",
  Gaucher_Leukocytes: "Gaucher_Leukocytes",
  Fabry_Leukocytes: "Fabry_Leukocytes",
  Leukocytes_NCL2: "Leukocytes_NCL2",
  'MPS I_Leukocytes':"MPS I_Leukocytes",
  Krabbe_Leukocytes:"Krabbe_Leukocytes",
  MLD_Leukocytes:"MLD_Leukocytes",
  GM1_Leukocytes:"GM1_Leukocytes",
  Glycoasparginase_Leukocytes:"Glycoasparginase_Leukocytes",
  TaysachSandhoffLeukocytes: "TaysachSandhoffLeukocytes",
};
export const biochemicalGeneticsTypeMap = {
  TMS: "TMS",
  Carnitine: "CARNITINE",
  Hemoglobinopathies: "HEMOGLOBINOPATHIES",
  CSF_GLYCENE: "CSF GLYCENE",
  CSF_AMINO_ACIDS: "CSF AMINO ACIDS",
  INTEGRATED_QUADRUPLE_MARKER: "INTEGRATED QUADRUPLE MARKER",
  OEMGA_3_AND_OMEGA_6: "OEMGA 3 AND OMEGA 6", //BIOCHEMICAL_GENETICS
  PLASMA_AMINO_ACIDS: "PLASMA AMINO ACIDS",
  Plasma_Glycine: "PLASMA GLYCINE",
  PLGF: "PLGF",
  URINE_AMINO_ACIDS: "URINE AMINO ACIDS",
  Urinary_Glycosaminoglycans_GAG_Quantification:
    "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
  VERY_LONG_CHAIN_FATTY_ACID: "VERY LONG CHAIN FATTY ACID",
  NEWBORN_SCREENING_BIO_1: "NEWBORN SCREENING (BIO-1)",
  NEWBORN_SCREENING_BIO_2: "NEWBORN SCREENING (BIO-2)",
  NEWBORN_SCREENING_BIO_3: "NEWBORN SCREENING (BIO-3)",
  NEWBORN_SCREENING_BIO_4: "NEWBORN SCREENING (BIO-4)",
  NEWBORN_SCREENING_BIO_5: "NEWBORN SCREENING (BIO-5)",
  NEWBORN_SCREENING_BIO_6: "NEWBORN SCREENING (BIO-6)",
  NEWBORN_SCREENING_BIO_7: "NEWBORN SCREENING (BIO-7)",
  VITAMIN_A_ALL_TRANSRETINOL: "VITAMIN - A  ALL TRANSRETINOL",
  VITAMIN_B1: "VITAMIN - B1",
  VITAMIN_B2: "VITAMIN - B2",
  VITAMIN_B3: "VITAMIN - B3",
  VITAMIN_B6: "VITAMIN - B6",
  VITAMIN_B7: "VITAMIN - B7",
  VITAMIN_B9: "VITAMIN - B9",
  VITAMIN_C_TOTAL_ASCORBIC: "VITAMIN - C TOTAL ASCORBIC",
  VITAMIN_E_ALPHATOCOPHEROL: "VITAMIN - E ALPHATOCOPHEROL",
  VITAMIN_K1: "VITAMIN - K1",
  LYSOSOMAL_STORAGE_DISORDERS:"LYSOSOMAL STORAGE DISORDERS",
  Alpha_Fucosidosis_Leukocytes:  "Alpha-Fucosidosis_Leukocytes",
  Alpha_Mannosidosis_Leukocytes:  "Alpha-Mannosidosis_Leukocytes",
  Beta_Mannosidosis_Leukocytes :  "Beta-Mannosidosis_Leukocytes",
  Biotinidase_Plasma :  "Biotinidase_Plasma",
  Chitotriosidase_Plasma:  "Chitotriosidase_Plasma",
  Gaucher_Leukocytes : "Gaucher_Leukocytes",
  Fabry_Leukocytes: "Fabry_Leukocytes",
  Leukocytes_NCL2:"Leukocytes_NCL2",
  Krabbe_Leukocytes:"Krabbe_Leukocytes",
  'MPS I_Leukocytes':"MPS I_Leukocytes",
  'MPS IIIB_Leukocytes':"MPS IIIB_Leukocytes",
  'MPS IIIC_Leukocytes':'MPS IIIC_Leukocytes',
  'MPS IIID_Leukocytes':'MPS IIID_Leukocytes',
  'MPS IVA_Leukocytes':'MPS IVA_Leukocytes',
  'MPS VI_Leukocytes':'MPS VI_Leukocytes',
  'MPS VII_Leukocytes':'MPS VII_Leukocytes',
  'MPS IIIA_Leukocytes':'MPS IIIA_Leukocytes',
  'MPS II_Leukocytes':'MPS II_Leukocytes',
  MLD_Leukocytes:"MLD_Leukocytes",
  GM1_Leukocytes:"GM1_Leukocytes",
  Glycoasparginase_Leukocytes:"Glycoasparginase_Leukocytes",
}
export const cytometryType = [
  {
    label: "CD3,CD4,CD8 COUNT-FLOWCYTOMETRY",
    value: "CD3_CD4_CD8_COUNT_FLOWCYTOMETRY",
  },
  {
    label: "CD4,CD8 COUNT - FLOWCYTOMETRY",
    value: "CD4_CD8_COUNT_FLOWCYTOMETRY",
  },
  {
    label: "CD-4 COUNT",
    value: "CD4_COUNT",
  },
  {
    label: "HLA B27 - FLOWCYTOMETRY",
    value: "HLA_B27_FLOWCYTOMETRY",
  },
  {
    label: "SPERM DNA FRAGMENTATION",
    value: 'SPERM_DNA_FRAGMENTATION'
  }
]
export const transplantImmunoGeneticsType = [
  {
    label: "CDC CROSS MATCH",
    value: "CDC_CROSS_MATCH",
  },
  {
    label: "Donor Specific Antibody(DSA-IgG) Test",
    value: "Donor_Specific_Antibody_DSA_IgG_Test",
  },
  {
    label: "HLA B * 51 / B * 52",
    value: "HLA_B_51_B_52",
  },
  {
    label: "HLA - CLASS II DQ TYPING",
    value: "HLA_CLASS_II_DQ_TYPING",
  },
  {
    label: "HLA TYPING CLASS 1 & 2 CLASS TYPE",
    value: "HLA_TYPING_CLASS_1_2_CLASS_TYPE",
  },
  {
    label: "HLA FLOW CYTOMETRY T & B CELL CROSSMATCH",
    value: "HLA_FLOW_CYTOMETRY_T_B_CELL_CROSSMATCH",
  },
  {
    label: "PRA CLASS I& CLASS II QUANTITATIVE",
    value: "PRA_CLASS_I_CLASS_II_QUANTITATIVE"
  },
  {
    label: "HLA TYPING A B DRB1",
    value: "HLA_TYPING_A_B_DRB_1"
  },
  {
    label: "HLA SAB CLASS I II TYPING",
    value: "HLA_SAB_CLASS_I_II_TYPING"
  },
]
export const molecularGeneticsType = [
  {
    label: "ADENOVIRUS QUALITATIVE REAL TIME PCR",
    value: "ADENOVIRUS_QUALITATIVE_REAL_TIME_PCR",
  },
  {
    label: "BCR-ABL QUALITATIVE",
    value: "BCR_ABL_QUALITATIVE",
  },
  {
    label: "BCR-ABL QUANTITATIVE",
    value: "BCR_ABL_QUANTITATIVE",
  },
  {
    label: "BK POLYOMA QUANTITATIVE PCR",
    value: "BK_POLYOMA_QUANTITATIVE_PCR",
  },
  {
    label: "CHLAMYDIA TRACHOMATIS DNA QUALITATIVE TEST",
    value: "CHLAMYDIA_TRACHOMATIS_DNA_QUALITATIVE_TEST",
  },
  {
    label: "BK VIRUS - BLOOD",
    value: "BK_VIRUS_BLOOD",
  },
  {
    label: "COVID-19 VIRUS QUALITATIVE PCR",
    value: "COVID_19_VIRUS_QUALITATIVE_PCR",
  },
  {
    label: "CYTOMEGALO VIRUS (CMV) QUANTITATIVE- PCR",
    value: "CYTOMEGALO_VIRUS_CMV_QUANTITATIVE_PCR",
  },
  {
    label: "CYTOMEGALOVIRUS (CMV) QUALITATIVE - PCR",
    value: "CYTOMEGALOVIRUS_CMV_QUALITATIVE_PCR",
  },
  {
    label: "EPSTEIN BAR VIRUS QUALITATIVE - BY REAL TIME PCR",
    value: "EPSTEIN_BAR_VIRUS_QUALITATIVE_BY_REAL_TIME_PCR",
  },
  {
    label: "HBV DNA QUALITATIVE PCR",
    value: "HBV_DNA_QUALITATIVE_PCR",
  },
  {
    label: "HBV DNA QUANTITATIVE REAL TIME PCR",
    value: "HBV_DNA_QUANTITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HCV RNA QUALITATIVE REAL TIME PCR",
    value: "HCV_RNA_QUALITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HCV RNA QUANTITATIVE REAL TIME PCR",
    value: "HCV_RNA_QUANTITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HEPATITIS C VIRUS (HCV) GENOTYPE",
    value: "HEPATITIS_C_VIRUS_HCV_GENOTYPE",
  },
  {
    label: "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUANTITATIVE",
    value: "HERPES_SIMPLEX_VIRUS_1_2_PCR_QUANTITATIVE",
  },
  {
    label: "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUALITATIVE",
    value: "HERPES_SIMPLEX_VIRUS_1_2_PCR_QUALITATIVE",
  },
  {
    label: "HIV 1 RNA QUALITATIVE REAL TIME PCR",
    value: "HIV_1_RNA_QUALITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HIV-1 VIRAL LOAD QUANTITATIVE -PCR",
    value: "HIV_1_VIRAL_LOAD_QUANTITATIVE_PCR",
  },
  {
    label: "HIV-1 DNA, Qualitative (Proviral DNA)",
    value: "HIV_1_DNA_Qualitative_Proviral_DNA",
  },
  {
    label: "HIV-2 RNA QUANTITATIVE REAL TIME PCR",
    value: "HIV_2_RNA_QUANTITATIVE_REAL_TIME_PCR",
  },
  {
    label: "HLA B27 BY RT PCR (QUALITATIVE)",
    value: "HLA_B27_BY_RT_PCR_QUALITATIVE",
  },
  {
    label: "H1N1/SWINE FLU BY REAL TIME PCR",
    value: "H1N1_SWINE_FLU_BY_REAL_TIME_PCR",
  },
  {
    label: "HUMAN PAPILLOMA VIRUS (HPV) QUALITATIVE",
    value: "HUMAN_PAPILLOMA_VIRUS_HPV_QUALITATIVE",
  },
  {
    label: "INFLUENZA H3N2 by REAL TIME PCR",
    value: "INFLUENZA_H3N2_by_REAL_TIME_PCR",
  },
  {
    label: "JAK2 GENE (Exon 14) V617F MUTATION DETECTION",
    value: "JAK2_Gene_Exon_14_V617F_Mutation_Detection",
  },
  {
    label: "JAPANESE ENCEPHALITIS VIRUS (JEV) QUALITATIVE PCR",
    value: "Japanese_Encephalitis_Virus_JEV_Qualitative_PCR",
  },
  {
    label: "JC VIRUS DNA DETECTION",
    value: "JC_VIRUS_DNA_DETECTION",
  },
  {
    label: "MUMPS VIRUS QUALITATIVE",
    value: "MUMPS_VIRUS_QUALITATIVE",
  },
  {
    label: "MYCOBACTERIUM TUBERCULOSIS PCR (TB-PCR)",
    value: "MYCOBACTERIUM_TUBERCULOSIS_PCR_TB_PCR",
  },
  {
    label: "NEISSERIA GONORRHOEA DNA QUALITATIVE",
    value: "NEISSERIA_GONORRHOEA_DNA_QUALITATIVE",
  },
  {
    label: "PML-RARA QUANTITATIVE",
    value: "PML_RARA_QUANTITATIVE",
  },
  {
    label: "PML-RARA QUALITATIVE",
    value: "PML_RARA_QUALITATIVE",
  },
  {
    label: "RESPIRATORY PANEL - BY REAL TIME PCR",
    value: "RESPIRATORY_PANEL_BY_REAL_TIME_PCR",
  },
  {
    label: "RUBELLA PCR QUALITATIVE",
    value: "RUBELLA_PCR_QUALITATIVE",
  },
  {
    label: "SPERM DNA FRAGMENTATION",
    value: "SPERM_DNA_FRAGMENTATION",
  },
  {
    label: 'TROPICAL FEVER PANEL',
    value: 'TROPICAL_FEVER_PANEL'
  },
  {
    label: 'RICKETSSIA PCR',
    value: 'RICKETSSIA_PCR'
  },
  {
    label: "VIRAL MENINGITIS PANEL - BY MULTIPLEX REAL TIME PCR",
    value: "VIRAL_MENINGITIS_PANEL_BY_MULTIPLEX_REAL_TIME_PCR",
  },
  {
    label: "WEST NILE VIRUS PCR",
    value: "WEST_NILE_VIRUS_PCR",
  },
  {
    label: "SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR",
    value: "SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR",
  },
]

export const CytopathalogyTypes = [
  {
    label: "Cytology Slide Reporting",
    value: "Cytology_Slide_Reporting",
  },
  {
    label: "FNAC (Sample In Clinic)",
    value: "FNAC",
  },
  {
    label: "Fluid For Cytology",
    value: "Fluid_For_Cytology",
  },
  {
    label: "Liquid Based Cytology - Pap Smear",
    value: "Liquid_Based_Cytology",
  },
  {
    label: "Fnac Slides Reporting",
    value: "Fnac_Slides_Reporting",
  },
  {
    label: "Pus For Cytology",
    value: "Pus_For_Cytology",
  },
  {
    label: "PAP SMEAR ABOVE 40",
    value: "PAP SMEAR ABOVE 40",
  },
  {
    label: "Smear For Malignant Cells",
    value: "SmearForMalignantCells",
  },
  {
    label: "Urine For Cytology",
    value: "Urine_For_Cytology",
  },
  {
    label: "PAP SMEAR - CONVENTIONAL",
    value: "PAP SMEAR - CONVENTIONAL",
  },
  {
    label: "Sputum For Cytology",
    value: "Sputum_For_Cytology",
  },
]
export const molecularGenomicsType = [
  {
    label: 'ATN1 GENE TRIPLET REPEAT ANALYSIS FOR DRPLA',
    value: 'ATN1_GENE_TRIPLET_REPEAT_ANALYSIS_FOR_DRPLA'
  },
  {
    label: "Beta Thalassemia Screening",
    value: "Beta_Thalassemia_Screening",
  },
  {
    label: "BCR-ABL KD MUTATION ANALYSIS (IRMA)",
    value: "BCR_ABL_KD_MUTATION_ANALYSIS_IRMA",
  },
  {
    label: "BCR-ABL QUALITATIVE TEST",
    value: "BCR_ABL_Qualitative_Test",
  },
  {
    label: "BCR-ABL IS -QUANTITATIVE TEST",
    value: "BCR_ABL_IS_Quantitative_Test",
  },
  {
    label: "BK Virus Quantitative PCR",
    value: "BK_Virus_Quantitative_PCR",
  },
  {
    label: "BK Virus Qualitative -PCR",
    value: "BK_Virus_Qualitative_PCR",
  },
  {
    label: "CD-4 COUNT",
    value: "CD4_COUNT",
  },
  {
    label: "CLOPIDOGREL/PRASUGREL/TICAGRELOR",
    value: "CLOPIDOGREL_PRASUGREL_TICAGRELOR",
  },
  {
    label: "Congenital adrenal hyperplasia CYP21A2",
    value: "Congenital_adrenal_hyperplasia_CYP21A2",
  },
  {
    label: "Cystic Fibrosis",
    value: "Cystic_Fibrosis",
  },

  {
    label: "Chlamydia Trachomatis Qualitative Test",
    value: "CHLAMYDIA_TRACHOMATIS_QUALITATIVE_TEST",
  },
  {
    label: "Chromosomal Micro array",
    value: "Chromosomal_Micro_array",
  },
  {
    label: "DMD Analysis by MLPA",
    value: "DMD_Analysis_Mlpa",
  },
  {
    label: "DPYD",
    value: "DPYD",
  },
  {
    label: "Factor- II Prothrombin Mutation",
    value: "Factor_II_Prothrombin_Mutation",
  },
  {
    label: "Factor- V Leiden Mutation Screening",
    value: "Factor_V_Leiden_Mutation_Screening",
  },
  {
    label: "Fragile-X-PCR",
    value: "FRAGILE_X_PCR",
  },
  {
    label: "FRIEDREICH ATAXIA",
    value: "FRIEDREICH_ATAXIA",
  },
  {
    label: "GILBERT SYNDROME",
    value: "GILBERT_SYNDROME",
  },
  {
    label: "HSV-1/2 Quantitative -PCR",
    value: "HSV_1_2_Quantitative_PCR",
  },
  // {
  //   label: "HEPATITIS C VIRUS (HCV) GENOTYPE",
  //   value: "HEPATITIS_C_VIRUS_HCV_GENOTYPE",
  // },
  {
    label: "HLA B51 TYPING",
    value: "HLA_B51_TYPING",
  },
  {
    label: "HUNTINGTON - CAG REPEAT ANALYSIS",
    value: "HUNTINGTON_CAG_REPEAT_ANALYSIS",
  },
  {
    label: "JAK2 E-14 MUTATION ANALYSIS",
    value: "JAK2_E_14_MUTATION_ANALYSIS",
  },
  {
    label: "JAK2 (EXONS 12-15) MUTATION ANALYSIS",
    value: "JAK2_EXONS_12_15_MUTATION_ANALYSIS",
  },
  {
    label: "JAK2 REFLEX PANEL",
    value: "JAK2_REFLEX_PANEL",
  },
  {
    label: "Maternal Cell Contamination Testing Report (MCC)",
    value: "Maternal_Cell_Contamination_Testing_Report_MCC",
  },
  {
    label: "MTHFR Screening",
    value: "MTHFR_Screening",
  },
  {
    label: "MTHFR, Factor V and II screening",
    value: "MTHFR_Factor_V_and_II_screening",
  },
  {
    label: "MPN Panel",
    value: "MPN_Panel",
  },
  {
    label: "MYOTONIC DYSTROPHY 1&2",
    value: "Myotonic_Dystrophy_1_2",
  },
  {
    label: "NIPT",
    value: "NIPT",
  },
  {
    label: "NIPT with Micro Deletions",
    value: "NIPT_with_Micro_Deletions",
  },
  {
    label: "Parental Carrier Testing",
    value: "Parental_Carrier_Testing",
  },
  {
    label: "Prothrombotic Profile",
    value: "Prothrombotic_Profile",
  },
  {
    label: "PML-RARA QUALITATIVE TEST",
    value: "PML_RARA_QUALITATIVE_TEST",
  },
  {
    label: "PML-RARA QUANTITATIVE TEST",
    value: "PML_RARA_QUANTITATIVE_TEST",
  },
  {
    label: "QF PCR",
    value: "QF_PCR",
  },
  {
    label: "Rubella Pcr",
    value: "RUBELLA_PCR",
  },

  {
    label: "SCA Panel Testing",
    value: "SCA_Panel_Testing",
  },
  {
    label: "SMA analysis by RFLP",
    value: "SMA_ANALYSIS_BY_RFLP",
  },
  // {
  //   label: "SPERM DNA FRAGMENTATION TEST",
  //   value: "SPERM_DNA_FRAGMENTATION_TEST"
  // },
  // {
  //   label: "SPERM DNA FRAGMENTATION",
  //   value: 'SPERM_DNA_FRAGMENTATION'
  // },
  {
    label: "Spinal Muscular Atrophy Deletion & Duplication Analysis (SMN_MLPA)",
    value: "Spinal_Muscular_Atrophy_Deletion_Duplication_Analysis_SMN_MLPA",
  },

  {
    label: "Tacrolimus",
    value: "Tacrolimus",
  },
  {
    label: "Targeted Testing",
    value: "Targeted_Testing",
  },
  {
    label: "Targeted Sanger Sequencing",
    value: "Targeted_Sanger_Sequencing",
  },
  {
    label: "TPMT",
    value: "TPMT",
  },

  // {
  //   label: "Urine Organic Acid (GC-MS)",
  //   value: "Urine_Organic_Acid_GC_MS",
  // },

  {
    label: "Whole Exome Sequencing",
    value: "WHOLE_EXOME_SEQUESNCING",
  },

  {
    label: "Y Chromosome Microdeletion",
    value: "Y_Chromosome_Microdeletion",
  },

  // {
  //   label: "SMA Analysis - Negative",
  //   value: "SMA_Analysis",
  // },

  // {
  //   label: "DMD Analysis - Negative",
  //   value: "DMD_Analysis_Negative",
  // },
]
export const doubleMarkersTypes = [
  {
    label: "CARNITINE",
    value: "Carnitine",
  },
  {
    label: "CSF AMINO ACIDS",
    value: "CSF_AMINO_ACIDS",
  },
  {
    label: "CSF GLYCENE",
    value: "CSF_GLYCENE",
  },
  {
    label: "DOUBLE MARKERS",
    value: "DOUBLE_MARKERS",
  },
  {
    value: "EARLY_QUADRUPLE_MARKERS",
    label: "EARLY QUADRUPLE MARKERS",
  },
  {
    label: "HEMOGLOBINOPATHIES",
    value: "Hemoglobinopathies",
  },
  {
    value: "INTEGRATED_QUADRUPLE_MARKERS",
    label: "INTEGRATED QUADRUPLE MARKERS",
  },
  {
    label: "NEWBORN SCREENING (BIO-1)",
    value: "NEWBORN_SCREENING_BIO_1",
  },
  {
    label: "NEWBORN SCREENING (BIO-2)",
    value: "NEWBORN_SCREENING_BIO_2",
  },
  {
    label: "NEWBORN SCREENING (BIO-3)",
    value: "NEWBORN_SCREENING_BIO_3",
  },
  {
    label: "NEWBORN SCREENING (BIO-4)",
    value: "NEWBORN_SCREENING_BIO_4",
  },
  {
    label: "NEWBORN SCREENING (BIO-5)",
    value: "NEWBORN_SCREENING_BIO_5",
  },
  {
    label: "NEWBORN SCREENING (BIO-6)",
    value: "NEWBORN_SCREENING_BIO_6",
  },
  {
    label: "NEWBORN SCREENING (BIO-7)",
    value: "NEWBORN_SCREENING_BIO_7",
  },
  {
    label: "OEMGA 3 AND OMEGA 6",
    value: "OEMGA_3_AND_OMEGA_6",
  },
  {
    label: "PLASMA AMINO ACIDS",
    value: "PLASMA_AMINO_ACIDS",
  },
  {
    label: "PLASMA CREATINE & GUANIDINO ACETIC ACID LEVELS",
    value: "PLASMA_CREATINE_GUANIDINO_ACETIC_ACID_LEVELS",
  },
  {
    label: "PLASMA GLYCINE",
    value: "Plasma_Glycine",
  },
  {
    label: "PLGF",
    value: "PLGF",
  },
  {
    label: "QUADRUPLE MARKERS",
    value: "QUADRUPLE_MARKERS",
  },
  {
    label: "TMS",
    value: "TMS",
  },
  {
    label: "TRIPLE MARKERS",
    value: "TRIPLE_MARKERS",
  },
  {
    label: "URINE ORGANIC ACID",
    value: "URINE_ORGANIC_ACID",
  },
  {
    label: "URINE AMINO ACIDS",
    value: "URINE_AMINO_ACIDS",
  },
  {
    label: "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
    value: "Urinary_Glycosaminoglycans_GAG_Quantification",
  },
  {
    value: "URINE_GUANIDINO_ACETIC_ACID_LEVELS",
    label: "URINE CREATINE & GUANIDINO ACETIC ACID LEVELS",
  },
  {
    label: "VERY LONG CHAIN FATTY ACID",
    value: "VERY_LONG_CHAIN_FATTY_ACID",
  },
  {
    label: "VITAMIN - A  ALL TRANSRETINOL",
    value: "VITAMIN_A_ALL_TRANSRETINOL",
  },
  {
    label: "VITAMIN - B1",
    value: "VITAMIN_B1",
  },
  {
    label: "VITAMIN - B2",
    value: "VITAMIN_B2",
  },
  {
    label: "VITAMIN - B3",
    value: "VITAMIN_B3",
  },

  {
    label: "VITAMIN - B6",
    value: "VITAMIN_B6",
  },
  {
    label: "VITAMIN - B7",
    value: "VITAMIN_B7",
  },
  {
    label: "VITAMIN - B9",
    value: "VITAMIN_B9",
  },
  {
    label: "VITAMIN - C TOTAL ASCORBIC",
    value: "VITAMIN_C_TOTAL_ASCORBIC",
  },
  {
    label: "VITAMIN - E ALPHATOCOPHEROL",
    value: "VITAMIN_E_ALPHATOCOPHEROL",
  },
  {
    label: "VITAMIN - K1",
    value: "VITAMIN_K1",
  },
  {
    label: "Alpha-Fucosidosis_Leukocytes",
    value: "Alpha_Fucosidosis_Leukocytes",
  } ,
  {
    label: "Alpha-Mannosidosis_Leukocytes",
    value: "Alpha_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Beta-Mannosidosis_Leukocytes",
    value: "Beta_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Biotinidase_Plasma",
    value: "Biotinidase_Plasma",
  } ,
  {
    label: "Chitotriosidase_Plasma",
    value: "Chitotriosidase_Plasma",
  } ,
  {
    label: "Fabry_Leukocytes",
    value: "Fabry_Leukocytes",
  } ,
  {
    label: "Gaucher_Leukocytes",
    value: "Gaucher_Leukocytes",
  } ,
  {
    label: "Leukocytes_NCL2",
    value: "Leukocytes_NCL2",
  } ,
  {
    label: "Krabbe_Leukocytes",
    value: "Krabbe_Leukocytes",
  } ,
  {
    label: "Glycoasparginase_Leukocytes",
    value: "Glycoasparginase_Leukocytes",
  } ,
  {
    label: "MPS I_Leukocytes",
    value: "MPS I_Leukocytes",
  } ,
  {
    label: "GM1_Leukocytes",
    value: "GM1_Leukocytes",
  } ,
  {
    label: "MLD_Leukocytes",
    value: "MLD_Leukocytes",
  } ,
 {
  label: "MPS II_Leukocytes",
  value: "MPS II_Leukocytes",
} ,
{
  label: "MPS IIIA_Leukocytes",
  value: "MPS IIIA_Leukocytes",
} ,
{
  label: "MPS IIIB_Leukocytes",
  value: "MPS IIIB_Leukocytes",
} ,
{
  label: "MPS IIIC_Leukocytes",
  value: "MPS IIIC_Leukocytes",
} ,  
{
  label: "MPS IIID_Leukocytes",
  value: "MPS IIID_Leukocytes",
} ,
{
  label: "MPS IVA_Leukocytes",
  value: "MPS IVA_Leukocytes",
} ,
{
  label: "MPS VI_Leukocytes",
  value: "MPS VI_Leukocytes",
} ,
{
  label: "MPS VII_Leukocytes",
  value: "MPS VII_Leukocytes ",
} ,
]
export const cytogeneticsTypes = [ 
  "KARYOTYPING","SOILDFISH","FISH"]
  export const corporateTypes = [ 
    "Honda Hyundai Motors","Bharathi Cement","Reliance Industries","Bajaj Finserv Ltd","Indian Oil Corporation Ltd"
]
  
  export const cytogeneticsTypesMap = [ 
    {label: " KARYOTYPING" ,value : "KARYOTYPING"},
    {label: " SOILDFISH" ,value : "SOILDFISH"},
    {label: " FISH" ,value : "FISH"},
    ]
    export const CytopathalogyMap = {
      Cytology_Slide_Reporting:'Cytology Slide Reporting',
      FNAC:"FNAC (Sample In Clinic)",
      Fluid_For_Cytology:"Fluid For Cytology",
      Liquid_Based_Cytology:"Liquid Based Cytology - Pap Smear",
      Fnac_Slides_Reporting:"Fnac Slides Reporting",
      Pus_For_Cytology:"Pus For Cytology",
      'PAP SMEAR ABOVE 40':"PAP SMEAR ABOVE 40",
      SmearForMalignantCells:"Smear For Malignant Cells",
      Urine_For_Cytology:"Urine For Cytology",
      'PAP SMEAR - CONVENTIONAL':"PAP SMEAR - CONVENTIONAL",
      Sputum_For_Cytology:"Sputum For Cytology"
    }
export const histopathologyTypesMap = [
  "HISTOPATHOLOGY(LARGE SPECIMEN)","HISTOPATHOLOGY(BIOPSY)","HISTOPATHOLOGY(IHC - 4 MARKERS)"]
  // {
  //   label:  "HISTOPATHOLOGY(LARGE SPECIMEN)",
  //   value:  "HISTOPATHOLOGY(LARGE SPECIMEN)",
  // },
  // {
  //   label: "HISTOPATHOLOGY(BIOPSY)",
  //   value:  "HISTOPATHOLOGY(BIOPSY)",
  // },
// ]
export const histopathologyTypesMaps = [
 
  {
    label:  "HISTOPATHOLOGY(LARGE SPECIMEN)",
    value:  "HISTOPATHOLOGY(LARGE SPECIMEN)",
  },
  {
    label: "HISTOPATHOLOGY(BIOPSY)",
    value:  "HISTOPATHOLOGY(BIOPSY)",
  },
  {
    label: "HISTOPATHOLOGY(IHC - 4 MARKERS)",
    value:  "HISTOPATHOLOGY(IHC - 4 MARKERS)",
  },
]

export const corporateTypesMaps = [ 
  {
    label:  "Honda Hyundai Motors",
    value:  "Honda Hyundai Motors",
  },
  {
    label: "Bharathi Cement",
    value: "Bharathi Cement",
  },
  {
    label: "Reliance Industries",
    value: "Reliance Industries",
  }, {
    label: "Bajaj Finserv Ltd",
    value:  "Bajaj Finserv Ltd",
  }, {
    label: "Indian Oil Corporation Ltd",
    value:  "Indian Oil Corporation Ltd",
  },
]

export const molecularGenomicsReportTypeMap = {
  ATN1_GENE_TRIPLET_REPEAT_ANALYSIS_FOR_DRPLA: 'ATN1 GENE TRIPLET REPEAT ANALYSIS FOR DRPLA',
  WHOLE_EXOME_SEQUESNCING: "Whole Exome Sequencing",
  RUBELLA_PCR: "Rubella Pcr",
  CHLAMYDIA_TRACHOMATIS_QUALITATIVE_TEST:
    "Chlamydia Trachomatis Qualitative Test",
  Chromosomal_Micro_array: "Chromosomal Micro array",
  FRAGILE_X_PCR: "Fragile-X-PCR",
  Y_Chromosome_Microdeletion: "Y Chromosome Microdeletion",
  // Urine_Organic_Acid_GC_MS: "Urine Organic Acid (GC-MS)",
  Spinal_Muscular_Atrophy_Deletion_Duplication_Analysis_SMN_MLPA:
    "Spinal Muscular Atrophy Deletion & Duplication Analysis (SMN_MLPA)",
  BK_Virus_Quantitative_PCR: "BK Virus Quantitative PCR",
  BK_Virus_Qualitative_PCR: "BK Virus Qualitative -PCR",
  // SMA_Analysis_Positive: "SMA Analysis",
  DMD_Analysis_Mlpa: "DMD Analysis by MLPA",
  // DMD_Analysis_Negative: "DMD Analysis",
  QF_PCR: "QF PCR",
  MTHFR_Screening: "MTHFR Screening",
  MTHFR_Factor_V_and_II_screening: "MTHFR, Factor V and II screening",
  Prothrombotic_Profile: "Prothrombotic Profile",
  SCA_Panel_Testing: "SCA Panel Testing",
  // SPERM_DNA_FRAGMENTATION_TEST: "SPERM DNA FRAGMENTATION TEST",
  Tacrolimus: "Tacrolimus",
  DPYD: "DPYD",
  HSV_1_2_Quantitative_PCR: "HSV-1/2 Quantitative -PCR",
  TPMT: "TPMT",
  GILBERT_SYNDROME: "GILBERT SYNDROME",
  Targeted_Testing: "Targeted Testing",
  Beta_Thalassemia_Screening: "Beta Thalassemia Screening",
  Cystic_Fibrosis: "Cystic Fibrosis",
  NIPT: "NIPT",
  NIPT_with_Micro_Deletions: "NIPT with Micro Deletions",
  PML_RARA_QUANTITATIVE_TEST: "PML-RARA QUANTITATIVE TEST",
  PML_RARA_QUALITATIVE_TEST: "PML-RARA QUALITATIVE TEST",
  JAK2_E_14_MUTATION_ANALYSIS: "JAK2 E-14 MUTATION ANALYSIS",
  HLA_B51_TYPING: "HLA B51 TYPING",
  // HEPATITIS_C_VIRUS_GENOTYPING: "HEPATITIS -C VIRUS GENOTYPING",
  BCR_ABL_IS_Quantitative_Test: "BCR-ABL IS -QUANTITATIVE TEST",
  BCR_ABL_Qualitative_Test: "BCR-ABL QUALITATIVE TEST",
  Factor_II_Prothrombin_Mutation: "Factor- II Prothrombin Mutation",
  Factor_V_Leiden_Mutation_Screening: "Factor- V Leiden Mutation Screening",
  Maternal_Cell_Contamination_Testing_Report_MCC:
    "Maternal Cell Contamination Testing Report (MCC)",
  Targeted_Sanger_Sequencing: "Targeted Sanger Sequencing",
  Parental_Carrier_Testing: "Parental Carrier Testing",
  FRIEDREICH_ATAXIA: "FRIEDREICH ATAXIA",
  MPN_Panel: "MPN Panel",
  JAK2_EXONS_12_15_MUTATION_ANALYSIS: "JAK2 (EXONS 12-15) MUTATION ANALYSIS",
  CLOPIDOGREL_PRASUGREL_TICAGRELOR: "CLOPIDOGREL/PRASUGREL/TICAGRELOR",
  Congenital_adrenal_hyperplasia_CYP21A2:
    "Congenital adrenal hyperplasia CYP21A2",
  Myotonic_Dystrophy_1_2: "MYOTONIC DYSTROPHY 1&2",
  JAK2_REFLEX_PANEL: "JAK2 REFLEX PANEL",
  SMA_ANALYSIS_BY_RFLP: "SMA analysis by RFLP",
  BCR_ABL_KD_MUTATION_ANALYSIS_IRMA: "BCR-ABL KD MUTATION ANALYSIS (IRMA)",
  HUNTINGTON_CAG_REPEAT_ANALYSIS: 'HUNTINGTON - CAG REPEAT ANALYSIS'
}

export const biochemicalGeneticsTypes = [
  {
    label: "CARNITINE",
    value: "Carnitine",
  },
  {
    label: "CSF GLYCENE",
    value: "CSF_GLYCENE",
  },
  {
    label: "CSF AMINO ACIDS",
    value: "CSF_AMINO_ACIDS",
  },
  {
    label: "HEMOGLOBINOPATHIES",
    value: "Hemoglobinopathies",
  },
  {
    label: "INTEGRATED QUADRUPLE MARKER",
    value: "INTEGRATED_QUADRUPLE_MARKER",
  },
  {
    label: "NEWBORN SCREENING (BIO-1)",
    value: "NEWBORN_SCREENING_BIO_1",
  },
  {
    label: "NEWBORN SCREENING (BIO-2)",
    value: "NEWBORN_SCREENING_BIO_2",
  },
  {
    label: "NEWBORN SCREENING (BIO-3)",
    value: "NEWBORN_SCREENING_BIO_3",
  },
  {
    label: "NEWBORN SCREENING (BIO-4)",
    value: "NEWBORN_SCREENING_BIO_4",
  },
  {
    label: "NEWBORN SCREENING (BIO-5)",
    value: "NEWBORN_SCREENING_BIO_5",
  },
  {
    label: "NEWBORN SCREENING (BIO-6)",
    value: "NEWBORN_SCREENING_BIO_6",
  },
  {
    label: "NEWBORN SCREENING (BIO-7)",
    value: "NEWBORN_SCREENING_BIO_7",
  },
  {
    label: "OEMGA 3 AND OMEGA 6",
    value: "OEMGA_3_AND_OMEGA_6",
  },
  {
    label: "PLASMA AMINO ACIDS",
    value: "PLASMA_AMINO_ACIDS",
  },
  {
    label: "PLASMA GLYCINE",
    value: "Plasma_Glycine",
  },
  {
    label: "PLGF",
    value: "PLGF",
  },
  {
    label: "URINE AMINO ACIDS",
    value: "URINE_AMINO_ACIDS",
  },
  {
    label: "URINARY GLYCOSAMINOGLYCANS (GAG) QUANTIFICATION",
    value: "Urinary_Glycosaminoglycans_GAG_Quantification",
  },
  {
    label: "VERY LONG CHAIN FATTY ACID",
    value: "VERY_LONG_CHAIN_FATTY_ACID",
  },
  {
    label: "VITAMIN - A  ALL TRANSRETINOL",
    value: "VITAMIN_A_ALL_TRANSRETINOL",
  },
  {
    label: "VITAMIN - B1",
    value: "VITAMIN_B1",
  },
  {
    label: "VITAMIN - B2",
    value: "VITAMIN_B2",
  },
  {
    label: "VITAMIN - B3",
    value: "VITAMIN_B3",
  },

  {
    label: "VITAMIN - B6",
    value: "VITAMIN_B6",
  },
  {
    label: "VITAMIN - B7",
    value: "VITAMIN_B7",
  },
  {
    label: "VITAMIN - B9",
    value: "VITAMIN_B9",
  },
  {
    label: "VITAMIN - C TOTAL ASCORBIC",
    value: "VITAMIN_C_TOTAL_ASCORBIC",
  },
  {
    label: "VITAMIN - E ALPHATOCOPHEROL",
    value: "VITAMIN_E_ALPHATOCOPHEROL",
  },
  {
    label: "VITAMIN - K1",
    value: "VITAMIN_K1",
  },
  {
    label: "Fabry_Leukocytes",
    value: "Fabry_Leukocytes",
  },
  {
    label: "MPS II_Leukocytes",
    value: "MPS II_Leukocytes",
  },
  {
    label: "Gaucher_Leukocytes",
    value: "Gaucher_Leukocytes",
  },
  {
    label: "Glycoasparginase_Leukocytes",
    value: "Glycoasparginase_Leukocytes",
  },
  {
    label: "GM1_Leukocytes",
    value: "GM1_Leukocytes",
  },
  {
    label: "Krabbe_Leukocytes",
    value: "Krabbe_Leukocytes",
  },
  {
    label: "Leukocytes_NCL2",
    value: "Leukocytes_NCL2",
  },
  {
    label: "MLD_Leukocytes",
    value: "MLD_Leukocytes",
  },
  {
    label: "MPS I_Leukocytes",
    value: "MPS I_Leukocytes",
  },
  {
    label: "MPS IIIA_Leukocytes",
    value: "MPS IIIA_Leukocytes",
  },
  {
    label: "MPS IIIB_Leukocytes",
    value: "MPS IIIB_Leukocytes",
  },
  {
    label: "MPS IIIC_Leukocytes",
    value: "MPS IIIC_Leukocytes",
  },
  {
    label: "MPS IIID_Leukocytes",
    value: "MPS IIID_Leukocytes",
  },
  {
    label: "MPS IVA_Leukocytes",
    value: "MPS IVA_Leukocytes",
  },
  {
    label: "MPS VI_Leukocytes",
    value: "MPS VI_Leukocytes",
  },
  {
    label: "MPS VII_Leukocytes",
    value: "MPS VII_Leukocytes",
  },
  {
    label: "LYSOSOMAL STORAGE DISORDERS",
    value: "LYSOSOMAL_STORAGE_DISORDERS",
  },    
  {
    label: "Alpha-Fucosidosis_Leukocytes",
    value: "Alpha_Fucosidosis_Leukocytes",
  } ,
  {
    label: "Alpha-Mannosidosis_Leukocytes",
    value: "Alpha_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Beta-Mannosidosis_Leukocytes",
    value: "Beta_Mannosidosis_Leukocytes",
  } ,
  {
    label: "Biotinidase_Plasma",
    value: "Biotinidase_Plasma",
  } ,
  {
    label: "Chitotriosidase_Plasma",
    value: "Chitotriosidase_Plasma",
  } ,
  {
    label: "YODA SHISHU SAMRAKSHA PANEL",
    value: "Yoda_Shishu_Samraksha_pannel",
  } ,

]
export const serologyReportTypes = [
  {
    label: "ALLERGY DRUGS COMPREHENSIVE PANEL",
    value: "ALLERGY_DRUGS_COMPREHENSIVE_PANEL",
  },
  {
    label: "ALLERGY ANTIBIOTICS PANEL",
    value: "ALLERGY_ANTIBIOTICS_PANEL",
  },
  {
    label: "ALLERGY ATROPINE",
    value: "ALLERGY_ATROPINE",
  },
  {
    label: "ALLERGY ANALEGESIC & ANTIPYRETICS",
    value: "ALLERGY_ANALEGESIC_ANTIPYRETICS",
  },
  {
    label: "ALLERGY HORMONAL DRUGS",
    value: "ALLERGY_HORMONAL_DRUGS",
  },
  {
    label: "ALLERGY LIDOCAINE",
    value: "ALLERGY_LIDOCAINE",
  },
  {
    label: "ALLERGY COMPREHENSIVE PANEL",
    value: "ALLERGY_COMPREHENSIVE_PANEL",
  },
  {
    label: "ALLERGY PET & AVIAN",
    value: "ALLERGY_PET_AVIAN",
  },
  {
    label: "ALLERGY DUST MITE",
    value: "ALLERGY_DUST_MITE",
  },
  {
    label: "ALLERGY COMMON HOUSEHOLD INSECTS",
    value: "ALLERGY_COMMON_HOUSEHOLD_INSECTS",
  },
  {
    label: "ALLERGY GRASS PROFILE",
    value: "ALLERGY_GRASS_PROFILE",
  },
  {
    label: "ALLERGY MOULDS & YEAST",
    value: "ALLERGY_MOULDS_YEAST",
  },
  {
    label: "ALLERGY WEEDS & TREE ALLENGER",
    value: "ALLERGY_WEEDS_TREE_ALLENGER",
  },
  {
    label: "ALLERGY FOOD NON-VEG",
    value: "ALLERGY_FOOD_NON_VEG",
  },
  {
    label: "ALLERGY FOOD VEG & NON VEG PANEL",
    value: "ALLERGY_FOOD_VEG_NON_VEG_PANEL",
  },
  {
    label: "ALLERGY FOOD VEGETARIAN PANEL",
    value: "ALLERGY_FOOD_VEGETARIAN_PANEL",
  },
  {
    label: "ALLERGY FRUITS PANEL",
    value: "ALLERGY_FRUITS_PANEL",
  },
  {
    label: "ALLERGY MILK & DAIRY PRODUCTS",
    value: "ALLERGY_MILK_DAIRY_PRODUCTS",
  },
  {
    label: "ALLERGY SEED / NUTS / LEGUMES",
    value: "ALLERGY_SEED_NUTS_LEGUMES",
  },
  {
    label: "ALLERGY SPICES",
    value: "ALLERGY_SPICES",
  },
  {
    label: "ALLERGY OCCUPATIONAL",
    value: "ALLERGY_OCCUPATIONAL",
  },
  {
    label: "ALLERGY PARASITES",
    value: "ALLERGY_PARASITES",
  },
]
export const pharmacoGenomicsTypes = [
  "DIABETES PGX",
  "RIVAROXABAN PGx",
  "WARFARIN/ACITROM BASIC",
]
export const pharmacoGenomicsType = [
  {
    label: "DIABETES PGX",
    value: "DIABETES_PGX",
  },
  {
    label: "RIVAROXABAN PGx",
    value: "RIVAROXABAN_PGx",
  },
  {
    label: "WARFARIN/ACITROM BASIC",
    value: "WARFARIN_ACITROM_BASIC",
  },
]
export const pharmacoGenomicsReportTypeMap = {
  DIABETES_PGX: "DIABETES PGX",
  RIVAROXABAN_PGx: "RIVAROXABAN PGx",
  WARFARIN_ACITROM_BASIC: "WARFARIN/ACITROM BASIC",
}
export const serologyImmunologyTypes = [
  "ALLERGY DRUGS COMPREHENSIVE PANEL",
  "ALLERGY ANTIBIOTICS PANEL",
  "ALLERGY ATROPINE",
  "ALLERGY ANALEGESIC & ANTIPYRETICS",
  "ALLERGY HORMONAL DRUGS",
  "ALLERGY LIDOCAINE",
  "ALLERGY COMPREHENSIVE PANEL",
  "ALLERGY PET & AVIAN",
  "ALLERGY DUST MITE",
  "ALLERGY COMMON HOUSEHOLD INSECTS",
  "ALLERGY GRASS PROFILE",
  "ALLERGY MOULDS & YEAST",
  "ALLERGY WEEDS & TREE ALLENGER",
  "ALLERGY FOOD NON - VEG",
  "ALLERGY FOOD VEG & NON VEG PANEL",
  "ALLERGY FOOD VEGETARIAN PANEL",
  "ALLERGY FRUITS PANEL",
  "ALLERGY MILK & DAIRY PRODUCTS",
  "ALLERGY SEED / NUTS / LEGUMES",
  "ALLERGY SPICES",
  "ALLERGY OCCUPATIONAL",
  "ALLERGY PARASITES",
]
export const serologyReportMap = {
  ALLERGY_DRUGS_COMPREHENSIVE_PANEL: "ALLERGY DRUGS COMPREHENSIVE PANEL",
  ALLERGY_ANTIBIOTICS_PANEL: "ALLERGY ANTIBIOTICS PANEL",
  ALLERGY_ANALEGESIC_ANTIPYRETICS: "ALLERGY ANALEGESIC & ANTIPYRETICS",
  ALLERGY_PARASITES: "ALLERGY PARASITES",
  ALLERGY_OCCUPATIONAL: "ALLERGY OCCUPATIONAL",
  ALLERGY_SPICES: "ALLERGY SPICES",
  ALLERGY_SEED_NUTS_LEGUMES: "ALLERGY SEED / NUTS / LEGUMES",
  ALLERGY_MILK_DAIRY_PRODUCTS: "ALLERGY MILK & DAIRY PRODUCTS",
  ALLERGY_FOOD_VEGETARIAN_PANEL: "ALLERGY FOOD VEGETARIAN PANEL",
  ALLERGY_FRUITS_PANEL: "ALLERGY FRUITS PANEL",
  ALLERGY_FOOD_NON_VEG: "ALLERGY FOOD NON - VEG",
  ALLERGY_FOOD_VEG_NON_VEG_PANEL: "ALLERGY FOOD VEG & NON VEG PANEL",
  ALLERGY_WEEDS_TREE_ALLENGER: "ALLERGY WEEDS & TREE ALLENGER",
  ALLERGY_MOULDS_YEAST: "ALLERGY MOULDS & YEAST",
  ALLERGY_GRASS_PROFILE: "ALLERGY GRASS PROFILE",
  ALLERGY_COMMON_HOUSEHOLD_INSECTS: "ALLERGY COMMON HOUSEHOLD INSECTS",
  ALLERGY_DUST_MITE: "ALLERGY DUST MITE",
  ALLERGY_PET_AVIAN: "ALLERGY PET & AVIAN",
  ALLERGY_COMPREHENSIVE_PANEL: "ALLERGY COMPREHENSIVE PANEL",
  ALLERGY_LIDOCAINE: "ALLERGY LIDOCAINE",
  ALLERGY_HORMONAL_DRUGS: "ALLERGY HORMONAL DRUGS",
  ALLERGY_ATROPINE: "ALLERGY ATROPINE",
}
export const flowCytometryReportTypeMap = {
  CD3_CD4_CD8_COUNT_FLOWCYTOMETRY: "CD3,CD4,CD8 COUNT-FLOWCYTOMETRY",
  CD4_CD8_COUNT_FLOWCYTOMETRY: "CD4,CD8 COUNT - FLOWCYTOMETRY",
  HLA_B27_FLOWCYTOMETRY: "HLA B27 - FLOWCYTOMETRY",
  CD4_COUNT: "CD-4 COUNT",
  SPERM_DNA_FRAGMENTATION: "SPERM DNA FRAGMENTATION",
}
export const transplantImmunoGeneticsMap = {
  CDC_CROSS_MATCH: "CDC CROSS MATCH",
  Donor_Specific_Antibody_DSA_IgG_Test: "Donor Specific Antibody(DSA-IgG) Test",
  HLA_B_51_B_52: "HLA B * 51 / B * 52",
  HLA_CLASS_II_DQ_TYPING: "HLA - CLASS II DQ TYPING",
  HLA_TYPING_CLASS_1_2_CLASS_TYPE: "HLA TYPING CLASS 1 & 2 CLASS TYPE",
  HLA_FLOW_CYTOMETRY_T_B_CELL_CROSSMATCH:
    "HLA FLOW CYTOMETRY T & B CELL CROSSMATCH",
    PRA_CLASS_I_CLASS_II_QUANTITATIVE:"PRA CLASS I& CLASS II QUANTITATIVE",
    HLA_TYPING_A_B_DRB_1:"HLA TYPING A B DRB1",
    HLA_SAB_CLASS_I_II_TYPING: "HLA SAB CLASS I II TYPING",
}
export const molecularGeneticsReportTypeMap = {
  ADENOVIRUS_QUALITATIVE_REAL_TIME_PCR: "ADENOVIRUS QUALITATIVE REAL TIME PCR",
  BK_POLYOMA_QUANTITATIVE_PCR: "BK POLYOMA QUANTITATIVE PCR",
  BK_VIRUS_BLOOD: "BK VIRUS - BLOOD",
  BCR_ABL_QUALITATIVE: "BCR-ABL QUALITATIVE",
  BCR_ABL_QUANTITATIVE: "BCR-ABL QUANTITATIVE",
  PML_RARA_QUANTITATIVE: "PML-RARA QUANTITATIVE",
  NEISSERIA_GONORRHOEA_DNA_QUALITATIVE: "NEISSERIA GONORRHOEA DNA QUALITATIVE",
  CHLAMYDIA_TRACHOMATIS_DNA_QUALITATIVE_TEST:
    "CHLAMYDIA TRACHOMATIS DNA QUALITATIVE TEST",
  PML_RARA_QUALITATIVE: "PML-RARA QUALITATIVE",
  COVID_19_VIRUS_QUALITATIVE_PCR: "COVID-19 VIRUS QUALITATIVE PCR",
  CYTOMEGALO_VIRUS_CMV_QUANTITATIVE_PCR:
    "CYTOMEGALO VIRUS (CMV) QUANTITATIVE- PCR",
  CYTOMEGALOVIRUS_CMV_QUALITATIVE_PCR:
    "CYTOMEGALOVIRUS (CMV) QUALITATIVE - PCR",
  EPSTEIN_BAR_VIRUS_QUALITATIVE_BY_REAL_TIME_PCR:
    "EPSTEIN BAR VIRUS QUALITATIVE - BY REAL TIME PCR",
  H1N1_SWINE_FLU_BY_REAL_TIME_PCR: "H1N1/SWINE FLU BY REAL TIME PCR",
  INFLUENZA_H3N2_by_REAL_TIME_PCR: "INFLUENZA H3N2 by REAL TIME PCR",
  HCV_RNA_QUALITATIVE_REAL_TIME_PCR: "HCV RNA QUALITATIVE REAL TIME PCR",
  HCV_RNA_QUANTITATIVE_REAL_TIME_PCR: "HCV RNA QUANTITATIVE REAL TIME PCR",
  HBV_DNA_QUALITATIVE_PCR: "HBV DNA QUALITATIVE PCR",
  HBV_DNA_QUANTITATIVE_REAL_TIME_PCR: "HBV DNA QUANTITATIVE REAL TIME PCR",
  HEPATITIS_C_VIRUS_HCV_GENOTYPE: "HEPATITIS C VIRUS (HCV) GENOTYPE",
  HERPES_SIMPLEX_VIRUS_1_2_PCR_QUANTITATIVE:
    "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUANTITATIVE",
  HERPES_SIMPLEX_VIRUS_1_2_PCR_QUALITATIVE:
    "HERPES SIMPLEX VIRUS 1 & 2 PCR- QUALITATIVE",
  HIV_1_RNA_QUALITATIVE_REAL_TIME_PCR: "HIV 1 RNA QUALITATIVE REAL TIME PCR",
  HIV_1_VIRAL_LOAD_QUANTITATIVE_PCR: "HIV-1 VIRAL LOAD QUANTITATIVE -PCR",
  HIV_2_RNA_QUANTITATIVE_REAL_TIME_PCR: "HIV-2 RNA QUANTITATIVE REAL TIME PCR",
  HLA_B27_BY_RT_PCR_QUALITATIVE: "HLA B27 BY RT PCR (QUALITATIVE)",
  MYCOBACTERIUM_TUBERCULOSIS_PCR_TB_PCR:
    "MYCOBACTERIUM TUBERCULOSIS PCR (TB-PCR)",
  RESPIRATORY_PANEL_BY_REAL_TIME_PCR: "RESPIRATORY PANEL - BY REAL TIME PCR",
  RUBELLA_PCR_QUALITATIVE: "RUBELLA PCR QUALITATIVE",
  SPERM_DNA_FRAGMENTATION: "SPERM DNA FRAGMENTATION",
  VIRAL_MENINGITIS_PANEL_BY_MULTIPLEX_REAL_TIME_PCR:
    "VIRAL MENINGITIS PANEL - BY MULTIPLEX REAL TIME PCR",
  HUMAN_PAPILLOMA_VIRUS_HPV_QUALITATIVE:
    "HUMAN PAPILLOMA VIRUS (HPV) QUALITATIVE",
  JC_VIRUS_DNA_DETECTION: "JC VIRUS DNA DETECTION",
  MUMPS_VIRUS_QUALITATIVE: "MUMPS VIRUS QUALITATIVE",
  HIV_1_DNA_Qualitative_Proviral_DNA: "HIV-1 DNA, Qualitative (Proviral DNA)",
  WEST_NILE_VIRUS_PCR: "WEST NILE VIRUS PCR",
  JAK2_Gene_Exon_14_V617F_Mutation_Detection:
    "JAK2 GENE (Exon 14) V617F MUTATION DETECTION",
  Japanese_Encephalitis_Virus_JEV_Qualitative_PCR:
    "JAPANESE ENCEPHALITIS VIRUS (JEV) QUALITATIVE PCR",
  TROPICAL_FEVER_PANEL: 'TROPICAL FEVER PANEL',
  SEPSIS_PANEL_WITH_AMR_GENES_BY_MULTIPLEX_PCR: 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR' || 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR',

}
export const biochemistryReportTypeMap = {
  SERUM_PROTEIN_ELECTROPHORESIS_SPE: 'SERUM PROTEIN ELECTROPHORESIS - SPE',
  IMMUNO_FIXATION_ELECTROPHORESIS_IFE_SERUM: 'IMMUNO FIXATION ELECTROPHORESIS - IFE (SERUM)'
}
export const biochemistryReportType = [
  {
    label: 'IMMUNO FIXATION ELECTROPHORESIS - IFE (SERUM)',
    value: 'IMMUNO_FIXATION_ELECTROPHORESIS_IFE_SERUM'
  },
  {
    label: 'SERUM PROTEIN ELECTROPHORESIS - SPE',
    value: 'SERUM_PROTEIN_ELECTROPHORESIS_SPE'
  }
]
export const biochemistryTypes = [
  "IMMUNO FIXATION ELECTROPHORESIS - IFE (SERUM)",
  "SERUM PROTEIN ELECTROPHORESIS - SPE"
]
export const resultFileCategoryMap = {
  DOUBLE_MARKERS: "DOUBLE MARKERS",
  URINE_ORGANIC_ACID: "URINE ORGANIC ACID",
  TRIPLE_MARKERS: "TRIPLE MARKERS",
  QUADRUPLE_MARKERS: "QUADRUPLE MARKERS",
  BIO: "BIO",
  PENTA_MARKERS: "PENTA MARKERS",
  TMS: "TMS",
  EARLY_QUADRUPLE_MARKERS: "EARLY QUADRUPLE MARKERS",
  INTEGRATED_QUADRUPLE_MARKERS: "INTEGRATED QUADRUPLE MARKERS",
  GUANIDINO_ACETATE: "PLASMA CREATINE AND GUINIDINO ACETIC ACID",
  URINE_GUANIDINO_ACETATE: "URINE CREATINE & GUANIDINO ACETIC ACID LEVELS",
}

export const reportTypesOld = [
  "ULTRASOUND WHOLE ABDOMEN & PELVIS",
  "MRI LUMBAR SPINE WITH SCREENING OF WHOLE SPINE",
  "MRI BRAIN ANGIOGRAM",
  "X-RAY CHEST PA VIEW",
]
export const reportCategory = [
  {
    label: "Radiology",
    value: "RADIOLOGY",
  },
]
export const statusTypes = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Archived",
    value: "ARCHIVED",
  },
]
export const reportStatus = [
  // {
  //   label: "All",
  //   value: "",
  // },
  {
    label: "Approved",
    value: "APPROVED",
  },
  // {
  //   label: "Completed",
  //   value: "COMPLETED",
  // },
  {
    label: "Draft",
    value: "DRAFT",
  },
  // {
  //   label: "Initiate",
  //   value: "INITIATE",
  // },
  {
    label: "Pending",
    value: "PENDING",
  },
  {
    label: "FA Pending",
    value: "FA PENDING",
  },
  // {
  //   label: "Verified",
  //   value: "VERIFIED",
  // },
]
export const departments = [
  {
    label: "All",
    value: "ALLDEPARTMENTS",
  },
  {
    label: "Biochemistry",
    value: "BIO_CHEMISTRY",
  },
  {
    label: "Biochemical Genetics",
    value: "BIOCHEMICAL_GENETICS",
  },
  {
    label: "Flow Cytometry",
    value: "FLOW_CYTOMETRY",
  },
  {
    label: "Genomics",
    value: "GENOMICS",
  },
  { label: "Histopathology", value: "HISTO_PATHOLOGY" },

  {
    label: "Molecular Genetics",
    value: "MOLECULAR_GENETICS",
  },
  {
    label: "Pharmacogenomics",
    value: "PHARMACO_GENOMICS",
  },
  {
    label: "Radiology",
    value: "RADIOLOGY",
  },
  {
    label: "Serology / Immunology",
    value: "SEROLOGY_IMMUNOLOGY",
  },
  {
    label: "Transplant Immunogenetics",
    value: "TRANSPLANT_IMMUNOGENETICS",
  },
  {
    label : "CYTOGENETICS",
    value : "CYTOGENETICS",
  },
  {
    label : "CORPORATE",
    value : "CORPORATE",
  }, 
  {
    label : "Cytopathology",
    value : "CYTOPATHOLOGY",
  },
]

export const reportCategories = [
  { label: "BIOCHEMISTRY", value: "BIOCHEMISTRY" },
  { label: "HAEMATOLOGY", value: "HAEMATOLOGY" },
  { label: "SEROLOGY", value: "SEROLOGY" },
]
export const bioChemistryDepartments = [
  "BIOCHEMISTRY",
  "HAEMATOLOGY",
  "SEROLOGY",
]

export const IHCHistopathology = [
  {
    ANTIBODIESNAME : "CD10",
    Clone : "56C6"
  },
  {
    ANTIBODIESNAME: "CD15",
    Clone: "MMA"
  },
  {
    ANTIBODIESNAME: "CD20",
    Clone: "L26"
  },
  {
    ANTIBODIESNAME: "CD23",
    Clone: "1B12"
  },
  {
   ANTIBODIESNAME: "CD30",
   Clone: "Ber-H2"
  },
  {
   ANTIBODIESNAME: "CD31",
   Clone: "JC/70A"
  },
  {
    ANTIBODIESNAME: "CD117",
    Clone: "Poly"
  },
  {
   ANTIBODIESNAME: "CD45 LCA ",
   Clone: "2B11+PD/26"
  },
  {
    ANTIBODIESNAME: "CD56",
    Clone: "123C3.D5"
  },
  {
    ANTIBODIESNAME: "CD79a B-Cell ",
    Clone: "HM47/A9 "
  },
  {
    ANTIBODIESNAME: "CD99",
    Clone: "Ho36-1.1 "
  },
  {
    ANTIBODIESNAME: "CDX2",
    Clone: "EP25 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "Chromogranin",
    Clone: "LK2H10 Pk/6ml"
  },
  {
   ANTIBODIESNAME: "Cyclin D1",
   Clone: "EP12 Pk/6ml"
  },
  {
   ANTIBODIESNAME: "BcI6",
   Clone: "LN22 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "Cytokeratin 20",
    Clone: "Ks20.8 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "Cytokeratin 5",
    Clone: "XM26 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "Cytokeratin 7,OV-TL 12/30",
    Clone: "OV-TL 12/30"
  },
  {
    ANTIBODIESNAME: "PCk",
    Clone: "PCk"
  },
  {
    ANTIBODIESNAME: "EMA",
    Clone: "E29 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "Napsin A",
    Clone: "KCG1.1 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "AMACR",
    Clone: "13H4 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "P53",
    Clone: "Do-7 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "P63",
    Clone: "DBR16.1 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "Synaptophysin",
    Clone: "SyPo2 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "TTF1",
    Clone: "orm          "
  },
  {
     ANTIBODIESNAME: "Vimentin",
     Clone: "V9 Pk/6ml"
  },
  {
    ANTIBODIESNAME: "WT1",
    Clone: "6F-H2 "
  },
  {
    ANTIBODIESNAME: "SMA",
    Clone: "1A4 "
  },
  {
    ANTIBODIESNAME: "Alk",
    Clone: "4A4 "
  },
  {
    ANTIBODIESNAME: "Androgen Receptor",
    Clone: "AR141"
  },
  {
    ANTIBODIESNAME: "IDH1",
    Clone: "R132H"
  },
  {
    ANTIBODIESNAME : "Calcitonin",
    Clone: "SP17"
  },
  {
    ANTIBODIESNAME: "Caldesmon",
    Clone: "Hhcd" 
  },
  {
    ANTIBODIESNAME: "Calponin",
    Clone: "CALP"
  },
  {
    ANTIBODIESNAME: "Calretinin",
    Clone: "H-5 "
  },
  {
    ANTIBODIESNAME: "CD138",
    Clone: "EP201"
  },
  {
    ANTIBODIESNAME: "CD34",
    Clone: "Qbend/10 "
  },
  {
    ANTIBODIESNAME: "CD4",
    Clone: "EP204"
  },
  {
    ANTIBODIESNAME: "CK19",
    Clone: "A53-B/A2.26 "
  },
  {
    ANTIBODIESNAME: "Desmi",
    Clone: "D33 "
  },
  {
    ANTIBODIESNAME: "DOG1",
    Clone: "DOG1.1 "
  },
  {
    ANTIBODIESNAME: "EBV",
    Clone: "CS1,CS2,CS3.CS4"
  },
  {
    ANTIBODIESNAME: "E-Cadherin",
    Clone: "SPM471 "
  },
  {
    ANTIBODIESNAME: "GATA-3",
    Clone: "L50-823 "
  },
  {
    ANTIBODIESNAME: "GFAP",
    Clone: "GA5 "
  },
  {
    ANTIBODIESNAME: "Glypican-3 ",
    Clone: "GYP7 "
  },
  {
    ANTIBODIESNAME: "INSM1",
    Clone: "A-8 "
  },
  {
    ANTIBODIESNAME: "MART-1",
    Clone: "A103 "
  },
  {
    ANTIBODIESNAME: "HMB45",
    Clone: "HMB45"
  },
  {
    ANTIBODIESNAME: "Mesothelin",
    Clone: "YP158 "
  },
  {
    ANTIBODIESNAME: "MUM1 Protein",
    Clone: "MUM1P "
  },
  {
    ANTIBODIESNAME: "MPO",
    Clone: "59A5 "
  },
  {
    ANTIBODIESNAME: "Myogenin",
    Clone: "F5D "
  },
  {
    ANTIBODIESNAME: "NKX3.1",
    Clone: 361
  },
  {
    ANTIBODIESNAME: "OCT3/4",
    Clone: "OCT4/6847R"
  },
  {
    ANTIBODIESNAME: "Pax-8",
    Clone: "4HB3"
  },
  {
    ANTIBODIESNAME: "PSMA",
    Clone: "3E6. "
  },
  {
    ANTIBODIESNAME: "RCC",
    Clone: "PN-15"
  },
  {
    ANTIBODIESNAME: "S100",
    Clone: "4C4.9 "
  },
  {
    ANTIBODIESNAME: "Sall4",
    Clone: 6000
  },
  {
    ANTIBODIESNAME: "SOX-10",
    Clone: "EP268 "
  },
  {
    ANTIBODIESNAME: "TDT",
    Clone: "SEN28"
  },
  {
    ANTIBODIESNAME: "STAT6",
    Clone: "D-1"
  },
  {
    ANTIBODIESNAME: "CD31",
    Clone: "D-1 "
  },
  {
    ANTIBODIESNAME: " BCl-2",
    Clone: "2R130"
  },
  {
    ANTIBODIESNAME: "CD19",
    Clone: "ZM 179"
  },
  {
    ANTIBODIESNAME: "MLH1 ",
    Clone: "2R347"
  },
  {
    ANTIBODIESNAME: "MSH2",
    Clone: " 2R250"
  },
  {
    ANTIBODIESNAME: "MSH6 ",
    Clone: " 2R342"
  },
  {
    ANTIBODIESNAME: "P16",
    Clone: "2R407"
  },
  {
    ANTIBODIESNAME: "PMS2",
    Clone: "ZR317"
  },
  {
    ANTIBODIESNAME: "Synaptophysin",
    Clone: "2M208"
  },
  {
    ANTIBODIESNAME: "Ki67",
    Clone: "IHC067"
  },
  {
    ANTIBODIESNAME: "CD 3",
    Clone: "LN10"
  },
  {
    ANTIBODIESNAME: "Melan A",
    Clone: "HMB45"
  },
  {
    ANTIBODIESNAME: "BcI6",
    Clone: "LN22 "
  },
  {
    ANTIBODIESNAME: "ER",
    Clone: "IHC423"
  },
  {
    ANTIBODIESNAME: "PR",
    Clone: "IHC751"
  },
  {
    ANTIBODIESNAME: "Her2neu",
    Clone: "EP3"
  },
  {
    ANTIBODIESNAME: "Pancytokeratin",
    Clone: "AE1+AE3"
  },
]
export const categories = ["BIOCHEMISTRY", "HAEMATOLOGY", "SEROLOGY"]
export const departmentCategory = [
  { department: "BIOCHEMISTRY", category: "BIOCHEMISTRY" },
  { department: "HAEMATOLOGY", category: "HAEMATOLOGY" },
  { department: "SEROLOGY", category: "SEROLOGY" },
]

export const subCategories = [
  {
    category: "HAEMATOLOGY",
    subcategory: [
      "CBP(COMPLETE BLOOD PICTURE)",
      "ESR (ERYTHROCYTE SEDIMENTATION RATE)",
    ],
  },
  {
    category: "BIOCHEMISTRY",
    subcategory: [
      "URIC ACID",
      "THYROID PROFILE (T3,T4,TSH)",
      "SERUM CREATININE",
      "LIVER FUNCTION TEST(LFT)",
      "LIPID PROFILE",
      "IRON PROFILE -I",
      "HBA1C",
      "ELECTROLYTES SERUM",
      "CALCIUM - TOTAL",
      "25 HYDROXY VITAMIN D",
    ],
  },
  {
    category: "SEROLOGY",
    subcategory: ["RHEUMATOID FACTOR ( RA)", "C-REACTIVE PROTEIN (CRP)"],
  },
]
export const acknowledgmentTypeMap = {
  ACKNOWLEDGED: "Acknowledged",
  COMPLETED: "Completed",
  CRITICAL_ALERT: "Critial Alert",
  DELAY_IN_REPORT: "Delay In Report",
  HOLD: "Hold",
  PROVISIONAL_REPORT: "Provisional Report",
  REJECTED: "Rejected",
  REPEAT: "Repeat",
}
export const karyotypeAndInterpretetion=[
  {
    Karyotype:'46,X,inv(Y)(p11q13)',
    Interpretation:'Presence of a pericentric inversion in chromosome Y, involving regions p11 & q13. Considered a polymorphic normal variant. Origin and clinical significance may be confirmed by parental karyotyping.'
 },
 {
    Karyotype:'46,X?',
    Interpretation:'Chromosome analysis of cultured amniocytes revealed normal chromosome complement.'
 },
 {
    Karyotype:'47,XY,+21',
    Interpretation:'Presence of an additional copy of chromosome 21, suggestive of trisomy 21. Indicates male child with Down syndrome. Correlate cytogenetic findings with clinical features.'
 },
 {
    Karyotype:'46,XY,inv(9)(p11q13)',
    Interpretation:'Presence of a pericentric inversion in chromosome 9. Generally considered polymorphic, but considering the prenatal diagnosis of monosomy 9p24.3q31.3, it may increase the risk of unbalanced progenies. Associated with congenital heart defects.'
 },
 {
    Karyotype:'46,XX,22pstk+',
    Interpretation:'Increase in length of the stalk on the short arm of one of the chromosomes 22. Considered a polymorphic variant. Role in sub-fertility debatable, but some findings suggest a probable role in pregnancy outcome.'
 },
 {
    Karyotype:'46,XX',
    Interpretation:'No evidence of structural or numerical abnormality. Suggestive of a normal female chromosome complement. Possibility of submicroscopic or cryptic rearrangements cannot be ruled out.'
 },
 {
    Karyotype:'46,XY',
    Interpretation:'No evidence of structural or numerical abnormality. Suggestive of a normal male chromosome complement. Possibility of submicroscopic or cryptic rearrangements cannot be ruled out.'
 },
 {
    Karyotype:'47,+21',
    Interpretation:'Presence of an additional copy of chromosome 21, indicative of Down syndrome. Correlate with USG findings.'
 },
 {
    Karyotype:'46,XY,DSD',
    Interpretation:'Discordance between chromosomal, gonadal, and phenotypic sex. Case of Disorder of Sexual Development (DSD). No evidence of structural or numerical abnormality.'
 },
 {
    Karyotype:'46,Yqh-',
    Interpretation:'Presence of a structural variation on one of the sex chromosomes. Considered a polymorphic variant. Parental karyotyping recommended to establish origin and clinical significance. Impact on fertility debatable.'
 },
 {
    Karyotype:'46,9qh+',
    Interpretation:'Increase in length of the heterochromatic region of one of the chromosomes 9. Considered a polymorphic variant. Origin and clinical significance may be confirmed by parental karyotyping.'
 },
 {
    Karyotype:'46,X,i(X)(q10)',
    Interpretation:'Presence of an isochromosome of the long arm of one of the chromosome X. Unbalanced structural abnormality with simultaneous duplication and deletion. Manifestation includes streak gonads, ovarian failure, short stature, Turner syndrome stigmata.	'
 },
 {
    Karyotype:'46,XY,1qh+',
    Interpretation:'Increase in length of the heterochromatic region of one of the chromosomes 1. Considered a polymorphic variant. Role in sub-fertility debatable, but some findings suggest a probable role in pregnancy outcome.'
 },
 {
    Karyotype:'45,X',
    Interpretation:'	Presence of a single copy of chromosome X, suggestive of monosomy X. Turner syndrome. Phenotype-genotype association may be attempted by correlating cytogenetic findings with USG findings.'
 },
]
export const nosileBoneOptions = ["Absent", "Present"]

export const statusOptions = ["Yes", "No"]
