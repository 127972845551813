<template>
  <section id="upload-page" v-loading="loading">
    <div class="upload-form">
      <h4 class="title">Upload File</h4>
      <div class="form">
        <div class="form-group">
          <label for="">Report Type <span class="star">*</span></label>
          <el-select
            placeholder="Select Report Type"
            v-model="test_category"
            filterable
          >
            <el-option
              v-for="report in reportTypes"
              :key="report"
              :label="report.label"
              :value="report.value"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="form-group"
          v-if="
            test_category == 'TMS' ||
            test_category == 'URINE_ORGANIC_ACID' ||
            test_category == 'BIO' ||
            test_category == 'GUANIDINO_ACETATE' ||
            test_category == 'URINE_GUANIDINO_ACETATE'
          "
        >
          <label for="">Upload File <span class="star">*</span></label>
          <el-upload
            class="upload-demo report-title"
            drag
            :limit="1"
            action
            :on-change="handleUpload"
            :on-remove="handleRemove"
            :auto-upload="false"
            :multiple="false"
            accept="application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv"
          >
            <el-icon><Upload /></el-icon>
            <div class="el-upload__text">
              <h4>Drag & drop</h4>
              <p>
                your file here, or
                <span>browse</span>
              </p>
            </div>
          </el-upload>
          <FormError errorName="critical_error"></FormError>
        </div>
        <div
          class="form-group"
          v-if="
            test_category == 'DOUBLE_MARKERS' ||
            test_category == 'TRIPLE_MARKERS' ||
            test_category == 'QUADRUPLE_MARKERS' ||
            test_category == 'PENTA_MARKERS' ||
            test_category == 'EARLY_QUADRUPLE_MARKERS' ||
            test_category == 'INTEGRATED_QUADRUPLE_MARKERS' ||
            test_category == 'KARYOTYPING' ||
            test_category == 'SOLIDFISH' ||
            test_category == 'FISH'

          "
        >
          <label for="">Upload File <span class="star">*</span></label>
          <el-upload
            class="upload-demo report-title"
            drag
            :limit="1"
            action
            :on-change="handleUpload"
            :on-remove="handleRemove"
            :auto-upload="false"
            :multiple="false"
            accept=".txt"
          >
            <el-icon><Upload /></el-icon>
            <div class="el-upload__text">
              <h4>Drag & drop</h4>
              <p>
                your file here, or
                <span>browse</span>
              </p>
            </div>
          </el-upload>
          <FormError errorName="critical_error"></FormError>
        </div>
      </div>
      <div class="submit-button" align="middle">
        <el-button @click="goToResultFiles">Cancel </el-button>
        <el-button type="primary" @click="uploadResultFile">Submit </el-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";

export default {
  data() {
    return {
      reportTypes: [
        { label: "TMS Report", value: "TMS" },
        { label: "Double Markers", value: "DOUBLE_MARKERS" },
        { label: "Urine Organic Acid", value: "URINE_ORGANIC_ACID" },
        { label: "Triple Markers", value: "TRIPLE_MARKERS" },
        { label: "Quadrable Markers", value: "QUADRUPLE_MARKERS" },
        { label: "BIO", value: "BIO" },
        // { label: "PENTA MARKERS", value: "PENTA_MARKERS" },
        { label: "Early Quadraple Markers", value: "EARLY_QUADRUPLE_MARKERS" },
        {
          label: "Integrated Quadraple Markers",
          value: "INTEGRATED_QUADRUPLE_MARKERS",
        },
        {
          label: "Plasma Creatine and Guinidino Acetic Acid",
          value: "GUANIDINO_ACETATE",
        },
        {
          label: "Urine Creatine & Guanidino Acetic Acid Levels",
          value: "URINE_GUANIDINO_ACETATE",
        },
        {
          label: "Karyotyping",
          value: "KARYOTYPING",
        },{
          label: "SolidFish",
          value: "SOLIDFISH",
        },
        {
          label: "fish",
          value: "FISH",
        },


      ],
      formData: {},
      test_category: "TMS",

      loading: false,
    };
  },
  computed: {
    ...mapGetters("resultFiles", ["getFileUploadStatus"]),
  },
  async mounted() {
    await this.$store.dispatch("errors/clear");
  },
  components: {
    FormError,
  },
  methods: {
    handleUpload(file) {
      this.formData = new FormData();
      this.formData.append("result_file", file.raw);
      this.formData.append("test_category", this.test_category);
    },
    handleRemove() {
      this.formData = {};
    },
    async uploadResultFile() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "resultFiles/uploadResultFiles",
          this.formData
        );
        if (this.getFileUploadStatus) {
          this.$notify.success({
            title: "Success",
            message: "Result file uploaded successfully",
          });
          this.goToResultFiles();
        } else if (this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Uploading Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    goToResultFiles() {
      this.$router.push({ name: "ResultFiles" });
    },
  },
};
</script>
<style lang="scss">
#upload-page {
  max-width: 400px;
  margin: 0 auto;
  background: #fff;
  padding: 40px;
  margin-top: 4em !important;
  border-radius: 5px;
  @media (max-width: 500px) {
    padding: 20px;
  }
  .title {
    margin-bottom: 1.5em;
    text-align: center;
    font-size: 1.3em;
    color: #333;
    letter-spacing: -0.4px;
  }
  .form {
    .form-group {
      margin-bottom: 20px;
      label {
        font-size: 14px;
        color: #6a6a6a;
        font-weight: 500;
        span {
          color: red;
        }
      }
      .el-upload {
        width: 100%;
        .el-upload-dragger {
          margin: 0;
          height: 150px;
          width: 100%;
        }
        .el-icon-upload {
          margin-top: 10px;
        }
      }
    }
  }
  .submit-button {
    margin-top: 2em;

    button {
      padding: 10px 40px;
      font-size: 1.1em;
      font-weight: 500;
      .el-button--primary {
        background: #0061ec;
        border-color: #0061ec;
      }
    }
  }
}

// #upload-page {
//   min-height: 100vh;
//   position: relative;
//   display: flex;
//   align-content: center;
//   align-items: center;
//   // min-height: 100vh;
//   background: #f1f5f7;
//   .left-container {
//     height: 100%;
//     background: #346ccb;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .image {
//       img {
//         max-width: 100%;
//         width: 500px;
//       }
//     }
//   }
//   .report-title {
//     padding-top: 2em;
//     padding-bottom: 1em;
//   }
//   .upload-form {
//     min-width: 300px;
//     margin: 0 auto;
//     background: rgba(255, 255, 255, 0.74);
//     border-radius: 10px;
//     padding: 30px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
//     @media (max-width: 550px) {
//       padding: 25px;
//     }
//     .title-block {
//       margin-bottom: 1.25em;
//       text-align: center;
//       h2 {
//         font-size: 1.75em;
//         font-weight: 500;
//         @media (max-width: 550px) {
//           font-size: 1.3em;
//         }
//       }
//       P {
//         margin-top: 0px;
//         color: #6a6a6a;
//         font-size: 0.95em;
//         letter-spacing: 0.3px;
//         @media (max-width: 550px) {
//           font-size: 0.9em;
//         }
//       }
//     }

//     .el-form-item__content {
//       margin-bottom: 0.5em;
//       .el-input__inner {
//         height: 48px;
//         font-weight: 500;
//         font-size: 15px;
//         color: #000;
//       }
//     }
//     .submit-button {
//       button {
//         font-weight: 500;
//         padding: 15px 35px;
//         border: none;
//         text-transform: uppercase;
//         color: #fff;
//         font-size: 1.1em;
//         letter-spacing: 0.3px;
//         width: 100%;
//         margin-top: 1em;
//         position: relative;
//         .el-icon-right {
//           font-weight: 800;
//           font-size: 20px;
//           position: absolute;
//           right: 20px;
//           top: 50%;
//           transform: translateY(-50%);
//         }
//       }
//     }
//   }
//   .logo {
//     position: absolute;
//     top: 30px;
//     left: 20px;
//     right: 20px;
//     margin-bottom: 2em;
//     img {
//       margin: 0 auto;
//       display: table;
//       max-width: 150px;
//     }
//   }
// }
</style>
