<template>
  <section id="all-patient-reports">
    <div class="page-navigation">
      <el-row>
        <el-col :span="12">
          <ul class="filter-navigation">
            <li>
              <div class="search-filter">
                <el-input
                  placeholder="Search Title"
                  v-model="searchString"
                  @input="onSearch"
                  clearable
                >
                  <i class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
            </li>
            <li>
              <div class="category-filter">
                <el-select
                  placeholder="Select Department"
                  v-model="searchDepartment"
                  @change="fetchData"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="department in getDepartmetns"
                    :key="department"
                    :label="department.label"
                    :value="department.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </li>
            <li v-if="searchDepartment == 'RADIOLOGY'">
              <div class="category-filter">
                <el-select
                  placeholder="Select Category"
                  v-model="searchCategory"
                  @change="loadData(1)"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="category in reportCategory"
                    :key="category"
                    :label="category"
                    :value="category"
                  >
                  </el-option>
                </el-select>
              </div>
            </li>
            <!-- <li>
              <div class="category-filter">
                <el-select
                  placeholder="Select Status"
                  v-model="status"
                  @change="loadData(1)"
                >
                  <el-option
                    v-for="status in statusList"
                    :key="status"
                    :label="status.label"
                    :value="status.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </li> -->
          </ul>
        </el-col>
        <el-col :span="12">
          <ul class="action-navigation">
            <li v-if="isAbleToAccess('ADD_PATIENT_REPORT')">
              <el-button @click="goToAddForm" class="default-button add-button"
                >Add Template
              </el-button>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div v-loading="loading" :element-loading-text="loadingText">
      <div class="vue-table">
        <el-table
          :data="reportTemplates"
          style="width: 100%"
          height="calc(100vh - 250px)"
          class="table-list display-web"
          @sort-change="changeSort"
        >
          <el-table-column
            prop="created_at"
            label="Created Date"
            width="180"
            sortable="custom"
            fixed
          >
            <template #default="scope">
              <span class="date" v-if="scope.row.created_at">
                {{ date(scope.row.created_at) }}
                {{ time(scope.row.created_at) }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="updated_at"
            label="Updated Date"
            width="180"
            sortable="custom"
            fixed
          >
            <template #default="scope">
              <span class="date" v-if="scope.row.updated_at">
                {{ date(scope.row.updated_at) }}
                {{ time(scope.row.updated_at) }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="template_title"
            label="Title"
            min-width="200"
            fixed
            sortable="custom"
          >
            <template #default="scope">
              <span class="doctor-name">
                {{ scope.row.template_title ? scope.row.template_title : "--" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            prop="department"
            label="Department"
            min-width="250"
            sortable="custom"
          >
            <template #default="scope">
              {{ scope.row.department || "--" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="category"
            label="Category"
            min-width="200"
            sortable="custom"
          >
            <template #default="scope">
              {{ scope.row.sub_category }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="status"
            label="Status"
            min-width="120"
            sortable="custom"
          >
            <template #default="{ row }">
              <div class="column-status">
                <el-tag
                  :type="row.status == 'ARCHIVED' ? 'danger' : 'success'"
                  >{{ row.status }}</el-tag
                >
              </div>
            </template>
          </el-table-column> -->
          <el-table-column
            label="Actions"
            fixed="right"
            :width="getActionsWidth"
          >
            <template #default="{ row }">
              <ul class="table-action-buttons">
                <li>
                  <el-button class="view-icon" @click="previewReport(row)">
                    <img
                      src="@/assets/images/icons/eye.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    row.status != 'ARCHIVED' && isAbleToAccess('EDIT_TEMPLATE')
                  "
                >
                  <el-button class="edit-icon" @click="editReport(row)">
                    <img
                      src="@/assets/images/icons/edit.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </el-button>
                </li>
                <li
                  v-if="
                    row.status != 'ARCHIVED' &&
                    isAbleToAccess('DELETE_TEMPLATE')
                  "
                >
                  <el-button class="edit-icon" @click="archiveReport(row)">
                    <el-icon>
                      <delete style="color: red" />
                    </el-icon>
                  </el-button>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div
        class="vue-pagination"
        v-if="reportTemplates && reportTemplates.length"
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="loadData"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="templateTitleDialogVisible" class="template-title-dialog">
      <el-dialog
        width="30%"
        v-model="templateTitleDialogVisible"
        title="Template Title"
        :before-close="handleClose"
        class="template-title-dialog"
      >
        <el-input
          placeholder="Please add a titile for Template "
          v-model="templateTitle"
        ></el-input>

        <template #footer>
          <span class="dialog-footer">
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="handleClose"
              >Cancel</el-button
            >
            <el-button
              size="medium"
              type="primary"
              @click="moveTemplateToLive"
              :loading="loading"
              :disabled="!templateTitle"
              >Submit</el-button
            >
          </span>
        </template>
        <p v-if="getError" class="template-error">
          {{ getError }}
        </p>

        <FormError errorName="template_title"></FormError>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { radiologyReportTypes, statusTypes } from "@/constants/formConstants";
import { ElMessageBox } from "element-plus";

import userRolesPermissionsMixin from "@/mixins/userRolesPermissionsMixin";
export default {
  middleware: "auth",
  mixins: [userRolesPermissionsMixin],
  computed: {
    ...mapGetters("reportTemplates", [
      "getAllReportTemplates",
      "getSingleReportTemplate",
      "getReportTemplateDeleteStatus",
      "getTemplateMoveStatus",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    getDepartmetns() {
      if (this.getProfileDetails && this.getProfileDetails.departments) {
        let userDepartments = this.getProfileDetails.departments;
        return this.departmentTypes.filter((obj) =>
          userDepartments.includes(obj.value)
        );
      }
      return this.departmentTypes;
    },
    getActionsWidth() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        this.widthByRole[this.getAuthenticatedUser.user_type]
      ) {
        return this.widthByRole[this.getAuthenticatedUser.user_type];
      }
      return 150;
    },
  },
  data() {
    return {
      reportCategory: radiologyReportTypes,
      statusList: statusTypes,
      dialogVisible: false,
      departmentTypes: [
        {
          label: "Radiology",
          value: "RADIOLOGY",
        },
        { label: "Histopathology", value: "HISTO_PATHOLOGY" },
        {
          label: "Biochemical Genetics",
          value: "BIOCHEMICAL_GENETICS",
        },
        {
          label: "Genomics",
          value: "GENOMICS",
        },
        {
          label: "Molecular Genetics",
          value: "MOLECULAR_GENETICS",
        },
        {
          label: "Flow Cytometry",
          value: "FLOW_CYTOMETRY",
        },
        {
          label: "Transplant Immunogenetics",
          value: "TRANSPLANT_IMMUNOGENETICS",
        },
        {
          label: "Serology / Immunology",
          value: "SEROLOGY_IMMUNOLOGY",
        },
        {
          label: "Pharmacogenomics",
          value: "PHARMACO_GENOMICS",
        },
        {
          label : "CYTOGENETICS",
          value : "CYTOGENETICS",
        },
        {
          label : "CORPORATE",
          value : "CORPORATE",
        },
        {
          label: "CYTOPATHOLOGY",
          value: "CYTOPATHOLOGY",
        }

      ],
      headerFooter: "",
      reportTemplates: [],
      page: 1,
      pageSize: 10,
      total: 0,
      orderBy: "",
      orderType: "",
      sortCodeMap: {
        ascending: "asc",
        descending: "desc",
      },
      searchString: "",
      tagByStatus: {
        APPROVED: "success",
        VERIFIED: "warning",
        INITIATE: "primary",
        DRAFT: "info",
        PENDING: "warning",
      },

      loading: false,
      loadingText: "",
      fromDate: "",
      toDate: "",
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      searchCategory: "",
      status: "ACTIVE",
      searchDepartment: "",
      widthByRole: {
        LAB_PHYSICIAN: 50,
        LAB_ASSISTANT: 50,
        DATA_OPERATOR: 50,
        LAB_DIRECTOR: 50,
        RADIOLOGIST: 50,
        DOCTOR: 50,
        ADMIN: 150,
      },
      templateId: "",
      templateTitleDialogVisible: false,
      templateTitle: "",
    };
  },
  async mounted() {
    await this.setQueryToData();
    await this.fetchAllReports();

    await this.$store.dispatch("errors/clear");
  },
  methods: {
    date(value) {
      if (!value) return;
      return moment(value).format("DD-MM-yyyy");
    },
    time(value) {
      if (!value) return;
      return moment(value).format("hh:mm a");
    },
    async fetchDateFilterData() {
      if (this.fromDate && this.toDate) {
        await this.loadData(1);
      } else if (!this.fromDate && !this.toDate) {
        this.loadData(1);
      }
    },
    goToAddForm() {
      this.$router.push({ name: "AddTemplate" });
    },

    converToUpperCase(str) {
      if (!str) return;
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
    async fetchAllReports() {
      try {
        this.loading = true;
        this.loadingText = "Fetching Report Templates...";
        let params = this.prepareParams();

        await this.$store.dispatch(
          "reportTemplates/fetchAllReportTemplates",
          params
        );
        this.setTableData();
        this.setQueryData(params);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async onSearch() {
      setTimeout(async () => {
        this.page = 1;
        await this.fetchAllReports();
      }, 600);
    },

    setTableData() {
      const tableData = JSON.parse(JSON.stringify(this.getAllReportTemplates));
      this.reportTemplates =
        tableData && tableData.data && tableData.data.length
          ? tableData.data
          : [];
      this.page = tableData.page || 1;
      this.total = tableData.total || 0;
      this.pageSize = tableData.limit || 10;
    },
    prepareParams() {
      let params = { page: this.page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.searchString) {
        params.search_string = this.searchString;
      }
      if (this.searchDepartment) {
        params.category = this.searchDepartment;
      }
      if (this.searchCategory) {
        params.sub_category = this.searchCategory;
      }
      if (this.status) {
        params.status = this.status;
      }

      return params;
    },

    setQueryData(data) {
      let params = {
        selectedPage: data.page || 1,
        selectedPageSize: data.limit || 10,
        selectedString: data.search_string || undefined,
        selectedTemplateFromDate: this.fromDate || undefined,
        selectedTEmplateToDate: this.toDate || undefined,
        selectedCategory: data.sub_category || undefined,
        selectedStatus: data.status || undefined,
        selectedDepartment: data.category || undefined,
      };

      this.$router
        .replace({
          name: this.$route.name,
          query: { ...params },
        })
        .catch(() => {});
    },
    fetchData() {
      this.searchCategory = "";
      this.page = 1;
      this.fetchAllReports();
    },
    setQueryToData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPage
          ? parseInt(this.$route.query.selectedPage)
          : 1;
        this.pageSize = this.$route.query.selectedPageSize
          ? parseInt(this.$route.query.selectedPageSize)
          : 10;
        this.searchString = this.$route.query.selectedString || "";
        this.fromDate = this.$route.query.selectedTemplateFromDate || "";
        this.toDate = this.$route.query.selectedTEmplateToDate || "";
        this.searchCategory = this.$route.query.selectedCategory || "";
        this.status = this.$route.query.selectedStatus || "ACTIVE";
        this.searchDepartment = this.$route.query.selectedDepartment || "";
      }
    },
    changeSort({ prop, order }) {
      this.orderBy = prop;
      this.orderType = this.sortCodeMap[order];
      this.page = 1;
      this.fetchAllReports();
    },
    async handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      await this.fetchAllReports();
    },
    async loadData(page) {
      this.page = page;

      await this.fetchAllReports();
    },

    async previewReport(patientInfo) {
      await this.fetchSinglePatientInfo(patientInfo._id);
      try {
        this.loading = true;
        this.loadingText = "Loading..";

        let params = { ...patientInfo };
        if (this.getSingleReportTemplate) {
          params.test_details = this.getSingleReportTemplate.template_body;
        }
        await this.$store.dispatch("patientReports/addPatientData", params);

        // let routerData =
        this.$router.push({
          name: "ReportTemplatePreview",
          query: this.$route.query,
        });

        // window.open(routerData.href, "_blank");

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSinglePatientInfo(templateId) {
      try {
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: templateId }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async editReport(patientInfo) {
      try {
        this.$router.push({
          name: "UpdateTemplate",
          params: {
            template_id: patientInfo._id,
          },
          query: this.$route.query,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async archiveReport(template) {
      await ElMessageBox.confirm(
        "Are you sure to delete the template. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          await this.archive(template);
        })
        .catch(() => {});
    },

    async moveToLive(template) {
      await this.$store.dispatch("errors/clear");

      this.templateId = template._id;
      this.templateTitle = template.template_title;
      this.templateTitleDialogVisible = true;
    },
    async moveTemplateToLive() {
      try {
        this.loading = true;
        let params = {
          template_id: this.templateId,
          template_title: this.templateTitle,
        };
        await this.$store.dispatch(
          "reportTemplates/movingTemplatesToLive",
          params
        );
        if (this.getTemplateMoveStatus) {
          this.$notify.success({
            title: "Success",
            message: "Template Moved To Live Successfully",
          });
          this.templateTitleDialogVisible = false;
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while template moving to live",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    handleClose() {
      this.templateTitleDialogVisible = false;
      this.templateTitle = "";
      this.templateId = "";
    },
    async archive(template) {
      try {
        this.loading = true;
        this.loadingText = "Deleting Template...";
        await this.$store.dispatch(
          "reportTemplates/archiveReportTemplateData",
          template._id
        );
        if (this.getReportTemplateDeleteStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Template Deleted Successfully",
          });
          this.fetchAllReports();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while deleting template",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
#all-patient-reports {
  tbody {
    td {
      &:last-child {
        background: #f5f5f5;
      }
    }
  }
  .view-icon {
    padding-top: 3px;
    padding-bottom: 1px;
  }
}
.template-error {
  color: red;
  text-align: start;
  margin: 0;
  font-size: 14px;
  padding: 5px;
}
</style>
