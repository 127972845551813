<template>
  <updatePassword></updatePassword>
</template>

<script>
import updatePassword from "@/components/UpdatePassword";
export default {
  components: {
    updatePassword,
  },
};
</script>
