import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat";

import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
// import BlockQuotePlugin from "@ckeditor/ckeditor5-block-quote/src/blockquote";

import ListPlugin from "@ckeditor/ckeditor5-list/src/list";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";

import FontPlugin from "@ckeditor/ckeditor5-font/src/font";

import TablePlugin from "@ckeditor/ckeditor5-table/src/table";
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TablePropertiesPlugin from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellPropertiesPluign from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import { ENTER_BR } from "@ckeditor/ckeditor5-vue";

import ImageInsertPlugin from "@ckeditor/ckeditor5-image/src/imageinsert";
// import AutoImagePlugin from "@ckeditor/ckeditor5-image/src/autoimage";

// import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";

// import CKFinderPlugin from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
import SimpleUploadAdapterPlugin from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";

import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaptionPlugin from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import LinkImagePlugin from "@ckeditor/ckeditor5-link/src/linkimage";

import { MyCustomUploadAdapterPlugin } from "@/config/customImageUpload";
import ImageProgressPlugin from "@ckeditor/ckeditor5-image/src/imageupload/imageuploadprogress";

import customSelectColors from "@/constants/colorsConstants";

import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";
// import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office";

export default {
  plugins: [
    EssentialsPlugin,
    BoldPlugin,
    ItalicPlugin,
    LinkPlugin,
    ParagraphPlugin,
    SubscriptPlugin,
    SuperscriptPlugin,
    UnderlinePlugin,
    StrikethroughPlugin,
    AutoformatPlugin,
    ListPlugin,
    HeadingPlugin,
    FontPlugin,
    TablePlugin,
    TableToolbarPlugin,
    TableCellPropertiesPluign,
    TablePropertiesPlugin,
    ImageInsertPlugin,
    // AutoImagePlugin,
    // ImageUploadPlugin,
    // CKFinderPlugin,
    SimpleUploadAdapterPlugin,

    Indent,
    ImagePlugin,
    ImageToolbarPlugin,
    ImageCaptionPlugin,
    ImageStylePlugin,
    ImageResizePlugin,
    LinkImagePlugin,
    MyCustomUploadAdapterPlugin,
    ImageProgressPlugin,
    AlignmentPlugin,
    // BlockQuotePlugin,
    // PasteFromOffice,
  ],
  fontSize: {
    options: [
      9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
    ],
  },
  fontColor: {
    colors: customSelectColors,
  },
  fontBackgroundColor: {
    colors: customSelectColors,
  },
  toolbar: {
    items: [
      "Font",
      "lineheight",
      "heading",
      "|",
      "fontSize",
      "|",
      "indent",
      "|",
      // "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "imageInsert",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "subscript",
      "superscript",
      // "highlight",
      // "removeFormat",
      "|",
      "alignment",
      "|",
      "numberedList",
      "bulletedList",
      // "|",
      // "indent",
      // "outdent",
      "|",
      // "todoList",
      "link",
      // "blockQuote",
      "insertTable",
      // "imageUpload",
      // "mediaEmbed",
      "|",
      "undo",
      "redo",
      // "ckfinder",
      // "uploadImage",
      // "CKFinder",
    ],
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
      ],
    },
    qtBorder: "0",
  },

  indentBlock: {
    offset: 1,
    unit: "em",
  },
  alignment: {
    options: [
      { name: "left", className: "ck-text-align-left" },
      { name: "right", className: "ck-text-align-right" },
      { name: "center", className: "ck-text-align-center" },
      { name: "justify", className: "ck-text-align-justify" },
    ],
  },
  heading: {
    options: [
      { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
      {
        model: "heading4",
        view: "h4",
        title: "Heading",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Sub-Heading",
        class: "ck-heading_heading5",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading10",
        view: "h10",
        title: "Page-Break",
        class: "ck-heading_heading10",
      },
      {
        model: "heading11",
        view: "h20",
        title: "End Of The Report",
        class: "ck-heading_heading11",
      },
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
    tableProperties: {
      borderColors: customSelectColors,
      backgroundColors: customSelectColors,
    },

    // Set the palettes for table cells.
    tableCellProperties: {
      borderColors: customSelectColors,
      backgroundColors: customSelectColors,
      padding: ["5px", "10px"],
    },
  },
  image: {
    toolbar: [
      "imageStyle:block",
      "imageStyle:side",
      "|",
      "toggleImageCaption",
      "imageTextAlternative",
      "|",
      "linkImage",
    ],
  },
  enterMode: ENTER_BR,
};
