<template>
    <div class="SmearForMalignantCells">
        <div class="edit-box mb-20">
            <span class="title">Cytology Type</span>
            <el-input placeholder="Please enter Cytology Type" v-model="Fluid_Cytology"/>
        </div>
        <div class="edit-box mb-20">
            <span class="title">LAB. REF. NO.</span>
            <ckeditor :editor="editor" v-model="Lab_Refno" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">TYPE OF SPECIMEN</span>
            <ckeditor :editor="editor" v-model="Speciment_Type" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">MICROSCOPY</span>
            <ckeditor :editor="editor" v-model="Micoroscopy" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">IMPRESSION</span>
            <ckeditor :editor="editor" v-model="Impression" tag-name="div" ref="myEditor" :config="editorConfig">
            </ckeditor>
        </div>
        <div class="edit-box mb-20">
            <span class="title">NOTE</span>
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" :config="editorConfig" v-model="Note">
            </ckeditor>
        </div>
   </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import EditorConfig from "@/config/editorConfig";


export default{
    components:{
        ckeditor: CKEditor.component,
    },
    props: {
      smearForMalignantCells: {
      type: Array,
      required: true
    }
   },
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            show: false,
            comments: "",
            recommendations: "",
            impression: "",
            resultData: "",
            references: "",
            remarks: "",
            editorConfig: EditorConfig,
            textarea: "",
            Fluid_Cytology:this.smearForMalignantCells?.length ==1?this.smearForMalignantCells[0].Fluid_Cytology:"",
            Lab_Refno:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].Lab_Refno:"",
            Speciment_Type:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].Speciment_Type:"",
            Micoroscopy:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].Micoroscopy:"",
            Impression:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].Impression:"",
            Note:this.smearForMalignantCells?.length==1  ?this.smearForMalignantCells[0].Note:"",
            signature_required:(this.patientInfo && this.patientInfo.is_required_signature) ? this.patientInfo.is_required_signature : false, 
        }        
    },
    methods: {
        handleSmearForMalignantCells() {
        let dd = window.document.getElementsByClassName("ck-content");
        const Lab_Refno = dd[0]?.innerHTML;
        const Speciment_Type = dd[1]?.innerHTML;
        const Micoroscopy = dd[2]?.innerHTML;  
        const Impression = dd[3]?.innerHTML;
        const note = dd[4]?.innerHTML
   const result = [{
           Fluid_Cytology: this.Fluid_Cytology,           
           Lab_Refno:Lab_Refno,
           Speciment_Type: Speciment_Type,
           Micoroscopy: Micoroscopy,  
           Impression: Impression,
           signature_required: this.signature_required,
           Note:note
          }]
        return this.$emit('sendSmearForMalignantCells', result);
    }
  },
}
</script>


<style lang="scss" scoped>
.SmearForMalignantCells {
    padding: 10px 10px;

    .edit-box {
        border: 1px solid #2eaf9f;

        .title {
            color: #ffffff;
            background: #2eaf9f;
            text-align: left;
            border: 1px solid #2eaf9f;
            font-weight: 600;
            display: block;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>