<template>
  <section class="add-patient-report-data" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
    <div class="AddReportTitle">
      <h3>
        {{ `${$route.name == "AddTemplate" ? "Add" : "Update"} Template` }}
      </h3>

      <div class="backNavigation">
        <!-- <el-button @click="goToReportTemplates" class="back-button">
          <el-icon class="el-icon-back"><Back /></el-icon>
          Back</el-button
        > -->
        <el-button @click="goToReportTemplates" class="back-btn">
          <img src="@/assets/images/icons/back-arrow.svg" alt="" class="img-fluid" />
          <span> Back </span>
        </el-button>
      </div>
    </div>
    <div class="addReportFilters">
      <el-row :gutter="10">
        <el-col :span="8">
          <div>
            <el-input v-model="patientInfo.template_title" placeholder="Template Title"></el-input>
            <FormError errorName="template_title"></FormError>
          </div>
        </el-col>
        <el-col :span="8">
          <el-select v-model="patientInfo.department" placeholder="Select Department" :clearable="true"
            @change="patientInfo.sub_category = ''" filterable>
            <el-option v-for="(item, index) in getDepartmetns" :key="index" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
          <FormError errorName="department"></FormError>
        </el-col>
        <el-col :span="8">
          <el-select v-model="patientInfo.sub_category" placeholder="Select Category" :clearable="true"
            @change="onChangeCategory" :disabled="!patientInfo.department" filterable>
            <el-option v-for="(report, index) in getSubCategories" :key="index" :label="report"
              :value="report"></el-option>
          </el-select>
          <FormError errorName="report_type"></FormError>
          <!-- </li> -->
        </el-col>
        <div class="fileUploadBlock mt-20" v-if="this.patientInfo.category == 'CORPORATE'">
          <div class="fileUploadArea" v-if="this.patientInfo.department == 'Department of CORPORATE'">
            <div class="fileUpload section-1">
              <cropper v-if="image.src" ref="cropper" class="cropper" @change="cropImage" :src="image.src" />
              <!-- <img v-if="this.patientInfo.corporate_logo" :src="this.patientInfo.corporate_logo" class="cropper" /> -->
              <div class="mt-20 centeredButton">
                <el-button type="primary" @click="file.click()">
                  <input type="file" ref="file" class="hidden" @change="uploadImage($event)"
                    accept="image/jpeg, image/png," />
                  Upload Company Logo
                </el-button>

              </div>
              <!-- <el-button type="info"  @click="cropImage()">Crop </el-button> -->
            </div>
            <div v-if="croppedimage" class="croppedBlock mt-20">
              <img v-if="croppedimage" :src="croppedimage" class="croppedImage" alt="Cropped Image" />
              <div>
              </div>
            </div>
          </div>
          <p v-if="imageFormateError" class="text">Please upload an image with JPEG, PNG, or JPG format.</p>

          <!-- <div class="fileUploadArea" v-if="this.patientInfo.department == 'Department of CORPORATE'">
      <div class="section-1">
        <cropper v-if="image1.src" ref="cropper1" class="cropper" @change="cropDoctorSignatureImage"  :src="image1.src" />
        <div class="button-wrapper">
            <el-button type="primary"  @click="file1.click()">
            <input
              type="file"
              ref="file1"
              class="hidden"
              @change="uploadDoctorSignatureImage($event)"
              accept="image/jpeg, image/png,"
            />
            Upload Doctor Signature
          </el-button>
          <el-button type="info"  @click="cropDoctorSignatureImage()">Crop </el-button>
        </div>
      </div>
      <div v-if="croppedimage1" class="croppedBlock">
        <img  v-if="croppedimage1" :src="croppedimage1"  class="croppedImage" alt="Cropped Image" />
        <div class="button-wrapper">
          <el-button type="danger" @click="removeDoctorSignatureImage()" v-if="image1.src">Remove</el-button>
        </div>
      </div>
    </div> -->
        </div>

      </el-row>
    </div>
    <div class="addReportPatientDemographics">
      <div class="inner">
        <div class="report-sub-title" v-if="showReportType && patientInfo.report_type">
          <h3>{{ patientInfo.report_type }}</h3>

          <span @click="onEditReportType" class="cursor-pointer">
            <el-icon>
              <Edit />
            </el-icon>
          </span>
        </div>
        <div class="report-type-edit-info" v-else-if="editReportType">
          <el-input v-model="reportTypeNew"></el-input>
          <el-button type="success" @click="updateReportType" :disabled="isReportTypeUpdateDisable">
            <el-icon>
              <Check />
            </el-icon>
          </el-button>
          <el-button type="danger" @click="cancelUpdateReportType">
            <el-icon>
              <Close />
            </el-icon>
          </el-button>
        </div>

        <div class="addReportEditor">
          <div class="editor" v-if="show && checkCKEditorContent()" v-loading="loadingEditor">
            <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="editorData" :config="editorConfig">
            </ckeditor>
          </div>
        </div>
        <div v-if="patientInfo.sub_category == 'KARYOTYPING'">

          <!-- Karyotyping Report Static Code Start -->
          <div class="double-marker">
            <div class="edit-box mb-20 edit-box-table">
              <div class="inside-table">
                <el-form :model="testResultsSummary">
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Sample Type" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeSample_Type"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Quality of Sample" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeQualityOfSample"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Yoda Cytogenetics Number" readonly>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.CytogeneticsNumber"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Clinical Indication" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeClinicalIndication"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row> -->
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Test Requested" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeTestRequested"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Test Methodology" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.karyotypeTestMethodology"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="No of cells counted" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.NoOfCellsCounted"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="No of cells Karyotyped" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.NoOfCellsKaryotyped"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Estimated band resolution" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.EstimatedBandResolution"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="Banding method" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.BandingMethod"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <el-form-item>
                        <el-input placeholder="ISCN" disabled>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item>
                        <el-input v-model="patientInfo.ISCN"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <div class="edit-box mb-20 editor">
              <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="editorData" :config="editorConfig">
              </ckeditor>
            </div>
            <!-- <div class="edit-box mb-20">
              <span class="title">Karyotype:</span>
              <el-input type="textarea" v-model="patientInfo.karyotype" rows="2"></el-input>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Interpretation:</span>
              <el-input type="textarea" v-model="patientInfo.interpretation" rows="3"></el-input>
            </div> -->
            <div class="edit-box mb-20">
              <span class="title">Recommendations:</span>
              <el-input type="textarea" autosize v-model="patientInfo.recommendations" rows="3"></el-input>
            </div>

            <div class="edit-box mb-20">
              <span class="title">References:</span>
              <el-input type="textarea" autosize v-model="patientInfo.References" rows="3" style="font-size:10px;"></el-input>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Disclaimer:</span>
              <el-input type="textarea" autosize v-model="patientInfo.Disclaimer" rows="3" style="font-size:8px;"></el-input>
            </div>
          </div>
        </div>

        <!-- SEPSIS Report Static Code Start -->
        <div
          v-if="patientInfo.sub_category && patientInfo.sub_category == 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'">
          <!-- <sepsis ref="SepsisTestList"></sepsis> -->
          <div>
            <div class="double-marker">
              <!-- Test List Table -->
              <div class="edit-box mb-20 edit-box-table">
                <div class="inside-table">
                  <el-form>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Test Name" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Result" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="ORGANISM" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="GRAM POSITIVE ORGANISMS" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Staphylococcus Aureus" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].staphylococcus_Aureus" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Staphylococcus Epidermidis" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].staphylococcus_Epidermidis" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Streptococcus Pneumoniae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].streptococcus_Pneumoniae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Streptococcus Pyogenes" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].streptococcus_Pyogenes" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Streptococcus Agalactiae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].streptococcus_Agalactiae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Enterococcus Fecalis" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].enterococcus_Fecalis" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Enterococcus Faecium" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].enterococcus_Faecium" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Listeria Monocytogenes" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramPostiveOrganisms[0].listeria_Monocytogenes" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item align="center">
                          <el-input placeholder="GRAM NEGATIVE ORGANISMS" readonly style="font-weight:bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Escherichia Coli" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Escherichia_Coli" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Klebsiella Pneumoniae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Klebsiella_pneumoniae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Klebsiella Oxytoca" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Klebsiella_Oxytoca" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Enterobacter Species" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Enterobacter_species" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Salmonella Spp" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Salmonella_spp" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Acinetobacter Baumannii" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Acinetobacter_baumannii" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Pseudomonas Beruginosa" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Pseudomonas_aeruginosa" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Haemophilus Influenzae (A-F)" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Haemophilus_influenzae" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Neisseria Meningitidis" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="GramNegativeOrganisms[0].Neisseria_meningitidis" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="YEAST" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Candida Auris" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Yeast[0].Candida_auris" placeholder="Select" :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Candida Albicans" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Yeast[0].Candida_albicans" placeholder="Select" :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Candida Lusitaniae" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Yeast[0].Candida_lusitaniae" placeholder="Select" :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item>
                          <el-input placeholder="ANTI-MICROBIAL RESISITANT GENE" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="sul2" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].sul2" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="dfrA1" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].dfrA1" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="dfrA5" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].dfrA5" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla CTX-M gr1" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_CTX_M_gr1" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla TEM" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_TEM" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla SHV" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_SHV" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla KPC" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_KPC" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla NDM" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_NDM" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla VIM" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_VIM" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla OXA-48" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_OXA_48" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="bla IMP" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].bla_IMP" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="mecA" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].mecA" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="vanA" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].vanA" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="vanB" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-select v-model="Anti_microbial_resistance_gene[0].vanB" placeholder="Select"
                            :clearable="true">
                            <el-option v-for="item in dropdownOptions" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
              <!-- Test List Table -->

              <!-- DRUGS INTERPRETATION Table -->
              <div class="edit-box mb-20 edit-box-table">
                <div class="inside-table">
                  <el-form>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="DRUGS INTERPRETATION" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="SENSITIVE / RESISTANT" readonly style="font-weight: bold;">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="COTRIMOXAZOLE (Trimethoprim & Sulfamethoxazole)" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cotrimoxazole">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Pencillin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Pencillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ampicillin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ampicillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Amoxicillin/ClavulanicAcid" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Amoxicillin_clavulanic_acid">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Piperacillin/Tazobactam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Piperacillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefazolin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefazolin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefoxitin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefoxitin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefuroxime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefuroxime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefotaxime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefotaxime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ceftriaxone" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ceftriaxone">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ceftazidime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefatazidime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefepime" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefepime">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Cefoperazone/Sulbactam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Cefoperazone">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ceftazidime/Avibactam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ceftazidime_avibactam">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Gentamicin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Gentamicin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Amikacin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Amikacin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Tigecycline" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Tigecycline">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Polymyxin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Polymyxin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Imipenem" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Imipenem">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Meropenem" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Meropenem">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Ertapenem" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Ertapenem">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Aztreonam" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Aztreonam">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Methicillin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Methicillin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Vancomycin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Vancomycin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="Teicoplanin" readonly>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input placeholder="" v-model="Drugs_Interpretation[0].Teicoplanin">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
              <!-- DRUGS INTERPRETATION Table -->
              <!-- <div class="edit-box mb-20 editor">
              <ckeditor :editor="editor" tag-name="div" ref="myEditor" v-model="editorData"
                :config="editorConfig">
              </ckeditor>
            </div> -->
              <div class="edit-box mb-20">
                <span class="title">Intended Use</span>
                <el-input type="textarea" rows="2" v-model="patientInfo.Intended_Use" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">Test Method</span>
                <el-input type="textarea" v-model="patientInfo.Test_Method" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">Test Limitations/ Attributes</span>
                <el-input type="textarea" v-model="patientInfo.Test_Limitations_Attributes" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">Disclaimer</span>
                <el-input type="textarea" v-model="patientInfo.Disclaimer" autosize></el-input>
              </div>
              <div class="edit-box mb-20">
                <span class="title">References</span>
                <!-- <ol>
                    <li>Tsalik EL, Jones D, Nicholson B, Waring L, Liesenfeld O, Park LP, Glickman SW, Caram LB, Langley RJ,
                        van Velkinburgh JC, Cairns CB, Rivers EP, Otero RM, Kingsmore SF, Lalani T, Fowler VG, Woods CW.
                        Multiplex PCR to diagnose bloodstream infections in patients admitted from the emergency department
                        with sepsis. J Clin Microbiol. 2010 Jan;48(1):26-33.</li>
                    <li>
                        Lucignano B, Ranno S, Liesenfeld O, Pizzorno B, Putignani L, Bernaschi P, Menichella D. Multiplex
                        PCR allows rapid and accurate diagnosis of bloodstream infections in newborns and children with
                        suspected sepsis. J Clin Microbiol. 2011 Jun;49(6):2252-8.
                    </li>
                    <li>
                        Westh, H. et al.Multiplex real-time PCR and blood culture for identification of bloodstream
                        pathogens in patients with suspected sepsis.Clinical Microbiology and Infection,2009 , Volume 15,
                        Issue 6, 544 – 551.
                    </li>
                </ol> -->
                <el-input type="textarea" v-model="patientInfo.References" autosize></el-input>
              </div>
            </div>
          </div>
        </div>
        <!-- SEPSIS Report Static Code Start -->

        <div v-if="shouldRenderElement">
          <div>
            <div>
              <span class="report-header">PHYSICAL EXAMINATION</span>
            </div>
            <el-form>
              <el-row :gutter="10">
                <el-col :span="5" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Height (cm)</div>
                    <el-input type="number" placeholder="Height" v-model="patientInfo.height" id="height"
                      @change="updateWeightStatus"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Weight (kg)</div>
                    <el-input type="number" placeholder="Weight" v-model="patientInfo.weight" id="weight"
                      @change="updateWeightStatus"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">BP</div>
                    <el-input placeholder="BP" v-model="patientInfo.bp" id="bp"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Pulse</div>
                    <el-input placeholder="Pulse" type="number" v-model="patientInfo.pulse" id="pulse"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div>
            </div>
            <div>
              <span class="report-header">PERSONAL HISTORY</span>
            </div>
            <el-form>
              <el-row>
                <el-col :span="20" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Past Complaints</div>
                    <el-input type="textarea" placeholder="Past Complaints"
                      v-model="patientInfo.past_complaints"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="20" :offset="2">
                  <el-form-item>
                    <div class="sub-title">Present Complaints</div>
                    <el-input type="textarea" placeholder="Present Complaints"
                      v-model="patientInfo.present_complaints"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="20" :offset="2">
                  <el-form-item>
                    <div class="sub-title">Family History</div>
                    <el-input type="textarea" placeholder="Family History"
                      v-model="patientInfo.family_history"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <!-- <div>
        <span class="report-header">BMI CALCULATOR</span>
      </div> -->
            <!-- <el-form>
        <el-row :gutter="10">
          <el-col :span="4" :offset="2" class="mt-10">
            <el-form-item>
              <el-input placeholder="Weight Status" v-model="patientInfo.weight_status"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Under Weight" v-model="patientInfo.under_weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Healthy Weight" v-model="patientInfo.healthy_weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Over Weight" v-model="patientInfo.over_weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mt-10">
            <el-form-item>
              <el-input placeholder="Obesity" v-model="patientInfo.obesity"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form> -->
            <div>
              <span class="report-header">VISUAL ACUITY</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="5">
                <p class="veEyeLabel">Right Eye</p>
              </el-col>
              <el-col :span="17">
                <el-form>
                  <el-row class="mt-10">
                    <el-form-item>
                      <el-row :gutter="5">
                        <el-col :span="6">
                          <div class="sub-title">Distance</div>
                          <el-input placeholder="Distance" v-model="patientInfo.right_eye_distance"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Near</div>
                          <el-input placeholder="Near" v-model="patientInfo.right_eye_near"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Colour Vision</div>
                          <el-input placeholder="Colour Vision"
                            v-model="patientInfo.right_eye_colour_version"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Corrections</div>
                          <el-input placeholder="Corrections" v-model="patientInfo.right_eye_corrections"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="5">
                <p class="veEyeLabel">Left Eye</p>
              </el-col>
              <el-col :span="17">
                <el-form>
                  <el-row class="mt-10">
                    <el-form-item>
                      <el-row :gutter="5">
                        <el-col :span="6">
                          <div class="sub-title">Distance</div>
                          <el-input placeholder="Distance" v-model="patientInfo.left_eye_distance"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Near</div>
                          <el-input placeholder="Near" v-model="patientInfo.left_eye_near"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Colour Vision</div>
                          <el-input placeholder="Colour Vision"
                            v-model="patientInfo.left_eye_colour_version"></el-input>
                        </el-col>
                        <el-col :span="6">
                          <div class="sub-title">Corrections</div>
                          <el-input placeholder="Corrections" v-model="patientInfo.left_eye_corrections"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>
            <div>
              <span class="report-header">SYSTEMIC EXAMINATION</span>
            </div>
            <el-form>
              <el-row class="mt-10">
                <el-form-item>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/skin.png"></el-image>
                      <el-input placeholder="Skin" v-model="patientInfo.skin"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/pft.png">
                      </el-image>
                      <el-input placeholder="PFT" v-model="patientInfo.pft"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/ecg-reading.png">
                      </el-image>
                      <el-input placeholder="ECG" v-model="patientInfo.ecg"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/audiometry.png">
                      </el-image>
                      <el-input placeholder="Audiometry" v-model="patientInfo.audiometry"></el-input>
                    </div>
                  </el-col>

                  <el-col :span="20" :offset="2">
                    <div class="systematicIcons">
                      <el-image style="width: 36px; height: 36px"
                        src="//hiremeus.s3.amazonaws.com/users/profile/verificationdocuments/x-ray.png">
                      </el-image>
                      <el-input placeholder="Chest X-Ray" v-model="patientInfo.chest_xray"></el-input>
                    </div>
                  </el-col>
                </el-form-item>
              </el-row>
            </el-form>
            <div>
              <span class="report-header">LAB INVESTIGATIONS</span>
            </div>
            <el-form>
              <el-row :gutter="9">
                <el-col :span="6" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">HB</div>
                    <el-input placeholder="HB" v-model="patientInfo.hb"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">RBS</div>
                    <el-input placeholder="RBS" v-model="patientInfo.rbs"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">URINE</div>
                    <el-input placeholder="URINE" v-model="patientInfo.urine"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div>
              <span class="report-header">REMARKS</span>
            </div>
            <el-form>
              <el-row>
                <el-col :span="20" :offset="2" class="mt-10">
                  <el-form-item>
                    <div class="sub-title">Remarks</div>
                    <el-input type="textarea" placeholder="Remarks / Advice by Physician"
                      v-model="patientInfo.corporate_remaks"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>

        <div v-if="patientInfo.sub_category == 'HISTOPATHOLOGY(IHC - 4 MARKERS)' && show">
          <div class="ihcReport">
            <h4 class="reportTitle">IHC - 4 MARKERS</h4>
            <div class="editor">
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>IHC NO</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the IHC No" v-model="patientInfo.histo_ihc_no"></el-input>
                </el-form-item>
              </el-col>
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Clinical Details</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the Clinical Details" v-model="patientInfo.clinical_details"></el-input>
                </el-form-item>
              </el-col>
              <!-- <table>
                <tbody>
                  <tr>
                    <td style="width: 160px;">
                      <p>Clinical Details</p>
                    </td>
                    <td>
                      <el-form-item class="mb-0">
                        <el-input placeholder="Enter the Clinical Details"
                          v-model="patientInfo.clinical_details"></el-input>
                      </el-form-item>
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Immunohistochemistry Report</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="edit-box ihcTable mt-10">
                <el-table border style="width: 100%" :data="ihcDetails">
                  <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                  <el-table-column label="IHC Marker" v-slot="scope">
                    <el-form>
                      <el-row>
                        <el-col :span="24">
                          <el-select placeholder="Select IHC Marker" v-model="scope.row.ihc_marker"
                            @change="handleDropdownChange(scope.row)" filterable :clearable="true">
                            <el-option v-for="item in sortedIHCtypes" :key="item.ANTIBODIESNAME"
                              :label="item.ANTIBODIESNAME" :value="item.ANTIBODIESNAME">
                            </el-option>
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-table-column>
                  <el-table-column label="Clone" width="300" v-slot="scope">
                    <el-form>
                      <el-row>
                        <el-col :span="24">
                          <el-input placeholder="Enter the clone" v-model="scope.row.ihc_clone">
                          </el-input>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-table-column>
                  <el-table-column label="Result" width="200" v-slot="scope">
                    <el-form>
                      <el-row>
                        <el-col :span="24">
                          <el-input placeholder="Enter the result" v-model="scope.row.ihc_result">
                          </el-input>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-table-column>
                  <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope">
                    <el-row :gutter="12">
                      <el-col :span="6">
                        <el-buttton class="c-pointer" @click="onAddIHC">
                          <el-icon :size="20">
                            <CirclePlus />
                          </el-icon></el-buttton>
                      </el-col>
                      <el-col :span="6">
                        <el-buttton>
                          <el-icon :size="19" @click="onDeleteIHC(scope.row)">
                            <Delete style="color: red;" />
                          </el-icon></el-buttton>
                      </el-col>
                    </el-row>
                  </el-table-column>
                </el-table>
              </div>

              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Final Diagnosis</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter final diagnosis" v-model="patientInfo.ihc_final_diagnosis"></el-input>
                </el-form-item>
              </el-col>
              <div class="importantNote">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h5>Comment</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <el-col :span="24">
                <el-form-item>
                  <el-input placeholder="Enter the comment" v-model="patientInfo.ihc_comment"></el-input>
                </el-form-item>
              </el-col>
            </div>
          </div>
        </div>

        <div class="editor" v-if="patientInfo.report_type == 'HISTOPATHOLOGY(BIOPSY)'">
          <el-row :gutter="10" style="margin-bottom: 15px;">
            <el-col :span="8" class="mb-6">
              <div style="font-size: 12px; font-weight: 500;">Clinical details provided</div>
              <el-input placeholder="Enter Clinical details" v-model="patientInfo.Clinical"></el-input>
            </el-col>
            <el-col :span="8" class="mb-6">
              <div style="font-size: 12px; font-weight: 500;">Site of Biopsy</div>
              <el-input placeholder="Enter site of Biopsy" v-model="patientInfo.Site"></el-input>
            </el-col>
          </el-row>

          <div style="font-weight: 500">Gross Description</div>
          <div>
            <ckeditor :editor="editor" tag-name="div" ref="DescriptionEditor" v-model="Description"
              :config="editorConfig">
            </ckeditor>
          </div>

          <div style="margin-top: 15px;font-weight: 500;">Microscopy</div>
          <ckeditor :editor="editor" tag-name="div" ref="MicroscopyEditor" v-model="Microscopy" :config="editorConfig">
          </ckeditor>

          <div style="margin-top: 15px;font-weight: 500;">Impression</div>
          <ckeditor :editor="editor" tag-name="div" ref="ImpressionEditor" v-model="Impression" :config="editorConfig">
          </ckeditor>

        </div>
        <!-- Fish template Report code start -->
        <div v-if="patientInfo.sub_category == 'FISH'">

          <!-- SOLIDFISH UI Start -->

          <div class="double-marker">

            <div class="edit-box mb-20">
              <span class="title">FLUORESCENCE IN SITU HYBRIDIZATION (FISH)[ISCN 2020]</span>
              <el-table border style="width: 100%" :data="fluorTableData">
                <el-table-column prop="sampletype" label="Sample Type" width="200" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Sample Type" v-model="scope.row.sampletype">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Clinical Indication" width="300" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Clinical Indication" v-model="scope.row.clinicalindication">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="testrequested	" label="Test Requested	" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Test Requested" v-model="scope.row.testrequested">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="method" label="Method" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="Method" v-model="scope.row.method">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
              </el-table>
              <el-table border style="width: 100%" :data="fluorTableData">
                <el-table-column label="Sample Quality" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Sample Quality" v-model="scope.row.SampleQuality">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Prob Details" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Prob Details" v-model="scope.row.ProbDetails">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="No Of cells" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="No Of cells" v-model="scope.row.NoOfcells">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
              </el-table>
            </div>
            <div class="edit-box mb-20">
              <span class="title">Result Table</span>
              <el-table border style="width: 100%" :data="resultTableData">
                <el-table-column prop="sno" label="S.No" width="70"></el-table-column>
                <el-table-column prop="result" label="Result (ISCN 2020)" width="300" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Result (ISCN 2020)" v-model="scope.row.result">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="chromosomeloci" label="Chromosome Loci/Color" width="200" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input type="textarea" placeholder="Chromosome Loci/Color"
                            v-model="scope.row.chromosomeloci">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="noofcells" label="No Of Cells" width="160" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item class="mb-0">
                          <el-input placeholder="No Of Cells" v-model="scope.row.noofcells">
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column prop="finalresult" label="Result" width="140" v-slot="scope">
                  <el-form>
                    <el-row>
                      <el-col :span="24">
                        <el-select v-model="scope.row.finalresult" placeholder="Select">
                          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-table-column>
                <el-table-column label="Add/Remove" width="110" fixed="right" v-slot="scope">
                  <el-row :gutter="12">
                    <el-col :span="6" v-if="scope.row.sno === resultTableData?.length">
                      <el-buttton @click="onAddItem" class="c-pointer"><el-icon :size="20">
                          <CirclePlus />
                        </el-icon></el-buttton>
                    </el-col>
                    <el-col :span="6" v-if="resultTableData?.length > 1"><el-buttton
                        @click="onDeleteItem(scope.row)"><el-icon :size="19">
                          <Delete style="color: red;" />
                        </el-icon></el-buttton>
                    </el-col>
                  </el-row>

                </el-table-column>
              </el-table>

            </div>
            <div class="edit-box mb-20 p-5">
              <span class="title">Image</span>

              <div class="upload-btn p-10">
                <el-upload class="upload-demo report-title" action :on-change="onChangeImage" accept="image/*"
                  :auto-upload="false" multiple :show-file-list="false">
                  <el-button type="primary">Click to upload</el-button>
                </el-upload>

                <div class="el-upload__tip">
                  jpg/png files with a size less than 500KB.
                </div>
              </div>

              <div class="image-flex" v-if="previewImage?.length > 0">
                <div v-for="(item, index) in previewImage" :key="item" class="img-block">
                  <img alt="" :src="item.imageUrl">
                  <el-input v-model="item.imageContent"></el-input>
                  <el-button type="danger" @click="deleteImage(index)" class="remove-btn" circle>
                    <el-icon>
                      <Close />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </div>


            <div class="edit-box mb-20">
              <span class="title">Interpretation Guide</span>
              <el-input type="textarea" rows="2" v-model="patientInfo.Interpretation_Guide"></el-input>
            </div>
            <div class="edit-box mb-20">
              <span class="title">References</span>
              <el-input type="textarea" rows="2" v-model="patientInfo.References"></el-input>
            </div>


          </div>
        </div>
        <!-- Fih report code end -->
        <div class="addReportActionButtons">
          <ul class="action-buttons">
            <li v-if="!isEditForm">
              <el-button @click="addTemplate" class="submit-button">Submit</el-button>
            </li>

            <li v-if="isEditForm">
              <el-button @click="saveAsTemplate" class="submit-button">Update</el-button>
            </li>
          </ul>
        </div>

         <!-- GaucherLeukocytes not using this component-->
         <div v-if="patientInfo.sub_category == 'testGaucherLeukocytes' && show">
          <GaucherLeukocytes></GaucherLeukocytes>
        </div>
        <!-- GaucherLeukocytes --> 

        <!-- FabryLeukocytes -->
        <div v-if="(patientInfo.sub_category == 'Fabry_Leukocytes'||patientInfo.sub_category == 'Gaucher_Leukocytes' || patientInfo.sub_category == 'Leukocytes_NCL2'|| patientInfo.sub_category == 'Krabbe_Leukocytes' || patientInfo.sub_category == 'Glycoasparginase_Leukocytes') && show">
          <FabryLeukocytes :Fabry_Leukocytes_Array="patientInfo.Fabry_Leukocytes" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="SavefabricData"></FabryLeukocytes>
      </div>
        <!-- FabryLeukocytes -->

         <!-- MPSILeukocytes -->
         <div v-if="(patientInfo.sub_category == 'MPS I_Leukocytes' || patientInfo.sub_category == 'MLD_Leukocytes') && show">
          <MPSILeukocytes :Fabry_Leukocytes_Array="patientInfo.Fabry_Leukocytes" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="SavefabricData"></MPSILeukocytes>
        </div>
        <!-- MPSILeukocytes -->
               
        <!-- MLDLeukocytes component was not using-->
                <div v-if="patientInfo.sub_category == 'testMLDLeukocytes' && show">
          <MLDLeukocytes></MLDLeukocytes>
        </div>
        <!-- MLDLeukocytes -->

         <!-- KrabbeLeukocytes component was not using-->
            <div v-if="patientInfo.sub_category == 'testKrabbeLeukocytes' && show">
          <KrabbeLeukocytes></KrabbeLeukocytes>
        </div>
        <!-- KrabbeLeukocytes -->

        <!-- LeukocytesNCL2 not using this component-->
        <div v-if="patientInfo.sub_category == 'testLeukocytesNCL2' && show">
          <LeukocytesNCL2></LeukocytesNCL2>
        </div>
        <!-- LeukocytesNCL2 -->

        <!-- GlycoasparginaseLeukocytes  not using this component-->
        <div v-if="patientInfo.sub_category == 'test GlycoasparginaseLeukocytes' && show">
          <GlycoasparginaseLeukocytes></GlycoasparginaseLeukocytes>
        </div>
        <!-- GlycoasparginaseLeukocytes -->

        <!-- GM1Leukocytes -->
        <div v-if="patientInfo.sub_category == 'GM1_Leukocytes' && show">
          <GM1Leukocytes :GM1_Leukocytes_Array="patientInfo.GM1_Leukocytes" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="SaveGM1Data"></GM1Leukocytes>
        </div>
        <!-- GM1Leukocytes -->

        <!-- MPSIILeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS II_Leukocytes' && show">
          <MPSIILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIILeukocytes>
        </div>
        <!-- MPSIILeukocytes -->

        <!-- MPSIIIALeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS IIIA_Leukocytes' && show">
          <MPSIIIALeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIIALeukocytes>
        </div>
        <!-- MPSIIIALeukocytes -->

        <!-- MPSIIIBLeukocytes -->
          <div v-if="patientInfo.sub_category == 'MPS IIIB_Leukocytes' && show">
            <MPSIIIBLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics"  ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIIBLeukocytes>
          </div>
        <!-- MPSIIIBLeukocytes -->

        <!-- MPSIIICLeukocytes -->
         <div v-if="patientInfo.sub_category == 'MPS IIIC_Leukocytes' && show">
            <MPSIIICLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics"  ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIICLeukocytes>
          </div>
        <!-- MPSIIICLeukocytes -->

        <!-- MPSIIIDLeukocytes -->
         <div v-if="patientInfo.sub_category == 'MPS IIID_Leukocytes' && show">
            <MPSIIIDLeukocytes :dataArray="this.patientInfo.BiochemicalGenitics"  ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIIIDLeukocytes>
          </div>
        <!-- MPSIIIDLeukocytes -->

        <!-- MPSIVALeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS IVA_Leukocytes' && show">
            <MPSIVALeukocytes :dataArray="this.patientInfo.BiochemicalGenitics"  ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSIVALeukocytes>
          </div>
        <!-- MPSIVALeukocytes -->

          <!-- MPSVILeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS VI_Leukocytes' && show">
          <MPSVILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSVILeukocytes>
        </div>
        <!-- MPSVILeukocytes -->

        <!-- MPSVIILeukocytes -->
        <div v-if="patientInfo.sub_category == 'MPS VII_Leukocytes' && show">
          <MPSVIILeukocytes :dataArray="this.patientInfo.BiochemicalGenitics" ref="childmpsLeukocytesRef" @sendmpsLeukocytes="handlempsLeukocytes"></MPSVIILeukocytes>
        </div>
        <!-- MPSVIILeukocytes -->

        <!-- AlphaFucosidosisLeukocytes -->
         <div v-if="patientInfo.sub_category == 'AlphaFucosidosisLeukocytes' && show">
            <AlphaFucosidosisLeukocytes></AlphaFucosidosisLeukocytes>
          </div>
        <!-- AlphaFucosidosisLeukocytes -->

        <!-- HLACLASSIIISABTYPING -->
         <div v-if="this.patientInfo.sub_category == 'HLA SAB CLASS I II TYPING' && show">
            <HLACLASSIIISABTYPING :HLA_SAB_Array="this.patientInfo.HLA_SAB" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="SaveHLASABData"></HLACLASSIIISABTYPING>
          </div>
        <!-- HLACLASSIIISABTYPING -->

        <!-- AlphaMannosidosisLeukocytes -->
          <div v-if="patientInfo.sub_category == 'AlphaMannosidosisLeukocytes' && show">
            <AlphaMannosidosisLeukocytes></AlphaMannosidosisLeukocytes>
          </div>
        <!-- AlphaMannosidosisLeukocytes -->

        <!-- BetaMannosidosisLeukocytes -->
         <div v-if="patientInfo.sub_category == 'BetaMannosidosisLeukocytes' && show">
            <BetaMannosidosisLeukocytes></BetaMannosidosisLeukocytes>
          </div>
        <!-- BetaMannosidosisLeukocytes -->

        <!-- BiotinidasePlasma -->
         <div v-if="patientInfo.sub_category == 'BiotinidasePlasma' && show">
            <BiotinidasePlasma></BiotinidasePlasma>
          </div>
        <!-- BiotinidasePlasma -->

        <!-- ChitotriosidasePlasma -->
        <div v-if="patientInfo.sub_category == 'ChitotriosidasePlasma' && show">
            <ChitotriosidasePlasma></ChitotriosidasePlasma>
          </div>
        <!-- ChitotriosidasePlasma -->

        <!-- CytologySlideReporting -->
        <div v-if="onCheckCytologySubCategory() && show">
          <CytologySlideReporting :patientInfo="patientInfo.cytopathology_details" :subCategory="patientInfo.sub_category" ref="childComponentRef"
            @childMethod="SaveCytopathologyReports"></CytologySlideReporting>
        </div>
        <!-- CytologySlideReporting -->

        <!-- LiquidBasedCytology -->
        <div v-if="patientInfo.sub_category == '_LiquidBasedCytology' && show">
          <LiquidBasedCytology ></LiquidBasedCytology>
        </div>
        <!-- LiquidBasedCytology -->

        <!-- FnacSlidesReporting -->
         <div v-if="patientInfo.sub_category == '_FnacSlidesReporting' && show">
          <FnacSlidesReporting ></FnacSlidesReporting>
        </div>
        <!-- FnacSlidesReporting -->

        <!-- PusForCytology -->
        <div v-if="this.onCheckCytopathologySubCategory()  && show">
          <PusForCytology :PusForCytology_Array="this.patientInfo.PusFor_Cytology" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="PusForCytologyData"></PusForCytology>
        </div>
        <!-- PusForCytology -->
        
        <!-- PapSmearAbove -->
        <div v-if="patientInfo.sub_category == 'PAP SMEAR ABOVE 40' && show">
          <PapSmearAbove :PusForPapSmearAbove_data="this.patientInfo.Cytopathology_SubCategoery" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="CytopathologySubCategoery"></PapSmearAbove>
        </div>
        <!-- PapSmearAbove -->
        
        <!-- SmearForMalignantCells -->
        <div v-if="(patientInfo.sub_category == 'Smear For MalignantCells' || patientInfo.sub_category == 'SmearForMalignantCells') && show">
          <SmearForMalignantCells :smearForMalignantCells="this.patientInfo.SmearFor_MalignantCells" ref="childSmearForMalignantCells" @sendSmearForMalignantCells="handleSmearForMalignantCells"></SmearForMalignantCells>
        </div>
        <!-- SmearForMalignantCells -->

        <!-- UrineForCytology -->
        <div v-if="patientInfo.sub_category == 'UrineForCytology' && show">
          <UrineForCytology ></UrineForCytology>
        </div>
        <!-- UrineForCytology -->
        
        <!-- PapSmearConventional -->
        <div v-if="patientInfo.sub_category == 'PAP SMEAR - CONVENTIONAL' && show">
          <PapSmearConventional :PusForPapSmearConventional_data="this.patientInfo.Cytopathology_SubCategoery" :patientInfo="patientInfo" ref="childComponentRef" @childMethod="CytopathologySubCategoery"></PapSmearConventional>
        </div>
        <!-- PapSmearConventional -->
        
        <!-- SputumForCytology -->
        <div v-if="patientInfo.sub_category == 'SputumForCytology' && show">
          <SputumForCytology ></SputumForCytology>
        </div>
        <!-- SputumForCytology -->
        <div v-if=" onCheckSubCategory() && show">
          <BioChemicalGeneticsGlobal :reportDetails="this.patientInfo" ref="childComponentRef" @childMethod="this.$route.params.template_id?updateTemplate:saveTemplate"></BioChemicalGeneticsGlobal>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import axios from "@/config/axios";
// import { ElMessage } from "element-plus";
import CKEditor from "@ckeditor/ckeditor5-vue";
import HLACLASSIIISABTYPING from "../transplanantImmunoGenetics/HLACLASSIIISABTYPING"
import FabryLeukocytes from "../bioChemicalGenetics/FabryLeukocytes.vue";
import MPSIILeukocytes from "../bioChemicalGenetics/MPSIILeukocytes.vue";
import CytologySlideReporting from "../cytopathology/CytologySlideReporting.vue";
import LiquidBasedCytology from "../cytopathology/LiquidBasedCytology.vue";
import FnacSlidesReporting from "../cytopathology/FnacSlidesReporting.vue";
import PusForCytology from "../cytopathology/PusForCytology.vue";
import PapSmearAbove from "../cytopathology/PapSmearAbove.vue";
import SmearForMalignantCells from "../cytopathology/SmearForMalignantCells.vue";
import UrineForCytology from "../cytopathology/UrineForCytology.vue";
import PapSmearConventional from "../cytopathology/PapSmearConventional.vue";
import SputumForCytology from "../cytopathology/SputumForCytology.vue";
import GaucherLeukocytes from "../bioChemicalGenetics/GaucherLeukocytes.vue";
import GlycoasparginaseLeukocytes from "../bioChemicalGenetics/GlycoasparginaseLeukocytes.vue";
import GM1Leukocytes from "../bioChemicalGenetics/GM1Leukocytes.vue";
import KrabbeLeukocytes from "../bioChemicalGenetics/KrabbeLeukocytes.vue";
import LeukocytesNCL2 from "../bioChemicalGenetics/LeukocytesNCL2.vue";
import MLDLeukocytes from "../bioChemicalGenetics/MLDLeukocytes.vue";
import MPSILeukocytes from "../bioChemicalGenetics/MPSILeukocytes.vue";
import MPSIIIALeukocytes from "../bioChemicalGenetics/MPSIIIALeukocytes.vue";
import MPSIIIBLeukocytes from "../bioChemicalGenetics/MPSIIIBLeukocytes.vue";
import MPSIIICLeukocytes from "../bioChemicalGenetics/MPSIIICLeukocytes.vue";
import MPSIVALeukocytes from "../bioChemicalGenetics/MPSIVALeukocytes.vue";
import MPSVILeukocytes from "../bioChemicalGenetics/MPSVILeukocytes.vue";
import MPSVIILeukocytes from "../bioChemicalGenetics/MPSVIILeukocytes.vue";
import MPSIIIDLeukocytes from "../bioChemicalGenetics/MPSIIIDLeukocytes.vue";
import AlphaFucosidosisLeukocytes from "../bioChemicalGenetics/AlphaFucosidosisLeukocytes.vue";
import AlphaMannosidosisLeukocytes from "../bioChemicalGenetics/AlphaMannosidosisLeukocytes.vue";
import BetaMannosidosisLeukocytes from "../bioChemicalGenetics/BetaMannosidosisLeukocytes.vue";
import BiotinidasePlasma from "../bioChemicalGenetics/BiotinidasePlasma.vue";
import ChitotriosidasePlasma from "../bioChemicalGenetics/ChitotriosidasePlasma.vue";
import {
  genderTypes,
  radiologyReportTypes,
  histopathologyTypesMap,
  CytopathalogyMap,
  tmsTypes,
  departments,
  genomicsReportTypes,
  molecularGeneticsReportTypes,
  transplantImmunoGenetics,
  cytometryReportTypes,
  serologyImmunologyTypes,
  pharmacoGenomicsTypes,
  biochemistryTypes,
  cytogeneticsTypes,
  cytogeneticsTypesMap,
  corporateTypes,
  IHCHistopathology,
} from "@/constants/formConstants";
import { ElMessageBox } from "element-plus";
import { mapGetters } from "vuex";

import FormError from "@/components/FormError";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { ref, onUnmounted, getCurrentInstance } from 'vue';
import EditorConfig from "@/config/editorConfig";
import BioChemicalGeneticsGlobal from "../BioChemicalGeneticsGlobal.vue";
// import corporateTemplate from "@/components/reportsTemplates/corporateTemplate"

export default {
  components: {
    ckeditor: CKEditor.component,
    FormError,
    Cropper,
    HLACLASSIIISABTYPING,
    FabryLeukocytes,
    GaucherLeukocytes,
    MPSIILeukocytes,
    CytologySlideReporting,
    LiquidBasedCytology,
    FnacSlidesReporting,
    PusForCytology,
    PapSmearAbove,
    SmearForMalignantCells,
    UrineForCytology,
    PapSmearConventional,
    SputumForCytology,
    GlycoasparginaseLeukocytes,
    GM1Leukocytes,
    KrabbeLeukocytes,
    LeukocytesNCL2,
    MLDLeukocytes,
    MPSILeukocytes,
    MPSIIIALeukocytes,
    MPSIIIBLeukocytes,
    MPSIIICLeukocytes,
    MPSIIIDLeukocytes,
    MPSIVALeukocytes,
    MPSVILeukocytes,
    MPSVIILeukocytes,
    AlphaFucosidosisLeukocytes,
    AlphaMannosidosisLeukocytes,
    BetaMannosidosisLeukocytes,
    BiotinidasePlasma,
    ChitotriosidasePlasma,
    BioChemicalGeneticsGlobal,
  },

  data() {
    return {
      // imageFormateError:false,
      IHCtypes: IHCHistopathology,
      filterIHCtypes: IHCHistopathology,
      ihcDetails: [{ sno: 1, ihc_marker: '', ihc_clone: '', ihc_result: '' }],
      croppedImageData: '',
      croppedimage: '',
      croppedImageData1: '',
      croppedimage1: '',
      askSaveForm: false,
      value: "",
      genderTypes: genderTypes,
      cytogeneticsTypesMap: cytogeneticsTypesMap,
      showKaryotypingUI: false,
      previewImage: [],
      departmentOptions: [
        "Department of Radiology",
        "Histopathology",
        "Biochemical Genetics"

      ],
      fluorTableData: [
        {
          sampletype: '',
          clinicalindication: '',
          testrequested: '',
          method: '',
          SampleQuality: '',
          ProbDetails: '',
          NoOfcells: ''
        },
      ],
      resultTableData: [{
        sno: 1,
        result: '',
        chromosomeloci: '',
        noofcells: '',
        finalresult: '',
      }],
      options: [{
        value: 'Positive',
        label: 'Positive'
      },
      {
        value: 'Negative',
        label: 'Negative'
      },
      {
        value: 'Mosaic',
        label: 'Mosaic'
      }
      ],
      departmentTypes: [
        {
          label: "Department of Bio-Chemistry",
          value: "BIO_CHEMISTRY",
        },
        {
          label: "Department of Radiology",
          value: "RADIOLOGY",
        },
        {
          label: "Department of Genomics and Molecular Diagnostics",
          value: "GENOMICS",
        },
        {
          label: "Department of Molecular Genetics",
          value: "MOLECULAR_GENETICS",
        },
        {
          label: "Department of Flow Cytometry",
          value: "FLOW_CYTOMETRY",
        },
        {
          label: "Department of Transplant Immunogenetics",
          value: "TRANSPLANT_IMMUNOGENETICS",
        },
        { label: "Histopathology", value: "HISTO_PATHOLOGY" },
        {
          label: "Biochemical Genetics",
          value: "BIOCHEMICAL_GENETICS",
        },
        {
          label: "Department of Serology / Immunology",
          value: "SEROLOGY_IMMUNOLOGY",
        },
        {
          label: "Department of Pharmacogenomics",
          value: "PHARMACO_GENOMICS",
        },
        {
          label: "Department of CYTOGENETICS",
          value: "CYTOGENETICS",
        },
        {
          label: "Department of CORPORATE",
          value: "CORPORATE",
        },
        {
          label: "Department of CYTOPATHOLOGY",
          value: "CYTOPATHOLOGY",
        },   
        {
          label: "Department of MolecularDiagnostics",
          value: "MOLECULARDIAGNISTICS", 
        },      
      ],
      dropdownOptions: [
        {
          label: "Detected",
          value: "Detected"
        },
        {
          label: "Not Detected",
          value: "Not Detected"
        },
      ],
      templates: [],
      patientInfo: {
        indication:'',
        leukocyte_enzyme_activity:'',
        method:'',
        result:'',
        department: "",
        report_type: "",
        template_title: "",
        Microscopy: "",
        Impression: "",
        category: "",
        sub_category: "",
        Clinical: "",
        Site: "",
        BlockType: "",
        storageType: "",
        Malignant: "",
        karyotype: "",
        recommendations: "",
        interpretation: "",
        karyotypeSample_Type: "",
        karyotypeQualityOfSample: "",
        karyotypeClinicalIndication: "",
        karyotypeTestRequested: "",
        karyotypeTestMethodology: "",
        NoOfCellsCounted: "",
        NoOfCellsKaryotyped: "",
        EstimatedBandResolution: "",
        BandingMethod: "",
        height: null,
        weight: null,
        bp: null,
        pulse: null,
        past_complaints: "",
        present_complaints: "",
        family_history: "",
        weight_status: "",
        under_weight: "",
        healthy_weight: "",
        over_weight: "",
        obesity: "",
        right_eye_distance: "",
        right_eye_near: "",
        right_eye_colour_version: "",
        right_eye_correction: "",
        left_eye_distance: "",
        left_eye_near: "",
        left_eye_colour_version: "",
        left_eye_corrections: "",
        skin: "",
        pft: "",
        ecg: "",
        audiometry: "",
        chest_xray: "",
        hb: "",
        rbs: "",
        urine: "",
        corporate_remaks: "",
        ISCN: "",
        clinical_details: '',
        histo_ihc_no: '',
        ihc_final_diagnosis: '',
        ihc_comment: '',
        Intended_Use: '',
        Test_Method: '',
        Test_Limitations_Attributes: '',
        Disclaimer: '',
        CytogeneticsNumber:'',
        test_Result:"",
        patient_HLA_Type:"",
        Clinical_significance:"",
        detailed_report:"",
        Interpretation:"",
        References:"",
        cytopathology_details:[]
      },
      isaddendumChecked: false,
      grossdescriptiontype: 'Others',
      unSavedChanges: false,
      loading: false,
      loadingText: "",
      editor: ClassicEditor,
      show: false,
      editorData: "",
      searchVisitId: "",
      reportType: "",
      Impression: "",
      Microscopy: "",
      Description: "",
      showReportType: true,
      editReportType: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      editorConfig: EditorConfig,
      showAddTemplate: false,
      reportTypeNew: "",
      GramPostiveOrganisms: [{
        staphylococcus_Aureus: '',
        staphylococcus_Epidermidis: '',
        streptococcus_Pneumoniae: '',
        streptococcus_Pyogenes: '',
        streptococcus_Agalactiae: '',
        enterococcus_Fecalis: '',
        enterococcus_Faecium: '',
        listeria_Monocytogenes: ''
      }],
      GramNegativeOrganisms: [{
        Escherichia_Coli: '',
        Klebsiella_pneumoniae: '',
        Klebsiella_Oxytoca: '',
        Enterobacter_species: '',
        Salmonella_spp: '',
        Acinetobacter_baumannii: '',
        Pseudomonas_aeruginosa: '',
        Haemophilus_influenzae: '',
        Neisseria_meningitidis: ''

      }],
      Yeast: [{
        Candida_auris: '',
        Candida_albicans: '',
        Candida_lusitaniae: '',
      }],
      Anti_microbial_resistance_gene: [{
        sul2: '',
        dfrA1: '',
        dfrA5: '',
        bla_CTX_M_gr1: '',
        bla_TEM: '',
        bla_SHV: '',
        bla_KPC: '',
        bla_NDM: '',
        bla_VIM: '',
        bla_OXA_48: '',
        bla_IMP: '',
        mecA: '',
        vanA: '',
        vanB: ''
      }],
      Drugs_Interpretation: [{
        Cotrimoxazole:'',
        Cefoperazone:'',
        Pencillin: '',
        Ampicillin: '',
        Amoxicillin_clavulanic_acid: '',
        Piperacillin: '',
        Cefazolin: '',
        Cefoxitin: '',
        Cefuroxime: '',
        Cefotaxime: '',
        Ceftriaxone: '',
        Ceftazidime: '',
        Cefatazidime: '',
        Cefepime: '',
        Cefoperazone_sulbactam: '',
        Ceftazidime_avibactam: '',
        Cefatazidime_Avibactam: '',
        Gentamicin: '',
        Amikacin: '',
        Tigecycline: '',
        Polymyxin: '',
        Imipenem: '',
        Meropenem: '',
        Ertapenem: '',
        Aztreonam: '',
        Methicillin: '',
        Vancomycin: '',
        Teicoplanin: ''

      }],
    };    
  },
  computed: {
    sortedIHCtypes() {   
      const sortedArray = [...this.IHCtypes]; 
      return sortedArray.sort((a, b) => a.ANTIBODIESNAME?.localeCompare(b.ANTIBODIESNAME));      
    },

    shouldRenderElement() {
      // Check if patientInfo.report_type is not empty and then check if it includes sub_category
      if (this.patientInfo.department == "Department of CORPORATE" && this.patientInfo.category == "CORPORATE") {
        return this.patientInfo.report_type && this.patientInfo.report_type.includes(this.patientInfo.sub_category);
      } else {
        return false;
      }
    },

    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("patientReports", [
      "getPatientAddStatus",
      "getSinglePatientReports",
      "getPatientUpdateStatus",
      "getPatientDetails",
    ]),

    ...mapGetters("reportTemplates", [
      "getSingleReportTemplate",
      "getReportTemplateUpdateStatus",
      "getReportTemplateAddStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),

    getSubCategories() {
      if (!this.patientInfo.department) {
        return [];
      } else if (this.patientInfo.department == "Histopathology") {
        return histopathologyTypesMap;
      }
      else if (this.patientInfo.department == "Department of CYTOPATHOLOGY") {
        return CytopathalogyMap;
      }
      else if (this.patientInfo.department == "Department of CYTOGENETICS") {
        return cytogeneticsTypes;
      }
      else if (this.patientInfo.department == "Department of CORPORATE") {
        return corporateTypes;
      }
      else if (this.patientInfo.department == "Department of Radiology") {
        return radiologyReportTypes;
      } else if (this.patientInfo.department == "Biochemical Genetics") {
        return tmsTypes;
      } else if (
        this.patientInfo.department ==
        "Department of Genomics and Molecular Diagnostics"
      ) {
        return genomicsReportTypes;
      } else if (
        this.patientInfo.department == "Department of Molecular Genetics"
      ) {
        return molecularGeneticsReportTypes;
      } else if (
        this.patientInfo.department == "Department of Flow Cytometry"
      ) {
        return cytometryReportTypes;
      } else if (
        this.patientInfo.department == "Department of Transplant Immunogenetics"
      ) {
        return transplantImmunoGenetics;
      } else if (
        this.patientInfo.department == "Department of Serology / Immunology"
      ) {
        return serologyImmunologyTypes;
      } else if (
        this.patientInfo.department == "Department of Pharmacogenomics"
      ) {
        return pharmacoGenomicsTypes;
      } else if (this.patientInfo.department == "Department of Bio-Chemistry") {
        return biochemistryTypes;
      } else {
        return [];
      }
    },
    getDepartmetns() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.departments) {
        let userDepartments = this.getAuthenticatedUser.departments;
        return this.departmentTypes.filter((obj) =>
          userDepartments.includes(obj.value)
        );
      }
      return this.departmentTypes;
    },
    isReportTypeUpdateDisable() {
      return this.reportTypeNew &&
        this.reportTypeNew.trim() &&
        this.reportTypeNew.trim().length
        ? false
        : true;
    },
    isEditForm() {
      return this.$route.params && this.$route.params.template_id
        ? true
        : false;
    },
    isDraft() {
      if (
        this.isEditForm &&
        this.patientInfo.report_status &&
        this.patientInfo.report_status != "DRAFT"
      ) {
        return false;
      }
      return true;
    },

    noContent() {
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0].innerHTML;
      return data.length ? false : true;
    },
  },
  mounted() {
    this.loadInitData();
    // this.fetchReportTemplates();
  },

  setup() {
    const cropper = ref(null);
    const image = ref({ src: null, file: null, type: null }); // Define image with initial src and type as null
    const file = ref(null);
    const imageFormateError = ref(false);
    const instance = getCurrentInstance();
    const allowedTypes = ["image/jpeg", "image/png"];
    const uploadImage = (event) => {
      const { files } = event.target;
      if (allowedTypes.includes(files[0].type)) {
        if (files && files[0]) {
          imageFormateError.value = false;
          if (image.value.src) {
            URL.revokeObjectURL(image.value.src); // Accessing image's value
          }
          const blob = URL.createObjectURL(files[0]);
          image.value.src = blob; // Accessing image's value
          image.value.type = files[0].type; // Accessing image's value
          image.value.file = files[0];
        }
      } else {
        imageFormateError.value = true
      }
    };
    onUnmounted(() => {
      if (image.value.src) {
        URL.revokeObjectURL(image.value.src); // Accessing image's value
      }
    });

    const cropper1 = ref(null);
    const image1 = ref({ src: null, type: null });
    const file1 = ref(null);
    const allowedTypes1 = ["image/jpeg", "image/png",];
    const uploadDoctorSignatureImage = (event) => {
      const { files } = event.target;
      if (allowedTypes1.includes(files[0].type)) {
        if (files && files[0]) {
          if (image1.value.src) {
            URL.revokeObjectURL(image1.value.src);
          }
          const blob = URL.createObjectURL(files[0]);
          image1.value.src = blob;
          image1.value.type = files[0].type;
          image1.value.file = files[0];
        }
      } else {
        instance.appContext.config.globalProperties.$notify.error({
          title: "Error",
          message: "Please upload an image with JPEG, PNG, or JPG format.",
        });
        return;
      }
    };

    onUnmounted(() => {
      if (image1.value.src) {
        URL.revokeObjectURL(image1.value.src);
      }
    });
    return {
      imageFormateError,
      image1,
      cropper1,
      file1,
      uploadDoctorSignatureImage,
      image,
      cropper,
      file,
      uploadImage,
    };


  },




  methods: {   

    handlempsLeukocytes(result) {
    this.patientInfo.BiochemicalGenitics = result;
   },

   SavefabricData(result){
    this.patientInfo.Fabry_Leukocytes = result
   },
   SaveGM1Data(result){
    this.patientInfo.GM1_Leukocytes = result
   },
   SaveHLASABData(result){
    this.patientInfo.HLA_SAB = result;
   },
   SaveCytopathologyReports(result){
      this.patientInfo.cytopathology_details=result
    },
    handleDropdownChange(row) {
      this.matchingIHCArray = []
      const stringValue = this.filterIHCtypes.filter(item => item.ANTIBODIESNAME === row.ihc_marker).map(item => item.Clone)
      row.ihc_clone = stringValue ? stringValue[0] : ''
      this.matchingIHCArray.push(row);
    },
    onAddIHC() {
      // this.IHCtypes = this.IHCtypes.filter(item => {
      //   // Check if the label of the current item is not present in any item of filterIHCtypes
      //   return !this.matchingIHCArray.some(option => option.ihc_marker === item.label);
      // });
      this.ihcDetails.push({
        sno: this.ihcDetails?.length + 1,
        ihc_marker: '',
        ihc_clone: '',
        ihc_result: ''
      });
    },
    onDeleteIHC(index) {
      // if(index.value!=""){
      //  const removedData = this.filterIHCtypes.filter(item =>item.label === index.ihc_marker);
      //   this.IHCtypes.push(...removedData)
      //   console.log(this.IHCtypes)
      // }
      if(this.ihcDetails.length > 1){
        this.ihcDetails.splice(index.sno - 1, 1)
        this.ihcDetails.forEach((data, index) => {
        data.sno = index + 1
      })
      }    
    },
    cropImage() {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { canvas } = cropper.getResult();
        this.croppedImageData = canvas.toDataURL(this.image.type);
        this.croppedimage = this.croppedImageData
      }
    },
    cropDoctorSignatureImage() {
      const cropper1 = this.$refs.cropper1;
      if (cropper1) {
        const { canvas } = cropper1.getResult();
        this.croppedImageData1 = canvas.toDataURL(this.image1.type);
        this.croppedimage1 = this.croppedImageData1
      }
    },
    dataURLtoFile(dataUrl, filename, type) {
      const arr = dataUrl.split(',');
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[-T:Z.]/g, "");
      //  const randomNumber = Math.floor(Math.random() * 1000); // Change 1000 to your desired range
      const extension = filename.split('.').pop();
      const nameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
      const newFilename = `${nameWithoutExtension}_${formattedDate}.${extension}`
      return new File([u8arr], newFilename, { type: type });
    },
    onAddItem() {
      this.resultTableData.push({
        sno: this.resultTableData?.length + 1,
        result: '',
        chromosomeloci: '',
        noofcells: '',
        finalresult: '',
      })

    },
    onDeleteItem(index) {
      this.resultTableData.splice(index.sno - 1, 1)
      this.resultTableData.forEach((data, index) => {
        data.sno = index + 1
      })
    },
    deleteImage(index) {
      this.previewImage.splice(index, 1)
    },
    // onChangeImage(image) {
    //   try {
    //     if (image.size / Math.pow(1024, 2) > 5) {
    //       ElMessage.error("Digital Signature size cannot exceed 5MB!");
    //       return false;
    //     }

    //     const reader = new FileReader();

    //     reader.onloadend = () => {
    //       this.previewImage.push(reader.result);
    //     };
    //     reader.readAsDataURL(image.raw);

    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    async onChangeImage(file) {
      const isValidFile = this.validateFile(file);
      if (!isValidFile) {
        this.$message.error('Invalid file format or size. Please upload a jpg/png file with a size less than 500KB.');
        return;
      }
      try {
        const selectedFile = file.raw;
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post("patient-reports/upload-pdf", formData, file.raw);
        const pdf = response.data.data.imageUrl;
        this.previewImage.push({ imageUrl: pdf, imageContent: '' });
      } catch (error) {
        console.error(error);
      }
    },
    validateFile(file) {
      const isValidType = ['image/jpeg', 'image/png'].includes(file.raw.type);
      const isValidSize = file.raw.size / 1024 < 500;
      return isValidType && isValidSize;
    },
    async loadInitData() {
      await this.$store.dispatch("errors/clear", {}, { root: true });

      if (this.$route.params.template_id) {
        await this.fetchSinglePatientInfo();
      }

      setTimeout(() => {
        this.show = true;
      });
    },
    async fetchReportTemplates() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data..";
        let params = {
          get_all: true,
          category: "CYTOGENETICS",

        };
        console.log(params.sub_category)
        if (this.reportType) {
          const matchingType = this.cytogeneticsTypesMap.find(type => type.value === this.reportType);

          if (matchingType) {
            params.sub_category = matchingType.value;
            this.showKaryotypingUI = matchingType.value === 'KARYOTYPING';
            // this.showSolidfishUI = matchingType.value === 'SOILDFISH';
            //this.showHematfishUI = matchingType.value === 'HEMATFISH';
          }
        }

        console.log(this.patientInfo.department)
        await this.$store.dispatch("reportTemplates/fetchAllReportTemplates", params);

        // this.templates = this.getAllReportTemplates.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async fetchSinglePatientInfo() {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        await this.$store.dispatch(
          "reportTemplates/fetchSingleReportTemplate",
          { template_id: this.$route.params.template_id }
        );
        this.setPatientData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    PusForCytologyData(result){
      this.patientInfo.PusFor_Cytology = result;
    },

    CytopathologySubCategoery(result){
      this.patientInfo.Cytopathology_SubCategoery = result;
    },

    handleSmearForMalignantCells(result){
      this.patientInfo.SmearFor_MalignantCells = result;
    },

    setPatientData() {
      this.patientInfo = { ...this.getSingleReportTemplate };
      this.ihcDetails=this.patientInfo.IHCResultTable.length > 0?this.patientInfo.IHCResultTable:this.ihcDetails      
      if (this.patientInfo.category == 'CORPORATE') {
        this.image.src = this.patientInfo.corporate_logo
        this.image.file = this.patientInfo.corporate_logo
        const urlParts = this.patientInfo.corporate_logo.split('/');
        const fileNameWithExtension = urlParts[urlParts.length - 1];
        const fileNameParts = fileNameWithExtension.split('.');
        this.image.file = {
          name: fileNameWithExtension,
          type: fileNameParts[fileNameParts.length - 1]
        }
        // this.image1.src = this.patientInfo.doctor_signature
        // this.image1.file = this.patientInfo.doctor_signature
        // const urlPart = this.patientInfo.doctor_signature.split('/');
        // const fileNameWithExtensions = urlPart[urlParts.length - 1];
        // const fileNamePart = fileNameWithExtensions.split('.');
        // this.image1.file = {
        //   name: fileNameWithExtensions,
        //   type: fileNamePart[fileNameParts.length - 1]
        // }
      }

      if (this.patientInfo.sub_category === 'FISH') {
        this.resultTableData = this.getSingleReportTemplate.HematFishResultTable
        this.fluorTableData = this.getSingleReportTemplate.fluorTableData
        this.previewImage = this.getSingleReportTemplate.HematFish_patient_image
      }
      this.reportType = this.patientInfo.report_type;
      this.editorData = this.getSingleReportTemplate.template_body;
      if (!this.patientInfo.sub_category) {
        this.patientInfo.sub_category = this.patientInfo.report_type;
      }
      if (this.patientInfo.sub_category === 'HISTOPATHOLOGY(BIOPSY)') {
        this.Description = this.getSingleReportTemplate.template_body
        this.Impression = this.getSingleReportTemplate.Impression
        this.Microscopy = this.getSingleReportTemplate.Microscopy
      }
      if(this.patientInfo.report_type==='SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR'){
        this.GramPostiveOrganisms=this.getSingleReportTemplate.GramPostiveOrganisms,
       this.GramNegativeOrganisms=this.getSingleReportTemplate.GramNegativeOrganisms
       this.Yeast=this.getSingleReportTemplate.Yeast
       this.Anti_microbial_resistance_gene=this.getSingleReportTemplate.Anti_microbial_resistance_gene
       this.Drugs_Interpretation=this.getSingleReportTemplate.Drugs_Interpretation
      }
    },
    async clearForm() {
      await ElMessageBox.confirm(
        "Are you sure to clear the form. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          await this.clearPatientForm();
        })
        .catch(() => { });
    },
    async addTemplate() {
      try {
        let params = this.prepareTemplateData();
        if (!params) {
          return;
        }                 
        this.loading = true;
        this.loadingText = "Loading...";
        if (this.patientInfo.department == "Department of CORPORATE" && !this.image.file  && this.patientInfo.sub_category != "") {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Please upload company logo.",
          });
          return
        }
        if (this.patientInfo.department == "Department of CORPORATE" && this.image.file && this.patientInfo.template_title) {
          const croppedFile = await this.dataURLtoFile(this.croppedImageData, this.image.file.name, this.image.file.type);
         // const croppedFile1 = await this.dataURLtoFile(this.croppedImageData1, this.image1.file.name, this.image1.file.type);
          this.image.file = croppedFile
        // this.image1.file = croppedFile1
          let formData = new FormData();
        // let formData1 = new FormData();
          formData.append('file', this.image.file);
         // formData1.append('file', this.image1.file);
          const logoresponse = await axios.post("patient-reports/upload-pdf", formData, this.image.file);
         // const signatureresponses = await axios.post("patient-reports/upload-pdf", formData1, this.image1.file);
          if (logoresponse.data.success == true) {
            const logopdf = logoresponse.data.data.imageUrl;
            params.corporate_logo = logopdf;
          }
          // if (signatureresponses.data.success == true) {
          //   const signaturepdf = signatureresponses.data.data.imageUrl;
          //   params.doctor_signature = signaturepdf;
          // }
        }
         await this.$store.dispatch("reportTemplates/addReportTemplate", params);
        if (this.getReportTemplateAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Added Successfully",
          });

          this.$router.push("/all-report-templates");
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding Template",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    
    async updateTemplateData() {
      try {
        let params = this.prepareTemplateData();
        if (!params) {
          return;
        }
        this.loading = true;
        this.loadingText = "Loading...";
        if (this.patientInfo.department == "Department of CORPORATE" && !this.image.file) {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Please upload logo and signature image files.",
          });
          return;
        }
        if (this.patientInfo.department == "Department of CORPORATE" && this.image.file) {
          const croppedFile = await this.dataURLtoFile(this.croppedImageData, this.image.file.name, this.image.file.type);
        //  const croppedFile1 = await this.dataURLtoFile(this.croppedImageData1, this.image1.file.name, this.image1.file.type);
          this.image.file = croppedFile
         // this.image1.file = croppedFile1
          let formData = new FormData();
        //  let formData1 = new FormData();
          formData.append('file', this.image.file);
         // formData1.append('file', this.image1.file);
          const logoresponse = await axios.post("patient-reports/upload-pdf", formData, this.image.file);
          //const signatureresponses = await axios.post("patient-reports/upload-pdf", formData1, this.image1.file);
          if (logoresponse.data.success == true) {
            const logopdf = logoresponse.data.data.imageUrl;
            params.corporate_logo = logopdf;
          }
          // if (signatureresponses.data.success == true) {
          //   const signaturepdf = signatureresponses.data.data.imageUrl;
          //   params.doctor_signature = signaturepdf;
          // }
        }
        if (this.patientInfo.department == "Department of Molecular Genetics" && this.patientInfo.sub_category == 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') {
          params.GramPostiveOrganisms = []
          params.GramNegativeOrganisms = []
          params.Yeast = []
          params.Anti_microbial_resistance_gene = []
          params.Drugs_Interpretation = []
          params.GramPostiveOrganisms = this.GramPostiveOrganisms,
            params.GramNegativeOrganisms = this.GramNegativeOrganisms,
            params.Yeast = this.Yeast,
            params.Anti_microbial_resistance_gene = this.Anti_microbial_resistance_gene,
            params.Drugs_Interpretation = this.Drugs_Interpretation
        }

        await this.$store.dispatch("reportTemplates/updateReportTemplate", {
          template_id: this.patientInfo._id,
          ...params,
        });

        if (this.getReportTemplateUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Report Template Updated Successfully",
          });

          this.$router.push({
            path: "/all-report-templates",
            query: this.$route.query,
          });
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at Updating Template",
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    prepareTemplateData() {
      
      if (this.onCheckSubCategory()) {
        this.$route.params.template_id ? this.$refs.childComponentRef.updateTemplate() : this.$refs.childComponentRef.childMethod();
        return;
      }
      if( this.patientInfo.category=='BIOCHEMICAL_GENETICS' && this.onCheckbioSubCategory()){
              this.$refs.childComponentRef.childMethod();
              let params = { ...this.patientInfo };
              return params;
        }
        if( this.patientInfo.category=='BIOCHEMICAL_GENETICS' && this.patientInfo.sub_category == "GM1_Leukocytes"){
              this.$refs.childComponentRef.childMethod();
              let params = { ...this.patientInfo };
              return params;
        }
          if(this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.onCheckCytopathologySubCategory()){
            this.$refs.childComponentRef.childMethod();
            let params = {...this.patientInfo};
            return params;
          }
            if(this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.patientInfo.sub_category == "PAP SMEAR - CONVENTIONAL"){
            this.$refs.childComponentRef.childMethod();
            let params = {...this.patientInfo};
            return params;
          }
           if(this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.patientInfo.sub_category == "PAP SMEAR ABOVE 40"){
            this.$refs.childComponentRef.childMethod();
            let params = {...this.patientInfo};
            return params;
          }
          if(this.onCheckCytologySubCategory()) { this.$refs.childComponentRef.childMethod();
            let params = { ...this.patientInfo };
            return params;
          }
          if(this.patientInfo.department == "Department of CYTOPATHOLOGY" && this.patientInfo.sub_category == "Smear For Malignant Cells"||this.patientInfo.sub_category == "SmearForMalignantCells") { this.$refs.childSmearForMalignantCells.handleSmearForMalignantCells();
            let params = { ...this.patientInfo };
            return params;
          }
      if( (this.patientInfo.department === 'Department of Transplant Immunogenetics') && this.patientInfo.sub_category == 'HLA SAB CLASS I II TYPING'){
            this.$refs.childComponentRef.childMethod();
            let params = { ...this.patientInfo };
            return params;
      }
      if (this.patientInfo.department == "Department of CORPORATE") {
        let params = { ...this.patientInfo };
        if ((params.height != null &&  params.height != "" && params.weight != null && params.weight != "")) {
          const height = parseFloat(params.height);
          const weight = parseFloat(params.weight);
          const heightInMeters = height / 100;
          const bmi = weight / (heightInMeters * heightInMeters);
          const Calculationbmi = bmi.toFixed(2);
          params.calculationbmi = Calculationbmi
        }else{
          const value = "NaN";
          params.calculationbmi = parseFloat(value);
        }
        return params;
      }
      if (this.patientInfo.report_type === 'HISTOPATHOLOGY(IHC - 4 MARKERS)') {
        this.patientInfo.IHCResultTable = this.ihcDetails.filter(detail => detail.ihc_marker !== '');
        const params = { ...this.patientInfo };
        return params
      }     
      let dd = window.document.getElementsByClassName("ck-content");
      const data = dd[0]?.innerHTML;
      const Microscopy = dd[1] ? dd[1].innerHTML : "";
      const Impression = dd[2] ? dd[2].innerHTML : "";
      // let stringContent = data.replace(new RegExp("<[^>]*>", "g"), "");
      // stringContent = stringContent.trim();

      // if (!stringContent) {
      //   this.$message.warning("No Content to Save Template");
      //   return;
      // }
      let params = { ...this.patientInfo };

      if (params.department) {
        let category = departments.find(
          (department) => department.label == params.department
        );
        if (category && category.value) {
          params.category = category.value;
        }
      }

      params.template_body = data;
      if (this.patientInfo.sub_category === 'FISH') {
        params.HematFishResultTable = this.resultTableData
        params.fluorTableData = this.fluorTableData
        params.HematFish_patient_image = this.previewImage
      }
      if (this.patientInfo.department == "Department of Molecular Genetics" && this.patientInfo.sub_category == 'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR') {
        params.GramPostiveOrganisms=[]
        params.GramNegativeOrganisms=[]
        params.Yeast=[]
        params.Anti_microbial_resistance_gene=[]
        params.Drugs_Interpretation=[]
        params.GramPostiveOrganisms=this.GramPostiveOrganisms,
          params.GramNegativeOrganisms=this.GramNegativeOrganisms,
          params.Yeast= this.Yeast,
          params.Anti_microbial_resistance_gene=this.Anti_microbial_resistance_gene,
          params.Drugs_Interpretation=this.Drugs_Interpretation
      }
      if(this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS II_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
            let params = { ...this.patientInfo };
            return params;
      } 
      if(this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIIA_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
            let params = { ...this.patientInfo };
            return params;
      }
       if(this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIIB_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
            let params = { ...this.patientInfo };
            return params;
      }
         if(this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIIC_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
            let params = { ...this.patientInfo };
            return params;
      }
       if(this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IIID_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
            let params = { ...this.patientInfo };
            return params;
      }
       if(this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == 'MPS IVA_Leukocytes') {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
            let params = { ...this.patientInfo };
            return params;
      }
       if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "MPS VI_Leukocytes") {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      if (this.patientInfo.department == "Biochemical Genetics" && this.patientInfo.sub_category == "MPS VII_Leukocytes") {
        this.$refs.childmpsLeukocytesRef.sendmpsLeukocytes();
        let params = { ...this.patientInfo };
        return params;
      }
      params.grossdescriptiontype = this.grossdescriptiontype;
      params.isaddendumChecked = this.isaddendumChecked;
      params.Microscopy = Microscopy ? Microscopy : undefined;
      params.Impression = Impression ? Impression : undefined;
      return params;
    },

    async saveAsTemplate() {
      await this.updateTemplateData();
    },
    clearPatientForm(clearPatientData = true) {
      if (clearPatientData) {
        this.patientInfo = {
          visit_id: "",
          patient_name: "",
          ref_doctor: "",
          uhid: "",
          mr_no: "",
          gender: "",
          age: "",
          hospital_name: "",
          registration_date: "",
          reported_date: "",
          received_date: "",
        };
      }

      window.document.getElementsByClassName("ck-content")[0].innerText = "";
      window.document.getElementsByClassName("ck-content").innerHTML = "";
      // this.editorData = "";
    },
    async previewReport() {
      try {
        this.loading = true;
        this.loadingText = "Loading..";
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;

        let params = { ...this.patientInfo };

        params.test_details = data;

        this.$store.dispatch("patientReports/addPatientData", params);

        let routerData = this.$router.resolve({
          name: "PatientReportPreview",
        });

        window.open(routerData.href, "_blank");

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async goToReportTemplates() {
      this.$router.push({ name: "ReportTemplates", query: this.$route.query });
    },

    async updatePatientInfo() {
      this.loading = true;
      this.loadingText = "Loading...";
      try {
        let params = { ...this.patientInfo };
        let dd = window.document.getElementsByClassName("ck-content");
        const data = dd[0].innerHTML;
        params.test_details = data;
        let patientId = this.$route.params.patient_id;
        await this.$store.dispatch("reportTemplates/updateReportTemplate", {
          params,
          patientId,
        });
        if (this.getPatientUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Patient Report Updated Successfully",
          });
          await this.goToReportTemplates();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Report",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async askRemoveConfirmation() {
      await ElMessageBox.confirm(
        "Are you want to clear template data. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.clearPatientForm(false);
        })
        .catch(() => { });
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    onEditReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = true;
      this.showReportType = false;
    },
    updateReportType() {
      this.patientInfo.report_type = this.reportTypeNew;
      this.editReportType = false;
      this.showReportType = true;
    },
    cancelUpdateReportType() {
      this.reportTypeNew = this.patientInfo.report_type;
      this.editReportType = false;
      this.showReportType = true;
    },
    onChangeCategory() {
      this.patientInfo.report_type = this.patientInfo.sub_category;
      if (this.patientInfo.department === "Histopathology") {
        this.patientInfo.category = "HISTO_PATHOLOGY";
      } else if (
        this.patientInfo.department ===
        "Department of Genomics and Molecular Diagnostics"
      ) {
        this.patientInfo.category = "GENOMICS";
      } else if (
        this.patientInfo.department === "Department of Molecular Genetics"
      ) {
        this.patientInfo.category = "MOLECULAR_GENETICS";
      } else if (
        this.patientInfo.department === "Department of Flow Cytometry"
      ) {
        this.patientInfo.category = "FLOW_CYTOMETRY";
      } else if (
        this.patientInfo.department ===
        "Department of Transplant Immunogenetics"
      ) {
        this.patientInfo.category = "TRANSPLANT_IMMUNOGENETICS";
      } else if (
        this.patientInfo.department == "Department of Serology / Immunology"
      ) {
        this.patientInfo.category = "SEROLOGY_IMMUNOLOGY";
      } else if (
        this.patientInfo.department == "Department of Pharmacogenomics"
      ) {
        this.patientInfo.category = "PHARMACO_GENOMICS";
      } else if (this.patientInfo.department == "Department of Bio-Chemistry") {
        this.patientInfo.category = "BIO_CHEMISTRY";
      } else if (this.patientInfo.department == "Department of CYTOGENETICS") {
        this.patientInfo.category = "CYTOGENETICS"
      }
      else if (this.patientInfo.department == "Department of CORPORATE") {
        this.patientInfo.category = "CORPORATE"
      }
      else if (this.patientInfo.department == "YODA SISHU SAMRAKSHA PANEL") {
        this.patientInfo.category = "YODA_SISHU_SAMRAKSHA"
      }
      else if (this.patientInfo.department == "Biochemical Genetics") {
        this.patientInfo.category = "BIOCHEMICAL_GENETICS"
      } else if (this.patientInfo.department == "Department of CYTOPATHOLOGY") {
        this.patientInfo.category = "CYTOPATHOLOGY"
      }
      else {
        this.patientInfo.category = "RADIOLOGY";
      }
    },
    checkCKEditorContent() {
      const excludedSubcategories = [
        'KARYOTYPING',
        'SEPSIS PANEL WITH AMR GENES - BY MULTIPLEX PCR',
        'HISTOPATHOLOGY(IHC - 4 MARKERS)',
        'HISTOPATHOLOGY(BIOPSY)',
        'FISH',
        'Gaucher_Leukocytes',
        'MPS II_Leukocytes',
        'Fabry_Leukocytes',
        'HLA SAB CLASS I II TYPING',
        'MPS IIIA_Leukocytes',
        'MPS IIIB_Leukocytes',
        'MPS IIIC_Leukocytes',
        'MPS IIID_Leukocytes',
        'MPS IVA_Leukocytes',
        "MPS VI_Leukocytes",
        "MPS VII_Leukocytes",
        "PAP SMEAR ABOVE 40",
        'PAP SMEAR - CONVENTIONAL',
        'LYSOSOMAL STORAGE DISORDERS',
        "Honda Hyundai Motors",
        "Bharathi Cement",
        "Reliance Industries",
        "Bajaj Finserv Ltd",
        "Indian Oil Corporation Ltd",
        'Leukocytes_NCL2',
        "Alpha-Fucosidosis_Leukocytes",
        "Alpha-Mannosidosis_Leukocytes",
        "Beta-Mannosidosis_Leukocytes",
        "Biotinidase_Plasma",
        "Chitotriosidase_Plasma",
        "Krabbe_Leukocytes",
        "Glycoasparginase_Leukocytes",
        "MPS I_Leukocytes",
        "Pus For Cytology",
        "MLD_Leukocytes",
        "GM1_Leukocytes",
        "Liquid Based Cytology - Pap Smear",
        "Fnac Slides Reporting",
        "Cytology Slide Reporting",
        "FNAC (Sample In Clinic)",
        "Fluid For Cytology",
        "Urine For Cytology",
        "Sputum For Cytology",
        "Smear For Malignant Cells"
      ];
      return !excludedSubcategories.includes(this.patientInfo.report_type);
    },
    onCheckSubCategory(){
      const subCategorys=[
      "Alpha-Fucosidosis_Leukocytes",
      "Alpha-Mannosidosis_Leukocytes",
      "Beta-Mannosidosis_Leukocytes",
      "Biotinidase_Plasma",
      "Chitotriosidase_Plasma"
      ];
      return subCategorys.includes(this.patientInfo.report_type)
    },
    onCheckbioSubCategory(){
      const subCategorys=[
      "Fabry_Leukocytes",
      "Gaucher_Leukocytes",
      "Leukocytes_NCL2",
      "Krabbe_Leukocytes",
      "MPS I_Leukocytes",
      "Glycoasparginase_Leukocytes",
      "MLD_Leukocytes"
      ];
      return subCategorys.includes(this.patientInfo.sub_category)
    },
    onCheckCytologySubCategory(){
      const subCategorys=[
      "Cytology Slide Reporting",
      "FNAC (Sample In Clinic)",
      "Fluid For Cytology",
      "Urine For Cytology",
      "Sputum For Cytology"
      ]; 
      return subCategorys.includes(this.patientInfo.report_type)
    },
    onCheckCytopathologySubCategory(){
      const subCategorys=[
      "Liquid Based Cytology - Pap Smear",
      "Fnac Slides Reporting",
      "Pus For Cytology",
      ];
      return subCategorys.includes(this.patientInfo.report_type)
    }
    
  },
  // beforeRouteLeave(to, from, next) {

  //     const answer = window.confirm(
  //       "Do you really want to leave? you have unsaved changes!"
  //     );
  //     if (answer) {
  //       next();
  //     } else {
  //       next(false);
  //     }

  // },
};
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 320px;
}

.report-header {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  background: #2eaf9f;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.veEyeLabel {
  text-align: right;
  font-weight: 500;
  margin-top: 45px;
}

.image-flex {
  display: grid;
  grid-template-columns: repeat(3, 265px);
  gap: 30px;
  padding: 0 10px;
  margin: 20px 0;
  align-items: center;
  justify-items: center;

  img {
    width: 240px;
    max-width: 240px;
    height: 200px;
    object-fit: contain;
  }

  h4 {
    text-align: center;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
  }

  .upload-btn {
    padding: 10px;
  }

  .img-block {
    position: relative;
    background: #f3f3f3;
    padding: 10px;
    border-radius: 6px;

    .remove-btn {
      display: none;
    }

    &:hover {
      .remove-btn {
        display: block;
        cursor: pointer;
      }
    }

    .remove-btn {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}

.fix-cropper-width {
  width: 600px;
  max-width: 600px;
}

#fileUploadArea {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fileUploadArea {
  .section-2 {
    margin-top: 2rem;
  }
}

.cropper {
  height: 280px;
  max-height: 100%;
  width: 280px;
  max-width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ddd;
}

// .uploadBlock{
//   background-color: #ddd;
//   width: 280px;
//   height: 280px;
//   padding: 10px;
//   box-sizing: border-box;
//   border-radius: 10px;
// }
.croppedBlock {
  background: #ddd;
  width: 280px;
  height: 280px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;

  .croppedImage {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: fill;

  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin: 25px auto;
  margin-left: 370px;
}
.centeredButton{
  display: flex;
  justify-content: center;
}
.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  background: #151515;
  cursor: pointer;
  transition: background 0.5s;
  border: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:hover {
    background: #2F2F2F;
  }

  input {
    display: none;
  }
}

.hidden {
  display: none;
}

.fileUploadBlock {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.fileUploadBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.editor {
  margin-top: 0.5em;
  padding: 10px;

  .ck-editor__editable {
    min-height: 320px;
  }
}
.add-patient-report-data .addReportPatientDemographics .title {
    text-align: left !important;
    text-transform: initial !important;
    padding: 6px !important;
}

</style>
