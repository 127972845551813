<template>
  <div class="add-acknowledgement" v-loading="loading">
    <el-row justify="center">
      <el-col :lg="20" :xl="16">
        <el-button @click="goBack" class="back-btn mb-10" round>
          <el-icon><Back /></el-icon>
          <span>Back to List</span>
        </el-button>
        <el-card shadow="never" class="p-0">
          <el-form v-model="addAcknowledgementFormData">
            <el-row :gutter="20" align="middle" justify="center">
              <el-col :xl="8" :lg="10">
                <el-input
                  placeholder="Search Barcode No"
                  size="large"
                  v-model="addAcknowledgementFormData.barcode_no"
                  clearable
                  @clear="clearAcknoledgement()"
                  @input="handleBarcodeInput"
                  @keypress="onlyNumber($event)"
                  maxlength="10"
                ></el-input>
              </el-col>
              <el-button
                @click="getBarcode"
                size="large"
                class="default-button export-button"
                :disabled="!hasBarcodeData"
                >Get Data</el-button
              >
            </el-row>
            <el-row :gutter="20" align="middle" justify="center" class="mt-10">
              <el-col :lg="10">
                <FormError errorName="barcode_no"></FormError>
                <span
                  style="color: red; font-size: 14px"
                  v-if="this.showMessage"
                >
                  {{ this.message }}</span
                >
              </el-col>
            </el-row>

            <el-divider class="mt-20"></el-divider>
            <div>
              <h3 class="sub-title">Patient Information</h3>
              <el-row :gutter="20">
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label"
                      >Patient Name <span class="star">*</span></label
                    >
                    <el-input
                      placeholder="Enter Patient Name"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.patient_details[
                          'patient_name'
                        ]
                      "
                      clearable
                      disabled
                    ></el-input>
                    <FormError
                      errorName="patient_details.patient_name"
                    ></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Patient Phone</label>
                    <el-input
                      placeholder="Enter Patient Phone"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.patient_details['mobile']
                      "
                      clearable
                      @keypress="onlyNumber($event)"
                      maxlength="10"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Patient Email</label>
                    <el-input
                      placeholder="Enter Patient Email"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.patient_details['email']
                      "
                      clearable
                      disabled
                    ></el-input>
                    <FormError errorName="patient_details.email"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Patient Gender</label>
                    <el-select
                      placeholder="Select Gender"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.patient_details['gender']
                      "
                      clearable
                      filterable
                      disabled
                    >
                      <el-option
                        v-for="(gender, index) in genderOptions"
                        :key="index"
                        :label="gender"
                        :value="gender"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Patient Age</label>
                    <el-input
                      placeholder="Enter Patient Age"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.patient_details[
                          'patient_age'
                        ]
                      "
                      clearable
                      disabled
                    ></el-input>
                    <FormError
                      errorName="patient_details.patient_age"
                    ></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Visit ID</label>
                    <el-input
                      placeholder="Enter Patient Visit ID"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.patient_details['visit_id']
                      "
                      clearable
                      disabled
                    ></el-input>
                    <FormError errorName="patient_details.visit_id"></FormError>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-divider class="mt-10"></el-divider>
            <div>
              <h3 class="sub-title">Client Information</h3>
              <el-row :gutter="20">
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label"
                      >Client Name <span class="star">*</span></label
                    >
                    <el-input
                      placeholder="Enter Client Name"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.client_details['client_name']
                      "
                      clearable
                      disabled
                    ></el-input>
                    <FormError
                      errorName="client_details.client_name"
                    ></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Client ID</label>
                    <el-input
                      placeholder="Enter Client ID"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.client_details['client_code']
                      "
                      clearable
                      disabled
                    ></el-input>
                    <FormError
                      errorName="client_details.client_code"
                    ></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Client Phone</label>
                    <el-input
                      placeholder="Enter Client Phone"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.client_details[
                          'client_phone'
                        ]
                      "
                      clearable
                      @keypress="onlyNumber($event)"
                      maxlength="10"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label"
                      >Client Email <span class="star">*</span></label
                    >
                    <el-input
                      placeholder="Enter Client Email"
                      size="large"
                      @change="setClientEMail"
                      v-model="
                        addAcknowledgementFormData.client_details[
                          'client_email'
                        ]
                      "
                      clearable
                    ></el-input>
                    <FormError
                      errorName="client_details.client_email"
                    ></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label"
                      >PRO Mobile <span class="star">*</span></label
                    >
                    <el-input
                      placeholder="Enter PRO Mobile"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.patient_details['pro_mobile']
                      "
                      clearable
                      maxlength="10"
                      @keypress="onlyNumber($event)"
                    ></el-input>
                    <FormError
                      errorName="patient_details.pro_mobile"
                    ></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label"
                      >Centre <span class="star">*</span></label
                    >
                    <el-select
                      placeholder="Select Centre"
                      size="large"
                      v-model="addAcknowledgementFormData.lab_id"
                      filterable
                      clearable
                      disabled
                    >
                      <el-option
                        v-for="(lab, index) in labOptions"
                        :key="index"
                        :label="lab.name"
                        :value="lab._id"
                      >
                      </el-option>
                    </el-select>
                    <FormError errorName="lab_id"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="12" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Client Address</label>
                    <el-input
                      :rows="2"
                      type="textarea"
                      placeholder="Enter Client Address"
                      size="large"
                      v-model="
                        addAcknowledgementFormData.client_details[
                          'client_address'
                        ]
                      "
                      clearable
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-divider class="mt-10"></el-divider>
            <div>
              <h3 class="sub-title">Sample Details</h3>
              <el-row :gutter="20">
                <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Departments</label>
                    <el-select
                      placeholder="Select Department"
                      size="large"
                      v-model="addAcknowledgementFormData.department_name"
                      filterable
                      clearable
                      @change="setFilteredTestNames"
                    >
                      <el-option
                        v-for="(depart, index) in this.listOfDepartments"
                        :key="index"
                        :label="depart"
                        :value="depart"
                      >
                      </el-option>
                    </el-select>
                    <FormError errorName="department_name"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Test Name</label>
                    <el-select
                      placeholder="Select Test Name"
                      size="large"
                      v-model="addAcknowledgementFormData.test_name"
                      multiple
                      collapse-tags
                      clearable
                      filterable
                      default-first-option
                      allow-create
                    >
                      <el-option
                        v-for="testName in this.testNameOptions"
                        :key="testName"
                        :label="testName"
                        :value="testName"
                      ></el-option>
                    </el-select>
                    <FormError errorName="test_name"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xl="6" :lg="6" :md="10" :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label"
                      >Status <span class="star">*</span></label
                    >
                    <el-select
                      placeholder="Select Status"
                      size="large"
                      v-model="addAcknowledgementFormData.status"
                      filterable
                    >
                      <el-option
                        v-for="(type, index) in typeOptions"
                        :key="index"
                        :label="type.label"
                        :value="type.value"
                      >
                      </el-option>
                    </el-select>
                    <FormError errorName="status"></FormError>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="12"
                  :lg="12"
                  :md="12"
                  :sm="12"
                  :xs="24"
                  v-if="
                    addAcknowledgementFormData.status === 'HOLD' ||
                    addAcknowledgementFormData.status === 'REJECTED' ||
                    addAcknowledgementFormData.status === 'CRITICAL_ALERT' ||
                    addAcknowledgementFormData.status === 'REPEAT' ||
                    addAcknowledgementFormData.status === 'DELAY_IN_REPORT'
                  "
                >
                  <el-form-item>
                    <label class="form-label"
                      >Reason(s)<span class="star">*</span></label
                    >
                    <el-select
                      placeholder="Select Reason"
                      size="large"
                      v-model="addAcknowledgementFormData.reasons"
                      multiple
                      collapse-tags
                      :max-collapse-tags="3"
                      clearable
                      filterable
                      default-first-option
                      allow-create
                      :reserve-keyword="false"
                    >
                      <el-option
                        v-for="reason in this.reasonOptions"
                        :key="reason"
                        :label="reason.reason"
                        :value="reason.reason"
                      ></el-option>
                    </el-select>
                    <FormError errorName="reasons"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :xs="24">
                  <el-form-item>
                    <label class="form-label">Notes</label>
                    <el-input
                      :rows="4"
                      type="textarea"
                      size="large"
                      placeholder="Enter Notes"
                      v-model="addAcknowledgementFormData.description"
                      clearable
                    >
                    </el-input>
                    <FormError errorName="description"></FormError>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-divider
              class="mt-10"
              v-if="!this.$route.params.id"
            ></el-divider>
            <div v-if="!this.$route.params.id">
              <h3 class="sub-title">Upload Attachments</h3>
              <el-row :gutter="20">
                <el-col :sm="24">
                  <el-form-item>
                    <el-upload
                      action
                      :on-change="handleUpload"
                      :auto-upload="false"
                      :limit="1"
                      :on-remove="handleFileRemove"
                    >
                      <el-button
                        class="btn values-button type-success"
                        :disabled="isUploadButtonDisabled"
                      >
                        Upload</el-button
                      >
                    </el-upload>
                    <FormError errorName="ack_proofs"></FormError>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-divider class="mt-10"></el-divider>
            <div>
              <h3 class="sub-title">Email Details</h3>
              <el-row :gutter="20">
                <el-col :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label"
                      >To Emails <span class="star">*</span></label
                    >
                    <el-select
                      placeholder="Enter Email"
                      size="large"
                      multiple
                      filterable
                      allow-create
                      :reserve-keyword="false"
                      default-first-option
                      v-model="addAcknowledgementFormData.email_to"
                      clearable
                      collapse-tags
                      :max-collapse-tags="3"
                    >
                    </el-select>
                    <FormError errorName="email_to"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">CC Emails</label>
                    <el-select
                      placeholder="Enter Email"
                      size="large"
                      multiple
                      filterable
                      allow-create
                      :reserve-keyword="false"
                      default-first-option
                      v-model="addAcknowledgementFormData.email_to_cc"
                      clearable
                      collapse-tags
                      :max-collapse-tags="3"
                    >
                      <el-option
                        v-for="(email, index) in this.ccEmails"
                        :key="index"
                        :label="email"
                        :value="email"
                      ></el-option>
                    </el-select>
                    <FormError errorName="email_to_cc"></FormError>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :xs="24">
                  <el-form-item>
                    <label class="form-label">Reply To Emails</label>
                    <el-input
                      placeholder="Enter Email"
                      size="large"
                      v-model="addAcknowledgementFormData.reply_to"
                      clearable
                    >
                    </el-input>
                    <FormError errorName="reply_to"></FormError>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="button-group">
              <el-button @click="goBack" class="default-button">
                Cancel
              </el-button>
              <el-button
                class="default-button add-button"
                @click="updateAcknowledgement"
                v-if="this.$route.params.id"
              >
                Update
              </el-button>
              <el-button
                class="default-button add-button"
                @click="addAcknowledgement"
                v-else
              >
                Submit
              </el-button>
            </div>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <div v-if="labPopup" class="vue-modal">
    <el-dialog
      v-model="labPopup"
      title="Alert"
      style="width: calc(100% - 20px); max-width: 600px"
      :before-close="handleClose"
      class=""
    >
      <h3>
        Check the barcode used. You are using the barcode outside your centre.
      </h3>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { genderTypes } from "@/constants/formConstants";
import FormError from "@/components/FormError";
import { errorNotification } from "@/helpers/notifications";
export default {
  components: {
    FormError,
  },
  data() {
    return {
      genderOptions: genderTypes,
      loading: false,
      reasonOptions: [],
      typeOptions: [
        {
          label: "Acknowledged",
          value: "ACKNOWLEDGED",
        },
        {
          label: "Completed",
          value: "COMPLETED",
        },
        {
          label: "Critical Alert",
          value: "CRITICAL_ALERT",
        },
        {
          label: "Delay In Report",
          value: "DELAY_IN_REPORT",
        },
        {
          label: "Hold",
          value: "HOLD",
        },
        {
          label: "Provisional Report",
          value: "PROVISIONAL_REPORT",
        },
        {
          label: "Rejected",
          value: "REJECTED",
        },
        {
          label: "Repeat",
          value: "REPEAT",
        },
      ],
      addAcknowledgementFormData: {
        barcode_no: "",
        patient_details: {
          patient_name: "",
          mobile: "",
          email: "",
          gender: "",
          visit_id: "",
          patient_age: "",
          pro_mobile: "",
        },
        client_details: {
          client_address: "",
          client_email: "",
          client_name: "",
          client_code: "",
          client_phone: "",
        },
        status: "",
        description: "",
        reasons: [],
        other_reasons: [],
        email_to: [],
        email_to_cc: [],
        department_name: [],
        test_name: [],
        reply_to: "",
        ack_proofs: "",
        lab_id: "",
      },
      showMessage: false,
      barcodeRemoved: false,
      listOfDepartments: [],
      testNameOptions: [],
      testNames: [],
      ccEmails: [],
      uploadedFileCount: 0,
      message: "",
      labOptions: [],
      labPopup: false,
    };
  },
  computed: {
    ...mapGetters("acknowledgements", [
      "getAddAcknowledgements",
      "getAcknowledgementUpdateStatus",
      "getSingleAcknowledgement",
      "getBarcodeData",
      "getRejectedReasons",
      "getClientEmails",
      "getTestNames",
      "getAllDepartments",
      "getAttachmentUploadData",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    hasBarcodeData() {
      return this.addAcknowledgementFormData.barcode_no.length >= 3;
    },
    isUploadButtonDisabled() {
      return this.uploadedFileCount >= 1;
    },
    ...mapGetters("auth", ["getAllowedLabs", "getAuthenticatedUser"]),
  },
  mounted() {
    this.$store.dispatch("errors/clear");
    this.fetchAllLabs();
    this.fetchReasons();
    // this.fetchEmails();
    if (this.$route.params.id) {
      this.fetchAcknowledgement();
    }
  },
  methods: {
    async fetchAllLabs() {
      try {
        await this.$store.dispatch("auth/fetchAllAllowedLabs", {
          get_all: true,
        });
        if (this.getAllowedLabs && this.getAllowedLabs.data) {
          this.labOptions = this.getAllowedLabs.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleFileRemove() {
      this.uploadedFileCount -= 1;
    },
    async handleUpload(file) {
      this.uploadedFileCount += 1;
      let params = {
        file: file.name,
        file_type: file.raw.type,
      };
      await this.$store.dispatch("acknowledgements/uploadAttachment", {
        params,
        file,
      });

      if (this.getAttachmentUploadData) {
        this.addAcknowledgementFormData.ack_proofs = [
          this.getAttachmentUploadData.path,
        ];
      } else {
        errorNotification(this.getError || "Error while uploading");
      }
    },
    setFilteredTestNames(depts) {
      this.addAcknowledgementFormData.reply_to = "";
      this.fetchDepartment();
      this.testNameOptions = this.testNames
        .filter((e) => depts == e.SubCategoryName)
        .map((e) => e.ItemName);
      if (this.testNameOptions.length == 1) {
        this.addAcknowledgementFormData.test_name = this.testNameOptions;
      } else {
        this.addAcknowledgementFormData.test_name = [];
      }
      // let selectedReplyto = departmentEmails.find((e) => e.department_name == depts)
      // if(selectedReplyto){
      //   this.addAcknowledgementFormData.reply_to = selectedReplyto.to_reply
      // } else {
      //   this.addAcknowledgementFormData.reply_to = ""
      // }
    },
    async fetchDepartment() {
      let params = {
        department_name: this.addAcknowledgementFormData.department_name,
        lab_id: this.addAcknowledgementFormData.lab_id,
      };
      await this.$store.dispatch("acknowledgements/fetchDepartments", params);
      if (
        this.getAllDepartments &&
        this.getAllDepartments.data &&
        this.getAllDepartments.data.length > 0
      ) {
        this.addAcknowledgementFormData.reply_to =
          this.getAllDepartments.data[0].reply_to;
      }
      if (
        this.getAllDepartments &&
        this.getAllDepartments.data &&
        Array.isArray(this.getAllDepartments.data) &&
        this.getAllDepartments.data.length > 0 &&
        this.getAllDepartments.data[0].cc
      ) {
        this.addAcknowledgementFormData.email_to_cc =
          this.getAllDepartments.data[0].cc;
      }
    },
    async fetchTestNames() {
      let params = {
        barcode_no: this.addAcknowledgementFormData.barcode_no,
      };
      await this.$store.dispatch("acknowledgements/fetchAllTestNames", params);
      if (this.getTestNames && this.getTestNames.data) {
        this.testNames = this.getTestNames.data;

        this.listOfDepartments = Array.from(
          new Set(this.testNames.map((e) => e.SubCategoryName))
        );
      }
    },
    async fetchReasons() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "acknowledgements/fetchAllRejectedReasons",
        params
      );
      if (
        this.getRejectedReasons &&
        this.getRejectedReasons.data &&
        this.getRejectedReasons.data.length
      ) {
        this.reasonOptions = this.getRejectedReasons.data;
      }
    },
    async fetchEmails() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "acknowledgements/fetchAllClientEmails",
        params
      );
      if (
        this.getClientEmails &&
        this.getClientEmails.data &&
        this.getClientEmails.data.length
      ) {
        this.ccEmails = this.getClientEmails.data;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z\s]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    setClientEMail() {
      if (this.addAcknowledgementFormData.client_details["client_email"]) {
        this.addAcknowledgementFormData.email_to = [
          this.addAcknowledgementFormData.client_details.client_email,
        ];
      } else {
        this.addAcknowledgementFormData.email_to = [];
      }
    },
    async setCentre(centre_id,data) {
      await this.$store.dispatch("auth/fetchAllAllowedLabs", {
        get_all: true,
      });

      if (
        this.getAllowedLabs &&
        this.getAllowedLabs.data &&
        centre_id
      ) {
        let defaultLab = this.getAllowedLabs.data.map((lab) => lab.lab_id);
        if (defaultLab) {
          if (defaultLab.includes(centre_id)) {
            const matchingLab = this.getAllowedLabs.data.find((lab) => lab.lab_id === centre_id);
          this.addAcknowledgementFormData.lab_id = matchingLab._id;
          this.labPopup = false
          this.addAcknowledgementFormData.client_details["client_name"] =
            data.ClientName || "";
          this.addAcknowledgementFormData.client_details["client_code"] =
            data.ClientCode.toString() || "";
          this.addAcknowledgementFormData.client_details["client_phone"] =
            data.ClientMobile || "";
          this.addAcknowledgementFormData.client_details["client_email"] =
            data.ClientEmail || "";
          this.addAcknowledgementFormData.client_details["client_address"] =
            data.ClientAdd || "";
          this.addAcknowledgementFormData.patient_details["patient_name"] =
            data.PatientName || "";
          this.addAcknowledgementFormData.patient_details["mobile"] =
            data.Mobile || "";
          this.addAcknowledgementFormData.patient_details["email"] =
            data.Email || "";
          this.addAcknowledgementFormData.patient_details["gender"] =
            data.Gender || "";
          this.addAcknowledgementFormData.patient_details["visit_id"] =
            data.VisitiID || "";
          this.addAcknowledgementFormData.patient_details["patient_age"] =
            data.Age || "";
          this.addAcknowledgementFormData.patient_details["pro_mobile"] =
            data.ProMobile || "";
          if (this.data && this.data.ClientEmail) {
            this.setClientEMail();
          }
        } else {
          this.labPopup = true;
        }
      } else {
        console.log("No matching lab found for centre_id:", centre_id);
      }
      }
    },
    async getBarcode() {
      try {
        this.loading = true;
        let barcode_no = this.addAcknowledgementFormData.barcode_no;
        await this.$store.dispatch(
          "acknowledgements/fetchBarcodeData",
          barcode_no
        );
        if (this.getBarcodeData) {
          this.showMessage = false;
          if (this.getBarcodeData && this.getBarcodeData[0].CentreID) {
            this.setCentre(this.getBarcodeData[0].CentreID,this.getBarcodeData[0]);
          }                             
        } else {
          this.showMessage = true;
          this.message = "No Data Found With This Barcode";
          this.clearAcknoledgement();
          this.addAcknowledgementFormData.barcode_no = barcode_no;
        }
        this.loading = false;
        this.fetchTestNames();
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    clearAcknoledgement() {
      this.addAcknowledgementFormData = {
        barcode_no: "",
        patient_details: {
          patient_name: "",
          mobile: "",
          email: "",
          gender: "",
          patient_age: "",
          pro_mobile: "",
        },
        client_details: {
          client_address: "",
          client_email: "",
          client_name: "",
          client_code: "",
          client_phone: "",
        },
        status: [],
        description: "",
        reasons: [],
        other_reasons: [],
        email_to: [],
        email_to_cc: [],
        visit_id: "",
        lab_id: "",
      };
    },
    handleBarcodeInput() {
      if (!this.addAcknowledgementFormData.barcode_no && !this.barcodeRemoved) {
        this.clearAcknoledgement();
        this.barcodeRemoved = true;
      } else {
        this.barcodeRemoved = false;
      }
    },
    async updateAcknowledgement() {
      try {
        this.loading = true;
        let id = this.$route.params.id;
        let params = this.addAcknowledgementFormData;
        if (!params.ack_proofs) delete params.ack_proofs;
        await this.$store.dispatch("acknowledgements/updateAcknowledgement", {
          params,
          id,
        });
        if (this.getAcknowledgementUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Acknowledgement Updated Successfully",
          });
          await this.goBack();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating Acknowledgement",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchAcknowledgement() {
      try {
        this.loading = true;
        let params = this.$route.params.id;
        await this.$store.dispatch(
          "acknowledgements/fetchAcknowledgementsById",
          params
        );
        if (this.getSingleAcknowledgement) {
          let labId = this.getSingleAcknowledgement && this.getSingleAcknowledgement.lab_id && this.getSingleAcknowledgement.lab_id._id
        this.addAcknowledgementFormData = {
          ...this.addAcknowledgementFormData,
          ...this.getSingleAcknowledgement,
          lab_id: labId
        };
          this.fetchTestNames();
          // this.fetchDepartment()
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async addAcknowledgement() {
      try {
        this.loading = true;
        let params = this.addAcknowledgementFormData;
        if (!params.ack_proofs) delete params.ack_proofs;
        await this.$store.dispatch(
          "acknowledgements/addAcknowledgement",
          params
        );
        if (this.getAddAcknowledgements) {
          this.$notify.success({
            title: "Success",
            message: "Acknowledgement Sent Successfully",
          });
          await this.goBack();
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error While sending Acknowledgement",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
  },
  watch: {
  'addAcknowledgementFormData.client_details.client_email': {
    handler: 'setClientEMail',
    deep: true,
    immediate: true, // optional, if you want to trigger the handler immediately
  },
},

};
</script>

<style lang="scss">
.add-acknowledgement {
  .sub-title {
    color: #227092;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 120%;
    margin: 0 0 1rem;
  }
  .back-btn {
    color: #fff;
    color: #fff;
    border-radius: 0;
    background-color: #2f80ed;
    border: #2f80ed;
    &:active,
    &:hover,
    &:focus {
      border: 1px solid #2f80ed;
    }
  }
  .form-label {
    margin-bottom: 0.25rem;
    line-height: 1;
    font-size: 14px;
    color: #1f232e;
  }
  .default-button {
    padding: 10px 15px;
    min-height: auto;
    height: 100%;
    line-height: 1;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    min-width: 150px;
    min-height: 40px;
    &.export-button {
      background: #28b4a6;
      color: #fff;
      border: none;
      @media (max-width: 576px) {
        margin-top: 10px;
      }
    }
    &.add-button {
      background: #0061ec;
      color: #fff;
      border: none;
      i {
        margin-left: 10px;
        font-weight: 800;
        font-size: 17px;
      }
      i.left-icon {
        margin-left: 0;
      }
    }
  }
  .el-divider {
    margin-inline: -20px;
    width: calc(100% + 40px);
  }

  .star {
    color: red;
  }

  .button-group {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 1.5rem 0;
  }
}
.vue-modal {
  .el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      font-size: 20px;
      padding: 10px 10px 5px;
      background: #2eaf9f;
      border-radius: 10px 10px 0 0;
      margin: 0;
      .el-dialog__title {
        color: #ffffff;
        font-weight: 500;
      }
    }
    .el-dialog__headerbtn {
      border: 1px solid #dcdce4;
      border-radius: 3px;
      padding: 0 5px;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-dialog__close {
        color: #ffffff;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      padding-bottom: 10px;
      padding-top: 10px;

      .input-d-flex {
        display: grid;
        grid-template-columns: 125px auto;
        align-items: center;
        margin: 10px 0;
        .name {
          color: #000000;
          font-size: 16px;
          margin: 0;
          font-weight: 500;
        }
        .value {
          font-weight: 500;
          color: #219388;
        }
        .el-input__inner {
          width: 80%;
        }
      }
    }
    .dialog-footer {
      .submit-btn {
        background: #0061ec;
        color: #fff;
        font-weight: 500;
        border: none;
      }
    }

    .err {
      color: red;
      white-space: nowrap;
    }
  }
}
</style>
