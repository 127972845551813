<template>
  <div id="add-user" v-loading="loading">
    <div class="add-users-card">
      <el-form class="vue-data" :model="addUserFormData">
        <el-row :gutter="20">
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text">
                User Type
                <span class="star">*</span>
              </label>

              <el-select
                v-model="addUserFormData.user_type"
                placeholder="Select Usertype"
                clearable
              >
                <el-option
                  v-for="(user, index) in userOptions"
                  :key="index"
                  :label="user.name"
                  :value="user.value"
                ></el-option>
              </el-select>
              <FormError errorName="user_type"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text"> Department </label>
              <el-select
                v-model="addUserFormData.departments"
                placeholder="Select Department"
                multiple
                collapse-tags
                clearable
              >
                <el-option
                  v-for="(user, index) in departmentOptions"
                  :key="index"
                  :label="user.label"
                  :value="user.value"
                ></el-option>
              </el-select>
              <FormError errorName="departments"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text">Centres</label>
              <el-select
                v-model="addUserFormData.allowed_labs"
                placeholder="Select Centre"
                multiple
                collapse-tags
                clearable
              >
                <el-option
                  v-for="(lab, index) in labOptions"
                  :key="index"
                  :label="lab.name"
                  :value="lab._id"
                ></el-option>
              </el-select>
              <FormError errorName="allowed_labs"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text">
                First Name
                <span class="star">*</span>
              </label>
              <el-input
                v-model="addUserFormData.first_name"
                placeholder="Enter First Name"
              ></el-input>
              <FormError errorName="first_name"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item label="" class="mb-05 form-mandatory-field">
              <label for="text">
                Last Name
                <span class="star">*</span>
              </label>
              <el-input
                v-model="addUserFormData.last_name"
                placeholder="Enter Last Name"
              ></el-input>
              <FormError errorName="last_name"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text"> Gender </label>
              <el-select
                v-model="addUserFormData.gender"
                placeholder="Select Gender"
                clearable
              >
                <el-option
                  v-for="(gender, index) in genderTypes"
                  :key="index"
                  :label="gender"
                  :value="gender"
                ></el-option>
              </el-select>
              <FormError errorName="gender"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text">
                Mobile
                <span class="star">*</span>
              </label>
              <el-input
                v-model="addUserFormData.mobile"
                maxLength="10"
                placeholder="Enter Mobile Number"
              ></el-input>
              <FormError errorName="mobile"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text"> Age </label>
              <el-input
                v-model="addUserFormData.age"
                placeholder="Enter Age"
              ></el-input>
              <FormError errorName="age"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text">
                Email
                <span class="star">*</span>
              </label>
              <el-input
                v-model="addUserFormData.email"
                placeholder="Enter Email"
              ></el-input>
              <FormError errorName="email"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text"> Zip </label>
              <el-input
                maxLength="6"
                v-model="addUserFormData.address.zip"
                placeholder="Enter Zip"
              ></el-input>
            </el-form-item>
            <FormError errorName="zip"></FormError>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text"> City </label>
              <el-input
                v-model="addUserFormData.address.city"
                placeholder="Enter City"
              ></el-input>
            </el-form-item>
            <FormError errorName="city"></FormError>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text"> State </label>
              <el-input
                v-model="addUserFormData.address.state"
                placeholder="Enter State"
              ></el-input>
            </el-form-item>
            <FormError errorName="state"></FormError>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text">
                Username
                <span class="star">*</span>
              </label>
              <el-input
                v-model="addUserFormData.username"
                placeholder="Enter Username"
              ></el-input>
              <FormError errorName="username"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" v-if="!this.userId">
            <el-form-item>
              <label for="text">
                Password
                <span class="star">*</span>
              </label>
              <el-input
                v-model="addUserFormData.password"
                placeholder="Enter Password"
              ></el-input>
              <FormError errorName="password"></FormError>
            </el-form-item>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12">
            <el-form-item>
              <label for="text"> Designation </label>
              <el-input
                v-model="addUserFormData.designation"
                placeholder="Enter Designation"
              ></el-input>
              <FormError errorName="designation"></FormError>
            </el-form-item>
          </el-col>
          <el-col
            :xl="3"
            :lg="4"
            :md="8"
            :sm="12"
            v-if="
              addUserFormData.user_type &&
              addUserFormData.user_type === 'DOCTOR'
            "
          >
            <el-form-item>
              <label for="text"> Signature </label>
              <el-input
                v-model="addUserFormData.signature_name"
                placeholder="Enter Signature"
              ></el-input>
            </el-form-item>
            <FormError errorName="signature_name"></FormError>
          </el-col>
          <el-col
            :xl="3"
            :lg="5"
            :md="8"
            :sm="12"
            v-if="
              addUserFormData.user_type &&
              addUserFormData.user_type === 'DOCTOR'
            "
          >
            <el-form-item class="add-digital-signature" :loading="imageLoading">
              <label for="text"> Digital Signature </label>
              <el-upload
                class="avatar-uploader"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="onChangeImage"
                accept="image/*"
              >
                <img
                  v-if="addUserFormData.digital_signature"
                  :src="addUserFormData.digital_signature"
                  class="avatar-preview"
                />
                <el-icon v-else class="avatar-uploader-icon">
                  <el-icon><Picture /></el-icon>
                </el-icon>
              </el-upload>
              <el-button
                type="danger"
                class="clear-signature"
                v-if="addUserFormData.digital_signature"
                @click="addUserFormData.digital_signature = ''"
                >Clear</el-button
              >
              <FormError errorName="digital_signature"></FormError>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="action-btn">
        <FormError errorName="critical_error"></FormError>
      </div>
      <div class="action-btn">
        <ul class="each-btn">
          <li>
            <el-button class="btn btn-cancel" @click="cancelUser()"
              >Cancel</el-button
            >
          </li>
          <li>
            <el-button class="btn btn-add" @click="addUser()">
              {{ this.userId ? "Update User" : "Add User" }}
            </el-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { genderTypes, users, departments } from "@/constants/formConstants";
import { mapGetters } from "vuex";
import FormError from "@/components/FormError";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      loading: false,
      imageLoading: false,
      genderTypes: genderTypes,
      userOptions: users,
      departmentOptions: departments,
      addUserFormData: {
        first_name: "",
        last_name: "",
        gender: "",
        age: "",
        email: "",
        mobile: "",
        user_type: "",
        username: "",
        password: "",
        designation: "",
        signature_name: "",
        digital_signature: "",
        address: {
          state: "",
          city: "",
          zip: "",
        },
        departments: [],
        allowed_labs:[]
      },
      labOptions:[]
    };
  },
  components: {
    FormError,
  },
  computed: {
    ...mapGetters("auth", ["getAddNewUser", "getUserById", "getUpdateUser","getAllowedLabs"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    userId() {
      return this.$route.params.user_id;
    },
  },
  async mounted() {
    this.$store.dispatch("errors/clear");
    this.fetchAllLabs()
    if (this.$route.params.user_id) {
      await this.fetchUserDetailsById();
    }
  },
  methods: {
    async fetchAllLabs(){
      try {
        await this.$store.dispatch("auth/fetchAllAllowedLabs",{
          get_all:true
        })
        if(this.getAllowedLabs && this.getAllowedLabs.data){
          this.labOptions = this.getAllowedLabs.data
        }
      } catch(error){
        console.log(error)
      }
    },
    async fetchUserDetailsById() {
      try {
        this.loading = true;
        let params = {
          user_id: this.userId,
        };
        await this.$store.dispatch("auth/fetchUserById", params);
        if (this.getUserById) {
          let allowed_labs = this.getUserById && this.getUserById.allowed_labs.map((lab) => lab._id)
          this.addUserFormData = {
            ...this.addUserFormData,
            ...this.getUserById,
            allowed_labs:allowed_labs
          };
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    scrollIntoFirstError() {
      var el = this.$el.getElementsByClassName("error")[0];
      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    cancelUser() {
      this.$router.push({ name: "UsersList" });
    },
    onChangeImage(image) {
      try {
        if (image.size / Math.pow(1024, 2) > 5) {
          ElMessage.error("Digital Signature size can not exceed 5MB!");
          return false;
        }
        this.imageLoading = true;
        const reader = new FileReader();

        reader.onloadend = () => {
          this.addUserFormData.digital_signature = reader.result;
        };
        reader.readAsDataURL(image.raw);
        this.imageLoading = false;
      } catch (err) {
        this.imageLoading = false;
        console.log(err);
      }
    },
    parseUserInfo(userData) {
      let params = { ...userData };
      if (!params.departments) delete params.departments;
      if (!params.gender) delete params.gender;
      if (!params.age) delete params.age;
      if (!params.zip) delete params.zip;
      if (!params.city) delete params.city;
      if (!params.state) delete params.state;
      if (!params.designation) delete params.designation;
      if (!params.signature_name) delete params.signature_name;
      if (!params.digital_signature) delete params.digital_signature;
      if(!params.allowed_labs) delete params.allowed_labs

      return params;
    },
    async addUser() {
      if (this.userId) {
        this.updateUser();
      } else {
        this.addNewUser();
      }
    },
    async addNewUser() {
      try {
        this.loading = true;
        let params = this.parseUserInfo(this.addUserFormData);
        await this.$store.dispatch("auth/addUserAccount", params);
        if (this.getAddNewUser) {
          this.$notify.success({
            title: "Success",
            message: "User Addedd Successfully",
          });

          this.$router.push("/users");
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Adding User",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async updateUser() {
      try {
        this.loading = true;
        let params = this.parseUserInfo(this.addUserFormData);
        params.user_id = this.userId;
        await this.$store.dispatch("auth/updateUserAccount", params);
        if (this.getUpdateUser) {
          this.$notify.success({
            title: "Success",
            message: "User Details Updated Successfully!",
          });

          this.$router.push("/users");
        } else if (this.getErrors) {
          this.scrollIntoFirstError();
        } else if (!this.getErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error while Updating User",
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
#add-user {
  .add-users-card {
    background: #ffffff;
    border: 1.2px solid #e7e7f7;
    border-radius: 6.40567px;
    padding: 20px;
    .vue-data {
      .el-form-item {
        margin-bottom: 25px !important;
      }
      label {
        display: block;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        line-height: 100%;
        color: #000000;
      }
      .el-input__inner {
        background: #ffffff;
        padding-right: 25px !important;
        padding-left: 5px;
        color: #000;
        border: 0.922417px solid rgba(156, 156, 156, 0.5);
        font-weight: 500;
        font-size: 13px;
        height: 28px;
        box-sizing: border-box;
        border-radius: 3.56227px;
        height: 30px !important;
      }
      .el-select__caret {
        height: 30px;
        line-height: 34px;
      }
    }
  }
  .action-btn {
    .each-btn {
      display: flex;
      justify-content: flex-end;
      .btn {
        background: #ffffff;
        border: none;
        box-sizing: border-box;
        min-height: 30px;
        margin: 0 10px;
        padding: 0px 20px;
        line-height: 4px;
        border-radius: 7.39894px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 5px;
        color: #4f4f4f;
        &.btn-cancel {
          background: #ffffff;
          color: #000000;
          border: 0.719521px solid #dedbdb;
          border-radius: 5px;
        }
        &.btn-add {
          background: #0061ec;
          color: #ffffff;
        }
      }
    }
  }
  .add-digital-signature {
    .clear-signature {
      position: absolute;
      right: 0;
      top: -10px;
      padding: 2px 10px;
      min-height: auto;
    }
    .avatar-uploader {
      margin: 10px 0;
    }
    .el-icon-picture-outline {
      align-items: center;
      font-size: 40px;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  .star {
    color: red;
  }
}
.el-select-dropdown__item:hover {
  background: #0a5da6 !important;
  color: #f8f7fa !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
</style>
